<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            <div class="white-container">
                <div class="row white-container-body">
                    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-12">
                                <h1 class="patient-center-title">Selecione a data da consulta:</h1>
                            </div>
                            <div class="col-12 date-choice">
                                <mat-form-field>
                                    <mat-label>Escolha o dia da consulta</mat-label>
                                    <input [min]="minDate" matInput [matDatepicker]="picker" (dateInput)="getListOfAvailableTimes()" [formControl]="selectedDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <hr />
                            <div class="col-12">
                                <h1 class="patient-center-title">Profissionais:</h1>
                            </div>                 
                            <div *ngIf="this.listOfAvailableDayTimesAndAttendantBySchedule && this.listOfAvailableDayTimesAndAttendantBySchedule.length == 0">
                                <div class="col-12 none-list">                                    
                                    <mat-icon>warning</mat-icon>
                                    <p>Não há especialistas atendendo nesse dia.</p>
                                </div>
                            </div> 
                            <div *ngIf="this.listOfAvailableDayTimesAndAttendantBySchedule && this.listOfAvailableDayTimesAndAttendantBySchedule.length > 0">
                                <div class="col-12" *ngFor="let item of this.listOfAvailableDayTimesAndAttendantBySchedule">
                                    <div class="user-list">
                                        <div class="user-icon-data">
                                            <mat-icon aria-hidden="false" aria-label="Conta">account_circle</mat-icon>
                                            <div class="user-data">
                                                <p style="font-weight: 600">
                                                    {{item.attendantName}} 
                                                    <span *ngIf="patientAppointmentSession && patientAppointmentSession.medicalSpecialtyName">
                                                        - {{patientAppointmentSession.medicalSpecialtyName}}
                                                    </span>
                                                </p>
                                                <p *ngIf="item.userCouncilNumber" style="font-size: 13px">
                                                    <span *ngIf="item.userCouncilUF">
                                                        CRM - {{item.userCouncilUF}}: {{item.userCouncilNumber}}
                                                    </span>
                                                    <span *ngIf="!item.userCouncilUF">
                                                        CRM: {{item.userCouncilNumber}}
                                                    </span>
                                                </p>
                                            </div>                                            
                                        </div>
                                        <div class="input-radio-list" *ngIf="item.listDayTimesStruct && item.listDayTimesStruct.length > 0">
                                            <div class="input-radio-item" *ngFor="let itemDayTimes of item.listDayTimesStruct">      
                                                <input [disabled] = "itemDayTimes.isOccupied || itemDayTimes.isSmallerNow" value="{{itemDayTimes.attendanceHour}}" type="radio" name="attendanceHourGroup" id="radio-{{item.schedule.idSchedule}}-{{itemDayTimes.attendanceHour}}"  (click)="selectionHourAndUser(item, itemDayTimes)">
                                                <label [ngClass]="{'disabled-occupied-label': itemDayTimes.isOccupied && !itemDayTimes.isSmallerNow, 'disabled-smaller-label':itemDayTimes.isSmallerNow}" for="radio-{{item.schedule.idSchedule}}-{{itemDayTimes.attendanceHour}}">{{itemDayTimes.attendanceHour}}</label>
                                            </div>
                                        </div>                            
                                        <div class="none-list" *ngIf="item.listDayTimesStruct && item.listDayTimesStruct.length == 0">
                                            <mat-icon aria-hidden="false" aria-label="Alerta">warning</mat-icon>
                                            <p>Não existem horários disponíveis para a data selecionada.</p>
                                        </div>
                                    </div>                        
                                </div>
                            </div>  
                        </div> 
                        <button mat-flat-button color="primary" class="button-submit" [disabled]="model.invalid">
                            <span *ngIf="isLoading == false">Seguir com o agendamento</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                        <button mat-flat-button type="button" color="secundary" class="button-back" (click)="backBegin()">
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>                       
                    </form> 
                    <div class="footer-white-container">
                        <p>Central do paciente</p>
                    </div>
                </div>                
            </div>
        </div>
    </body>
</div>