import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LoginComponent } from './pages/login/login.component';
import { PatientQueueInfoComponent } from './pages/patient-queue-info/patient-queue-info.component';
import { LoginFailedModalComponent } from './pages/welcome/login-modal/login-failed-modal/login-failed-modal.component';
import { LoginCheckAgainModalComponent } from './pages/welcome/login-modal/login-check-again-modal/login-check-again-modal.component';
import { LoginModalComponent } from './pages/welcome/login-modal/login-modal.component';
import { LoginVerifyModalComponent } from './pages/welcome/login-verify-modal/login-verify-modal.component';
import { NoEpisodeComponent } from './pages/welcome/no-episode/no-episode.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [WelcomeComponent,
    NoEpisodeComponent,
    LoginComponent,
    PatientQueueInfoComponent,
    LoginFailedModalComponent,
    LoginCheckAgainModalComponent,
    LoginModalComponent,
    LoginVerifyModalComponent
  ],
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    FormsModule
  ],
  providers: [
    AuthGuard
  ],
})
export class WelcomeModule { }
