import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;

  ngOnInit(): void {
    //this.alertService.show("Titulo", "Production: " + environment.production + " - Staging: " + environment.staging , AlertType.success);

    this.isLoading = false;

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  singin() {
    if (this.model.invalid)
      return;

    this.isLoading = true;

    this.authService.signIn(this.model.get('login').value, this.model.get('password').value, '192.168.0.2')
      .subscribe((response) => {
          //ToDo
      },
        (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
        });
  }
}
