import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-room-confirm-modal',
  templateUrl: './leave-room-confirm-modal.component.html',
  styleUrls: ['./leave-room-confirm-modal.component.css']
})
export class LeaveRoomConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<LeaveRoomConfirmModalComponent>,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({leave: false}); 
  }
  
  clickConfirm(){
    this.matDialogRef.close({leave: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({leave: false}); 
  }
}
