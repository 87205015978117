import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WebsocketPatientService } from '../../services/websocket-patient.service';

@Component({
  selector: 'app-alert-header',
  templateUrl: './alert-header.component.html',
  styleUrls: ['./alert-header.component.css']
})
export class AlertHeaderComponent implements OnInit, OnChanges {

  constructor(private webSocketPatientService: WebsocketPatientService) { }

  @Input() idEpisode: number;
  @Input() healthUnitName: string;

  public nameRoom: string;

  private socket: any;

  ngOnInit(): void {
    if (this.idEpisode) {
      this.startWebsocket(this.idEpisode);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idEpisode) {
      if (!changes.idEpisode.currentValue) return;
      if (changes.idEpisode.currentValue !== changes.idEpisode.previousValue) {
        this.startWebsocket(changes.idEpisode.currentValue);
      }
    }
  }

  startWebsocket(idEpisode: number) {
    this.socket = this.webSocketPatientService.websocketConnection();
    this.socket.emit('join', `idEpisode-${idEpisode}`)
      .on('clientPatient', (res: ({ nameRoom: string, nameSector: string })) => this.alertHeader(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idEpisode-${this.idEpisode}`);
      });
  }

  ngOnDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  alertHeader(res: ({ nameRoom: string, nameSector: string })) {
    this.nameRoom = res.nameRoom;
    setTimeout(() => {
      this.nameRoom = null;
    }, 300000)
  }
}
