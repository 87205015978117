import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-queue-info',
  templateUrl: './patient-queue-info.component.html',
  styleUrls: ['./patient-queue-info.component.css']
})
export class PatientQueueInfoComponent implements OnInit {
  
  constructor( ) { }

  public listQueueHistory: any[] = [];
 
  ngOnInit(): void {
    this.listQueueHistory.push(({teste: "Teste"}))
  }

  ngOnDestroy(): void{
  }
}
 