export class UserPatientRequest {
    public idUserPatient: number;

    public idPatient: number;

    public idHealthUnit: number;
    
    public listIdHealthUnitInGroup: number[];

    public cpf: string;

    public idGender: number;

    public password: string;

    public phoneNumber: string;

    public userName: string;

    public motherName: string;

    public userBirthDate: Date;

    public userEmail: string;

    public isActive: boolean;
}