import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.css']
})
export class CancelModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<CancelModalComponent>,
  private alertService: AlertService,) { }
  
  public isLoading:boolean;  
  public colorCode: string;

  ngOnInit(): void {
    this.isLoading = false;
    this.colorCode = this.data.colorCode;
  }
  
  close(){
    this.matDialogRef.close(false); 
  }

  clickReload() {
    this.matDialogRef.close(true);
  }

}