import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-absent-medical-alert-modal',
  templateUrl: './absent-medical-alert-modal.component.html',
  styleUrls: ['./absent-medical-alert-modal.component.css']
})
export class AbsentMedicalAlertModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<AbsentMedicalAlertModalComponent>,
  ) { }

  public isLoading: boolean;
  public isLastWarning: boolean = false;
  public colorCode: string = '#99C8D6';

  ngOnInit(): void {
    this.isLoading = false;

    if (this.data.colorCode) {
      this.colorCode = this.data.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    this.isLastWarning = this.data.isLastWarning;
    if(this.isLastWarning)
      setTimeout(() => {this.close();}, 20000);
    else
      setTimeout(() => {this.close();}, 60000);
  }

  close() {
    this.matDialogRef.close({ keepWaiting: false }); 
  }

  keepWaiting() {
    this.matDialogRef.close({ keepWaiting: true });
  }

}
