import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetConfigResponse } from '../../responses/telemedicine/get-config.response';
import { TelemedicineExternalSearchRequest } from '../../requests/telemedicine/external-search.request';
import { ExternalSearchResponse } from '../../responses/telemedicine/external-search.response';

@Injectable({
    providedIn: 'root'
})

export class ClientTelemedicineService extends CommonService {

    constructor(private httpClient: HttpClient) { super(); }

    public getClientConfig(uriClient: string): Observable<GetConfigResponse> {
        let uri = `ClientTelemedicine/config/${uriClient}`

        return this.httpClient.get<GetConfigResponse>(environment.urlApiOrchestratorTelemedicine + uri, this.addHeaderInternalToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public externalSearch(request: TelemedicineExternalSearchRequest): Observable<ExternalSearchResponse> {
        let uri = `ClientTelemedicine/`

        return this.httpClient.post<ExternalSearchResponse>(environment.urlApiOrchestratorTelemedicine + uri, request, this.addHeaderInternalToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}