import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostTelemedicineResponse } from '../../responses/orchestrator-telemedicine/get-telemedicine.response';

@Injectable({
  providedIn: 'root'
})

export class IntegrationTelemedicineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getPatientDataGuid(idHealthUnit: number, guid: string): Observable<PostTelemedicineResponse> {
    let uri = `IntegrationTelemedicine/idHealthUnit/${idHealthUnit}/hash/${guid}`;

    return this.httpClient.get<PostTelemedicineResponse>(environment.urlApiOrchestratorTelemedicine + uri, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}