import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListCityResponse } from '../../responses/user/list-city.response';
import { GetHealthUnitResponse } from '../../responses/user/get-health-unit.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public GetHealthUnitCity(): Observable<GetHealthUnitResponse> {

    let uri = 'HealthUnitPatientCenter';

    return this.httpClient.get<GetHealthUnitResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getHealthUnit(idHealthUnit: number): Observable<GetHealthUnitResponse> {
    let uri = `ClientHealthUnit/idHealthUnit/${idHealthUnit}`
    return this.httpClient.get<GetHealthUnitResponse>(environment.urlApiUser + uri, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    