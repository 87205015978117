import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})

export class WelcomeComponent implements OnInit {
  constructor(private utilSelfTriageService: UtilsSelfTriageService,
    public router: Router,
  ) { }
  
  @Input() preview: boolean = false;

  public patientCenterAccessSelfTriage: boolean = false;
  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public selfTriageConfig: SelfTriageConfigStruct;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.welcome;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();
    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();

    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();
    
    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }
  }

  clickGo() {
    if(this.preview)
      return
    
    this.router.navigate([`self-triage/orientations/${this.utilSelfTriageService.getUri()}`]);
  }
}