import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { EpisodePreEvaluation } from 'src/app/shared/services/models/telemedicine/episode-pre-evaluation.model';
import { SymptomDuration } from 'src/app/shared/services/models/telemedicine/symptom-duration.model';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-pre-evaluation',
  templateUrl: './pre-evaluation.component.html',
  styleUrls: ['./pre-evaluation.component.css']
})
export class PreEvaluationComponent implements OnInit {

  @Input() colorCode: string;
  @Input() symptomDurationList: SymptomDuration[]
  @Input() episodePreEvaluation: EpisodePreEvaluation;
  @Output() next = new EventEmitter<any>();
  @Output() updatePreEvaluation = new EventEmitter<EpisodePreEvaluation>();

  constructor(private formBuilder: FormBuilder,
    private utilsTelemedicineService: UtilsTelemedicineService,
  ) { }

  public model: FormGroup;
  public maxLength = 200;
  public flowEnum = TelemedicineFlowEnum.preEvaluation;

  ngOnInit(): void {
    document.documentElement.style.setProperty('--colorCodeTelemedicine', this.colorCode);
    this.model = this.formBuilder.group({
      symptomDescription: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
      symptomDuration: [null, [Validators.required]]
    });

    this.utilsTelemedicineService.setOldFlowControl(TelemedicineFlowEnum.preEvaluation);
  }

  submit() {
    if (this.model.invalid)
      return;

    this.episodePreEvaluation.idSymptomDuration = this.model.get('symptomDuration').value;
    this.episodePreEvaluation.symptomDescription = this.model.get('symptomDescription').value;

    this.forward();
  }

  forward() {
    this.next.emit();
    this.updatePreEvaluation.emit(this.episodePreEvaluation);
  }
}