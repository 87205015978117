<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h2>{{this.data.title}}</h2>
        </div>
         
        <div class="body-modal">
            {{this.data.description}}         
        </div>
        <div class="footer-modal">
            <button mat-flat-button color="primary" class="btn-block btn-confirm" (click)="clickDelete()">
                <span *ngIf="isLoading == false">Sim, excluir</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="secundary" class="btn-block btn-cancel" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>