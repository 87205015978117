export class PostPatientPreAppointmentRequest {
    public idSchedule: number;
    public datetimeStart: Date;
    public datetimeEnd: Date;
    public idUser: number;
    public idMedicalSpecialty: number;
    public idPatientHealthcareAgreement: number;
    public idTypeOfCare: number;
    public timeZoneValue: number;
    public idHealthcareAgreement: number;
    public idHealthcareAgreementPlan: number;
    public healthcareAgreementCardNumber: string;
    public healthcareAgreementExpirationDate: Date;
}