export class PatientAppointmentSessionStruct {
    public idSchedule: number;
    public datetimeStart: Date;
    public datetimeEnd: Date;
    public idUser: number;
    public idMedicalSpecialty: number;
    public idPatientHealthcareAgreement: number;
    public idTypeOfCare: number;
    public healthUnitName: string;
    public medicalSpecialtyName: string;
    public typeOfCareName: string;
    public crmUser: string;
    public userCouncilUF: string;
    public userName: string;
    public timeZoneValue: number;
    public isCheckedNoHealthPlan: boolean;
    public idHealthcareAgreement: number;
    public idHealthcareAgreementPlan: number;
    public healthcareAgreementCardNumber: string;
    public nameHealthcareAgreement: string;
    public healthcareAgreementExpirationDate: Date;
}