import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListHealthcareAgreementResponse } from '../../responses/private-billing/list-healthcare-agreement.response';
import { ListPlanResponse } from '../../responses/private-billing/list-plan.response';

@Injectable({
  providedIn: 'root'
})
export class HealthcareAgreementPlanService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAllToSelect(searchText?: string, maxSizeSelect?: number, isActiveHealthcareAgreement: boolean = null): Observable<ListHealthcareAgreementResponse> {
    let uri = `HealthcareAgreementPlan/getAllToSelect?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (maxSizeSelect)
      uri = uri + `maxSizeSelect=${maxSizeSelect}&`;

    if (isActiveHealthcareAgreement == true || isActiveHealthcareAgreement == false)
      uri = uri + `isActiveHealthcareAgreement=${isActiveHealthcareAgreement}`;

    return this.httpClient.get<ListHealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllToSelectById(idHealthcareAgreement: number, searchText?: string, maxSizeSelect?: number): Observable<ListPlanResponse> {
    let uri = `HealthcareAgreementPlan/getAllByIdHealthcareAgreement?idHealthcareAgreement=${idHealthcareAgreement}`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (maxSizeSelect)
      uri = uri + `maxSizeSelect=${maxSizeSelect}`;

    return this.httpClient.get<ListPlanResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}