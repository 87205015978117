<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">
                <h1 class="self-triage-title">Avaliação</h1>
                <hr />
                <div *ngIf="!flowchartSelected" class="row">
                    <div class="col-12 title">
                        <h2>Selecione o principal sintoma que você tem neste momento e diremos o que fazer a seguir:
                        </h2>
                    </div>
                </div>
                <div *ngIf="!flowchartSelected && protocol" class="row div-of-child">
                    <app-flowchart [protocol]="protocol" [colorCode]="colorCode"
                        (selectedFlowchart)="selectedFlowchart($event)"></app-flowchart>
                </div>
                <div *ngIf="flowchartSelected && !selectedCounseling" class="row div-of-child">
                    <app-question (goBack)="goBack()" (selectedQuestionFunction)="selectedQuestion($event)"
                        (selectCounseling)="selectCounseling($event)" [currentQuestion]="question"
                        [flowchartName]="flowchart.name" [colorCode]="colorCode"></app-question>
                </div>
                <div *ngIf="selectedCounseling" class="success">
                    <img class="welcome-img" src="assets/images/self-triage/endOfCounseling.png" />
                    <span class="sentence-of-end-evaluation">
                        Avaliação concluída com sucesso!
                    </span>
                    <div class="row">
                        <div class="col-6">
                            <button mat-flat-button color="secundary" class="button-back" (click)="goBack()">
                                <span *ngIf="isLoading == false">Retornar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                        <div class="col-6">
                            <button mat-flat-button color="primary" class="button-go" (click)="finishFlowchart()">
                                <span *ngIf="isLoading == false">Próximo</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>
            </div>
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>

        </div>
    </body>
</div>