import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientTypeStatusEnum } from 'src/app/shared/enum/patient-type-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { QrBaseAccessService } from 'src/app/shared/services/API/orchestrator-queue/qr-base-access.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { LoginCheckPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/login-check-patient.request';
import { PatientCompleteDataStruct } from 'src/app/shared/structs/patient.complete.data.struct';


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<LoginModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private qrBaseAccess: QrBaseAccessService,
    private alertService: AlertService,
    private maskService: MaskService) { }

  public isLoading: boolean;
  public searchText: string;
  public model: UntypedFormGroup;
  public CPF: string;
  public guid: string;
  public birthdate: Date;
  public motherName: string;
  public idLoginPassType: number;
  public masks: Masks;
  public patientTypeStatusEnum: PatientTypeStatusEnum;
  public loginCheckPatientRequest: LoginCheckPatientRequest = new LoginCheckPatientRequest();
  public patientCompleteDataStruct: PatientCompleteDataStruct;
  public searchModel: UntypedFormGroup;
  public patientTypeBirthDate: PatientTypeStatusEnum = PatientTypeStatusEnum.BirthDate;
  public patientTypeCPF: PatientTypeStatusEnum = PatientTypeStatusEnum.CPF;
  public patientTypeMotherName: PatientTypeStatusEnum = PatientTypeStatusEnum.MotherName;

  ngOnInit(): void {
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.idLoginPassType = this.data.idLoginPassType;
    this.patientTypeStatusEnum = null;
    this.model = this.formBuilder.group({
      birthDate: [''],
      CPF: [''],
      motherName: ['']
    });
  }

  search() {
    this.isLoading = true;
    let loginCheckPatientRequest = new LoginCheckPatientRequest();

    if (this.idLoginPassType == PatientTypeStatusEnum.BirthDate) {
      loginCheckPatientRequest.birthDate = new Date(this.formatDate(this.model.get("birthDate").value));
    }
    else if (this.idLoginPassType == PatientTypeStatusEnum.CPF) {
      loginCheckPatientRequest.CPF = this.model.get("CPF").value.replace(/\D/g, "");
    }
    else if (this.idLoginPassType == PatientTypeStatusEnum.MotherName) {
      loginCheckPatientRequest.motherName = this.model.get("motherName").value;
    }

    this.qrBaseAccess.getPatientData(this.data.guid, loginCheckPatientRequest).subscribe((response) => {
      this.matDialogRef.close({ response, loginCheckPatientRequest });
    },
      (error) => {
        this.isLoading = false;
        this.model.get("CPF").setValue(null);
        this.model.get("motherName").setValue(null);
        this.model.get("birthDate").setValue(null);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  formatDate(dateUnformatted: string): string {
    if (dateUnformatted) {
      let darr = dateUnformatted.split("/");  //2019-10-01
      let dobj = darr[2] + "-" + darr[1] + "-" + darr[0];
      return dobj;
    }
    return null;
  }
}