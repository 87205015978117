import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { ClientDigitalPromptServiceConfigService } from 'src/app/shared/services/API/digital-prompt-service/client-digital-prompt-service-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { UserPatient } from 'src/app/shared/services/models/patient-center/user-patient.model';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { AppointmentDetailsModalComponent } from './appointment-details-modal/appointment-details-modal.component';
import { PatientAppointmentStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-appointment.struct';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { AppointmentDataStruct } from 'src/app/shared/services/structs/schedule/appointment-data.struct';
import { OutOfHoursAppointmentModalComponent } from 'src/app/shared/components/out-of-hours-appointment-modal/out-of-hours-appointment-modal.component';
import { MinutesEnum } from 'src/app/shared/enum/minutes.enum';
import { CreateAppointmentCallService } from 'src/app/shared/services/API/orchestrator-schedule/create-appointment-call.service';
import { AppointmentStatusEnum } from 'src/app/shared/enum/appointment-status.enum';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { PatientCenterAppointmentService } from 'src/app/shared/services/API/orchestrator-schedule/orchestratort-patient-appointment.service';

@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.css']
})
export class PatientAppointmentComponent implements OnInit {
  constructor(
    private patientAppointmentOrchService: PatientCenterAppointmentService,
    private patientAppointmentService: PatientAppointmentService,
    private alertService: AlertService,
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private clientDigitalPromptServiceConfigService: ClientDigitalPromptServiceConfigService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private createAppointmentCallService: CreateAppointmentCallService,
  ) { }

  public masks: Masks;
  public isLoading: boolean = false;
  public uri: string;
  public colorCode: string = '#99C8D6';
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public listFuturePatientAppointment: PatientAppointmentStruct[] = [];
  public listPastPatientAppointment: PatientAppointmentStruct[] = [];
  public patient: UserPatient;
  public standardAttendance: number = NatureOfAttendanceEnum.presencial;
  public onlineAttendance: number = NatureOfAttendanceEnum.online;

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');

    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();

    if (this.digitalPromptServiceConfig) {
      if (this.digitalPromptServiceConfig.useColor == true) {
        this.colorCode = this.digitalPromptServiceConfig.colorCode;
        document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
      }
    }
    else
      this.getConfig(this.uri);

    this.getPatientAppointment();
  }

  getConfig(uri: string) {
    this.clientDigitalPromptServiceConfigService.getClientConfig(uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.digitalPromptServiceConfig = response.digitalPromptConfig;
        this.utilsDigitalPromptServiceService.setDigitalPromptServiceStruct(response.digitalPromptConfig);

        if (response.digitalPromptConfig.useColor) {
          this.colorCode = this.digitalPromptServiceConfig.colorCode;
          document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getPatientAppointment() {
    this.isLoading = true;
    this.patientAppointmentOrchService.getPatientAppointment().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFuturePatientAppointment = [];
        this.listPastPatientAppointment = [];

        let now = new Date();
        let array = response.listPatientAppointment;

        for (let index = 0; index < array.length; index++) {
          let appointment = array[index];
          const appointmentDate = new Date(appointment.datetimeEnd);

          if (appointmentDate.getTime() >= now.getTime() && !appointment.idEpisode)
            this.listFuturePatientAppointment.push(appointment);
          else
            this.listPastPatientAppointment.push(appointment);
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  showDetails(appointment: PatientAppointment) {
    const dialogRef = this.dialog.open(AppointmentDetailsModalComponent, {
      data: {
        colorCode: this.colorCode,
        appointment: appointment,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.cancel)
          this.cancelAppointment(appointment.idPatientAppointment);
      }
    });
  }

  cancelAppointment(idPatientAppointment: number) {
    this.isLoading = true;
    this.patientAppointmentService.deletePatientAppointment(idPatientAppointment, AppointmentStatusEnum.AgendamentoCancelado).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.getPatientAppointment();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  goBack() {
    this.router.navigate([`patient-center/hub/${this.uri}`])
  }

  startAppointment(patientAppointment: PatientAppointment) {
    this.isLoading = true;

    let diff = (this.verifyDifferenceInMinutes(patientAppointment.datetimeStart));
    let newDate = new Date();
    if ((diff <= MinutesEnum.cincoMinutos) && (newDate <= new Date(patientAppointment.datetimeEnd))) {
      this.createAppointmentCallService.GetTokenTwilioVideo(patientAppointment.idPatientAppointment).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          let appointmentData: AppointmentDataStruct = new AppointmentDataStruct;
          appointmentData = response.appointmentDataStruct;

          this.utilsDigitalPromptServiceService.setAppointmentDataToTwilioSession(appointmentData);
          this.utilsDigitalPromptServiceService.setAppointmentDataSession(patientAppointment);
          this.router.navigate([`patient-center/attend-patient/video/${this.uri}`]);

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
    else if (newDate < new Date(patientAppointment.datetimeStart)) {
      var modalText = "A consulta só é liberada para a entrada 5 minutos antes de iniciar."
      this.showOutOfHoursAppointmentModal(modalText);
    }
    else if (newDate > new Date(patientAppointment.datetimeStart)) {
      var modalText = "A consulta já foi encerrada."
      this.showOutOfHoursAppointmentModal(modalText);
    }
  }

  showOutOfHoursAppointmentModal(modalText: string) {
    const dialogRef = this.dialog.open(OutOfHoursAppointmentModalComponent, {
      data: {
        colorCode: this.colorCode,
        text: modalText,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.isLoading = false;
      }
    });
  }

  verifyDifferenceInMinutes(datetimeStart: Date) {
    let todayDate: Date = new Date();
    let dateStart: Date = new Date(datetimeStart);
    var diff = (dateStart.getTime() - todayDate.getTime()) / 1000;
    diff /= 60;
    return diff;
  }
}