import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-delete-item-modal',
  templateUrl: './delete-item-modal.component.html',
  styleUrls: ['./delete-item-modal.component.css']
})
export class DeleteItemModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<DeleteItemModalComponent>,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
    if(this.data.color)
      document.documentElement.style.setProperty('--color', this.data.color);
  }
  
  close(){
    this.matDialogRef.close({deleteItem: false}); 
  }
  
  clickDelete(){
    this.matDialogRef.close({deleteItem: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteItem: false}); 
  }
}