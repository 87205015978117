import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';

@Component({
  selector: 'app-patient-hold-modal',
  templateUrl: './patient-hold-modal.component.html',
  styleUrls: ['./patient-hold-modal.component.css']
})
export class PatientHoldModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientHoldModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  public isLoading: boolean = true;
  public colorCode: string;
  public isMedic: boolean = true;
  private intervalId: any;
  public time: string;

  ngOnInit(): void {
    this.isMedic = this.data.isMedic;
    this.colorCode = this.data.colorCode ? this.data.colorCode : '#85C443';
    this.intervalId = setInterval(() => {
      this.time = this.data.websocketTelemedicineUtilService.time;
    }, 100);
    this.data.websocketTelemedicineUtilService.emitStartCount();
  }

  openModalCancel() {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        colorCode: this.colorCode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEvade) {
        this.matDialogRef.close(result);
      }
    });
  }

  init() {
    this.matDialogRef.close({ confirmAttend: true });
  }
}