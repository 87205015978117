<div class="client-body">
    <div class="white-body">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <h1>QRCode Inválido!</h1>
                    <p><b>Feche a sua guia!</b></p>
                </div>
            </div>
        </div>
    </div>
</div>