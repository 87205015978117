import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListPatientAppointmentResponse } from "../../responses/schedule/list-patient-appointment.response";
import { PatientAppointmentResponse } from "../../responses/schedule/patient-appointment.response";
import { PostPatientAppointmentRequest } from "../../requests/orchestrator-schedule/post-patient-appointment.request";

@Injectable({
    providedIn: 'root'
})
export class PatientAppointmentService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getPatientAppointmentById(idPatientAppointment: number): Observable<PatientAppointmentResponse> {

        let uri = `PatientAppointmentPatientCenter/idPatientAppointment/${idPatientAppointment}`;

        return this.httpClient.get<PatientAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getPatientAppointmentByIdUser(): Observable<ListPatientAppointmentResponse> {

        let uri = `PatientAppointmentPatientCenter/getAllByTokenUser`;

        return this.httpClient.get<ListPatientAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public postPatientAppointment(request: PostPatientAppointmentRequest): Observable<ReturnStruct> {

        let uri = `PatientCenterAppointment`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorSchedule + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public deletePatientAppointment(idPatientAppointment: number, idAppointmentStatus: number): Observable<ReturnStruct> {

        let uri = `PatientAppointmentPatientCenter/idPatientAppointment/${idPatientAppointment}/idAppointmentStatus/${idAppointmentStatus}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}