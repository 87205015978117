<div>
    <app-header></app-header>

    <body class="body-container">
        <div class="body-content">
            <div class="white-container">
                <div style="width: 100%;">
                    <h2 [style.color]="colorCode">Redefinir senha</h2>
                    <hr />
                </div>
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <p>Insira sua nova senha</p>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label class="input-label">Nova senha</mat-label>
                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                                    'visibility'}}</mat-icon>
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPassword"
                                    required>
                                <mat-error *ngIf="model.get('newPassword').invalid">Informe a nova senha</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="border-field" appearance="outline">
                                <mat-label>Repetir senha</mat-label>
                                <mat-icon matSuffix (click)="hideRepeat = !hideRepeat">{{hideRepeat ? 'visibility_off' :
                                    'visibility'}}</mat-icon>
                                <input matInput [type]="hideRepeat ? 'password' : 'text'"
                                    formControlName="repetedPassword" required>
                                <mat-error *ngIf="model.get('repetedPassword').invalid">Informe a nova senha</mat-error>
                            </mat-form-field>
                            <p [ngStyle]="{ 'color': model.get('newPassword').invalid ? '#fc0303' : '#000000' }">* Use
                                entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras
                                minúsculas, números e caracteres especiais.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="button-login">
                            <button mat-flat-button type="submit" color="primary" class="button-go btn-block">
                                <span *ngIf="isLoading == false">Redefinir Senha</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                            <button mat-flat-button type="button" routerLink="/patient-center/login" color="accent"
                                class="btn-block btn-return">
                                <span>VOLTAR</span>
                            </button>
                        </div>
                    </div>

                </form>

                <div class="footer-white-container">
                    <p>Central do Paciente</p>
                </div>

            </div>
        </div>
    </body>
</div>