<div class="container-box">
    <div class="container orientations-session">
        <img class="end-img" src="assets/images/patient-center/cancel-appointment.png" />
        <hr>

        <h2 class="patient-center-subtitle">Você tem certeza que deseja realizar o cancelamento dessa consulta?</h2>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="cancelAppointment()">
            <span *ngIf="!isLoading">SIM, CANCELAR</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
        <button mat-flat-button class="btn-secundary-flex" color="primary" type="button" (click)="goBack()">
            <span *ngIf="!isLoading">NÃO, VOLTAR</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>

</div>