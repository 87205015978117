import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { PatientHealthcareAgreementResponse } from '../../responses/patient-center/patient-healthcare-agreement.response';
import { ListPatientHealthcareAgreementResponse } from '../../responses/patient-center/list-patient-healthcare-agreement.response';
import { PatientHealthcareAgreementRequest } from '../../requests/patient-center/patient-healthcare-agreement.request';


@Injectable({
  providedIn: 'root'
})

export class PatientHealthcareAgreementPatientCenterService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getById(idPatientHealthcareAgreement: number): Observable<PatientHealthcareAgreementResponse> {
    let uri = `PatientHealthcareAgreementPatientCenter/idPatientHealthcareAgreement/${idPatientHealthcareAgreement}`;

    return this.httpClient.get<PatientHealthcareAgreementResponse>(environment.urlApiPatientCenter + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetAllByIdPatient(): Observable<ListPatientHealthcareAgreementResponse> {
    let uri = `PatientHealthcareAgreementPatientCenter/GetAllByIdPatient`;

    return this.httpClient.get<ListPatientHealthcareAgreementResponse>(environment.urlApiPatientCenter + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public CreatePatientHealthcareAgreement(request: PatientHealthcareAgreementRequest): Observable<ReturnStruct> {
    let uri = `PatientHealthcareAgreementPatientCenter`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiPatientCenter + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public UpdatePatientHealthcareAgreement(request: PatientHealthcareAgreementRequest): Observable<ReturnStruct> {
    let uri = `PatientHealthcareAgreementPatientCenter/idPatientHealthcareAgreement/${request.idPatientHealthcareAgreement}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiPatientCenter + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public DeletePatientHealthcareAgreement(idPatientHealthcareAgreement: number): Observable<ReturnStruct> {
    let uri = `PatientHealthcareAgreementPatientCenter/idPatientHealthcareAgreement/${idPatientHealthcareAgreement}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPatientCenter + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}