<div mat-dialog-content>
    <div class="mat-dialog-content">

        <h1 class="self-triage-title">{{flowchart.name}}</h1>
        <hr/>
        <div class="row results-content">
            <div class="col-12">
                <div *ngFor="let item of results;let i = index" class="question-answer-container" [ngStyle]="{'border-color': colorCode}">
                    <p class="question"><b>{{i+1}} - Pergunta:</b> <br>{{item.statement}}</p>
                    <p class="anwser"><b>Resposta:</b> {{item.answer}}</p>
                </div>
            </div>
        </div>
        <div class="row counseling"> 
            <div class="col-2 div-icon">
                <mat-icon class="counseling-icon"  aria-label="assistant">assistant</mat-icon>
            </div>
            <div class="col-10 ">
                <p><b>Aconselhamento:</b></p>
                <p>{{counseling}}</p>
            </div>
        </div>

        <div class="button-footer">
            <button mat-flat-button color="secundary" class="button-back" (click)="close()">
                <span *ngIf="isLoading == false">Fechar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        
        <div class="footer-white-container">
            <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
        </div>
        
    </div>
</div>