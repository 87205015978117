import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttendanceLocalEnum } from 'src/app/shared/enum/telemedicine/attendance-local.enum';

@Component({
  selector: 'app-ended-query',
  templateUrl: './ended-query.component.html',
  styleUrls: ['./ended-query.component.css']
})
export class EndedQueryComponent implements OnInit {

  @Input() colorCode: string;
  @Input() isMobileVersion: boolean;
  @Input() attendanceLocal: number;
  @Output() finish = new EventEmitter<any>();

  constructor() {
  }

  public attendanceLocalEnum: typeof AttendanceLocalEnum = AttendanceLocalEnum;

  ngOnInit(): void {    
  }
  
  endedQuery() {
    this.finish.emit();
  }
}