import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientSelfTriageProtocolService } from 'src/app/shared/services/API/self-triage/client-self-triage-protocol.service';
import { SelfTriageProtocolService } from 'src/app/shared/services/API/self-triage/self-triage-protocol.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DirectionButton } from 'src/app/shared/services/models/self-triage/direction-button.model';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { SelfTriageCounselingModel } from 'src/app/shared/services/models/self-triage/self-triage-counseling.model';
import { PatientRequest } from 'src/app/shared/services/requests/self-triage/patient.request';
import { SelfTriageResultRequest } from 'src/app/shared/services/requests/self-triage/self-triage-result.request';
import { GetProtocolSessionResponse } from 'src/app/shared/services/responses/self-triage/get-protocol-session.response';
import { FlowchartCompleteStruct } from 'src/app/shared/services/structs/self-triage/flowchart-complete.struct';
import { ProtocolCompleteStruct } from 'src/app/shared/services/structs/self-triage/protocol-complete.struct';
import { QuestionAnswerStruct } from 'src/app/shared/services/structs/self-triage/question-answer.struct';
import { QuestionStruct } from 'src/app/shared/services/structs/self-triage/question.struct';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.css']
})

export class ProtocolComponent implements OnInit {
  constructor(private alertService: AlertService,
    public formBuilder: FormBuilder,
    private clientSelfTriageProtocolService: ClientSelfTriageProtocolService,
    private selfTriageProtocolService: SelfTriageProtocolService,
    private maskService: MaskService,
    private utilSelfTriageService: UtilsSelfTriageService,
    public router: Router,
    private datepipe: DatePipe,
  ) { }

  public evaluationModel: FormGroup;
  public counselingModel: FormGroup;
  public masks: Masks;

  public uri: string;
  public colorCode: string = '#99C8D6';

  public selfTriageConfig: SelfTriageConfigStruct;
  public protocol: ProtocolCompleteStruct;
  public patient: Patient;
  public counseling: SelfTriageCounselingModel;
  public flowchart: FlowchartCompleteStruct;
  public question: QuestionStruct;
  public questionsHistoric: QuestionStruct[] = [];
  public results: QuestionAnswerStruct[] = [];
  public listButtons: DirectionButton[] = [];

  public patientCenterAccessSelfTriage: boolean = false;
  public flowchartSelected: boolean = false;
  public selectedCounseling: boolean = false;
  public isTriageFinished: boolean = false;
  public protocolSelected: boolean = false;
  public isLoading: boolean = false;

  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.evaluation;
  public idSelfTriagePatient: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();
    this.uri = this.utilSelfTriageService.getUri();
    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();

    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();

    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }

    this.patient = this.utilSelfTriageService.getPatientData();
    if (this.patient) {
      this.idSelfTriagePatient = this.patient.idPatient;
      this.protocolClient(this.patient.birthDate);
    }
    else
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]); //VERIFICAR ALGUMA MENSAGEM DE ERRO

    this.evaluationModel = this.formBuilder.group({
      questionAnswerHistory: this.formBuilder.array([]),
      selectedCounseling: [false, [Validators.requiredTrue]]
    });

    this.counselingModel = this.formBuilder.group({
      idCounseling: [null],
      listIdDirectionButton: [null],
    });
  }

  selectedFlowchart(idFlowchart: number) {
    let flowchart: FlowchartCompleteStruct = this.protocol.listFlowchart.find(x => x.idFlowchart == idFlowchart);
    this.flowchart = flowchart;
    this.question = flowchart.question;
    this.flowchartSelected = true;
  }

  selectedQuestion({ question, answer }) {
    let questionAnswer: QuestionAnswerStruct = {
      idQuestion: this.question.idQuestion,
      statement: this.question.statement,
      idAnswer: answer.idAnswer,
      answer: answer.answer
    };

    this.results.push(questionAnswer);
    this.addQuestionAnswer(questionAnswer);
    this.questionsHistoric.push(this.question);
    this.question = question;
  }

  addQuestionAnswer(questionAnswer: Object) {
    (this.evaluationModel.controls['questionAnswerHistory'] as FormArray)
      .push(this.createQuestionAnswer(questionAnswer));
  }

  createQuestionAnswer(questionAnswer: any) {
    return this.formBuilder.group({
      question: [questionAnswer.question],
      answer: [questionAnswer.answer],
    });
  }

  goBack() {
    this.flowchart = null;
    this.question = null;
    this.flowchartSelected = false;
    this.selectedCounseling = false;
    this.results = [];
    this.isTriageFinished = false;
    this.evaluationModel.get('selectedCounseling').setValue(false);
  }

  selectCounseling({ counseling, answer }) {
    this.results.push({
      idQuestion: this.question.idQuestion,
      statement: this.question.statement,
      idAnswer: answer.idAnswer,
      answer: answer.answer
    });

    this.selectedCounseling = true;
    this.counseling = counseling;
  }

  finishFlowchart() {
    this.isLoading = true;
    this.evaluationModel.get('selectedCounseling').setValue(true);
    this.evaluationModel.get('selectedCounseling').updateValueAndValidity();
    let listIdButtons = this.selfTriageConfig.listCounselingDirection.find(x => x.idSelfTriageCounseling == this.counseling.idSelfTriageCounseling)?.listIdDirectionButton;
    if (this.counseling.idSelfTriageCounseling && this.counseling.idSelfTriageCounseling != 0)
      this.counselingModel.get('idCounseling').setValue(this.counseling.idSelfTriageCounseling);
    if (listIdButtons) {
      this.counselingModel.get('listIdDirectionButton').setValue(listIdButtons);
      this.listButtons = this.selfTriageConfig.listButtons.filter(x => listIdButtons.includes(x.idDirectionButton));
    }
    // stepper.next();

    let request: SelfTriageResultRequest = new SelfTriageResultRequest;
    request.flowchart = this.flowchart.name;
    request.idFlowchart = this.flowchart.idFlowchart;
    request.counseling = this.counseling.selfTriageCounselingName;
    request.idCounseling = this.counseling.idSelfTriageCounseling;
    request.idPatient = this.idSelfTriagePatient;
    request.listQuestionAnswer = this.results;
    request.patientRegisterRequest = new PatientRequest();
    request.patientRegisterRequest = this.mapperPatientData();

    if (this.patientCenterAccessSelfTriage) {
      this.selfTriageProtocolService.SaveSelfTriageResult(this.uri, request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.isLoading = false;
            this.alertService.show("Erro", "Houve um erro ao tentar salvar sua classificação!", AlertType.error);
            return;
          }

          this.patient.idPatient = response.idPatient;
          this.utilSelfTriageService.updatePatientData(this.patient);
          this.mapperCounseling();
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.clientSelfTriageProtocolService.SaveSelfTriageResult(this.uri, request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.isLoading = false;
            this.alertService.show("Erro", "Houve um erro ao tentar salvar sua classificação!", AlertType.error);
            return;
          }

          this.mapperCounseling();
        },
        error: (error) => {
          console.log(error);
          this.alertService.show("Erro", "Houve um erro ao tentar salvar sua classificação!", AlertType.error);
          return;
        }
      });
    }
  }

  protocolClient(birthDate: Date) {
    this.clientSelfTriageProtocolService.getClientProtocol(this.uri, this.datepipe.transform(birthDate, 'yyyy-MM-dd')).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.protocol = response.protocol;
        // this.patientConfirm = true;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapperPatientData() {
    let patientRegisterRequest = new PatientRequest();
    let patient = this.utilSelfTriageService.getPatientData();

    if (patient != null) {
      patientRegisterRequest.patientName = patient.patientName;
      patientRegisterRequest.cpf = patient.cpf;
      patientRegisterRequest.phone = patient.phone;
      patientRegisterRequest.email = patient.email;
      patientRegisterRequest.birthDate = patient.birthDate;
      patientRegisterRequest.idGender = patient.idGender;
      patientRegisterRequest.city = patient.city;
      patientRegisterRequest.state = patient.state;
      patientRegisterRequest.neighborhood = patient.neighborhood;
      patientRegisterRequest.idHealthUnit = this.selfTriageConfig.idHealthUnit;
      patientRegisterRequest.listIdHealthUnit = this.selfTriageConfig.listIdHealthUnit;
    }

    return patientRegisterRequest;
  }

  mapperCounseling() {
    this.protocolSelected = true;
    this.isTriageFinished = true;

    let counselingConfigSession: GetProtocolSessionResponse = new GetProtocolSessionResponse();
    counselingConfigSession.counseling = this.counseling;
    counselingConfigSession.listButtons = this.listButtons;
    counselingConfigSession.flowchart = this.flowchart;
    counselingConfigSession.results = this.results;
    this.utilSelfTriageService.setProtocolResponse(counselingConfigSession);

    this.router.navigate([`self-triage/counseling/${this.utilSelfTriageService.getUri()}`]);
  }
}