<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">

                <h1 class="self-triage-title">Dados Pessoais</h1>
                <hr />

                <form class="form" [formGroup]="identificationModel" (ngSubmit)="searchByCPF()">
                    <div class="row">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-self-triage': !identificationModel.get('cpf').errors || !identificationModel.get('cpf').touched,'outline-spinner form-field-self-triage-error': identificationModel.get('cpf').errors && identificationModel.get('cpf').touched}">
                            <mat-label class="input-label">Digite seu CPF</mat-label>
                            <input #self matInput [style.borderColor]="colorCode" inputmode="numeric" type="text"
                                formControlName="cpf" [mask]="masks.cpf" required>
                            <mat-error *ngIf="identificationModel.get('cpf').invalid">{{getCpfErrorMessage()}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="lgpd-self-triage">
                        <mat-checkbox color="primary" formControlName="privacyPolicy" required
                            (click)="checkSelection()">
                            Afirmo que li e aceito os <b class="terms-of-use"> Termos de Uso </b> da plataforma
                        </mat-checkbox>
                        <mat-error
                            *ngIf="identificationModel.get('privacyPolicy').invalid && identificationModel.get('privacyPolicy').touched">
                            É preciso aceitar a política de privacidade</mat-error>
                    </div>
                </form>

                <button mat-flat-button color="primary" class="button-go" (click)="searchByCPF()"
                    [disabled]="!this.identificationModel.valid">
                    <span *ngIf="isLoading == false">Prosseguir</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>

                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>

            </div>

            <app-pagination [screenStage]="this.thisScreen"></app-pagination>

        </div>
    </body>
</div>