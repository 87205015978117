import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientStatusEnum } from 'src/app/shared/enum/patient-status.enum';
import { PatientTypeStatusEnum } from 'src/app/shared/enum/patient-type-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { QrBaseAccessService } from 'src/app/shared/services/API/orchestrator-queue/qr-base-access.service';
import { LoginCheckPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/login-check-patient.request';
import { UtilService } from 'src/app/shared/services/util.service';
import { QueueHistoryStruct } from 'src/app/shared/structs/queue-history.struct';
import { LoginCheckAgainModalComponent } from './login-modal/login-check-again-modal/login-check-again-modal.component';
import { LoginFailedModalComponent } from './login-modal/login-failed-modal/login-failed-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LoginVerifyModalComponent } from './login-verify-modal/login-verify-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute,
    private qrBaseAccess: QrBaseAccessService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private utilService: UtilService) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public healthUnitName: string;
  public patientName: string;
  public ticketInitials: string;
  public ticketSequence: number
  public curentLocationName: string;
  public dateTimeQueueArrival: Date
  public estimatedWaitingTime: string;
  public idStatusQueue: number;
  public isVerify: boolean = false;
  public guid: string;
  public GetEpisodeErro: boolean = false;
  public idLoginPassType: number;
  private loginCheckPatientRequest: LoginCheckPatientRequest;
  public patientHistory: QueueHistoryStruct[] = [];
  public idEpisode: number;

  public idStatusAguardando: number = PatientStatusEnum.aguardando;
  public idStatusAtendido: number = PatientStatusEnum.atendido;
  public idStatusChamando: number = PatientStatusEnum.chamando;
  public idStatusEvadido: number = PatientStatusEnum.evadido;
  public idStatusEmAtendimento: number = PatientStatusEnum.emAtendimento;
  public idIntervalUpdate: any
  private confirmationModalIsOpen: boolean = false;
  ngOnDestroy() {
    if (this.idIntervalUpdate) {
      this.idIntervalUpdate.clearInterval();
    }
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.guid = this.router.url.replace("/", "");

    this.getPatientExist();
  }

  startInterval() {
    if (!this.idIntervalUpdate) {
      this.idIntervalUpdate = setInterval(() => {
        if (this.loginCheckPatientRequest && (this.loginCheckPatientRequest.CPF || this.loginCheckPatientRequest.birthDate || this.loginCheckPatientRequest.motherName)) {
          this.getPatient(this.loginCheckPatientRequest);
        } else {
          this.getPatientExist();
        }
      }, 600000);
    }
  }

  getPatientExist() {
    this.qrBaseAccess.getPatient(this.guid).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        this.GetEpisodeErro = true;
        return;
      }
      if (response.idLoginPassType == PatientTypeStatusEnum.None) {
        if (!this.idIntervalUpdate) {
          this.LoginModalVerifyComponent();
        }
        this.getPatient(new LoginCheckPatientRequest());
      }
      else if (response.idLoginPassType == PatientTypeStatusEnum.BirthDate ||
        PatientTypeStatusEnum.CPF || PatientTypeStatusEnum.MotherName) {
        this.idLoginPassType = response.idLoginPassType;
        if (!this.confirmationModalIsOpen) {
          this.openModalConfirmation();
        }
        this.isVerify = true;
      }
    },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  LoginModalVerifyComponent() {
    const dialogRef = this.dialog.open(LoginVerifyModalComponent, {
      disableClose: true,
    });
  }

  getPatient(login: LoginCheckPatientRequest) {
    this.qrBaseAccess.getPatientData(this.guid, login).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        this.GetEpisodeErro = true;
        return;
      }
      if (response.patientName) {
        this.getPatientHistory();
      }
      this.idEpisode = response.idEpisode;
      this.healthUnitName = response.healthUnitName;
      this.idStatusQueue = response.idStatusQueue;
      this.isVerify = true;
      this.curentLocationName = response.curentLocationName;
      this.patientName = response.patientName;
      this.estimatedWaitingTime = response.estimatedWaitingTime;
      this.ticketInitials = response.ticketInitials;
      this.ticketSequence = response.ticketSequence;
      this.startInterval();
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalConfirmation() {
    const dialogRef = this.dialog.open(LoginModalComponent, {
      disableClose: true,
      data: {
        idLoginPassType: this.idLoginPassType,
        guid: this.guid
      },
    });
    this.confirmationModalIsOpen = true;
    dialogRef.afterClosed().subscribe(({ response, loginCheckPatientRequest }) => {
      this.confirmationModalIsOpen = false;
      this.loginCheckPatientRequest = loginCheckPatientRequest;
      if (response) {
        if (response.isError && response.errorCode == 500) {
          this.alertService.show('Erro inesperado', response.errorDescription, AlertType.error);
          this.GetEpisodeErro = true;
        } if (response.isError || !response.isAuthorized) {
          this.openFailedModal();
        } else {
          this.getPatientHistory();
          this.idEpisode = response.idEpisode;
          this.healthUnitName = response.healthUnitName;
          this.idStatusQueue = response.idStatusQueue;
          this.isVerify = true;
          this.curentLocationName = response.curentLocationName;
          this.patientName = response.patientName;
          this.estimatedWaitingTime = response.estimatedWaitingTime;
          this.ticketInitials = response.ticketInitials;
          this.ticketSequence = response.ticketSequence;
          this.startInterval();
        }
      }
    });
  }

  openFailedModal() {
    const dialogRef = this.dialog.open(LoginFailedModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        this.openModalConfirmation();
      } else {
        this.openModalAlert();
      }
    });
  }

  openModalAlert() {
    const dialogRef = this.dialog.open(LoginCheckAgainModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(() => {
      this.GetEpisodeErro = true;
    });
  }

  getPatientHistory() {
    this.qrBaseAccess.getPatientHistory(this.guid, this.loginCheckPatientRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.patientHistory = response.listQueueHistory;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


}