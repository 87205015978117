import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { EpisodePreEvaluationRequest } from '../../requests/telemedicine/episode-pre-evaluation.request';

@Injectable({
    providedIn: 'root'
})

export class EpisodePreEvaluationService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    public saveEpisodePreEvaluation(request: EpisodePreEvaluationRequest): Observable<ReturnStruct> {
        let uri = `EpisodePreEvaluation`
        
        return this.httpClient.post<ReturnStruct>(environment.urlApiTelemedicine + uri, request, this.addHeaderInternalToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}