import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NpsRoutingModule } from './nps-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { NpsComponent } from './nps/nps.component';
import { VotingPageComponent } from './voting-page/voting-page.component';
import { HeaderComponent } from './header/header.component';
import { EndNpsComponent } from './end-nps/end-nps.component';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    NpsComponent,
    VotingPageComponent,
    HeaderComponent,
    EndNpsComponent,
  ],
  imports: [
    CommonModule,
    NpsRoutingModule,
    SharedModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
  ],
})
export class NpsModule { }
