export class PostPatientAppointmentRequest {
    public idSchedule: number;
    public datetimeStart: Date;
    public datetimeEnd: Date;
    public tokenUser: string;
    public tokenPatient: string;
    public idUser: number;
    public idMedicalSpecialty: number;
    public idPatientHealthcareAgreement: number;
    public idTypeOfCare: number;
    public userName: string;
    public healthUnitName: string;
    public timeZoneValue: number;
    public idHealthcareAgreement: number;
    public idHealthcareAgreementPlan: number;
    public healthcareAgreementCardNumber: string;
    public healthcareAgreementExpirationDate: Date;
}