<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                Seja bem-vindo(a) à
                <div class="section-subtitle">
                    Telemedicina
                </div>
            </div>
            <img class="image-container" src="assets\images\telemedicine\tela-inicial-web.png">

            <button mat-flat-button class="forward-button" (click)="forward()">
                <span>COMEÇAR</span>
            </button>

            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>