import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelfTriageCounselingModel } from 'src/app/shared/services/models/self-triage/self-triage-counseling.model';
import { FlowchartCompleteStruct } from 'src/app/shared/services/structs/self-triage/flowchart-complete.struct';
import { QuestionAnswerStruct } from 'src/app/shared/services/structs/self-triage/question-answer.struct';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.css']
})
export class ResumeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ResumeModalComponent>) { }

  public isLoading: boolean = false;
  public colorCode: string = this.data.colorCode;
  public results: QuestionAnswerStruct[] = this.data.results;
  public flowchart: FlowchartCompleteStruct = this.data.flowchart;;
  public counseling: SelfTriageCounselingModel = this.data.counseling;;

  ngOnInit(): void {
  }

  close() {
    this.isLoading = true;
    this.matDialogRef.close();
  }
}
