<div class="container-box">
    <div class="container orientations-session">
        <h2 [ngStyle]="{'color': colorCode}">Por favor, acesse seu e-mail e ative a sua conta para acessar a Central
            do Paciente. </h2>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="ok()">
            <span>Ok</span>
        </button>
    </div>
</div>