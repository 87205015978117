<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            <div class="white-container">
                <h1 class="patient-appointment-section">Próximos agendamentos</h1>
                <hr />
                <div class="card-list"
                    *ngIf="(listFuturePatientAppointment && listFuturePatientAppointment.length > 0) && !isLoading">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6" *ngFor="let item of listFuturePatientAppointment">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">
                                        <mat-icon class="date-icon" matSuffix>today</mat-icon>
                                        {{item.datetimeStart | date:'dd/MM/yyyy'}} às
                                        {{item.datetimeStart | date:'HH:mm'}}</span>
                                    <span class="secundary">Dr(a). {{item.userName}}</span>
                                    <span class="secundary">{{item.medicalSpecialtyName}}</span>
                                    <span class="secundary">Atendimento: {{item.natureOfAttendanceName}}</span>
                                </div>
                                <div class="button-container">
                                    <a mat-flat-button type="button" (click)="showDetails(item)"
                                        class="btn-block secondary-button">
                                        <span class="label">Ver detalhes</span>
                                    </a>
                                    <button *ngIf="item.idTypeOfCare === onlineAttendance" matTooltip="Iniciar consulta"
                                        mat-flat-button (click)="startAppointment(item)"
                                        class="btn-block primary-button">
                                        <span class="label">Iniciar consulta</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-appointment"
                    *ngIf="(!listFuturePatientAppointment || listFuturePatientAppointment.length == 0) && !isLoading">
                    <span>Não há consultas agendadas</span>
                </div>
                <div class="spinner-container" *ngIf="isLoading">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
                <h1 class="patient-appointment-section">Histórico de agendamentos</h1>
                <hr />
                <div class="card-list"
                    *ngIf="(listPastPatientAppointment && listPastPatientAppointment.length > 0) && !isLoading">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6" *ngFor="let item of listPastPatientAppointment">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">
                                        <mat-icon class="date-icon" matSuffix>today
                                        </mat-icon>
                                        {{item.datetimeStart | date:'dd/MM/yyyy'}} às
                                        {{item.datetimeStart | date:'HH:mm'}}
                                    </span>
                                    <span class="secundary">Dr(a). {{item.userName}}</span>
                                    <span class="secundary">{{item.medicalSpecialtyName}}</span>
                                    <span class="secundary">
                                        Atendimento: {{item.idTypeOfCare == 1 ? 'presencial' : 'telemedicina'}}
                                    </span>
                                </div>
                                <div class="button-container">
                                    <a mat-flat-button type="button" (click)="showDetails(item)"
                                        class="btn-block secondary-button">
                                        <span class="label">Ver detalhes</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-appointment"
                    *ngIf="(!listPastPatientAppointment || listPastPatientAppointment.length == 0) && !isLoading">
                    <span>
                        Não há consultas agendadas
                    </span>
                </div>
                <div class="spinner-container" *ngIf="isLoading">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
                <div class="footer-white-container">
                    <p>Central do paciente</p>
                </div>
                <button matTooltip="Voltar" mat-flat-button (click)="goBack()" class="btn-block secondary-button back">
                    <span class="label">Voltar</span>
                </button>
            </div>
        </div>
    </body>
</div>