import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ended-query',
  templateUrl: './ended-query.component.html',
  styleUrls: ['./ended-query.component.css']
})
export class EndedQueryComponent implements OnInit {

  @Input() colorCode: string;
  @Input() isMobileVersion: boolean;
  @Output() finish = new EventEmitter<any>();

  constructor(){

  }

  ngOnInit(): void{

  }
  endedQuery(){
    this.finish.emit();
  }
}