import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { catchError, retry } from 'rxjs/operators';
import { ListNatureOfAttendanceResponse } from '../../responses/medical-record/list-nature-of-attendance-response';


@Injectable({
    providedIn: 'root'
  })
  export class LookupMedicalRecordService extends CommonService {
  
    constructor(private router: Router,
      private httpClient: HttpClient) {
      super();
    }

  public listNatureOfAttendance(): Observable<ListNatureOfAttendanceResponse> {

    let uri = `LookupPatientCenter/GetNatureOfAttendance`
    
    return this.httpClient.get<ListNatureOfAttendanceResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}