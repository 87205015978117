<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="section-title">
            <h1>Deseja realmente cancelar?</h1>
        </div>
        <h2>Todos os dados preenchidos até agora serão perdidos.</h2>
        <div class="button-container">
            <button mat-flat-button color="primary" type="button" class="forward-button" (click)="close()">
                <span>NÃO</span>
            </button>
            <button mat-flat-button class="btn-secundary-flex" type="button" (click)="clickReload()">
                <span>SIM</span>
            </button>
        </div>
    </div>
</div>