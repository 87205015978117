import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientCenterRoutingModule } from './patient-center-routing.module';
import { HeaderComponent } from './pages/patient-center/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ForgotPasswordComponent } from './pages/patient-center/forgot-password/forgot-password.component';
import { MatRadioModule } from '@angular/material/radio';
import { PatientCenterComponent } from './pages/patient-center/patient-center.component';
import { LoginComponent } from './pages/patient-center/login/login.component';
import { ActivateAccountModalComponent } from './pages/patient-center/login/activate-account-modal/activate-account-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PatientRegisterComponent } from './pages/patient-center/patient-register/patient-register.component';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmationEmailModalComponent } from './pages/patient-center/patient-register/confirmation-email-modal/confirmation-email-modal.component';
import { HubComponent } from './pages/patient-center/hub/hub.component';
import { MatButtonModule } from '@angular/material/button';
import { PatientAppointmentComponent } from './pages/patient-center/patient-appointment/patient-appointment.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HealthcareAgreementComponent } from './pages/patient-center/healthcare-agreement/healthcare-agreement.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AttendPatientVideoComponent } from './pages/patient-center/attend-patient-video/attend-patient-video.component';
import { EndedQueryComponent } from './pages/patient-center/attend-patient-video/ended-query/ended-query.component';
import { SchedulingInformationComponent } from './pages/patient-center/scheduling-information/scheduling-information.component';
import { SchedulingDateComponent } from './pages/patient-center/scheduling-date/scheduling-date.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SchedulingConfirmComponent } from './pages/patient-center/scheduling-confirm/scheduling-confirm.component';
import { SchedulingEndComponent } from './pages/patient-center/scheduling-end/scheduling-end.component';
import { AppointmentDetailsModalComponent } from './pages/patient-center/patient-appointment/appointment-details-modal/appointment-details-modal.component';
import { SharedModule } from '../shared/shared.module';
import { HasPreAppointmentModalComponent } from './pages/patient-center/hub/has-pre-appointment-modal/has-pre-appointment-modal.component';
import { RecoverPasswordComponent } from './pages/patient-center/recover-password/recover-password.component';
import { CancelAppointmentModalComponent } from './pages/patient-center/patient-appointment/appointment-details-modal/cancel-appointment-modal/cancel-appointment-modal.component';
import { AbsentMedicalAlertModalComponent } from './pages/patient-center/attend-patient-video/alert-modal/absent-medical-alert-modal.component';
import { NotificationComponent } from './pages/patient-center/hub/notification/notification.component';
import { NotificationDisplayModalComponent } from './pages/patient-center/hub/notification/notification-display-modal/notification-display-modal.component';
import {MatBadgeModule} from '@angular/material/badge';
import { ProfileInfoComponent } from './pages/patient-center/profile-info/profile-info.component';
import { UpdateHealthcareAgreementModalComponent } from './pages/patient-center/healthcare-agreement/update-healthcare-agreement-modal/update-healthcare-agreement-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ForgotPasswordComponent,
    ActivateAccountModalComponent,
    PatientCenterComponent,
    LoginComponent,
    PatientRegisterComponent,
    ConfirmationEmailModalComponent,
    HubComponent,
    HealthcareAgreementComponent,
    PatientAppointmentComponent,
    AttendPatientVideoComponent,
    EndedQueryComponent,
    SchedulingInformationComponent,
    SchedulingDateComponent,
    SchedulingConfirmComponent,
    SchedulingEndComponent,
    AppointmentDetailsModalComponent,
    HasPreAppointmentModalComponent,
    RecoverPasswordComponent,
    UpdateHealthcareAgreementModalComponent,
    CancelAppointmentModalComponent,
    AbsentMedicalAlertModalComponent,
    NotificationComponent,
    NotificationDisplayModalComponent,
    ProfileInfoComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    RecaptchaModule,
    FormsModule,
    NgxCaptchaModule,
    MatTooltipModule,
    MatButtonModule,
    MatRadioModule,
    RecaptchaFormsModule,
    MatCheckboxModule,
    RecaptchaModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    PatientCenterRoutingModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatBadgeModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ]
})
export class PatientCenterModule { }
