import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-emergency-warning',
  templateUrl: './emergency-warning.component.html',
  styleUrls: ['./emergency-warning.component.css']
})
export class EmergencyWarningComponent implements OnInit {

  constructor(private utilSelfTriageService: UtilsSelfTriageService,
    public router: Router,
  ) { }

  public patientCenterAccessSelfTriage: boolean = false;
  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public emergency: string = '';
  public symptoms: string = '';
  public selfTriageConfig: SelfTriageConfigStruct;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.identification;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();
    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();

    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();

    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }

    this.emergency = (this.selfTriageConfig.emergency) ? this.selfTriageConfig.emergency : "Procure atendimento presencial urgente se você apresentar um destes sintomas:";
    this.symptoms = (this.selfTriageConfig.symptoms) ? this.selfTriageConfig.symptoms : `● Chiado, falta de ar ou dificuldade em respirar
<br>● Dor no peito
&#10● Fraturas deslocadas ou expostas
● Desmaios ou tonturas
● Dormência ou fraqueza repentina
● Sangramento que não pode ser parado
● Dor abdominal - especialmente dor intensa localizada
● Febre com convulsões ou qualquer febre em crianças menores de 3 meses
● Confusão ou alterações no estado mental
● Tosse ou vômito com sangue
● Dor de cabeça severa ou lesão na cabeça, especialmente se o indivíduo estiver usando aspirina ou anticoagulantes
● Sangue na urina ou diarreia com sangue
● Súbita incapacidade de falar, ver, andar ou se mover`;
  }

  clickGo() {
    if (this.patientCenterAccessSelfTriage) 
      this.router.navigate([`self-triage/protocol/${this.utilSelfTriageService.getUri()}`]);
    else
      this.router.navigate([`self-triage/additional-data/${this.utilSelfTriageService.getUri()}`]);
  }

  clickBack() {
    this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
  }
}