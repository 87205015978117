<div mat-dialog-container class="content">
    <ngContainer *ngIf="!isLoading">
        
        <div class="button-container">
            <button mat-mini-fab (click)="clickCancel()">
                <mat-icon>
                    close
                </mat-icon> 
            </button>
        </div>
        <div class="mat-dialog-container-medic-hold-modal">            
            <img src="assets\images\triage-and-telemedicine-end-modal.png"> 
            <div class="text-part">
                <div class="head-confirm-modal-list">
                    <b>{{data.title}}</b>
                </div>
                <br>
                <div class="txtCenter"> 
                    {{data.subTitle}}
                </div>   
            </div>                        
        </div>
    </ngContainer>
</div>