<div>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row" *ngFor="let item of protocol.listFlowchart; let i = index">            
                <div class="col-12 input-radio-list">
                    <input value="{{item.idFlowchart}}" type="radio" id="radio-{{item.idFlowchart}}" formControlName="idFlowchart">
                    <label for="radio-{{item.idFlowchart}}">{{item.name}}</label>
                </div>            
        </div>
        <button mat-flat-button color="primary" type="submit" class="button-go" [disabled]="!this.model.valid">
            <span *ngIf="isLoading == false">Próximo</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button> 
    </form>
</div>

