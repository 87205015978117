import { QuestionAnswerStruct } from "../../structs/self-triage/question-answer.struct";
import { PatientRequest } from "./patient.request";

export class SelfTriageResultRequest {
    public idFlowchart: number;
    public idCounseling: number;
    public idPatient: number;

    public counseling: string;
    public flowchart: string;

    public listQuestionAnswer: QuestionAnswerStruct[];
    public patientRegisterRequest: PatientRequest;
    
    public patientCenterAccessSelfTriage: boolean;
}