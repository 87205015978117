<div>
    <app-header></app-header>

    <body class="body-container">
        <div class="body-content">

            <div class="white-container">

                <h1 class="nps-title">Net Promoter Score</h1>
                <hr />
                <p>{{this.npsMessageResponse.npsMessage.commentsCollection}}</p>
                <form class="form form-nps" [formGroup]="npsModel">
                    <div *ngFor="let item of arrayIndexNps; let i = index">
                        <div class="input-radio-nps">
                            <input type="radio" id="radio-{{i}}" [value]="i" formControlName="nps">
                            <label for="radio-{{i}}">{{i}}</label>
                        </div>
                    </div>
                </form>

                <div class="col-12">
                    <div class="row">
                        <form [formGroup]="npsModel" *ngIf="commentCollectionActive">
                            <mat-form-field class="border-comments" appearance="outline">
                                <mat-label>Comentário</mat-label>
                                <textarea matInput type="text" maxlength="160"
                                    formControlName="commentsCollection"></textarea>
                            </mat-form-field>
                            <!-- <span class="title-description-count">{{ npsModel.get('commentsCollection').value.length ?
                                npsModel.get('commentsCollection').value.length : 0 }}/160 caracteres</span> -->
                        </form>
                    </div>
                </div>
                <button mat-flat-button color="primary" class="button-go" (click)="saveNote()"
                    [disabled]="!this.npsModel.valid">
                    <span *ngIf="isLoading == false">Enviar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>

                <div class="footer-white-container">
                    <p>NET PROMOTER SCORE (NPS)</p>
                </div>

            </div>

        </div>
    </body>
</div>