import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { UtilsNpsService } from '../utils.service';
import { ClientNpsMessageService } from 'src/app/shared/services/API/nps/client-nps-message.service';


@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class NpsComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public utilsNpsService: UtilsNpsService,
    public clientNpsMessageService: ClientNpsMessageService,
    public router: Router,
  ) { }

  public guid: string;
  public checkedGuid: boolean = false;
  public checkedLoading: boolean = false;
  public isLoading: boolean = false;

  public preview: boolean = false;

  ngOnInit(): void {
    this.utilsNpsService.removeNpsMessageResponse();

    if (this.activatedRoute.snapshot.paramMap.get('guid')) {
      this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
      this.getNpsMessage();
    }
  }

  ngOnDestroy(): void {
  }

  getNpsMessage() {
    this.clientNpsMessageService.getNpsMessageByGuid(this.guid).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.checkedLoading = true;
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);
          this.utilsNpsService.setNpsMessageResponse(response);
          this.router.navigate([`nps/end-nps/${this.guid}`]);
          return;
        }

        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.npsMessage != null)
          this.checkedGuid = true;

        this.checkedLoading = true;
        this.utilsNpsService.setNpsMessageResponse(response);
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}