import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientService } from '../../../../shared/services/API/self-triage/patient.service'
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { environment } from 'src/environments/environment';
import { TermsOfUseModalComponent } from './terms-of-use-modal/terms-of-use-modal.component';

@Component({
  selector: 'app-patient-identification',
  templateUrl: './patient-identification.component.html',
  styleUrls: ['./patient-identification.component.css']
})
export class PatientIdentificationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    public dialog: MatDialog,
    private patientService: PatientService,
    private alertService: AlertService,
    public router: Router,
    private utilSelfTriaveService: UtilsSelfTriageService,
  ) { }

  public checkedPatient: boolean = false;
  public masks: Masks = this.maskService.getMasks();
  public isLoading: boolean = false;
  public patient: Patient;
  public isLoadingCpf: boolean;
  public colorCode: string = '#99C8D6';
  public urlPrivacyPolicy = environment.urlUIPatientBase + "assets/documents/privacy_policy.pdf";

  public selfTriageConfig: SelfTriageConfigStruct;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.identification;

  public identificationModel: FormGroup;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilSelfTriaveService.getSelfTriageConfigStruct();

    if (!this.selfTriageConfig) this.router.navigate([`self-triage/${this.utilSelfTriaveService.getUri()}`]);

    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }

    this.identificationModel = this.formBuilder.group({
      cpf: ['', [
        Validators.required,
        VerifyCPF()]],
      privacyPolicy: [null, [Validators.requiredTrue]],
    });
  }

  searchByCPF() {
    if (this.identificationModel.invalid) {
      this.identificationModel.markAllAsTouched();
      this.alertService.show('Atenção', 'Preencha todos os campos em vermelho', AlertType.warning);
      return;
    }
    this.patient = new Patient();
    this.patient.cpf = this.identificationModel.get('cpf').value;
    if (this.selfTriageConfig.isExternalSearchEnabled) {
      this.utilSelfTriaveService.updatePatientData(this.patient);
      this.router.navigate([`self-triage/search-patient/${this.utilSelfTriaveService.getUri()}`]);
    }
    else
      this.internalSearch();
  }

  internalSearch() {
    this.isLoading = true;
    let cpf = this.identificationModel.get('cpf').value;
    let jsonListIdHealthUnit = JSON.stringify(this.selfTriageConfig.listIdHealthUnit);
    this.patientService.getPatientByCpf(cpf.replace(' ', ''), jsonListIdHealthUnit).subscribe({
      next: (response) => {
        if (response.isError && response.errorDescription != "CPF não encontrado.") {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        if (response.patient != null)
          this.patient = response.patient;

        this.utilSelfTriaveService.updatePatientData(this.patient);
        this.isLoading = false;
        this.isLoadingCpf = false;
        this.router.navigate([`self-triage/emergency-warning/${this.utilSelfTriaveService.getUri()}`]);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(TermsOfUseModalComponent, {
      panelClass: 'terms-of-use-self-triage',
      data: {
        colorCode: this.colorCode
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        this.router.navigate([`self-triage/${this.utilSelfTriaveService.getUri()}`]);
    });
  }

  checkSelection() {
    if (this.identificationModel.get('privacyPolicy').value == true) this.openPanelLogoModal();
  }

  getCpfErrorMessage() {
    if (this.identificationModel.get('cpf').hasError('required'))
      return 'Informe o CPF';

    return this.identificationModel.get('cpf').hasError('invalidCPF') ? 'CPF inválido' : '';
  }
}