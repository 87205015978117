export enum WebsocketTelemedicineActionEnum {
    confirmAttend = 1,
    medicEvasion = 2,
    patientEvasion = 3,
    endMedicalCare = 4,
    returnToQueue = 5,
    forward = 6,
    timeExpired = 7,
    startCountdown = 8,
    patientInternetDown = 9,
}