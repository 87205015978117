import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ViaCepResponse } from '../../responses/integration/via-cep.response';

@Injectable({
  providedIn: 'root'
})
export class ViaCepService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAddressByCep(cep: string): Observable<ViaCepResponse> {
    
    let uri = `ViaCep/cep/${cep}`
    
    return this.httpClient.get<ViaCepResponse>(environment.urlApiIntegration + uri, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }
}
