import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { PatientPreAppointmentCenterService } from 'src/app/shared/services/API/orchestrator-schedule/patient-pre-appointment-center.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientPreAppointmentResponse } from 'src/app/shared/services/responses/orchestrator-schedule/patient-pre-appointment.response';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { PatientAppointmentSessionStruct } from 'src/app/shared/structs/patient-center/patient-appointment-session.struct';
import { HasPreAppointmentModalComponent } from '../hub/has-pre-appointment-modal/has-pre-appointment-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-scheduling-end',
  templateUrl: './scheduling-end.component.html',
  styleUrls: ['./scheduling-end.component.css']
})
export class SchedulingEndComponent implements OnInit {

  constructor(
    public router: Router,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private patientPreAppointmentCenterService: PatientPreAppointmentCenterService
  ) { }

  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public uri: string;
  

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    if(!this.digitalPromptServiceConfig)
      this.router.navigate([`digital-prompt-service/${this.utilsDigitalPromptServiceService.getUri()}`]);
    
    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    } 
    
  }

  clickGo() {
    this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  navigatePatientScheduling() {
    this.isLoading = true;
    this.patientPreAppointmentCenterService.getPatientPreAppointmentData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.patientPreAppointment){
          this.openHasPreAppointmentModal(response);

        } else {
          this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openHasPreAppointmentModal(patientPreAppointmentResponse: PatientPreAppointmentResponse){
    this.isLoading = true;
    const dialogRef = this.dialog.open(HasPreAppointmentModalComponent, {
      data: {
        colorCode: this.colorCode,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.continuePreAppointment){
        let patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
        patientAppointmentSession.idSchedule = patientPreAppointmentResponse.patientPreAppointment.idSchedule;
        patientAppointmentSession.datetimeStart = patientPreAppointmentResponse.patientPreAppointment.datetimeStart;
        patientAppointmentSession.datetimeEnd = patientPreAppointmentResponse.patientPreAppointment.datetimeEnd;
        patientAppointmentSession.idUser = patientPreAppointmentResponse.patientPreAppointment.idUser;
        patientAppointmentSession.idPatientHealthcareAgreement = patientPreAppointmentResponse.patientPreAppointment.idPatientHealthcareAgreement;
        patientAppointmentSession.idTypeOfCare = patientPreAppointmentResponse.patientPreAppointment.idTypeOfCare;
        patientAppointmentSession.idMedicalSpecialty = patientPreAppointmentResponse.patientPreAppointment.idMedicalSpecialty;
        patientAppointmentSession.healthUnitName = patientPreAppointmentResponse.healthUnitName;
        patientAppointmentSession.medicalSpecialtyName = patientPreAppointmentResponse.medicalSpecialtyName;
        patientAppointmentSession.typeOfCareName = patientPreAppointmentResponse.typeOfCareName;
        patientAppointmentSession.nameHealthcareAgreement = patientPreAppointmentResponse.healthPlanName;
        patientAppointmentSession.userName = patientPreAppointmentResponse.userName;
        patientAppointmentSession.idHealthcareAgreement = patientPreAppointmentResponse.patientPreAppointment.idHealthcareAgreement;
        patientAppointmentSession.idHealthcareAgreementPlan = patientPreAppointmentResponse.patientPreAppointment.idHealthcareAgreementPlan;
        patientAppointmentSession.healthcareAgreementCardNumber = patientPreAppointmentResponse.patientPreAppointment.healthcareAgreementCardNumber;
        patientAppointmentSession.healthcareAgreementExpirationDate = patientPreAppointmentResponse.patientPreAppointment.healthcareAgreementExpirationDate;
        
        this.router.navigate([`patient-center/scheduling-confirm/${this.uri}`]);
        this.isLoading = false;
      } else if(result && !result.continuePreAppointment){
        this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);
        this.isLoading = false;
      }
    });
  }

}
