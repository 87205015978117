<h1>Dados inválidos!</h1>
<div class="row">
    <div class="col-12">
        <div class="row">
            <p><b>Se a senha for sua, verifique com a recepção se os dados estão corretos e atualize-os!</b></p>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <p><b>Se não, essa senha pertence a outro paciente. Retire uma nova senha!</b></p>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-sm-6 col-md-4"></div>
    <div class="col-12 col-sm-6 col-md-4">
        <button mat-flat-button color="primary" class=" block" type="button" (click)="openModalConfirmation()">
            <span *ngIf="!isLoading">Ok</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
    <div class="col-12 col-sm-6 col-md-4"></div>
</div>