<div class="container-box">
    <div class="container orientations-session">
        <div class="section-title">
            <h2>Deseja continuar o agendamento anterior?</h2>
        </div>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="confirm()">
            <span *ngIf="!isLoading">Sim</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
        <button mat-flat-button class="btn-secundary-flex" color="primary" type="button" (click)="cancel()">
            <span *ngIf="!isLoading">Não</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>

</div>