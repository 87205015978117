import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';
import { sessionTimeoutEnum } from 'src/app/shared/enum/digital-prompt/session-timeout.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string = "#d4d4d4";
  userActivity;
  public timeoutValue: number;
  public isLogoUploaded: boolean = false;
  public clientLogo: any;
  public sanitezedLogo: any;
  public logoString64: string;

  ngOnInit(): void {
    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);
    let digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();

    if (digitalPromptConfig.isLogoUploaded == true) {
      this.isLogoUploaded = true;
      this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${digitalPromptConfig.logoString64}`);
    }
    else if (digitalPromptConfig.useColor == true)
      this.colorCode = digitalPromptConfig.colorCode;
    
    
    if (digitalPromptConfig.sessionTimeoutValue && digitalPromptConfig.idSessionTimeout != sessionTimeoutEnum.semLimite)
    {
      this.timeoutValue = digitalPromptConfig.sessionTimeoutValue * 3600000;
      this.setTimeout();
    }
  }

  @HostListener('window:mousemove') refreshUserState() {
    if(this.timeoutValue != null)
    {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  @HostListener('window:touchmove') refreshUserStateMobile() {
    if(this.timeoutValue != null)
    {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.timeoutLogout(), this.timeoutValue);
  }

  timeoutLogout() {
    this.alertService.show('Erro', 'Você foi deslogado por inatividade', AlertType.error);
    this.authService.logout();
  }
}