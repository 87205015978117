import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-orientations',
  templateUrl: './orientations.component.html',
  styleUrls: ['./orientations.component.css']
})
export class OrientationsComponent implements OnInit {

  constructor(
    private utilSelfTriageService: UtilsSelfTriageService,
    public router: Router,
  ) { }

  public patientCenterAccessSelfTriage: boolean = false;
  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public titlePage: string = '';
  public descriptionPage: string = '';
  public selfTriageConfig: SelfTriageConfigStruct;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.orientations;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();
    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();

    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();

    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
    else {
      if (this.selfTriageConfig.useColor == true) {
        this.colorCode = this.selfTriageConfig.colorCode;
        document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
      }

      this.titlePage = (this.selfTriageConfig.titlePage) ? this.selfTriageConfig.titlePage : "Faça sua avaliação e receba orientações adequadas para o seu bem-estar";
      this.descriptionPage = (this.selfTriageConfig.descriptionPage) ? this.selfTriageConfig.descriptionPage : `Ao final da autotriagem, você receberá aconselhamentos específicos que foram preparados pela equipe médica da sua Unidade de Saúde de referência.
  
      Em casos muito urgentes, como dificuldade para respirar, sangramento intenso e dores muito fortes, procure uma Unidade de Pronto Atendimento (UPA 24h) o mais rápido possível.`;
    }
  }

  clickGo() {
    if (this.patientCenterAccessSelfTriage)
      this.router.navigate([`self-triage/emergency-warning/${this.utilSelfTriageService.getUri()}`]);
    else
      this.router.navigate([`self-triage/patient-identification/${this.utilSelfTriageService.getUri()}`]);
  }
}