import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyDate } from 'src/app/shared/custom-validators/date.validator';
import { ClientPatientService } from 'src/app/shared/services/API/medical-record/client-patient.service';
import { ClientUserPatientService } from 'src/app/shared/services/API/patient-center/client-user-patient.service';
import { LookupService } from 'src/app/shared/services/API/patient-center/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Gender } from 'src/app/shared/services/models/patient-center/gender.model';
import { UserPatientRequest } from 'src/app/shared/services/requests/patient-center/user-patient.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { ConfirmationEmailModalComponent } from './confirmation-email-modal/confirmation-email-modal.component';
import { ValidateEmailRequest } from 'src/app/shared/services/requests/patient-center/validate-email.request';
import { ConfirmationEmailRequest } from 'src/app/shared/services/requests/patient-center/confirmation-email.request';
import { SRVEmailService } from 'src/app/shared/services/API/srv-email/srv-email.service';
import { environment } from 'src/environments/environment';
import { VerifyName } from 'src/app/shared/custom-validators/name.validator';
import { VerifyConfirmPassword, VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { GetClientPatientByCpfResponse } from 'src/app/shared/services/responses/medical-record/client-patient.response';
import { PhoneValidator } from 'src/app/shared/custom-validators/phone.validator';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
  styleUrls: ['./patient-register.component.css']
})
export class PatientRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    public router: Router,
    public dialog: MatDialog,
    private lookupService: LookupService,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private clientPatientService: ClientPatientService,
    private clientUserPatientService: ClientUserPatientService,
    private SRVemailService: SRVEmailService) {
  }

  public model: FormGroup;
  public checkedCpf: boolean = false;
  public masks: Masks;
  public isLoading: boolean;
  public colorCode: string = '#99C8D6';
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public listGender: Gender[];
  public hidePassword: boolean = true;
  public hidePasswordConfirm: boolean = true;
  public listIdHealthUnitInGroup: number[];
  public isCpfEncountered: boolean = false;
  public idPatient: number;

  ngOnInit(): void {
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.masks = this.maskService.getMasks();

    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    this.model = this.formBuilder.group({
      idPatient: [null],
      completeName: ['', [Validators.required, VerifyName()]],
      completeMotherName: ['', [Validators.required, VerifyName()]],
      cpf: ['', [Validators.required, VerifyCPF()]],
      phone: ['', [Validators.required, PhoneValidator()]],
      birthDate: ['', [Validators.required, VerifyDate()]],
      idGender: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      emailConfirm: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, VerifyPassword()]],
      passwordConfirm: ['', [Validators.required, VerifyConfirmPassword()]],
    });

    this.listIdHealthUnitInGroup = this.utilsDigitalPromptServiceService.getHealthUnit()?.listIdHealthUnitInGroup
    this.lookupGender();
  }

  lookupGender() {
    this.lookupService.getAll().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listGender = response.listGender;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos em vermelho", AlertType.error);
      return;
    }
    if (this.model.get('password').value != this.model.get('passwordConfirm').value) {
      this.alertService.show('Erro', "Os dois campos de senha devem ser iguais", AlertType.error);
      return;
    }

    let config = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    let request: UserPatientRequest = new UserPatientRequest();

    request.idHealthUnit = config.idHealthUnit;
    request.idGender = this.model.get('idGender').value;
    request.userName = this.model.get('completeName').value;
    request.motherName = this.model.get('completeMotherName').value;
    request.cpf = this.model.get('cpf').value;
    request.userEmail = this.model.get('email').value;
    request.password = this.model.get('password').value;
    request.phoneNumber = this.model.get('phone').value;
    request.listIdHealthUnitInGroup = this.listIdHealthUnitInGroup;
    request.idPatient = this.model.get('idPatient').value;
    request.isActive = false;

    let userBirthDate = this.model.get('birthDate').value;
    let birthDate = new Date(parseInt(userBirthDate.slice(4, 8)), parseInt(userBirthDate.slice(2, 4)) - 1, parseInt(userBirthDate.slice(0, 2)));
    request.userBirthDate = birthDate;

    this.clientUserPatientService.CreateUserPatient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Dados cadastrados com sucesso!', AlertType.success);

        this.sendConfirmationEmail(response.guid);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  sendConfirmationEmail(guid: string,) {
    let request: ConfirmationEmailRequest = new ConfirmationEmailRequest();
    request.userEmail = this.model.value.email;
    request.userName = this.model.value.completeName;
    request.isAtivation = true;
    request.urlPatientCenter = environment.urlUIPatientBase + "digital-prompt-service/" + this.utilsDigitalPromptServiceService.getUri() + "/" + guid;

    this.SRVemailService.registryConfirmationEmail(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Email enviado com sucesso!', AlertType.success);

        this.openConfirmationEmailModal();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCpf() {
    const cpfControl = this.model.get('cpf');
    const cpfValue = cpfControl.value;

    if (!cpfValue || cpfControl.invalid)
      return;

    this.isLoading = true;
    let jsonListIdHealthUnit = null;
    if (this.listIdHealthUnitInGroup) {
      jsonListIdHealthUnit = JSON.stringify(this.listIdHealthUnitInGroup);
    }

    this.clientPatientService.getPatient(cpfValue, jsonListIdHealthUnit).subscribe({
      next: (response) => {
        if (response.isError)
          this.handlePatientSearchError(response);
        else {
          this.updatePatientFields(response.patient);
          this.checkExistingUserPatient(cpfValue);
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private handlePatientSearchError(response: GetClientPatientByCpfResponse) {
    let alert = '';
    let alertType: AlertType;

    if (response.errorDescription === 'CPF não encontrado.') {
      this.clearPatientFields();
      this.enableForm();
      alert = 'Aviso';
      alertType = AlertType.warning;
    } else {
      this.disableForm();
      alert = 'CPF já cadastrado!';
      alertType = AlertType.warning;
    }

    this.alertService.show(alert, response.errorDescription, alertType);
    this.isLoading = false;
  }

  private updatePatientFields(patient: PatientStruct) {
    if (patient.idPatient != null) {
      this.model.get('idPatient').setValue(patient.idPatient);
      this.model.get('idGender').enable();
      this.model.get('completeName').setValue(patient.patientName);
      this.model.get('idGender').setValue(patient.idGender);
      this.model.get('birthDate').setValue(this.formatDate(patient.birthDate));
      this.model.get('email').setValue(patient.email);

      let phone = patient.phone1 || patient.phone2;
      if (phone != null) {
        const regex = /\(|\)|\-/gi
        phone = phone.replace(regex, "").replaceAll(" ", "");
        this.model.get('phone').setValue(phone);
      }
    }
    else
      this.clearPatientFields();

  }

  private clearPatientFields() {
    this.model.get('idGender').setValue('');
    this.model.get('idGender').enable();

    this.model.get('completeName').setValue('');
    this.model.get('completeName').enable();

    this.model.get('completeMotherName').setValue('');
    this.model.get('completeMotherName').enable();

    this.model.get('birthDate').setValue('');
    this.model.get('birthDate').enable();

    this.model.get('email').setValue('');
    this.model.get('email').enable();

    this.model.get('emailConfirm').setValue('');
    this.model.get('emailConfirm').enable();

    this.model.get('phone').setValue('');
    this.model.get('phone').enable();

    this.model.get('password').setValue('');
    this.model.get('password').enable();

    this.model.get('passwordConfirm').setValue('');
    this.model.get('passwordConfirm').enable();

  }

  private formatDate(date) {
    const birthDate = new Date(date);
    const birthDateYear = birthDate.getFullYear().toString();
    const birthDateMonth = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const birthDateDay = birthDate.getDate().toString().padStart(2, '0');
    return birthDateDay + birthDateMonth + birthDateYear;
  }

  private checkExistingUserPatient(cpf: string) {
    const digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.clientUserPatientService.getUserPatient(cpf, digitalPromptConfig.idHealthUnit).subscribe({
      next: (response) => {
        if (!response.isError) {
          this.disableForm();
          this.alertService.show('CPF já cadastrado!', '', AlertType.warning);
          this.isCpfEncountered = true;
        }
        else
          this.isCpfEncountered = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  private disableForm() {
    this.model.disable();
    this.model.get('cpf').enable();
    this.model.get('idGender').enable();
    this.isLoading = false;
  }

  private enableForm() {
    this.model.enable();
    this.model.get('idGender').enable();
    this.isLoading = false;
  }

  validateEmail() {
    let email = this.model.get('email');

    if (!email.value || email.invalid)
      return;

    let idHealthUnit = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct().idHealthUnit;

    let request: ValidateEmailRequest = new ValidateEmailRequest();

    request.idHealthUnit = idHealthUnit;
    request.userEmail = email.value;

    this.clientUserPatientService.ValidateEmail(request).subscribe({
      next: (response) => {
        if (response.isError) {
          if (response.errorCode === -1) {
            this.alertService.show('Erro', 'Email já cadastrado', AlertType.warning);
            this.model.get('email').setErrors({ registeredEmail: true });
            this.model.get('emailConfirm').setErrors({ registeredEmail: true });
          }
          else
            this.alertService.show('Erro', response.errorDescription, AlertType.error);

          this.isLoading = false;
          return;
        }

        this.model.get('email').setErrors(null);
        this.model.get('email').updateValueAndValidity();
        this.validateEmailConfirm();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validatePasswordConfirm() {
    let password = this.model.get('password');
    let passwordConfirm = this.model.get('passwordConfirm');
    if (!password.value || !passwordConfirm.value)
      return;

    if (passwordConfirm.value != password.value) {
      passwordConfirm.setErrors({ divergentPassword: true });
      passwordConfirm.markAsTouched();
    }
    else
      passwordConfirm.setErrors(null);

    this.model.updateValueAndValidity();
  }

  validateEmailConfirm() {
    let email = this.model.get('email');
    let emailConfirm = this.model.get('emailConfirm');

    if (!email.value || !emailConfirm.value)
      return;

    if (emailConfirm.value != email.value) {
      emailConfirm.setErrors({ divergentEmail: true });
      emailConfirm.markAsTouched();
    }
    else
      emailConfirm.setErrors(null);
  }

  openConfirmationEmailModal() {
    const dialogRef = this.dialog.open(ConfirmationEmailModalComponent, {
      data: {
        colorCode: this.colorCode,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([`patient-center/${this.utilsDigitalPromptServiceService.getUri()}`]);
    });
  }

  cancel() {
    this.router.navigate([`patient-center/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }
}