import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { WebsocketAppointmentActionEnum } from 'src/app/shared/components/enum/websocket-appointment-action.enum';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { WarnPatientDoctorEvadeComponent } from 'src/app/shared/components/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TriggerWebsocketService } from 'src/app/shared/services/API/orchestrator-telemedicine/trigger-websocket.service';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { AppointmentDataStruct } from 'src/app/shared/services/structs/schedule/appointment-data.struct';
import { WebsocketAppointmentUtilService } from 'src/app/shared/services/websocket-util/websocket-appointment-util.service';
import { UtilsTelemedicineService, VideoCallData } from 'src/app/telemedicine/utils.service';
import { AbsentMedicalAlertModalComponent } from './alert-modal/absent-medical-alert-modal.component';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { AppointmentStatusEnum } from 'src/app/shared/enum/appointment-status.enum';

@Component({
  selector: 'app-attend-patient-video',
  templateUrl: './attend-patient-video.component.html',
  styleUrls: ['./attend-patient-video.component.css']
})
export class AttendPatientVideoComponent implements OnInit, AfterViewInit {

  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;

  constructor(
    public utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private websocketAppointmentUtilService: WebsocketAppointmentUtilService,
    public dialog: MatDialog,
    public triggerWebsocketService: TriggerWebsocketService,
    public utilsTelemedicineService: UtilsTelemedicineService,
    public patientAppointmentService: PatientAppointmentService,
  ) { }

  public timeout: any;
  public uri: string;
  public colorCode: string = '#99C8D6';
  public btnColorCode: string;
  public logoString64: string;
  public sanitezedLogo: any;
  public checkedUri: boolean = false;
  public digitalPromptConfig: DigitalPromptConfigStruct;
  public clientLogo: any;
  public isLogoUploaded: boolean = false;
  public idPatientAppointment: number;
  public isMobileVersion: boolean;
  public isFinished: boolean = false;
  public tamanho: number;
  public sanitizedLogo: any;
  public appointmentData: AppointmentDataStruct;
  public countdownInterval: any;
  public patientAppointment: PatientAppointment;
  public modalOpeningCount: number = 0;
  public isAbsentMedical: boolean = true;

  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {

      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
      if(this.digitalPromptConfig)
        this.colorCode = this.digitalPromptConfig?.colorCode;
      // this.configClient();
    }

    this.isMobile();
    this.sanitizedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);

    this.appointmentData = this.utilsDigitalPromptServiceService.getAppointmentDataToTwilioSession();
    this.patientAppointment = this.utilsDigitalPromptServiceService.getAppointmentDataSession();
    let videoCallData: VideoCallData = {
      attendantUserName: this.appointmentData.attendantUserName,
      idAttendantUser: this.appointmentData.idAttendantUser,
      videoToken: this.appointmentData.patientToken,
      idEpisode: null
    }
    this.utilsTelemedicineService.updateVideoCallData(videoCallData);

    this.idPatientAppointment = this.appointmentData.idPatientAppointment;
    this.startWebsocket();
  }

  ngAfterViewInit(): void {
    this.timeout = setTimeout(() => this.startVideo(), 1000);
  }

  startVideo() {
    clearTimeout(this.timeout);
    this.twiliovideo.onRoomChanged(this.appointmentData.patientToken).then(result => {
      let room = result;
      if(room.participants.size === 0) {
        this.setCountdownStart();
      } else {
        this.isAbsentMedical = false;
      }
    });
  }

  onLeaveRoom(isEvasion: boolean) {
    clearTimeout(this.timeout);
    this.appointmentData = null;
    this.utilsDigitalPromptServiceService.removeAppointmentDataToTwilioSession();
    this.isFinished = true;
    if (isEvasion) {
      this.utilsDigitalPromptServiceService.removePatientData();
      this.websocketAppointmentUtilService.emitAction(this.idPatientAppointment, WebsocketAppointmentActionEnum.patientEvasion);
    }
  }

  //Websocket
  startWebsocket() {
    
    if (!this.websocketAppointmentUtilService.isConnect()) {
      this.websocketAppointmentUtilService.connectwebsocketRoom(this.idPatientAppointment, this);
    }
    this.websocketAppointmentUtilService.setThisParam(this);
    this.websocketAppointmentUtilService.setFunctions(this.verifyTrigger, null, null, null, null);
    this.websocketAppointmentUtilService.emitAction(this.idPatientAppointment, WebsocketAppointmentActionEnum.patientEnter);
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected) {
        this.socketDisconneted = false;
      } else {
        this.socketDisconneted = true;
      }
    } else {
      this.socketDisconneted = false;
    }
    if (this.onlineOffline !== navigator.onLine) {
      this.onlineOffline = navigator.onLine;
    }

    var actualDate = new Date();
    var finalDate = new Date(this.patientAppointment.datetimeEnd);

    if (this.twiliovideo.activeRoom) {
      if(this.twiliovideo.participants && this.twiliovideo.activeRoom.participants.size > 0 && this.isAbsentMedical) {
        this.isAbsentMedical = false;
        clearInterval(this.countdownInterval);
      }

      if ((actualDate > finalDate) && this.isAbsentMedical) {
        this.isAbsentMedical = false;
        this.openFinalAbsentMedicalWarningModal();
      }
    }

  }, 1000);

  ngOnDestroy() {
    this.websocketAppointmentUtilService.clearSocket();
    clearInterval(this.idInterval);
    clearInterval(this.countdownInterval);
  }

  setCountdownStart() {
    var actualDate = new Date();
    var startDate: Date = new Date(this.patientAppointment.datetimeStart);
    if(actualDate > startDate) {
      this.countdownAbsentMedical();
    }
    else {
      const timeout: number = startDate.getTime() - actualDate.getTime();
      setTimeout(() => {
        if(this.isAbsentMedical)
          this.countdownAbsentMedical();
      }, timeout);
    }
  }

  countdownAbsentMedical() {
    this.countdownInterval = setInterval(() => {
      if (this.modalOpeningCount < 2 && this.isAbsentMedical) {
        this.openAbsentMedicalWarningModal();
      } else if (this.modalOpeningCount >= 2 && this.isAbsentMedical) {
        this.openFinalAbsentMedicalWarningModal();
      }
    }, 300000);
  }

  openAbsentMedicalWarningModal() {
    const dialogAlert = this.dialog.open(AbsentMedicalAlertModalComponent, {
      disableClose: true,
      data: {
        title: 'O médico ainda não entrou!',
        description: 'Deseja continuar aguardando?',
        colorCode: this.colorCode,
        isLastWarning: false
      },
    });
    dialogAlert.afterClosed().subscribe({
      next: result => {
        if(!result.keepWaiting){
          clearInterval(this.countdownInterval);
          this.finish();
        }
        else {
          this.modalOpeningCount++;
        }
      }
    });
  }

  openFinalAbsentMedicalWarningModal() {
    const dialogAlert = this.dialog.open(AbsentMedicalAlertModalComponent, {
      disableClose: true,
      data: {
        title: 'Médico ausente!',
        description: 'Sua consulta foi finalizada porque o médico não compareceu ao atendimento. Por favor, agende um novo horário na Central do Paciente.',
        colorCode: this.colorCode,
        isLastWarning: true
      },
    });
    dialogAlert.afterClosed().subscribe({
      next: result => {
        clearInterval(this.countdownInterval);
        this.finish();
      }
    });
  }

  verifyTrigger(client: any, thisParam: any) {
    thisParam.socketDisconneted = false;

    if (client.action == WebsocketAppointmentActionEnum.medicEvasion) {
      thisParam.openDoctorEvadeModal();
    }
    else if (client.action == WebsocketAppointmentActionEnum.endMedicalCare) {
      // this.openEndAttendPatientModal(); finalização do atendimento
      thisParam.utilsDigitalPromptServiceService.removeAppointmentDataToTwilioSession();
      thisParam.utilsDigitalPromptServiceService.removePatientData();

      if (thisParam.twiliovideo.activeRoom) {
        thisParam.onLeaveRoom(false);
      }

      thisParam.twiliovideo.onLeaveRoom();
    }
  }

  finish() {
    this.cancelAppointment();
    this.utilsDigitalPromptServiceService.removeAppointmentDataToTwilioSession();
    this.utilsDigitalPromptServiceService.removeAppointmentDataSession();
    this.utilsDigitalPromptServiceService.removePatientData();
    this.websocketAppointmentUtilService.clearSocket();
    this.router.navigate([`patient-center/hub/${this.uri}`]);
  }

  openDoctorEvadeModal() {
    const dialogRef = this.dialog.open(WarnPatientDoctorEvadeComponent, {
      data: {
        uri: this.uri,
        idPatientAppointment: this.idPatientAppointment,
        colorCode: this.colorCode,
        isAttendPatientCenter: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.twiliovideo.onLeaveRoom();
        this.onLeaveRoom(false);    
        this.websocketAppointmentUtilService.clearSocket(); 
        this.router.navigate([`patient-center/hub/${this.uri}`]);
      }
    });
  }

  triggerAction(action: number) {
    this.triggerWebsocketService.triggerAction(this.idPatientAppointment, action).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || screen.orientation.type == "portrait-primary") {
      this.isMobileVersion = true;
    }
    else {
      this.isMobileVersion = false;
    }
  }

  async cancelAppointment() {
    this.patientAppointmentService.deletePatientAppointment(this.idPatientAppointment, AppointmentStatusEnum.MedicoNaoCompareceu).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.router.navigate(['/medic/medical-schedule']);
      },
      error: error => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
