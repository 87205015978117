import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListPatientAppointmentResponse } from "../../responses/schedule/list-patient-appointment.response";
import { PatientAppointmentResponse } from "../../responses/schedule/patient-appointment.response";
import { PostPatientAppointmentRequest } from "../../requests/orchestrator-schedule/post-patient-appointment.request";
import { PostPatientPreAppointmentRequest } from "../../requests/schedule/post-patient-pre-appointment.request";
import { PatientPreAppointmentResponse } from "../../responses/schedule/patient-pre-appointment.response";

@Injectable({
    providedIn: 'root'
})
export class PatientPreAppointmentService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }
    
    public postPatientPreAppointment(request: PostPatientPreAppointmentRequest): Observable<ReturnStruct> {

        let uri = `PatientPreAppointment`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiSchedule + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getPatientPreAppointment(): Observable<PatientPreAppointmentResponse> {

        let uri = `PatientPreAppointment/getAllByTokenIdUser`;

        return this.httpClient.get<PatientPreAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public deletePatientPreAppointment(): Observable<ReturnStruct> {

        let uri = `PatientPreAppointment/DeleteByTokenId`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}