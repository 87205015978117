import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ExternalSearchVariablesWebhook } from '../../requests/self-triage/ExternalSearchVariablesWebhook.request';
import { PatientRequest } from '../../requests/self-triage/patient.request';
import { GetPatientByCns } from '../../responses/self-triage/get-patient-by-cns.response';
import { GetPatientByCPF } from '../../responses/self-triage/get-patient-by-cpf.response';
import { CreatePatientResponse } from '../../responses/telemedicine/create-patient.response';

@Injectable({
    providedIn: 'root'
})

export class PatientService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    public getPatientByCpf(cpf: string, jsonListIdHealthUnit: string): Observable<GetPatientByCPF> {

        return this.httpClient.get<GetPatientByCPF>(environment.urlApiSelfTriage + `Patient/jsonListIdHealthUnit/${jsonListIdHealthUnit}?cpf=${cpf}`)
            .pipe(
                catchError(this.handleError)
            )
    }

    public getPatientExternalSearch(externalSearchUrl: string, body: ExternalSearchVariablesWebhook, externalSearchKey: string): Observable<GetPatientByCns> {
        let headers = 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': externalSearchKey
            };
        

        return this.httpClient.post<GetPatientByCns>(externalSearchUrl, body, {headers: new HttpHeaders(headers)})
            .pipe(
                catchError(this.handleError)
            )
    }
    
    public createPatient(createPatientRequest: PatientRequest): Observable<CreatePatientResponse>{
        return this.httpClient.post<CreatePatientResponse>(environment.urlApiSelfTriage + `Patient`, createPatientRequest)
        .pipe(
          catchError(this.handleError)
        )
    }
}