import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LoginCheckPatientRequest } from '../../requests/orchestrator-queue/login-check-patient.request';
import { GetPatientCompleteDataResponse } from '../../responses/get-patient-complete-data.response';
import { GetPatientLoginTypeResponse } from '../../responses/get-patient-login-type.response';
import { ListPatientQueueHistoryResponse } from '../../responses/list-patient-queue-history.response';

@Injectable({
  providedIn: 'root'
})
export class QrBaseAccessService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listQueueHistory(guid: string, request: LoginCheckPatientRequest): Observable<GetPatientCompleteDataResponse> {
    let uri = `QRBaseAccess/GetCompletePatientData/guid/${guid}`

    return this.httpClient.post<GetPatientCompleteDataResponse>(environment.urlApiOrchestratorQueue + uri, request)
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatient(guid: string): Observable<GetPatientLoginTypeResponse> {
    let uri = `QRBaseAccess/GetBasicPatientData/guid/${guid}`

    return this.httpClient.get<GetPatientLoginTypeResponse>(environment.urlApiOrchestratorQueue + uri)
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatientHistory(guid: string, request: LoginCheckPatientRequest): Observable<ListPatientQueueHistoryResponse> {
    let uri = `QRBaseAccess/GetPatientHistory/guid/${guid}`

    return this.httpClient.post<ListPatientQueueHistoryResponse>(environment.urlApiOrchestratorQueue + uri, request)
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatientData(guid: string, request: LoginCheckPatientRequest): Observable<GetPatientCompleteDataResponse> {
    let uri = `QRBaseAccess/GetCompletePatientData/guid/${guid}`

    return this.httpClient.post<GetPatientCompleteDataResponse>(environment.urlApiOrchestratorQueue + uri, request)
      .pipe(
        catchError(this.handleError)
      )
  }
}