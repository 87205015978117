import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilsDigitalPromptServiceService } from '../../utils.service';
import { ClientDigitalPromptServiceConfigService } from 'src/app/shared/services/API/digital-prompt-service/client-digital-prompt-service-config.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';

@Component({
  selector: 'app-patient-center',
  templateUrl: './patient-center.component.html',
  styleUrls: ['./patient-center.component.css']
})
export class PatientCenterComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private clientConfigService: ClientDigitalPromptServiceConfigService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private healthUnitService: HealthUnitService,
  ) { }

  public uri: string;
  
  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public checkMobile: boolean = false;
  public isLoading: boolean = false;
  public preview: boolean = false;

  ngOnInit(): void {
    this.utilsDigitalPromptServiceService.removePatientData();
    this.utilsDigitalPromptServiceService.removeDigitalPromptService();
    this.utilsDigitalPromptServiceService.removeUri();

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.configClient();
    }

    this.detectMobile();
  }

  ngOnDestroy(): void {
  }

  configClient() {
    this.clientConfigService.getClientConfig(this.uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.getHealthUnit(response.digitalPromptConfig.idHealthUnit);
        this.mapperConfig(response.digitalPromptConfig);
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthUnit(idHealthUnit: number) {
    this.healthUnitService.getHealthUnit(idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.utilsDigitalPromptServiceService.updatehealthUnit(response.healthUnitStruct);
        this.checkedLoading = true;
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  detectMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    )
      this.checkMobile = true;
    else
      this.checkMobile = false;
  }

  mapperConfig(digitalPromptConfigStruct: DigitalPromptConfigStruct) {
    if (this.activatedRoute.snapshot.queryParamMap.get('preview'))
      this.preview = (this.activatedRoute.snapshot.queryParamMap.get('preview') == 'true') ? true : false;

    if (digitalPromptConfigStruct != null && digitalPromptConfigStruct.isActive) {
      this.checkedUri = true;
      this.utilsDigitalPromptServiceService.updateUri(this.uri)

      if (this.preview && digitalPromptConfigStruct.colorCodePreview) {
        digitalPromptConfigStruct.useColor = true;
        digitalPromptConfigStruct.colorCode = digitalPromptConfigStruct.colorCodePreview;
      }

      if (this.preview && digitalPromptConfigStruct.logoNamePreview) {
        digitalPromptConfigStruct.isLogoUploaded == true;
        digitalPromptConfigStruct.logoString64 = digitalPromptConfigStruct.logoString64Preview;
      }
      digitalPromptConfigStruct.logoString64Preview = null;
      digitalPromptConfigStruct.colorCodePreview = null;
    }

    this.utilsDigitalPromptServiceService.setDigitalPromptServiceStruct(digitalPromptConfigStruct);
    this.checkedLoading = true;
  }
}