import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { PatientPreAppointmentService } from 'src/app/shared/services/API/schedule/patient-pre-appointment.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { PostPatientAppointmentRequest } from 'src/app/shared/services/requests/orchestrator-schedule/post-patient-appointment.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { PatientAppointmentSessionStruct } from 'src/app/shared/structs/patient-center/patient-appointment-session.struct';

@Component({
  selector: 'app-scheduling-confirm',
  templateUrl: './scheduling-confirm.component.html',
  styleUrls: ['./scheduling-confirm.component.css']
})
export class SchedulingConfirmComponent implements OnInit {
  constructor(
    public router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog, 
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private patientAppointmentService: PatientAppointmentService,
    private patientPreAppointmentService: PatientPreAppointmentService
  ) { }

  public model: FormGroup;
  public selectedInitialDate: Date;
  public masks: Masks;
  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public uri: string;
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
  public postPatientAppointmentRequest: PostPatientAppointmentRequest = new PostPatientAppointmentRequest;
  public dateAppointment: string;
  public timeAppointment: string;
  public seconds: number = 900;
  public interval: any;

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    if(!this.digitalPromptServiceConfig)
      this.router.navigate([`digital-prompt-service/${this.utilsDigitalPromptServiceService.getUri()}`]);
    
    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }  

    this.patientAppointmentSession = this.utilsDigitalPromptServiceService.getSchedulingInformations();
    if(!this.patientAppointmentSession)
      this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);

    this.masks = this.maskService.getMasks();

    this.dateAppointment = new Date(this.patientAppointmentSession.datetimeStart).toLocaleDateString();
    this.timeAppointment = this.formatHour(new Date(this.patientAppointmentSession.datetimeStart));
    this.verifyHasPreAppointment();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  submit() {
    if (this.isLoading)
      return;
    this.saveAppointment();
    this.isLoading = true;
    
  }

  backBegin(){
    this.router.navigate([`patient-center/scheduling-date/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  saveAppointment(){
    this.postPatientAppointmentRequest.idSchedule = this.patientAppointmentSession.idSchedule;
    this.postPatientAppointmentRequest.datetimeStart = this.patientAppointmentSession.datetimeStart;
    this.postPatientAppointmentRequest.datetimeEnd = this.patientAppointmentSession.datetimeEnd;
    this.postPatientAppointmentRequest.idUser = this.patientAppointmentSession.idUser;
    this.postPatientAppointmentRequest.idPatientHealthcareAgreement = this.patientAppointmentSession.idPatientHealthcareAgreement;
    this.postPatientAppointmentRequest.idTypeOfCare = this.patientAppointmentSession.idTypeOfCare;
    this.postPatientAppointmentRequest.idMedicalSpecialty = this.patientAppointmentSession.idMedicalSpecialty;
    this.postPatientAppointmentRequest.userName = this.patientAppointmentSession.userName;
    this.postPatientAppointmentRequest.healthUnitName = this.patientAppointmentSession.healthUnitName;
    this.postPatientAppointmentRequest.timeZoneValue = this.patientAppointmentSession.timeZoneValue;

    this.postPatientAppointmentRequest.idHealthcareAgreement = this.patientAppointmentSession.idHealthcareAgreement;
    this.postPatientAppointmentRequest.idHealthcareAgreementPlan = this.patientAppointmentSession.idHealthcareAgreementPlan;
    this.postPatientAppointmentRequest.healthcareAgreementCardNumber = this.patientAppointmentSession.healthcareAgreementCardNumber;
    this.postPatientAppointmentRequest.healthcareAgreementExpirationDate = this.patientAppointmentSession.healthcareAgreementExpirationDate;

    this.isLoading = true;
    this.patientAppointmentService.postPatientAppointment(this.postPatientAppointmentRequest).subscribe({
      next: (response) => { 
        if (response.isError && response.errorCode == 1) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.backBegin();
          return;
        }      
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }  
        this.utilsDigitalPromptServiceService.removeSchedulingInformations();
        this.router.navigate([`patient-center/scheduling-end/${this.utilsDigitalPromptServiceService.getUri()}`]);
        this.isLoading = false; 
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  formatHour(value: Date){
    let hour: string = value.toLocaleTimeString()
    hour = hour.substring(0, 2);
    let minute: string = value.toLocaleTimeString();
    minute = minute.substring(3, 5)
    return `${hour}h${minute}`;
  }

  openEndPreAppointmentModal(){
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        title: 'Alerta',
        description: 'O tempo de pré-agendamento expirou. Favor selecionar um novo horário para prosseguir com o agendamento.',
        colorCode: this.colorCode
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.backBegin()
      }
    });
  }

  verifyHasPreAppointment() {
    this.isLoading = true;
    this.patientPreAppointmentService.getPatientPreAppointment().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.patientPreAppointment){          
          let dateNow: Date = new Date();
          dateNow = new Date(dateNow.getTime() + (dateNow.getTimezoneOffset() * 60000));

          let dateInclusionPreAppointment: Date = new Date(response.patientPreAppointment.datetimeInclusion);

          let timeDifferenceInMillis: number = dateNow.getTime() - dateInclusionPreAppointment.getTime();
          let timeDifferenceInSeconds: number = timeDifferenceInMillis / 1000;
          this.seconds = this.seconds - timeDifferenceInSeconds;
          this.startTime();

        } else {
          this.openEndPreAppointmentModal();
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);
    var seconds = this.seconds;
    var $this = this;
    function myMethod() {
      if (seconds > 0)
        seconds = seconds - 1;

      if (seconds <= 0) {
        clearInterval($this.interval);
        $this.openEndPreAppointmentModal();
      }
    }
  }  
}
