import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.css']
})
export class TermsOfUseModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TermsOfUseModalComponent>,
    public router: Router,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string = this.data.colorCode;
  public selfTriageConfig: SelfTriageConfigStruct;
  public urlPrivacyPolicy = environment.urlUIPatientBase + "assets/documents/privacy_policy.pdf";

  ngOnInit(): void {
  }

  clickGo() {
    this.matDialogRef.close(true);
  }

  clickBack() {
    this.matDialogRef.close(false);
  }
}