import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import { WelcomeModule } from './welcome/welcome.module';
import { HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SelfTriageModule } from './self-triage/self-triage.module';
import { TelemedicineModule } from './telemedicine/telemedicine.module';
import { environment } from 'src/environments/environment';
import { NpsModule } from './nps/pages/nps.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { PatientCenterModule } from './patient-center/patient-center.module';

@NgModule({
  declarations: [

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AuthModule,
    PatientCenterModule,
    WelcomeModule,
    SelfTriageModule,
    TelemedicineModule,
    NpsModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: true,
      enabled: environment.production || environment.staging
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
