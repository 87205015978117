<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            <div class="white-container">
                <div class="row white-container-body">
                    <div class="col-11">
                        <h1 class="patient-center-title">Agendamento de consultas</h1>
                    </div>                  
                    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>   
                    <form class="form" [formGroup]="model" (ngSubmit)="submit()" *ngIf="!isFirstLoading">                    
                        <div class="row">
                            <div *ngIf = "this.digitalPromptServiceConfig.allowSelectionHealthcareAgreement">
                                <h2 class="patient-center-subtitle">Selecione o seu convênio:</h2>
                                <div class="col-12">
                                    <mat-form-field appearance="outline"
                                        [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('idPatientHealthcareAgreement').errors || !model.get('idPatientHealthcareAgreement').touched,'outline-spinner form-field-patient-center-error': model.get('idPatientHealthcareAgreement').errors && model.get('idPatientHealthcareAgreement').touched}">
                                        <mat-label>Selecione uma opção</mat-label>
                                        <mat-select formControlName="idPatientHealthcareAgreement" (click)="verifyPatientHasHealthPlanToEmitAlert()">
                                            <mat-option *ngFor="let item of listPatientHealthcareAgreement" [value]="item.idPatientHealthcareAgreement" (click)="filterOptionMedicalSpecialtyStruct(true,item )">
                                                {{item.nameHealthcareAgreement}} - {{item.planName}} ({{item.healthcareAgreementCardNumber}})</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="model.get('idPatientHealthcareAgreement').invalid">Campo de preenchimento obrigatório</mat-error>
                                    </mat-form-field>
                                    <div class="row check-and-link-health-plan">
                                        <div class="col-12 col-sm-6 no-health-plan">
                                            <mat-checkbox (change)="checkNoHealthPlanSelection($event.checked)" [ngModel]="isCheckedNoHealthPlan" [ngModelOptions]="{standalone: true}" [disabled]="isFirstLoading">
                                                <span>Não tenho convênio</span>
                                            </mat-checkbox>
                                        </div>
                                        <div class="col-12 col-sm-6" [ngClass]="{'disabled-link': isFirstLoading}">
                                            <a [routerLink]="['/patient-center/healthcare-agreement',uri]" [queryParams]="{ isScheduling: true }" (click)="onRedirectToHealthcareAgreement()">
                                                <mat-icon>edit_note</mat-icon>
                                                <span>Gerenciar convênios</span>
                                            </a>
                                        </div> 
                                    </div>                                                                   
                                </div>
                                <hr />
                            </div>                        
                            <h2 class="patient-center-subtitle">Especialidade médica:</h2>
                            <div class="col-12" >
                                <mat-form-field appearance="outline"
                                    [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('idSpecialty').errors || !model.get('idSpecialty').touched,'outline-spinner form-field-patient-center-error': model.get('idSpecialty').errors && model.get('idSpecialty').touched}">
                                    <mat-label>Selecione uma opção</mat-label>
                                    <mat-select formControlName="idSpecialty">
                                        <mat-option *ngFor="let item of listMedicalSpecialtyCompleted" [value]="item.medicalSpecialty.idMedicalSpecialty" [disabled]="!item.hasTheSelectedHealthcareAgreement" (click)="filterOptionNatureOfAttendanceSelect(item)">
                                            {{item.medicalSpecialty.medicalSpecialtyName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idSpecialty').invalid">Campo de preenchimento obrigatório</mat-error>
                                </mat-form-field>
                            </div>
                            <hr />
                            <h2 class="patient-center-subtitle">Tipo de atendimento</h2>
                            <div class="col-12">
                                <mat-form-field appearance="outline"
                                    [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('idNatureOfAttendance').errors || !model.get('idNatureOfAttendance').touched,'outline-spinner form-field-patient-center-error': model.get('idNatureOfAttendance').errors && model.get('idNatureOfAttendance').touched}">
                                    <mat-label>Selecione uma opção</mat-label>
                                    <mat-select  formControlName="idNatureOfAttendance" >
                                        <mat-option *ngFor="let item of listNatureOfAttendanceToDisplay" [value]="item.idNatureOfAttendance">
                                            {{item.natureOfAttendanceName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idNatureOfAttendance').invalid">Campo de preenchimento obrigatório</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <button mat-flat-button color="primary" class="button-submit" [disabled]="model.invalid">
                            <span *ngIf="isLoading == false">Seguir com o agendamento</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                        <button mat-flat-button type="button" color="secundary" class="button-back" (click)="backBegin()">
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </form>
                    
                    
                    <div class="footer-white-container">
                        <p>Central do paciente</p>
                    </div>
                </div>                
            </div>
        </div>
    </body>
</div>