<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="container orientations-session">
                <div class="section-title" [ngStyle]="{'color': colorCode}">
                    <h1>Orientações para começar</h1>
                </div>
                <div class="form-group">
                    <div class="container-checkbox">
                        <mat-checkbox class="ckeck-orientations" (change)="onCheckChange()" [disabled]="isDisabled"
                            id="" [(ngModel)]="checkedPlace">
                            <div class="div-flex">
                                Estou em um lugar tranquilo e iluminado
                            </div>
                        </mat-checkbox>
                        <br />
                        <mat-checkbox class="ckeck-orientations" (change)="onCheckChange()" [disabled]="isDisabled"
                            id="" [(ngModel)]="checkedNotDriving">
                            <div class="div-flex">
                                Não estou dirigindo
                            </div>
                        </mat-checkbox>
                        <br />
                        <mat-checkbox (change)="onCheckChange()" [disabled]="isDisabled" id=""
                            [(ngModel)]="checkedClothing">
                            <div class="div-flex">
                                Estou devidamente vestido
                            </div>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 forward-button">
                        <button mat-flat-button color="primary" (click)="forward()" [disabled]="!isAllChecked">
                            <span *ngIf="!isLoading">PRÓXIMO</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading">
                            </mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>