import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsNpsService } from '../utils.service';
import { ClientNpsMessageService } from 'src/app/shared/services/API/nps/client-nps-message.service';
import { NpsMessageResponse } from 'src/app/shared/services/responses/nps/nps-message.reponse';

@Component({
  selector: 'app-voting-page',
  templateUrl: './voting-page.component.html',
  styleUrls: ['./voting-page.component.css']
})
export class VotingPageComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    public router: Router,
    private utilsNpsService: UtilsNpsService,
    private clientNpsMessageService: ClientNpsMessageService,
  ) { }

  public isLoading: boolean = false;
  public arrayIndexNps = new Array(11);
  public npsModel: FormGroup;
  public npsMessageResponse: NpsMessageResponse;
  public colorCode: string = "";
  public commentCollectionActive: boolean = false;

  ngOnInit(): void {
    this.npsMessageResponse = this.utilsNpsService.getNpsMessageResponse();

    if (!this.npsMessageResponse.npsMessage)
      this.router.navigate([`nps/${this.npsMessageResponse.npsMessage.guid}`]);

    if (this.npsMessageResponse.npsScreenConfig.useColor) {
      this.colorCode = this.npsMessageResponse.npsScreenConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeNps', this.colorCode);
    }

    this.npsModel = this.formBuilder.group({
      nps: ['', [Validators.required]],
      commentsCollection: ['']
    });

    if (this.npsMessageResponse.npsScreenConfig.commentCollectionActive)
      this.commentCollectionActive = true;
  }

  saveNote() {
    this.npsMessageResponse.npsMessage.note = this.npsModel.get("nps").value;

    if (this.npsModel.get("commentsCollection") && this.npsModel.get("commentsCollection") != null)
      this.npsMessageResponse.npsMessage.commentsCollection = this.npsModel.get("commentsCollection").value;

    this.clientNpsMessageService.updateMessage(this.npsMessageResponse.npsMessage).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate([`nps/end-nps/${this.npsMessageResponse.npsMessage.guid}`]);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}