<div class="container-box">
    <div class="container orientations-session">
        <div class="section-title">
            <h3>Resumo do agendamento</h3>
        </div>
        <div class="medic-card">
            <div>
                <mat-icon>account_circle</mat-icon>
            </div>
            <div>
                <p class="info-label">Doutor(a):</p>
                <p class="info-content">{{apppointment.userName}}</p>
                <p *ngIf="apppointment.userCouncil && apppointment.userCouncilUF" class="info-content">CRM - {{apppointment.userCouncilUF}}: {{apppointment.userCouncil}}</p>                
                <p *ngIf="apppointment.userCouncil && !apppointment.userCouncilUF" class="info-content">CRM: {{apppointment.userCouncil}}</p>



            </div>
        </div>
        <div class="row appointment-info">
            <div class="col-12 col-sm-6 col-md-6">
                <p class="info-label">Especialidade:</p>
                <p class="info-content">{{apppointment.medicalSpecialtyName}}</p>
            </div>
            <div class="col-12 col-sm6 col-md-6">
                <p class="info-label">Tipo de atendimento:</p>
                <p class="info-content">{{apppointment.natureOfAttendanceName}}</p>
            </div>
            <div class="col-12 col-sm6 col-md-6">
                <p class="info-label">Data:</p>
                <p class="info-content">{{apppointment.datetimeStart | date: 'dd/MM/yyyy'}} às
                    {{apppointment.datetimeStart | date: 'HH:mm'}}</p>
            </div>
            <div class="col-12 col-sm6 col-md-6">
                <p class="info-label">Convênio:</p>
                <p class="info-content">{{apppointment.healthPlanName ? apppointment.healthPlanName : '-'}}</p>
            </div>
            <div class="col-12 col-sm6 col-md-6">
                <p class="info-label">Unidade de Saúde:</p>
                <p class="info-content">{{apppointment.healthUnitName}}</p>
            </div>
            <div class="col-12 col-sm6 col-md-6">
                <p class="info-label">Endereço:</p>
                <p class="info-content">{{apppointment.healthUnitAddress ? apppointment.healthUnitAddress : '-'}}</p>
            </div>
        </div>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="goBack()">
            <span *ngIf="!isLoading">Voltar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading">
            </mat-spinner>
        </button>
        <button mat-flat-button *ngIf="isFutureAppointment(apppointment.datetimeStart)" class="btn-secundary-flex"
            color="primary" type="button" (click)="cancelAppointment()">
            <span *ngIf="!isLoading">Cancelar agendamento</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading">
            </mat-spinner>
        </button>
    </div>
</div>