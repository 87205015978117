import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetConfigResponse } from '../../responses/digital-prompt-service/nps-message.reponse';

@Injectable({
    providedIn: 'root'
})

export class ClientDigitalPromptServiceConfigService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public getClientConfig(uriClient: string): Observable<GetConfigResponse> {
        let uri = `ClientDigitalPromptConfig/uri/${uriClient}`

        return this.httpClient.get<GetConfigResponse>(environment.urlApiDigitalPrompt + uri, this.addHeaderInternalToken())
            .pipe(catchError(this.handleError));
    }
}