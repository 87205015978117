import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { LoginRequest } from '../../requests/patient-center/login.request';
import { LookupResponse } from '../../responses/patient-center/lookup.response';

@Injectable({
  providedIn: 'root'
})

export class LookupService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAll(): Observable<LookupResponse> {
    let uri = `Lookup`;

    return this.httpClient.get<LookupResponse>(environment.urlApiPatientCenter + uri)
      .pipe(
        catchError(this.handleError)
      )
  }
}