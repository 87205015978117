import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-list',
  templateUrl: './loading-list.component.html',
  styleUrls: ['./loading-list.component.css']
})
export class LoadingListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
