import { ValidatorFn, AbstractControl } from "@angular/forms";

export function PhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = control.value;
    const regex = /^\d{2}9\d{4,5}\d{4}$/;

    if (!phoneNumber || !regex.test(phoneNumber)) 
      return { 'invalidPhone': true };

    return null;
  };
}