import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDisplayModalComponent } from './notification-display-modal/notification-display-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MessagePatientCenterStruct } from 'src/app/shared/services/structs/notification/message-patient-center.struct';
import { MessagePatientCenterService } from 'src/app/shared/services/API/notification/message-patient-center.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private messagePatientCenterService: MessagePatientCenterService,
  ) { }

  @Input() isVisible: boolean;
  @Input() isLoading: boolean;
  @Input()  colorCode: string;
  @Input() listMessagePatientCenter: MessagePatientCenterStruct[];

  @Output() closeNotification = new EventEmitter<any>();
  @Output() populateNotification = new EventEmitter<any>();

  public isModalView: boolean = false;
  public idUserMessagePatientCenter: number;

  ngOnInit(): void {
  }

  singleRead(idUserMessagePatientCenter: number){
    this.messagePatientCenterService.markNotificationRead(idUserMessagePatientCenter).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.repopulate();
      
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  readAll() {
    this.messagePatientCenterService.markAllNotificationRead().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.repopulate();

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  singleDelete(messagePatientCenterStruct: MessagePatientCenterStruct){
    this.messagePatientCenterService.deleteNotification(messagePatientCenterStruct.idUserMessagePatientCenter).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.repopulate();

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  deleteAll() {
    this.messagePatientCenterService.deleteAllNotification().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.repopulate();

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openViewModal(messagePatientCenter: MessagePatientCenterStruct) {
    if (messagePatientCenter.isRead != true)
      this.singleRead(messagePatientCenter.idUserMessagePatientCenter);

    this.isModalView = true;
    this.idUserMessagePatientCenter = messagePatientCenter.idUserMessagePatientCenter;

    const dialogRef = this.dialog.open(NotificationDisplayModalComponent, {
      data: {
        messagePatientCenter: messagePatientCenter,
        color: this.colorCode,
      },
      panelClass: "border-radius-box"
      
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.isModalView = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
  

  repopulate() {
    this.populateNotification.emit();
  }

  close() {
    this.closeNotification.emit();
  }
}
