<div mat-dialog-content>
    <div class="mat-dialog-content">

        <h1 class="self-triage-title">Termos de Uso</h1>
        <hr/>
        <div class="row terms-of-use-content">
            <p>Ao utilizar esta plataforma para o atendimento online, serão tratados (a luz da Lei 13.709/18 (LGPD)) dados pessoais sensíveis.</p>
            <p>O uso destes dados tem por finalidade exclusiva o atendimento realizado por profissionais da área da saúde e está amparado juridicamente pelo artigo 11 - f.</p>
            <p>Ao se cadastrar nesta plataforma, o titular de dados declara estar ciente do tratamento e dá o seu consentimento expresso para o tratamento de seus dados pessoais, especificados em nossa <a href="{{urlPrivacyPolicy}}" target="_blank"> Política de Privacidade </a>.</p>
        </div>

        <div class="botton-content">
            <button mat-flat-button color="primary" class="button-go" (click)="clickGo()">
                <span *ngIf="isLoading == false">Aceito</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="secundary" class="button-back" (click)="clickBack()">
                <span *ngIf="isLoading == false">Não aceito</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        
        <div class="footer-white-container">
            <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
        </div>
        
    </div>
</div>
