import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { DeleteItemModalComponent } from 'src/app/shared/components/delete-item-modal/delete-item-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { UserPatient } from 'src/app/shared/services/models/patient-center/user-patient.model';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { PatientHealthcareAgreementPatientCenterService } from 'src/app/shared/services/API/patient-center/patient-healthcare-agreement-patient-center.service';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { PatientHealthcareAgreementRequest } from 'src/app/shared/services/requests/patient-center/patient-healthcare-agreement.request';
import { HealthcareAgreementPlanService } from 'src/app/shared/services/API/private-billing/healthcare-agreement-plan.service';
import { PatientHealthcareAgreementService } from 'src/app/shared/services/API/orchestrator-schedule/patient-healthcare-agreement.service';
import { PatientHealthcareAgreementStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-healthcare-agreement.struct';
import { UpdateHealthcareAgreementModalComponent } from './update-healthcare-agreement-modal/update-healthcare-agreement-modal.component';
import { VerifyMinDate } from 'src/app/shared/custom-validators/date.validator';

@Component({
  selector: 'app-healthcare-agreement',
  templateUrl: './healthcare-agreement.component.html',
  styleUrls: ['./healthcare-agreement.component.css']
})
export class HealthcareAgreementComponent implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog, 
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private patientHealthcareAgreementPatientCenterService: PatientHealthcareAgreementPatientCenterService,
    private healthcareAgreementPlanService: HealthcareAgreementPlanService,
    private patientHealthcareAgreementService: PatientHealthcareAgreementService,
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public patient: UserPatient;
  
  public listPatientHealthcareAgreement: PatientHealthcareAgreementStruct[] = [];
  public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listActiveHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listHealthcareAgreementPlan: Plan[] = [];
  
  public colorCode: string = '#99C8D6';

  public isFirstLoading: boolean;
  public isLoading: boolean = true;
  public backToScheduling: boolean = false;
  public loadingHealthcareAgreement: boolean = false;

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.masks = this.maskService.getMasks();

    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    let isScheduling = this.activatedRoute.snapshot.queryParamMap.get('isScheduling');
    if (isScheduling)
      this.backToScheduling = true;
    
    this.model = this.formBuilder.group({
      healthcareAgreement: ['', [Validators.required]],
      healthcareAgreementPlan: [{value: '', disabled: true}, [Validators.required]],
      healthcareAgreementCardNumber: ['', [Validators.required]],
      healthcareAgreementExpirationDate: ['', [VerifyMinDate(), Validators.required]],
    });

    this.getHealthcareAgreement();
    this.getAllPatientHealthcareAgreement();
  }

  submit(formDirective: FormGroupDirective) {
    if (this.isLoading)
    return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    let request = new PatientHealthcareAgreementRequest();
    request.idHealthcareAgreement = this.model.get('healthcareAgreement').value;
    request.idHealthcareAgreementPlan = this.model.get('healthcareAgreementPlan').value;
    request.healthcareAgreementCardNumber = this.model.get('healthcareAgreementCardNumber').value;
    request.healthcareAgreementExpirationDate = this.maskService.formatStringToDate(this.model.get('healthcareAgreementExpirationDate').value);

    this.patientHealthcareAgreementPatientCenterService.CreatePatientHealthcareAgreement(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.model.reset();
        formDirective.resetForm();
        this.getAllPatientHealthcareAgreement();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    })
  }

  backPage(){
    this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  backToSchedulingPage(){
    this.router.navigate([`patient-center/scheduling-information/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  getAllPatientHealthcareAgreement() {
    this.loadingHealthcareAgreement = true;
    
    this.patientHealthcareAgreementService.getAllPatientHealthcareAgreementByIdPatient().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loadingHealthcareAgreement = false;
          return;
        }
        this.listPatientHealthcareAgreement = response.listPatientHealthcareAgreementStruct;
        this.loadingHealthcareAgreement = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.loadingHealthcareAgreement = false;
      }
    })
  }

  getHealthcareAgreement(getJustActive?: boolean) {
    this.healthcareAgreementPlanService.getAllToSelect(null, null, getJustActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listHealthcareAgreement = response.list;
        this.listActiveHealthcareAgreement = response.list.filter(item => item.isActive);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getPlans() {
    this.isLoading = true;
    let idHealthcareAgreement = this.model.get('healthcareAgreement').value ? this.model.get('healthcareAgreement').value : null;
    this.listHealthcareAgreementPlan = [];
    
    this.healthcareAgreementPlanService.GetAllToSelectById(idHealthcareAgreement, null, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listHealthcareAgreementPlan = response.listPlan.filter(item => !item.isDeleted);
        this.model.get('healthcareAgreementPlan').setValue(null);
        if(this.listHealthcareAgreementPlan?.length > 0)
          this.model.get('healthcareAgreementPlan').enable();
        else
          this.model.get('healthcareAgreementPlan').disable();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openUpdateModal(patientHealthcareAgreementItem){
    const dialogRef = this.dialog.open(UpdateHealthcareAgreementModalComponent, {
      data: {
        color: this.colorCode,
        listHealthcareAgreement: this.listHealthcareAgreement,
        patientHealthcareAgreement: patientHealthcareAgreementItem,
      },
    });
    
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.updateItem){
        this.getAllPatientHealthcareAgreement();
      }
    });
  }

  openDeleteModal(idPatientHealthcareAgreement){
    const dialogRef = this.dialog.open(DeleteItemModalComponent, {
      data: {
        title: 'Deseja excluir o Convênio?',
        description: 'Você realmente deseja excluir este convênio? Ele perderá todas as configurações realizadas e essa ação é irreversível.',
        color: this.colorCode,
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteItem){
        this.isLoading = false;
        this.deletePatientHealthcareAgreement(idPatientHealthcareAgreement);
      }
    });
  }

  deletePatientHealthcareAgreement(idPatientHealthcareAgreement){
    this.isLoading = true;
    this.patientHealthcareAgreementPatientCenterService.DeletePatientHealthcareAgreement(idPatientHealthcareAgreement).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Convênio excluido com sucesso!", AlertType.success); 
        this.isLoading = false;
        this.getAllPatientHealthcareAgreement();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    })
  }
}
