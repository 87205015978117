import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-end-self-triage',
  templateUrl: './end-self-triage.component.html',
  styleUrls: ['./end-self-triage.component.css']
})
export class EndSelfTriage implements OnInit {

  constructor(
    private utilSelfTriaveService: UtilsSelfTriageService,
    public router: Router,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public selfTriageConfig: SelfTriageConfigStruct;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.end;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilSelfTriaveService.getSelfTriageConfigStruct();

    if (!this.selfTriageConfig) this.router.navigate([`self-triage/${this.utilSelfTriaveService.getUri()}`]);

    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }
  }

  clickGo() {
    this.router.navigate([`self-triage/welcome/${this.utilSelfTriaveService.getUri()}`]);
  }

}
