import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ProtocolComponent } from './protocol/protocol.component';
import { UtilsSelfTriageService } from '../../utils.service';
import { ClientSelfTriageConfigService } from 'src/app/shared/services/API/orchestrator-patient/client-self-triage-config.service';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';

@Component({
  selector: 'app-self-triage',
  templateUrl: './self-triage.component.html',
  styleUrls: ['./self-triage.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class SelfTriageComponent implements OnInit {
  @ViewChild(ProtocolComponent) protocolComponent: ProtocolComponent;

  constructor(private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private clientConfigService: ClientSelfTriageConfigService,
    private utilsSelfTriageService: UtilsSelfTriageService
  ) { }

  public uri: string;

  public selfTriageConfig: SelfTriageConfigStruct = new SelfTriageConfigStruct();
  public patient: Patient = new Patient();

  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public checkMobile: boolean = false;
  public isLoading: boolean = false;
  public preview: boolean = false;
  public celerusAccessSelfTriage: boolean = false;
  public patientCenterAccessSelfTriage: boolean = false;
  public forwardMedic: boolean = false;

  ngOnInit(): void {
    this.selfTriageConfig = this.utilsSelfTriageService.getSelfTriageConfig();
    this.patient = this.utilsSelfTriageService.getPatientData();

    this.patientCenterAccessSelfTriage = this.patient ? this.patient.patientCenterAccessSelfTriage : false;
    if (this.patientCenterAccessSelfTriage) {
      //Central do paciente > Autotriagem
      this.utilsSelfTriageService.setSelfTriageAccessSelfTriage(this.patientCenterAccessSelfTriage);
      this.mapperConfig(this.selfTriageConfig);
    }
    else {
      //Celerus > Autotriagem
      this.celerusAccessSelfTriage = true;
      
      this.utilsSelfTriageService.removePatientData();
      this.utilsSelfTriageService.removeSelfTriageConfig();
      this.utilsSelfTriageService.removeUri();

      if (this.activatedRoute.snapshot.paramMap.get('uri')) {
        this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
        this.configClient();
      }
    }

    this.detectMobile();
  }

  ngOnDestroy(): void {
  }

  configClient() {
    this.clientConfigService.getClientConfig(this.uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.utilsSelfTriageService.updateFullUrl(window.location.href);
        this.utilsSelfTriageService.setSelfTriageListExternalSearchField(response.listField);
        this.mapperConfig(response.selfTriageConfig);
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  detectMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    )
      this.checkMobile = true;
    else
      this.checkMobile = false;
  }

  mapperConfig(selfTriageConfig: SelfTriageConfigStruct) {
    if (this.activatedRoute.snapshot.queryParamMap.get('preview'))
      this.preview = (this.activatedRoute.snapshot.queryParamMap.get('preview') == 'true') ? true : false;

    if (selfTriageConfig != null &&
      ((selfTriageConfig.isActive && this.celerusAccessSelfTriage) ||
        (selfTriageConfig.useSelfTriageInPatientCenter && this.patientCenterAccessSelfTriage))) {
      this.checkedUri = true;
      if (this.patientCenterAccessSelfTriage)
        this.utilsSelfTriageService.updateUri(selfTriageConfig.url)
      else
        this.utilsSelfTriageService.updateUri(this.uri)

      if (this.preview && selfTriageConfig.colorCodePreview) {
        selfTriageConfig.useColor = true;
        selfTriageConfig.colorCode = selfTriageConfig.colorCodePreview;
      }

      if (this.preview && selfTriageConfig.logoNamePreview) {
        selfTriageConfig.isLogoUploaded == true;
        selfTriageConfig.logoString64 = selfTriageConfig.logoString64Preview;
      }
      selfTriageConfig.logoString64Preview = null;
      selfTriageConfig.colorCodePreview = null;
    }

    this.utilsSelfTriageService.setSelfTriageConfigResponse(selfTriageConfig);
    this.checkedLoading = true;
  }
}