import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ClientTelemedicineAccessRequest } from '../../requests/telemedicine/client-telemedicine-access.request';
import { ClientConfigResponse } from '../../responses/client-config.response';
import { ClientTelemedicineAccessResponse } from '../../responses/telemedicine/client-telemedicine-access.response';
import { PostAccessResponse } from '../../responses/telemedicine/post-access.response';

@Injectable({
    providedIn: 'root'
})

export class ClientTelemedicineAccessService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getIdPatientFromGuid(guid: string): Observable<ClientTelemedicineAccessResponse>{
    let uri = `ClientTelemedicineAccess/guid/${guid}`;
  
    return this.httpClient.get<ClientTelemedicineAccessResponse>(environment.urlApiTelemedicine + uri)
    .pipe(
      catchError(this.handleError)
    )
  }

  public postTelemedicineAccess(request: ClientTelemedicineAccessRequest): Observable<PostAccessResponse> {
    let uri = `ClientTelemedicineAccess`

    return this.httpClient.post<PostAccessResponse>(environment.urlApiTelemedicine + uri, request)
    .pipe(
      catchError(this.handleError)
    )
  }
}