import { ValidatorFn, AbstractControl } from "@angular/forms";

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const valid = EMAIL_REGEX.test(control.value);
    return valid ? null : { invalidEmail: { value: control.value } };
  };
}