import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { PatientAccessService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access.service';
import { SelfTriagePatientStruct } from 'src/app/shared/services/structs/telemedicine/self-triage-patient.struct';
import { ClientTelemedicineService } from 'src/app/shared/services/API/telemedicine/client-telemedicine.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { EpisodeData, PatientData, UtilsTelemedicineService } from '../../utils.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { QueueUnStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/queue.struct';
import { HealthIdentificationEnum } from 'src/app/shared/enum/health-identification.enum'
import { PatientDataRequest } from 'src/app/shared/services/requests/orchestrator-telemedicine/patient-data.request';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-telemedicine/episode.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { EpisodePreEvaluation } from 'src/app/shared/services/models/telemedicine/episode-pre-evaluation.model';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { TelemedicineLookupService } from 'src/app/shared/services/API/telemedicine/lookup.service';
import { SymptomDuration } from 'src/app/shared/services/models/telemedicine/symptom-duration.model';
import { EpisodePreEvaluationRequest } from 'src/app/shared/services/requests/telemedicine/episode-pre-evaluation.request';
import { EpisodePreEvaluationService } from 'src/app/shared/services/API/telemedicine/episode-pre-evaluation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { validateBirthDate } from 'src/app/shared/custom-validators/birth-date-validator';
import { PatientEpisodeQueueService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-episode-queue.service';
import { QueueStatusEnum } from 'src/app/shared/enum/telemedicine/queue-status.enum';
import { EpisodeStatusEnum } from 'src/app/shared/enum/telemedicine/episode-status.enum';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { environment } from 'src/environments/environment';
import { HealthUnitStateModel } from 'src/app/shared/services/models/user/health-unit-state.model';
import { HealthUnitCityModel } from 'src/app/shared/services/models/user/health-unit-city.model';

@Component({
  selector: 'app-telemedicine-self-triage',
  templateUrl: './telemedicine-self-triage.component.html',
  styleUrls: ['./telemedicine-self-triage.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class TelemedicineSelfTriageComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private telemedicineLookupService: TelemedicineLookupService,
    private episodePreEvaluationService: EpisodePreEvaluationService,
    public patientAccessService: PatientAccessService,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private clientConfigService: ClientTelemedicineService,
    private episodeOrchestratorTelemedicine: EpisodeService,
    public patientAccessLeaving: PatientAccessLeavingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private patientEpisodeQueueService: PatientEpisodeQueueService,
  ) { }

  public healthIdentificationEnum: HealthIdentificationEnum;
  public patientDataRequest: PatientDataRequest = new PatientDataRequest();
  public colorCode: string = "";
  public logoString64: string;
  public uri: string;
  public checkedPatient: boolean = false;
  public patientName: string;
  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public telemedicineConfig: TelemedicineConfigStruct;
  public isLogoUploaded: boolean = false;
  public isLoading: boolean;
  public idEpisode: number;
  public idStatusQueue: number;
  public clientLogo: any;
  public queue: QueueUnStruct;
  public episodeData: EpisodeData;
  public selfTriagePatient: SelfTriagePatientStruct;
  public patientData: PatientData = new PatientData();
  public isLoadingCpf: boolean;
  public actualTicket: string = "-";
  public episodePreEvaluation: EpisodePreEvaluation = new EpisodePreEvaluation();
  public isMobileVersion: boolean;
  public symptomDurationList: SymptomDuration[];
  public preview: boolean = false;
  public celerusAccessTelemedicine: boolean = false;
  public selfTriageAccessTelemedicine: boolean = false;
  public guid: string;
  public idSelfTriagePatient: number;
  public identificationModel: FormGroup;
  public additionalDataModel: FormGroup;
  public orientationsModel: FormGroup;
  public oldFlowControl: number = 0;

  //autotriagem
  public isSelectCity: boolean = false;
  public urlListState: string = environment.urlApiUser + "ClientHealthUnitState";
  public listAllStates: HealthUnitStateModel[] = [];
  public preSelectState: any[] = [];
  public urlListCity: string;
  public listAllCities: HealthUnitCityModel[] = [];
  public preSelectCity: any[] = [];

  //Variáveis de fluxo
  public flowControl: number = TelemedicineFlowEnum.welcome;
  public welcome: number = TelemedicineFlowEnum.welcome;
  public patientIdentification: number = TelemedicineFlowEnum.patientIdentification;
  public emergencyCheck: number = TelemedicineFlowEnum.emergencyCheck;
  public preEvaluation: number = TelemedicineFlowEnum.preEvaluation;
  public additionalData: number = TelemedicineFlowEnum.additionalData;
  public orientations: number = TelemedicineFlowEnum.orientations;
  public waitingArea: number = TelemedicineFlowEnum.waitingArea;

  ngOnInit(): void {
    this.isMobile();

    this.oldFlowControl = this.utilsTelemedicineService.getOldFlowControl();
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
    this.episodeData = this.utilsTelemedicineService.getEpisodeData();

    this.createFormGroup()
    this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
    if (this.oldFlowControl != null && this.oldFlowControl != this.welcome) {
      this.flowControl = this.oldFlowControl;
    }
    else {
      this.getPatientByGuid(this.guid);
    }

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.utilsTelemedicineService.updateUri(this.uri);
      this.configClient();
      this.telemedcineLookup();
    }
  }

  goToWaitingArea() {
    this.flowControl = TelemedicineFlowEnum.waitingArea;
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet)
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }

  telemedcineLookup() {
    this.telemedicineLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.symptomDurationList = response.listSymptomDuration;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  configClient() {
    this.clientConfigService.getClientConfig(this.uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.telemedicineConfig = response.telemedicineConfig;
        this.mapperTelemedicineConfig();
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);

        const cpfToUse = this.patientData?.cpf ?? null;

        if ((this.activatedRoute.snapshot.paramMap.get('guid')))
          this.getPatientEpisodeQueueByCPF(cpfToUse, this.telemedicineConfig.idHealthUnit);
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToPatientData(patient: PatientStruct): PatientData {
    let PatientData: PatientData = {
      birthDate: patient.birthDate,
      city: patient.city,
      cns: patient.cns,
      cpf: patient.cpf,
      email: patient.email,
      idGender: patient.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patient.idPatient,
      neighborhood: patient.neighborhood,
      patientName: patient.patientName,
      idPatientTelemedicineIntegration: patient.idPatientTelemedicineIntegration,
      phone: patient.phone1 ? patient.phone1 : patient.phone2,
      state: patient.state,
      zipCode: patient.zipCode,
      datetimeinclusion: null,
      healthPlan: patient.healthInsurance,
      healthPlanExpirationDate: patient.healthInsuranceExpirationDate,
      healthPlanNumber: patient.healthInsuranceRegistration,
      idQueue: this.queue?.idQueue,
      patientCenterAccessTelemedicine: false,
      street: patient.street,
      houseNumber: patient.houseNumber,
    };

    return PatientData;
  }

  mapToPatientDataRequest(patientData: PatientData): PatientDataRequest {
    let patientDataRequest: PatientDataRequest = {
      birthDate: patientData.birthDate,
      city: patientData.city,
      cns: patientData.cns,
      cpf: patientData.cpf,
      email: patientData.email,
      idGender: patientData.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patientData.idPatient,
      neighborhood: patientData.neighborhood,
      patientName: patientData.patientName,
      phone: patientData.phone ? patientData.phone : null,
      zipCode: patientData.zipCode,
      state: patientData.state,
      datetimeinclusion: null,
      healthPlan: patientData.healthPlan,
      healthPlanExpirationDate: patientData.healthPlanExpirationDate,
      healthPlanNumber: patientData.healthPlanNumber,
      idPatientTelemedicineIntegration: patientData.idPatientTelemedicineIntegration,
      street: patientData.street,
      houseNumber: patientData.houseNumber,
    };

    return patientDataRequest;
  }

  savePatient() {
    this.isLoading = true;
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.patientDataRequest = this.mapToPatientDataRequest(this.patientData);

    this.episodeOrchestratorTelemedicine.createEpisode(this.patientDataRequest, this.patientData.idHealthUnit, NatureOfAttendanceEnum.online).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.idEpisode = response.idEpisode;

        if(this.episodePreEvaluation.idSymptomDuration && this.episodePreEvaluation.symptomDescription){
          let preEvaluationRequest: EpisodePreEvaluationRequest = new EpisodePreEvaluationRequest();
          preEvaluationRequest.idEpisode = this.idEpisode;
          preEvaluationRequest.idSymptomDuration = this.episodePreEvaluation.idSymptomDuration;
          preEvaluationRequest.symptomDescription = this.episodePreEvaluation.symptomDescription;
          this.savePreEvaluation(preEvaluationRequest);
        }
        this.actualTicket = `${response.ticketInitials} ${response.ticketSequence}`;

        let episode = new EpisodeData();
        episode.idEpisode = response.idEpisode;
        episode.idQueue = response.idQueue;
        episode.actualTicket = response.ticketInitials + response.ticketSequence;

        this.utilsTelemedicineService.createEpisodeData(episode);
        this.patientData.idQueue = response.idQueue;

        this.utilsTelemedicineService.updatePatientData(this.patientData);

        if (this.flowControl == this.orientations)
          this.flowControl += 1;

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  savePreEvaluation(request: EpisodePreEvaluationRequest) {
    this.episodePreEvaluationService.saveEpisodePreEvaluation(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  next() {
    if (this.flowControl != this.orientations)
      this.flowControl += 1;
    else
      this.savePatient();
  }

  mapperTelemedicineConfig() {
    if (this.telemedicineConfig != null && this.telemedicineConfig.isActive) {
      this.checkedUri = true;
      if (this.preview && this.telemedicineConfig.colorCodePreview) {
        this.telemedicineConfig.useColor = true;
        this.telemedicineConfig.colorCode = this.telemedicineConfig.colorCodePreview;
        this.colorCode = this.telemedicineConfig.colorCodePreview;
      }
      else if (this.telemedicineConfig.useColor == true) {
        this.colorCode = this.telemedicineConfig.colorCode;
      }
      if (this.preview && this.telemedicineConfig.logoNamePreview) {
        this.telemedicineConfig.isLogoUploaded == true;
        this.telemedicineConfig.logoString64 = this.telemedicineConfig.logoString64Preview;
      }
      else if (this.telemedicineConfig.isLogoUploaded == true) {
        this.isLogoUploaded = true;
        this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.telemedicineConfig.logoString64}`);
      }
      this.telemedicineConfig.logoString64Preview = null;
      this.telemedicineConfig.colorCodePreview = null;
    }
    this.checkedLoading = true;
  }

  updatePatient(patient: PatientStruct) {
    this.patientData = this.mapToPatientData(patient);
  }

  updatePreEvaluation(preEvaluation: EpisodePreEvaluation) {
    this.episodePreEvaluation = preEvaluation;
  }

  getPatientByGuid(guid) {
    this.patientAccessService.getIdPatientFromGuid(guid).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.guid = null;
          return;
        }
        this.idSelfTriagePatient = response.telemedicineAccess.idSelfTriagePatient;

        if (response.patient)
          this.patientData = this.mapSelfTriagePatientToPatientStruct(response.patient);
        else if (response.patientMedicalRecord)
          this.patientData = this.mapToPatientData(response.patientMedicalRecord);
      },
      error: (error) => {
        this.guid = null;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.guid = this.router.url.replace("/", "");
  }

  mapSelfTriagePatientToPatientStruct(data: SelfTriagePatientStruct): PatientData {
    let patientAux = new PatientData()
    patientAux.idPatient = data.idPatient;
    patientAux.patientName = data.patientName;
    patientAux.cpf = data.cpf;
    patientAux.birthDate = data.birthDate;
    patientAux.idGender = data.idGender;
    patientAux.neighborhood = data.neighborhood;
    patientAux.city = data.city;
    patientAux.state = data.state;
    patientAux.street = data.street;
    patientAux.houseNumber = data.houseNumber;
    patientAux.phone = data.phone;
    patientAux.email = data.email;

    return patientAux;
  }

  createFormGroup() {
    this.identificationModel = this.formBuilder.group({
      cpf: ['', [
        Validators.required,
        VerifyCPF()]],
      privacyPolicy: [null, [Validators.requiredTrue]],
    });

    this.additionalDataModel = this.formBuilder.group({
      completeName: ['', [Validators.required]],
      cpf: ['', [Validators.required, VerifyCPF()]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      birthDate: ['', [Validators.required, validateBirthDate]],
      gender: ['', [Validators.required]],
      zipCode: [''],
      state: [''],
      city: [''],
      neighborhood: [''],
      street: [''],
      houseNumber: [''],
      cns: [''],
      healthPlan: [''],
      healthPlanNumber: [''],
      healthPlanExpirationDate: ['']
    });

    this.orientationsModel = this.formBuilder.group({
      place: [false, [Validators.requiredTrue]],
      drive: [false, [Validators.requiredTrue]],
      dressed: [false, [Validators.requiredTrue]],
    });
  }

  getPatientEpisodeQueueByCPF(cpf: string, idHealthUnit: number) {
    this.patientEpisodeQueueService.getPatientEpisodeQueueByCPF(cpf, idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError && response.errorDescription != "CPF não encontrado.") {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        console.log(response)

        this.isLoading = false;
        this.isLoadingCpf = false;

        if (response.patient && response.patient.idPatient) {
          this.checkedPatient = true;

          this.queue = response.queue;

          let patient: PatientStruct = response.patient ? response.patient : new PatientStruct();       

          this.utilsTelemedicineService.updatePatientData(this.mapToPatientData(patient));

          if (response.episode?.idStatus == EpisodeStatusEnum.iniciado) {
            if (response.queue?.idStatusQueue == QueueStatusEnum.naoAtendido) {
              let episode = new EpisodeData();

              episode.idEpisode = response.episode.idEpisode;
              episode.idQueue = response.queue.idQueue;
              episode.actualTicket = response.episode.fullTicket;

              this.utilsTelemedicineService.createEpisodeData(episode);

              this.flowControl = TelemedicineFlowEnum.waitingArea;
            } 
            
            else if (response.episode && response.queue) {
              this.updateStatusPatient(response.episode.idEpisode, response.queue.idQueue);
            }
          } 
          
          else if (response.episode?.idStatus == EpisodeStatusEnum.finalizadoEvasao) {
            this.alertService.show('Alerta', 'Seu atendimento anterior foi finalizado automaticamente. Continue o processo para retornar a fila.', AlertType.warning);
          }

          if (!response.queue)
            this.queue = new QueueUnStruct();

        }

        this.identificationModel.get("cpf").setValidators([]);
        this.identificationModel.get("cpf").updateValueAndValidity();
        this.identificationModel.get("privacyPolicy").setValidators([]);
        this.identificationModel.get("privacyPolicy").updateValueAndValidity();

        if (this.flowControl != TelemedicineFlowEnum.waitingArea && response.patient) {
          this.populateAdditionalData(response.patient);
        }

      },
      error: (error) => {
        this.isLoading = false;
        this.isLoadingCpf = false;
        this.checkedPatient = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient(idEpisode: number, idQueue: number) {
    this.isLoading = true;
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = "Paciente evadido após sair da fila da telemedicina.";
    statusQueueRequest.idEpisode = idEpisode;
    statusQueueRequest.idHealthUnit = this.patientData.idHealthUnit;
    statusQueueRequest.idQueue = idQueue;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;

    this.patientAccessLeaving.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateAdditionalData(patient: PatientStruct) {    
    this.additionalDataModel.get('completeName').setValue(patient.patientName);
    this.additionalDataModel.get('cpf').setValue(patient.cpf);
    this.additionalDataModel.get('phone').setValue(patient.phone1);
    this.additionalDataModel.get('email').setValue(patient.email);
    this.additionalDataModel.get('birthDate').setValue(this.dataAtualFormatada(patient.birthDate));
    this.additionalDataModel.get('gender').setValue(patient.idGender);
    this.additionalDataModel.get('neighborhood').setValue(patient.neighborhood);
    this.additionalDataModel.get('street').setValue(patient.street);
    this.additionalDataModel.get('houseNumber').setValue(patient.houseNumber ? patient.houseNumber.toString() : '');
    this.additionalDataModel.get('cns').setValue(patient?.cns);
    this.additionalDataModel.get('healthPlan').setValue(patient?.healthInsurance);
    this.additionalDataModel.get('healthPlanNumber').setValue(patient?.healthInsuranceRegistration);
    this.additionalDataModel.get('zipCode').setValue(patient?.zipCode);
    this.additionalDataModel.get('city').setValue(patient?.city);
    this.additionalDataModel.get('state').setValue(patient?.state);

    this.additionalDataModel.get('healthPlanExpirationDate').setValue(this.dataAtualFormatada(patient?.healthInsuranceExpirationDate));
    this.isLoading = false;
    if (this.guid)
      this.flowControl = this.orientations;
  }

  dataAtualFormatada(dateToFormat: Date) {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();

      return diaF + "/" + mesF + "/" + anoF;
    }

    return null;
  }
  reload() {
    this.flowControl = this.welcome;
  }
}