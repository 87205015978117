<app-telemedicine-header [telemedicineConfig]="telemedicineConfig"></app-telemedicine-header>
<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode, 'border-bottom' : 'none', 'margin-bottom' : '0px'}">
                <h1>Triagem concluída!</h1>
                <div class="section-sub-title">
                    <p class="description">Você será encaminhado(a) para a fila de espera do atendimento médico.</p>
                </div>
                <div class="margin-img">
                    <img class="img-class-mobile" *ngIf="isMobileVersion" src="assets/images/ended-query.png" />
                    <img class="img-class" *ngIf="!isMobileVersion" src="assets/images/ended-query.png" />
                </div>
            </div>
        </div>
    </div>
</div>