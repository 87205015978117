import { Component, Input, OnInit } from '@angular/core';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PatientDataRequest } from 'src/app/shared/services/requests/orchestrator-telemedicine/patient-data.request';
import { GenerateTelemedicineEpisodeResponse } from 'src/app/shared/services/responses/orchestrator-telemedicine/generate-telemedicine-episode.response';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { EpisodeData, PatientData, UtilsTelemedicineService, VideoCallData } from 'src/app/telemedicine/utils.service';
import { PatientHoldModalComponent } from './patient-hold-modal/patient-hold-modal.component';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/components/enum/websocket-telemedicine-action.enum';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebsocketTelemedicineUtilService } from 'src/app/shared/services/websocket-util/websocket-telemedicine-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { WarnPatientDoctorEvadeComponent } from 'src/app/shared/components/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { QueueUnService } from '../../services/API/queue/queue-un.service';
import { GetAvarageWaitingTimeAndLastAttendedTicketResponse } from '../../services/responses/queue/get-avarage-waiting-time-and-last-attended-ticket.response';

@Component({
  selector: 'app-waiting-area',
  templateUrl: './waiting-area.component.html',
  styleUrls: ['./waiting-area.component.css']
})
export class WaitingAreaComponent implements OnInit {

  constructor(private alertService: AlertService,
    public utilService: UtilsTelemedicineService,
    private patientAcessLeavingService: PatientAccessLeavingService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public websocketTelemedicineUtilService: WebsocketTelemedicineUtilService,
    private router: Router,
    private utilSelfTriageService: UtilsSelfTriageService,
    private activatedRoute: ActivatedRoute,
    private queueUnService: QueueUnService,
  ) { }

  @Input() isLoading: boolean = false;
  public checkedLoading: boolean = false;
  public idHealthIdentification: number;
  public action: number;
  public natureOfAttendanceOnline: NatureOfAttendanceEnum = NatureOfAttendanceEnum.online;
  public patientData: PatientData;
  public patientDataRequest: PatientDataRequest = new PatientDataRequest();
  public generateTelemedicineEpisodeResponse: GenerateTelemedicineEpisodeResponse;
  public hasWaitingTime: boolean = false;
  public actualTicket: string = "-";
  public lastTicket: string = "-";
  public sanitezedLogo: any;
  public clientLogo: any;
  public waitingTime: string = "-";
  public uri: string;
  public episodeData: EpisodeData = new EpisodeData();
  public config: TelemedicineConfigStruct;
  public colorCode: string;
  public flowEnum = TelemedicineFlowEnum.waitingArea;
  private dialogRefHoldModal: MatDialogRef<PatientHoldModalComponent>;
  private dialogRefWarnPatient: MatDialogRef<WarnPatientDoctorEvadeComponent>;
  private timeJustExpired: boolean = false;
  private isMedic: boolean = true;

  ngOnInit(): void {
    console.log("init do wait");
    this.uri = this.utilService.getUri();
    this.patientData = this.utilService.getPatientData();
    this.episodeData = this.utilService.getEpisodeData();
    this.config = this.utilService.getTelemdicineConfig();
    this.utilService.setOldFlowControl(TelemedicineFlowEnum.waitingArea);

    if (!this.config.colorCode)
      this.colorCode = '#99C8D6';
    else
      this.colorCode = this.config.colorCode;

    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.config.logoString64}`);
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.config.logoString64}`);  
    this.actualTicket = this.episodeData.actualTicket ? this.episodeData.actualTicket : "-";

    this.getAvarageWaitingTimeAndLastAttendedTicketInActualQueue(this.episodeData.idEpisode);

    this.startWebsocket();
    this.checkedLoading = true;

    if (this.activatedRoute.snapshot.queryParamMap.get('warnMedicLeft') === 'true') {
      this.openDoctorEvadeModal();
    }
  }

  startWebsocket() {
    this.websocketTelemedicineUtilService.setContext(this);
    if (this.websocketTelemedicineUtilService.isConnect() !== true) {
      this.websocketTelemedicineUtilService.connectwebsocketRoom(this.episodeData.idEpisode, this.config.waitingTimePatientDoctorInSeconds, this);
    }
    this.websocketTelemedicineUtilService.setFunctions(this.updateInfoWaiting);
  }

  ngOnDestroy() {
    if ((this.action && this.action != WebsocketTelemedicineActionEnum.confirmAttend) && (this.websocketTelemedicineUtilService.isConnect()))
      this.websocketTelemedicineUtilService.clearSocket();
  }

  updateInfoWaiting(client: any) {

    if (client.action == WebsocketTelemedicineActionEnum.patientInternetDown) {
      if (this.dialogRefHoldModal && this.dialogRefHoldModal.getState() == MatDialogState.OPEN) this.dialogRefHoldModal.close();
      this.alertService.show('Aviso', 'Houve uma instabilidade, aguarde alguns segundos!', AlertType.warning);
      return;
    }

    if (client.action == WebsocketTelemedicineActionEnum.startCountdown) {
      if (!this.dialogRefHoldModal || this.dialogRefHoldModal.getState() != MatDialogState.OPEN) {
        this.openPatientHoldModal(client);
      }
      return;
    }

    if (client.action == WebsocketTelemedicineActionEnum.timeExpired) {
      this.alertService.show('Aviso', 'O tempo para o atendimento expirou!', AlertType.warning);
      this.dialogRefHoldModal.close();
      this.timeJustExpired = true;
      return;
    }


    if (client.action == WebsocketTelemedicineActionEnum.medicEvasion) {
      if (!this.timeJustExpired && this.dialogRefHoldModal && this.dialogRefHoldModal.getState() == MatDialogState.OPEN) {
        this.dialogRefHoldModal.close();
        this.openDoctorEvadeModal();
      }
      return;
    }

    if (client.idEpisode && client.idEpisode != this.episodeData.idEpisode)
      return;

    if (client && client.action == WebsocketTelemedicineActionEnum.patientEvasion) {
      this.alertService.show('Aviso', 'O seu atendimento foi cancelado por não entrar no tempo proposto!', AlertType.warning);
      this.updateStatusPatient(StatusQueueEnum.evadido);
      this.utilService.clearTelemdicineConfig();
      this.utilService.removeEpisodeData();
      this.utilService.removePatientData();
      window.location.reload();
    }

    if(client.isMedic != null && client.isMedic != undefined)
      this.isMedic = client.isMedic;

    if (this.episodeData.idEpisode == client.idEpisode) {
      let videoCallData: VideoCallData = {
        attendantUserName: client.attendantUserName,
        idAttendantUser: client.idAttendantUser,
        idEpisode: client.idEpisode,
        videoToken: client.tokenPatient
      }
      this.utilService.updateVideoCallData(videoCallData);
      this.patientData.idQueue = client.idQueue;
      this.utilService.updatePatientData(this.patientData);
    }
  }

  openPatientHoldModal(client) {
    this.timeJustExpired = false;
    if (this.dialogRefWarnPatient) this.dialogRefWarnPatient.close();
    this.dialogRefHoldModal = this.dialog.open(PatientHoldModalComponent, {
      data: {
        colorCode: this.colorCode,
        telemedicineConfig: this.config,
        isMedic: this.isMedic,
        websocketTelemedicineUtilService: this.websocketTelemedicineUtilService
      },
      panelClass: "border-radius-box"
    });
    this.dialogRefHoldModal.afterClosed().subscribe({
      next: result => {
        if (result && result.isEvade) {
          this.websocketTelemedicineUtilService.emitAction(WebsocketTelemedicineActionEnum.patientEvasion, this.episodeData.idEpisode)
          if (this.utilService.getSelfTriageAcessTelemedicine() && this.utilSelfTriageService.getFullUrl()) {
            window.open(this.utilSelfTriageService.getFullUrl(), "_self");
          } else {
            window.location.reload();
          }
        }
        else if (result && result.confirmAttend) {
          this.websocketTelemedicineUtilService.emitAction(WebsocketTelemedicineActionEnum.confirmAttend, this.episodeData.idEpisode)
          this.router.navigate([`telemedicine/${this.uri}/attend-patient/video`]);
          this.action = WebsocketTelemedicineActionEnum.confirmAttend;
        }
      }
    });
  }

  evadePatient() {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        uri: this.uri,
        idEpisode: this.episodeData.idEpisode,
        idHealthUnit: this.patientData.idHealthUnit,
        idQueue: this.patientData.idQueue,
        colorCode: this.colorCode,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isEvade) {
          if (this.utilService.getSelfTriageAcessTelemedicine() && this.utilSelfTriageService.getFullUrl()) {
            window.open(this.utilSelfTriageService.getFullUrl(), "_self");
          } else {
            window.location.href = window.location.pathname;
          }
        }
      }
    });
  }

  updateStatusPatient(idStatusQueue) {
    this.isLoading = true;
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
    statusQueueRequest.idEpisode = this.episodeData.idEpisode;
    statusQueueRequest.idHealthUnit = this.patientData.idHealthUnit;
    statusQueueRequest.idQueue = this.patientData.idQueue;
    statusQueueRequest.idStatusQueue = idStatusQueue;
    if (StatusQueueEnum.evadido == idStatusQueue) {
      this.utilService.clearTelemdicineConfig();
      this.utilService.removeEpisodeData();
      this.utilService.removePatientData();
      statusQueueRequest.conclusionNote = "Paciente evadido após sair da fila da telemedicina.";
    }

    this.patientAcessLeavingService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openDoctorEvadeModal() {
    this.dialogRefWarnPatient = this.dialog.open(WarnPatientDoctorEvadeComponent, {
      data: {
        uri: this.uri,
        idEpisode: this.episodeData.idEpisode,
        colorCode: this.colorCode,
      },
    });
    this.updateStatusPatient(StatusQueueEnum.nao_atendido);
  }

  getAvarageWaitingTimeAndLastAttendedTicketInActualQueue(idEpisode: number): void {
    this.queueUnService.GetAvarageWaitingTimeAndLastAttendedTicketInActualQueue(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.waitingTime = response.averageWaitingTime; 
        this.concatTicketInitialsAndLastTicketSequence(response);    

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  concatTicketInitialsAndLastTicketSequence(ticketInfo: GetAvarageWaitingTimeAndLastAttendedTicketResponse): void {
    this.lastTicket = "-";

    let hasLastTicketInitials: boolean = (ticketInfo.lastTicketInitials && ticketInfo.lastTicketInitials.trim() != "");
    let hasLastTicketNumberSequence: boolean = (ticketInfo.lastTicketSequence && ticketInfo.lastTicketSequence > 0);

    if (hasLastTicketInitials && hasLastTicketNumberSequence) 
      this.lastTicket = ticketInfo.lastTicketInitials + ticketInfo.lastTicketSequence.toString();
    
  }
}