import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-login-failed-modal',
  templateUrl: './login-failed-modal.component.html',
  styleUrls: ['./login-failed-modal.component.css']
})
export class LoginFailedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<LoginFailedModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private maskService: MaskService) { }


  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  openModalConfirmation() {
    this.matDialogRef.close(true);
  }

  openModalAlert() {
    this.matDialogRef.close(false);
  }
}