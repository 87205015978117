import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { RecoverOptionEnum } from 'src/app/shared/enum/recover-option.enum';
import { RecoverPasswordService } from 'src/app/shared/services/API/orchestrator-user/recover-password.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { RecoverPasswordResponse } from 'src/app/shared/services/responses/patient-center/recover-password.response';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private maskService: MaskService,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private recoverPasswordService: RecoverPasswordService,
  ) { }

  public masks: Masks;
  public isLoading: boolean;
  public model: FormGroup;
  public state: boolean;
  public recoverOption: number;
  public colorCode: string = '#67C4E0';
  public cpf: string;
  public digitalPromptConfig: DigitalPromptConfigStruct;
  public cpfNotEncountred: boolean = false;
  public isAvanced: boolean = false;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.isLoading = false;
    this.state = true;
    this.model = this.formBuilder.group({
      cpf: ['', [Validators.required, VerifyCPF()]],
      idHealthUnit: ['', [Validators.required]],
    });

    this.cpf = this.utilsDigitalPromptServiceService.getCPF();

    if (this.cpf != null) {
      this.model.get('cpf').setValue(this.cpf);
      this.formatCPF();
    }

    this.digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();

    if (this.digitalPromptConfig) {
      if (this.digitalPromptConfig.useColor == true) {
        this.colorCode = this.digitalPromptConfig.colorCode;
        document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
      }

      this.model.get('idHealthUnit').setValue(this.digitalPromptConfig.idHealthUnit);
    }
  }

  checkCpf() {
    if (!this.model.get('cpf').value || this.isLoading == true) {
      this.alertService.show("Erro", 'O campo CPF é obrigatório', AlertType.error);
      return;
    }
  }

  submit() {
    if (this.model.invalid || this.isLoading)
      return this.isLoading = false;

    if (!this.recoverOption || this.recoverOption == undefined) {
      this.alertService.show('Erro', "É necessário escolher uma forma de recuperação", AlertType.error);
      this.isLoading = false;
      return;
    }

    let login = this.model.get('cpf').value;
    let idHealthUnit = this.model.get('idHealthUnit').value;
    this.isLoading = true;
    this.recoverPasswordService.recoverPasswordToken(login, idHealthUnit, this.utilsDigitalPromptServiceService.getUri(), this.recoverOption).subscribe({
      next: (response: RecoverPasswordResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.recoverOption == RecoverOptionEnum.sms)
          this.alertService.show('Sucesso', `O link para redefinição de senha foi enviado para o número ${response.mockPhone}`, AlertType.success);
        else
          this.alertService.show('Sucesso', `O link para redefinição de senha foi enviado para o email ${response.mockEmail}`, AlertType.success);

        this.isLoading = false;
        this.router.navigate([`patient-center/${this.utilsDigitalPromptServiceService.getUri()}`]);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getCpfErrorMessage() {
    if (this.model.get('cpf').hasError('required'))
      return 'Informe o CPF';

    return this.model.get('cpf').hasError('invalidCPF') ? 'CPF inválido' : '';
  }

  formatCPF() {
    if (this.cpf != null && this.cpf != undefined) {
      this.cpf = this.cpf.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos

      // Formatação do CPF
      this.model.get('cpf').setValue(this.cpf.slice(0, 3) + '.' + this.cpf.slice(3, 6) + '.' + this.cpf.slice(6, 9) + '-' + this.cpf.slice(9));
    }
  }
}