import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PatientAppointmentStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-appointment.struct';

@Component({
  selector: 'app-has-pre-appointment-modal',
  templateUrl: './has-pre-appointment-modal.component.html',
  styleUrls: ['./has-pre-appointment-modal.component.css']
})
export class HasPreAppointmentModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;
  public text: string;
  public apppointment: PatientAppointmentStruct;
  public standardAttendance: number = NatureOfAttendanceEnum.presencial;
  public onlineAttendance: number = NatureOfAttendanceEnum.online;

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
  }

  confirm() {
    this.matDialogRef.close({continuePreAppointment: true });
  }

  cancel() {
    this.matDialogRef.close({continuePreAppointment: false });
  }
}
