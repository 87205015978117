import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-episode',
  templateUrl: './no-episode.component.html',
  styleUrls: ['./no-episode.component.css']
})
export class NoEpisodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
