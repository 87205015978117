import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SelfTriageResultRequest } from '../../requests/self-triage/self-triage-result.request';
import { CreatePatientResponse } from '../../responses/telemedicine/create-patient.response';

@Injectable({
    providedIn: 'root'
})

export class SelfTriageProtocolService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public SaveSelfTriageResult(uriClient: string, body: SelfTriageResultRequest): Observable<CreatePatientResponse> {
        let uri = `SelfTriageProtocolPatientCenter/uri/${uriClient}`

        return this.httpClient.post<CreatePatientResponse>(environment.urlApiOrchestratorClassification + uri, body, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}