import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ExternalSearchFieldEnum } from 'src/app/shared/enum/self-triage/external-search-field.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientService } from 'src/app/shared/services/API/self-triage/patient.service';
import { ExternalSearchVariablesWebhook } from 'src/app/shared/services/requests/self-triage/ExternalSearchVariablesWebhook.request';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { Router } from '@angular/router';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyCNS } from 'src/app/shared/custom-validators/cns.validator';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { GetPatientByCns } from 'src/app/shared/services/responses/self-triage/get-patient-by-cns.response';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.css']
})

export class SearchPatientComponent implements OnInit {

  constructor(
    private utilSelfTriaveService: UtilsSelfTriageService,
    public router: Router,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private alertService: AlertService,
    private maskService: MaskService,
  ) { }

  public model: FormGroup;
  public checkedCns: boolean = false;
  public isLoading: boolean = false;
  public externalSearchVariablesWebhook: ExternalSearchVariablesWebhook;
  public selfTriageConfig: SelfTriageConfigStruct;
  public patientData: Patient;
  public externalSearchFieldDescription: string;
  public masks: Masks;
  public fieldMask: Masks = null;
  public fieldTypeInsuranceNumber: number = ExternalSearchFieldEnum.numeroCarteirinha;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.identification;
  public inputTitle: string;

  public colorCode: string = '#99C8D6';

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.selfTriageConfig = this.utilSelfTriaveService.getSelfTriageConfigStruct();
    this.patientData = this.utilSelfTriaveService.getPatientData();

    if (!this.selfTriageConfig) {
      this.router.navigate([`self-triage/${this.utilSelfTriaveService.getUri()}`]);
    }  

    else if (this.selfTriageConfig.useColor) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }

    this.model = this.formBuilder.group({
      externalSearchField: ['', [Validators.required]],
    });
    
    this.externalSearchVariablesWebhook = new ExternalSearchVariablesWebhook();

    this.externalSearchFieldDescription = this.utilSelfTriaveService.getSelfTriageListExternalSearchField().find(x => x.idExternalSearchField == this.selfTriageConfig.idExternalSearchField).externalSearchFieldDescription;
    
    if (this.selfTriageConfig.idExternalSearchField == ExternalSearchFieldEnum.cpf) {
      this.fieldMask = this.masks.cpf;
      this.model.get('externalSearchField').setValidators([Validators.required,VerifyCPF()])
      this.model.get('externalSearchField').updateValueAndValidity();
      this.model.get('externalSearchField').setValue(this.patientData.cpf);
      this.inputTitle = "CPF";
    }
    else if (this.selfTriageConfig.idExternalSearchField == ExternalSearchFieldEnum.cns) {
      this.fieldMask = this.masks.cns;
      this.model.get('externalSearchField').setValidators([Validators.required,VerifyCNS()])
      this.model.get('externalSearchField').updateValueAndValidity();
      this.inputTitle = "CNS";
    }
    else{
      this.inputTitle = "Número da carteirinha do convênio";
    }
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.searchByKey();
  }

  searchByKey() {
    this.isLoading = true;

    if (this.selfTriageConfig.idExternalSearchField == ExternalSearchFieldEnum.cpf) {
      this.externalSearchVariablesWebhook.cpf = this.model.get('externalSearchField').value?.replaceAll("-", "").replaceAll(".", "");
    }
    else if (this.selfTriageConfig.idExternalSearchField == ExternalSearchFieldEnum.cns) {
      this.externalSearchVariablesWebhook.cns = this.model.get('externalSearchField').value?.replaceAll(" ", "");
    }

    else if (this.selfTriageConfig.idExternalSearchField == ExternalSearchFieldEnum.numeroCarteirinha) {
      this.externalSearchVariablesWebhook.insuranceNumber = this.model.get('externalSearchField').value;
    }

    this.patientService.getPatientExternalSearch(this.selfTriageConfig.externalSearchUrl, this.externalSearchVariablesWebhook, this.selfTriageConfig.externalSearchKey).subscribe({
      next: (response) => {
       if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.mapToPatient(response)
      this.isLoading = false;
      this.router.navigate([`self-triage/emergency-warning/${this.utilSelfTriaveService.getUri()}`]);

    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToPatient(getPatient: GetPatientByCns){
    this.patientData.patientName = getPatient.patientName;
    this.patientData.cpf = getPatient.cpf;
    this.patientData.birthDate = getPatient.birthDate;
    this.patientData.phone = getPatient.phone;
    this.patientData.email = getPatient.email;
    this.patientData.idGender = getPatient.idGender;
    this.patientData.state = getPatient.state;
    this.patientData.city = getPatient.city;
    this.patientData.neighborhood = getPatient.neighborhood;
    this.utilSelfTriaveService.updatePatientData(this.patientData);
  }
}
