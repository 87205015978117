import { Injectable } from '@angular/core';
import { FilterSelectModel } from '../components/filter-select/filter-select.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  creatInitials(userName): string {
    if (userName.split(' ').length == 2) 
      return userName.split(' ')[0][0] + userName.split(' ')[1][0];
    else 
      return userName[0];
  }

  getKeySelectedModel(filterSelectModel: FilterSelectModel) {
    if (filterSelectModel == null)
      return null;

    let listKeys: number[] = [];

    filterSelectModel.itemSelectModel.forEach(element => {
      if (element.checked)
        listKeys.push(parseInt(element.key))
    });

    if (listKeys.length == 0)
      return null;

    return JSON.stringify(listKeys);
  }

  getPatientName(): string | null {
    const patientToken: string = sessionStorage.getItem('token');
    return patientToken ? JSON.parse(patientToken).userName : null;
  }

  getIdUserPatient(): number | null {
    const patientToken: string = sessionStorage.getItem('token');
    return patientToken ? JSON.parse(patientToken).idUserPatient : null;
  }

  getIdHealthUnit(): number | null {
    const patientToken: string = sessionStorage.getItem('token');
    return patientToken ? JSON.parse(patientToken).idHealthUnit : null;
  }

  setSecundaryHeaderModuleConfig(data: any, moduleName: string): void {
    sessionStorage.setItem(moduleName, JSON.stringify(data));
  }

  getSecundaryHeaderModuleConfig(moduleName: string): any {
    const data: string = sessionStorage.getItem(moduleName);
    return data ? JSON.parse(data) : false
  }

  cleanSecundaryHeaderModuleConfig(moduleName: string): void {
    sessionStorage.removeItem(moduleName);
  }

  validatorCns(cns: any) {
    let cnsString = cns.toString();

    var validateSize = (cnsString.length == 15)
    var validateStart = ['1', '2', '7', '8', '9'].includes(cnsString[0])

    if (validateSize && validateStart) {
      //CNS Iniciados em 7, 8 ou 9
      if (['7', '8', '9'].includes(cnsString[0])) {
        var sum = cnsString.split('').reduce((total, cns, index) => total + (cns * (15 - index)), 0);
        if (sum % 11 != 0) 
          return false;
        else 
          return true;
      } 
      else if (['1', '2'].includes(cnsString[0])) {
        //CNS Iniciados em 1, 2
        var pis = cnsString.substring(0, 11);
        var sum = pis.split('').reduce((total, cns, index) => total + (cns.valueOf() * (15 - index)), 0)

        var rest = sum % 11
        var dv = rest == 0 ? 0 : 11 - rest

        var result = dv == 10 ? `${pis}001${(11 - ((sum + 2) % 11))}` : `${pis}000${dv}`
        if (result != cnsString) 
          return false;
         else 
          return true;
      } 
      else 
        return false;
    } 
    else 
      return false;
  }

  validatorCpf(cpfString) {
    let isValid: boolean = true;

    if (cpfString.length != 11) 
      return false;

    var Soma;
    var Resto;

    Soma = 0;
    if (cpfString == "00000000000" ||
      cpfString == "11111111111" ||
      cpfString == "22222222222" ||
      cpfString == "33333333333" ||
      cpfString == "44444444444" ||
      cpfString == "55555555555" ||
      cpfString == "66666666666" ||
      cpfString == "77777777777" ||
      cpfString == "88888888888" ||
      cpfString == "99999999999") {
      isValid = false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (11 - i)
    };

    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) 
      Resto = 0;

    if (Resto != parseInt(cpfString.substring(9, 10))) 
      isValid = false;

    Soma = 0;

    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (12 - i)
    };
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) 
      Resto = 0;

    if (Resto != parseInt(cpfString.substring(10, 11))) 
      isValid = false;

    if (!isValid) 
      return false;
    else 
      return true;
  }
}