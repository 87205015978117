import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GenderResponse } from '../../responses/medical-record/gender.response';
import { GetClientPatientByCpfResponse } from '../../responses/medical-record/client-patient.response';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';

@Injectable({
    providedIn: 'root'
})

export class ClientPatientService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,
        private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService) {
        super();
    }

    public getPatient(cpf: string, jsonListIdHealthUnit: string): Observable<GetClientPatientByCpfResponse> {

        let uri = `ClientPatient`;

        let idHealthUnit = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct().idHealthUnit;
        uri = uri + `/cpf/${cpf}/idHealthUnit/${idHealthUnit}?`;

        if(jsonListIdHealthUnit)
        uri = uri + `jsonListIdHealthUnit=${jsonListIdHealthUnit}`;

        return this.httpClient.get<GetClientPatientByCpfResponse>(environment.urlApiMedicalRecord + uri)
            .pipe(
                catchError(this.handleError)
            )
    }
}