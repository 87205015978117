export enum sessionTimeoutEnum {
    umaHora = 1,
    duasHoras = 2,
    tresHoras = 3,
    quatroHoras = 4,
    cincoHoras = 5,
    seisHoras = 6,
    seteHoras = 7,
    oitoHoras = 8,
    noveHoras = 9,
    dezHoras = 10,
    onzeHoras = 11,
    dozeHoras = 12,
    vinteEQuatroHoras = 13,
    trintaESeisHoras = 14,
    quarentaEOitoHoras = 15,
    centoESessentaEOitoHoras = 16,
    semLimite = 17
}