import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { TriggerWebsocketService } from 'src/app/shared/services/API/orchestrator-telemedicine/trigger-websocket.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { WebsocketTelemedicineService } from 'src/app/shared/services/websocket-telemedicine.service';
import { EpisodeData, PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { LoginCheckPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/login-check-patient.request';

@Component({
  selector: 'app-remake-queue-modal',
  templateUrl: './remake-queue-modal.component.html',
  styleUrls: ['./remake-queue-modal.component.css']
})
export class RemakeQueueModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RemakeQueueModalComponent>,
    private router: Router,
    private utilService: UtilsTelemedicineService,
    private alertService: AlertService,
    public patientAccessLeaving: PatientAccessLeavingService,
    private triggerWebsocketService: TriggerWebsocketService,
    private websocketTelemedicineService: WebsocketTelemedicineService,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public seconds: any = 180;
  public isLoading: boolean;
  public episodeData: EpisodeData;
  public index: any;
  public colorCode: string;
  public guid: string;
  public login: LoginCheckPatientRequest;
  public patientData: PatientData;
  public interval: any;
  public time: any;

  ngOnInit(): void {
    this.episodeData = this.utilService.getEpisodeData();
    this.patientData = this.utilService.getPatientData();
    this.colorCode = this.data.colorCode ? this.data.colorCode : '#85C443';
    this.startTime();
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);
    let duration = this.seconds;
    var $this = this;
    function myMethod() {
      if (duration == 0) {
        clearInterval($this.interval);
        $this.clickCancel();
      }
      else {

        duration = duration - 1;

        let minutes = String(Math.trunc(duration / 60));
        let seconds = String(duration % 60);

        seconds = seconds.length === 1 ? "0" + seconds : seconds;
        $this.time = minutes + " min " + seconds + "s";

        // document.getElementById('segundo').innerHTML = time;
      }
    }
  }

  clickCancel() {
    this.matDialogRef.close({ remake: false });
  }

  clickConfirm() {
    this.matDialogRef.close({ remake: true });
  }

}
