import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { DirectionButton } from "../../models/self-triage/direction-button.model";
import { SelfTriageCounselingModel } from "../../models/self-triage/self-triage-counseling.model";
import { FlowchartCompleteStruct } from "../../structs/self-triage/flowchart-complete.struct";
import { QuestionAnswerStruct } from "../../structs/self-triage/question-answer.struct";

export class GetProtocolSessionResponse extends ReturnStruct {
    public listButtons: DirectionButton[] = [];
    public counseling: SelfTriageCounselingModel;
    public flowchart: FlowchartCompleteStruct;
    public results: QuestionAnswerStruct[] = [];
}