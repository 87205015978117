import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-check-again-modal',
  templateUrl: './login-check-again-modal.component.html',
  styleUrls: ['./login-check-again-modal.component.css']
})
export class LoginCheckAgainModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<LoginCheckAgainModalComponent>,
    public dialog: MatDialog,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  openModalConfirmation() {
    this.matDialogRef.close();
  }
}