import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListPatientAppointmentResponse } from "../../responses/schedule/list-patient-appointment.response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";

@Injectable({
    providedIn: 'root'
})
export class PatientCenterAppointmentService extends CommonService {

    constructor(private httpClient: HttpClient) { super(); }

    public getPatientAppointment(): Observable<ListPatientAppointmentResponse> {
        let uri = `PatientCenterAppointment/getAll`;

        return this.httpClient.get<ListPatientAppointmentResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}