import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { GetListOfAvailableTimesService } from 'src/app/shared/services/API/orchestrator-schedule/get-list-of-available-times.service';
import { PatientPreAppointmentService } from 'src/app/shared/services/API/schedule/patient-pre-appointment.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { PostPatientPreAppointmentRequest } from 'src/app/shared/services/requests/schedule/post-patient-pre-appointment.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { AvailableDayTimesAndAttendantByScheduleStruct } from 'src/app/shared/services/structs/orchestrator-schedule/available-day-times-and-attendant-by-schedule.struct';
import { DayTimesStruct } from 'src/app/shared/services/structs/schedule/day-times.struct';
import { PatientAppointmentSessionStruct } from 'src/app/shared/structs/patient-center/patient-appointment-session.struct';

@Component({
  selector: 'app-scheduling-date',
  templateUrl: './scheduling-date.component.html',
  styleUrls: ['./scheduling-date.component.css']
})
export class SchedulingDateComponent implements OnInit {
  constructor(
    public router: Router,
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog, 
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private getListOfAvailableTimesService: GetListOfAvailableTimesService,
    private patientPreAppointmentService: PatientPreAppointmentService,
  ) { }

  public model: FormGroup;
  public selectedDate: FormControl;
  public masks: Masks;
  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public uri: string;
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public listOfAvailableDayTimesAndAttendantBySchedule: AvailableDayTimesAndAttendantByScheduleStruct[];
  public postPatientPreAppointmentRequest: PostPatientPreAppointmentRequest = new PostPatientPreAppointmentRequest;
  public patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
  public minDate = new Date();
  public timeZoneValue: number;

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    if(!this.digitalPromptServiceConfig)
      this.router.navigate([`digital-prompt-service/${this.utilsDigitalPromptServiceService.getUri()}`]);
    
    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }  

    this.patientAppointmentSession = this.utilsDigitalPromptServiceService.getSchedulingInformations();
    if(!this.patientAppointmentSession)
      this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);

    this.masks = this.maskService.getMasks();
    
    this.model = this.formBuilder.group({
      idUser: [null, [Validators.required]],
      datetimeStart: [null, [Validators.required]],
      datetimeEnd: [null, [Validators.required]],
      idSchedule: [null, [Validators.required]],
    });
    if (this.patientAppointmentSession.datetimeStart)
      this.selectedDate = new FormControl(new Date(this.patientAppointmentSession.datetimeStart), Validators.required);
    else
      this.selectedDate = new FormControl(new Date(), Validators.required);

    this.deletePreAppointmentPatient();
   
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.patientAppointmentSession.datetimeStart = this.model.get('datetimeStart').value;
    this.patientAppointmentSession.datetimeEnd = this.model.get('datetimeEnd').value;
    this.patientAppointmentSession.idSchedule = this.model.get('idSchedule').value;
    this.patientAppointmentSession.idUser = this.model.get('idUser').value;
    this.patientAppointmentSession.timeZoneValue = this.timeZoneValue;
    

    this.savePreAppointment();
  }

  backBegin(){
    this.router.navigate([`patient-center/scheduling-information/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  getListOfAvailableTimes() {
    this.model.reset();
    if (!this.selectedDate.value){
      this.isLoading = false;
      this.alertService.show('Erro', "Data selecionada inválida.", AlertType.error);
      return;
    }

    let copyOfDate: Date = new Date(this.selectedDate.value);
    let timeZoneOffset: number = copyOfDate.getTimezoneOffset();
    copyOfDate.setMinutes(copyOfDate.getMinutes() - timeZoneOffset);
    this.isLoading = true;
    let dataString: string = copyOfDate.toISOString();
    this.getListOfAvailableTimesService.getListOfAvailableSchedulingTimes(this.patientAppointmentSession.idMedicalSpecialty, dataString).subscribe({
      next: (response) => {
        if(response.isError && response.errorCode == 1) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.listOfAvailableDayTimesAndAttendantBySchedule = [];
          return;
        }        
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfAvailableDayTimesAndAttendantBySchedule = response.listOfAvailableDayTimesAndAttendantByScheduleStruct;
        this.timeZoneValue = response.timeZoneValue;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectionHourAndUser(availableDayTimesAndAttendantBySchedule: AvailableDayTimesAndAttendantByScheduleStruct, dayTimesStruct: DayTimesStruct){
    this.model.get('datetimeStart').setValue(dayTimesStruct.timeStart);
    this.model.get('datetimeStart').markAsTouched();
    this.model.get('datetimeStart').updateValueAndValidity();

    this.model.get('datetimeEnd').setValue(dayTimesStruct.timeEnd);
    this.model.get('datetimeEnd').markAsTouched();
    this.model.get('datetimeEnd').updateValueAndValidity();

    this.model.get('idUser').setValue(availableDayTimesAndAttendantBySchedule.schedule.idUser);
    this.model.get('idUser').markAsTouched();
    this.model.get('idUser').updateValueAndValidity();    

    this.model.get('idSchedule').setValue(availableDayTimesAndAttendantBySchedule.schedule.idSchedule);
    this.model.get('idSchedule').markAsTouched();
    this.model.get('idSchedule').updateValueAndValidity();

    this.patientAppointmentSession.userName = availableDayTimesAndAttendantBySchedule.attendantName;
    this.patientAppointmentSession.userCouncilUF = availableDayTimesAndAttendantBySchedule.userCouncilUF;
    this.patientAppointmentSession.crmUser = availableDayTimesAndAttendantBySchedule.userCouncilNumber;
  }

  savePreAppointment(){
    this.postPatientPreAppointmentRequest.idSchedule = this.patientAppointmentSession.idSchedule;
    this.postPatientPreAppointmentRequest.datetimeStart = this.patientAppointmentSession.datetimeStart;
    this.postPatientPreAppointmentRequest.datetimeEnd = this.patientAppointmentSession.datetimeEnd;
    this.postPatientPreAppointmentRequest.idUser = this.patientAppointmentSession.idUser;
    this.postPatientPreAppointmentRequest.idPatientHealthcareAgreement = this.patientAppointmentSession.idPatientHealthcareAgreement;
    this.postPatientPreAppointmentRequest.idTypeOfCare = this.patientAppointmentSession.idTypeOfCare;
    this.postPatientPreAppointmentRequest.idMedicalSpecialty = this.patientAppointmentSession.idMedicalSpecialty;
    this.postPatientPreAppointmentRequest.timeZoneValue = this.timeZoneValue;

    this.postPatientPreAppointmentRequest.idHealthcareAgreement = this.patientAppointmentSession.idHealthcareAgreement;
    this.postPatientPreAppointmentRequest.idHealthcareAgreementPlan = this.patientAppointmentSession.idHealthcareAgreementPlan;
    this.postPatientPreAppointmentRequest.healthcareAgreementCardNumber = this.patientAppointmentSession.healthcareAgreementCardNumber;
    this.postPatientPreAppointmentRequest.healthcareAgreementExpirationDate = this.patientAppointmentSession.healthcareAgreementExpirationDate;
    this.isLoading = true;
    this.patientPreAppointmentService.postPatientPreAppointment(this.postPatientPreAppointmentRequest).subscribe({
      next: (response) => { 
        if (response.isError && response.errorCode == 1) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.getListOfAvailableTimes();
          return;
        }      
        else if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }  
        this.utilsDigitalPromptServiceService.setSchedulingInformations(this.patientAppointmentSession);
        this.router.navigate([`patient-center/scheduling-confirm/${this.utilsDigitalPromptServiceService.getUri()}`]);
        this.isLoading = false; 
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  deletePreAppointmentPatient(){
    this.isLoading = true;
    this.patientPreAppointmentService.deletePatientPreAppointment().subscribe({
      next: (response) => {       
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }  
        
        this.getListOfAvailableTimes();
        this.isLoading = false; 

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
