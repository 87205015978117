import { Injectable } from "@angular/core";
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { GetProtocolSessionResponse } from "../shared/services/responses/self-triage/get-protocol-session.response";
import { DigitalPromptConfigStruct } from "../shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct";
import { PatientAppointmentSessionStruct } from "../shared/structs/patient-center/patient-appointment-session.struct";
import { AppointmentDataStruct } from "../shared/services/structs/schedule/appointment-data.struct";
import { HealthUnitStruct } from "../shared/services/structs/user/health-unit.struct";
import { PatientAppointment } from "../shared/services/models/schedule/patient-appointment.model";


@Injectable({
    providedIn: 'root'
})
export class UtilsDigitalPromptServiceService {
    constructor() { }

    updateUri(uri: string) {
        sessionStorage.setItem("uri", uri);
    }

    removeUri() {
        sessionStorage.removeItem("uri");
    }

    getUri() {
        let uri: string = sessionStorage.getItem("uri");
        return uri;
    }

    getCPF() {
        let cpf: string = sessionStorage.getItem("cpf");
        return cpf;
    }

    setDigitalPromptServiceStruct(data: DigitalPromptConfigStruct): void {
        sessionStorage.setItem("digitalPromptConfigStruct", JSON.stringify(data));
    }

    setCPF(cpf: number): void {
        sessionStorage.setItem("cpf", JSON.stringify(cpf));
    }

    getDigitalPromptServiceStruct(): DigitalPromptConfigStruct {
        const data: string = sessionStorage.getItem("digitalPromptConfigStruct");
        return data ? JSON.parse(data) : null
    }

    removeDigitalPromptService(): void {
        sessionStorage.removeItem("DigitalPromptService");
    }

    updatePatientData(patientData: Patient) {
        sessionStorage.setItem("patientData", JSON.stringify(patientData));
    }

    removePatientData() {
        sessionStorage.removeItem("patientData");
    }

    getPatientData() {
        let patientData: Patient = JSON.parse(sessionStorage.getItem("patientData"))
        return patientData;
    }

    setProtocolResponse(data: GetProtocolSessionResponse): void {
        sessionStorage.setItem("protocolResponse", JSON.stringify(data));
    }

    getProtocolResponse() {
        let data: GetProtocolSessionResponse = JSON.parse(sessionStorage.getItem("protocolResponse"))
        return data;
    }

    

    setSchedulingInformations(data: PatientAppointmentSessionStruct): void {
        sessionStorage.setItem("patientPreAppointment", JSON.stringify(data));
    }

    getSchedulingInformations() {
        let data: PatientAppointmentSessionStruct = JSON.parse(sessionStorage.getItem("patientPreAppointment"))
        return data;
    }

    removeSchedulingInformations() {
        sessionStorage.removeItem("patientPreAppointment");
    }

    setAppointmentDataToTwilioSession(data: AppointmentDataStruct): void {
        sessionStorage.setItem("AppointmentDataToTwilio", JSON.stringify(data));
    }

    getAppointmentDataToTwilioSession(): AppointmentDataStruct {
        let appointmentData: AppointmentDataStruct = JSON.parse(sessionStorage.getItem("AppointmentDataToTwilio"));
        return appointmentData;
    }

    removeAppointmentDataToTwilioSession() {
        sessionStorage.removeItem("AppointmentDataToTwilio");
    }

    updatehealthUnit(healthUnit: HealthUnitStruct): void {
        sessionStorage.setItem("healthUnit", JSON.stringify(healthUnit));
    }
    
    getHealthUnit() {
        let healthUnit: HealthUnitStruct = JSON.parse(sessionStorage.getItem("healthUnit"))
        return healthUnit;
    }

    setAppointmentDataSession(data: PatientAppointment): void {
        sessionStorage.setItem("AppointmentData", JSON.stringify(data));
    }

    getAppointmentDataSession(): PatientAppointment {
        let appointmentData: PatientAppointment = JSON.parse(sessionStorage.getItem("AppointmentData"));
        return appointmentData;
    }

    removeAppointmentDataSession() {
        sessionStorage.removeItem("AppointmentData");
    }

    updateToken(data: any): void {
        sessionStorage.setItem("token", JSON.stringify(data));
    }
}