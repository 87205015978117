<div [ngClass] = "{'body-remote-participant':isMobileVersion}" #remoteBody>
    <div id="alone" [ngClass]="{ 'is-alone': isAlone && !isMobileVersion,'is-alone-mobile': isAlone && isMobileVersion,'d-none': !isAlone }">
        <mat-icon class="time-icon">timer</mat-icon>
        <br />
        <h1>Você é o único nesta sala.</h1>
        <h2>Aguarde a entrada do outro participante.</h2>        
    </div>
    <div #list [ngClass]="{'video-remote-participant': isMobileVersion, 'd-none': isAlone }" (click)="changeDoctorScreenToLarger()">
        
    </div>
</div>