import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-email-modal',
  templateUrl: './confirmation-email-modal.component.html',
  styleUrls: ['./confirmation-email-modal.component.css']
})

export class ConfirmationEmailModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmationEmailModalComponent>,) {
    this.matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
  }

  public colorCode: string;

  ok() {
    this.matDialogRef.close({});
  }
}