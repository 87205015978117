import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        let isValid: boolean = true;

        let error = { invalidName: { value: control.value } };

        const value = control.value.toString();

        if (value == null || value == undefined) {
            return error;
        }

        const validator = /(\w.+\s).+/;

        isValid = validator.test(value);

        return !isValid ? error : null
    };

}

