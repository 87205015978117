<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                <h2>Pré-avaliação</h2>
            </div>
            <div class="section-subtitle">
                Conte um pouco mais sobre como você está se sentindo agora
            </div>
            <!-- class="outline-spinner form-field-telemedicine" -->
            <form class="form pre-evaluation-form" [formGroup]="model" (ngSubmit)="submit()">
                <span class="pre-evaluation-question">Qual o seu principal sintoma?</span>
                <mat-form-field required appearance="outline"
                    [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('symptomDescription').errors || !model.get('symptomDescription').touched,'outline-spinner form-field-telemedicine-error': model.get('symptomDescription').errors && model.get('symptomDescription').touched}">
                    <textarea matInput type="text" formControlName="symptomDescription" maxlength="200">
                </textarea>
                    <mat-error
                        *ngIf="model.get('symptomDescription').errors && model.get('symptomDescription').hasError('required')">Informe
                        o sintoma</mat-error>
                    <mat-error
                        *ngIf="model.get('symptomDescription').errors && model.get('symptomDescription').hasError('maxlength')">Limite
                        de caracteres atingido</mat-error>
                </mat-form-field>
                <span class="char-count">{{this.model.get('symptomDescription').value.length}}/{{this.maxLength}}</span>

                <span class="pre-evaluation-question">Há quanto tempo sente esses sintomas?</span>
                <mat-form-field required appearance="outline"
                    [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('symptomDuration').errors || !model.get('symptomDuration').touched,'outline-spinner form-field-telemedicine-error': model.get('symptomDuration').errors && model.get('symptomDuration').touched}">
                    <mat-select formControlName="symptomDuration" aria-placeholder="a">
                        <mat-option *ngFor="let item of symptomDurationList" [value]="item.idSymptomDuration">
                            {{item.symptomDurationDescription}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="model.get('symptomDuration').errors && model.get('symptomDuration').hasError('required')">Selecione
                        uma opção</mat-error>
                </mat-form-field>

                <button [disabled]="!model.valid" mat-flat-button color="primary" class="forward-button" type="submit">
                    <span>CONTINUAR</span>
                </button>
            </form>
            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>