<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h2>Editar Dados</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-modal">
                <div class="row">
                    <div class="col-12 add-healthcare-agreement">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreement').errors || !model.get('healthcareAgreement').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreement').errors && model.get('healthcareAgreement').touched}">
                            <mat-label>Qual é o seu convênio?</mat-label>
                            <mat-select formControlName="healthcareAgreement" (selectionChange)="getPlans(model.get('healthcareAgreement').value)">
                                <mat-option *ngFor="let item of this.listHealthcareAgreement" [value]="item.idHealthcareAgreement">
                                    {{item.nameHealthcareAgreement}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('healthcareAgreement').invalid">Campo de preenchimento obrigatório</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12 add-healthcare-agreement">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementPlan').errors || !model.get('healthcareAgreementPlan').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementPlan').errors && model.get('healthcareAgreementPlan').touched}">
                            <mat-label>Qual é o seu plano?</mat-label>
                            <mat-select formControlName="healthcareAgreementPlan">
                                <mat-option *ngFor="let item of this.listHealthcareAgreementPlan" [value]="item.idPlan">
                                    {{item.planName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('healthcareAgreementPlan').invalid">Campo de preenchimento obrigatório</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementCardNumber').errors || !model.get('healthcareAgreementCardNumber').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementCardNumber').errors && model.get('healthcareAgreementCardNumber').touched}">
                            <mat-label class="input-label">Número da carteirinha</mat-label>
                            <input matInput inputmode="numeric" type="text" formControlName="healthcareAgreementCardNumber" 
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                min="0">
                            <mat-error *ngIf="model.get('healthcareAgreementCardNumber').invalid">carteirinha inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementExpirationDate').errors || !model.get('healthcareAgreementExpirationDate').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementExpirationDate').errors && model.get('healthcareAgreementExpirationDate').touched}">
                            <mat-label class="input-label">Data de expiração da carteirinha</mat-label>
                            <input matInput inputmode="numeric" type="text" [mask]="masks.date" formControlName="healthcareAgreementExpirationDate" >
                            <mat-error *ngIf="model.get('healthcareAgreementExpirationDate').invalid">carteirinha inválida</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-modal">
                <button mat-flat-button color="primary" class="button-submit" [disabled]="model.invalid">
                    <span *ngIf="isLoading == false">Slavar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button type="button" color="secundary" class="button-back" (click)="clickCancel()">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </form> 
    </div>
</div>
