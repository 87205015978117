import { Component, EventEmitter, Input, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-url-modal',
  templateUrl: './url-modal.component.html',
  styleUrls: ['./url-modal.component.css']
})
export class UrlModalComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<UrlModalComponent>,
  private alertService: AlertService,) { }

  public isLoading: boolean = false;
  public colorCode: string = '#99C8D6';
  public goUrl: boolean;
  public buttonName: string;
  public buttonDescription: string;

  ngOnInit(): void {
    this.goUrl = false;
    this.buttonName = this.data.buttonName;
    this.buttonDescription = this.data.buttonDescription;
  }

  advance() {
    this.goUrl = true;
    this.matDialogRef.close({isAdvanced: this.goUrl}); 
  }

  close() {
    this.goUrl = false;
    this.matDialogRef.close();
  }

}
