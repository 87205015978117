<div mat-dialog-content>
    <div class="mat-dialog-content">

        <h1 class="self-triage-button-name">{{buttonName}}</h1>
        <hr/>
        <div class="row description">
            <div class="col-12">
                <p class="description">{{buttonDescription}}</p>
            </div>
        </div>
        <div class="button-footer">
            <button mat-flat-button color="secundary" class="button-selection" (click)="advance()">
                <span *ngIf="isLoading == false">Prosseguir</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        <div class="button-footer">
            <button mat-flat-button color="secundary" class="button-back" (click)="close()">
                <span *ngIf="isLoading == false">Fechar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        
        <div class="footer-white-container">
            <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
        </div>
        
    </div>
</div>