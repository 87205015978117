import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { RecoverPasswordRequest } from '../../requests/patient-center/recover-password.request';
import { RecoverPasswordResponse } from '../../responses/patient-center/recover-password.response';

@Injectable({
  providedIn: 'root'
})

export class RecoverPasswordService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public verifyToken(token: string): Observable<ReturnStruct> {

    return this.httpClient.get<ReturnStruct>(environment.urlApiPatientCenter + `RecoverPassword?token=${token}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  public recoverPassword(body: RecoverPasswordRequest): Observable<ReturnStruct> {

    return this.httpClient.put<ReturnStruct>(environment.urlApiPatientCenter + "RecoverPassword", body)
      .pipe(
        catchError(this.handleError)
      );
  }

  public recoverPasswordToken(login: string, idHealthUnit, uri: string): Observable<RecoverPasswordResponse> {
    let body = {
      login: login,
      idHealthUnit: idHealthUnit,
      uri: uri
    };

    return this.httpClient.post<RecoverPasswordResponse>(environment.urlApiPatientCenter + "RecoverPassword", body)
      .pipe(
        catchError(this.handleError)
      );
  }
}