<div class="container-box">
    <div class="container orientations-session">
        <div class="mat-dialog-container-medic-hold-modal" *ngIf="time">
            <h1 [ngStyle]="{'color': colorCode}">O {{this.isMedic ? "médico" : "enfermeiro"}} está pronto para começar!
            </h1>
            <h2>Você tem:</h2>
            <p class="text" [ngStyle]="{'color': colorCode}">{{time}}</p>
            <h2>para iniciar o atendimento</h2>
        </div>
        <div class="mat-dialog-container-medic-hold-modal" *ngIf="!time">
            <div class="c-loader" [ngStyle]="{'border-top-color': colorCode}"></div>
            <h2 class="txtCenter">Aguarde!</h2>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 forward-button">
        <button mat-flat-button color="primary" class="-flex" [disabled]="!time" (click)="init()">INICIAR</button>
        <button mat-flat-button class="btn-secundary-flex" type="button" (click)="openModalCancel()">SAIR DA
            FILA</button>
    </div>
</div>