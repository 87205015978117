import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common-service';
import { catchError, retry } from 'rxjs/operators';
import { ReturnStruct } from '../structs/return.struct';
import { PatientCenterLoginResponse } from './responses/auth/patient-center-login.response';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public signIn(login: string, password: string, ip: string): Observable<any> {

    let userAuthRequest = {
      login: login,
      password: password,
      ip: ip
    };

    return this.httpClient.post<any>(environment.urlApiAuth + "GenerateToken", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public signInPatientCenter(cpf: string, password: string, ip: string, token: string, logoHealthUnit: string, idHealthUnit: number, listIdHealthUnitInGroup: number[], timeoutValue: number): Observable<PatientCenterLoginResponse> {

    let userAuthRequest = {
      cpf: cpf,
      password: password,
      ip: ip,
      token: token,
      logoHealthUnit: logoHealthUnit,
      idHealthUnit: idHealthUnit,
      listIdHealthUnitInGroup: listIdHealthUnitInGroup,
      timeoutValue: timeoutValue
    };

    return this.httpClient.post<PatientCenterLoginResponse>(environment.urlApiAuth + "GeneratePatientCenterToken", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateToken(userLoginResponse: PatientCenterLoginResponse) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  updateDefaultModule(newDefaultModule: any) {
    let data = JSON.parse(sessionStorage.getItem("token"));
    if (newDefaultModule != undefined && newDefaultModule != null) {
      data.idDefaultModule = newDefaultModule.idModule;
      data.uriDefaultModule = newDefaultModule.uri;
      data.defaultModuleName = newDefaultModule.nameModule;
    }
    else {
      data.idDefaultModule = null;
      data.uriDefaultModule = null;
      data.defaultModuleName = null;
    }
    sessionStorage.setItem("token", JSON.stringify(data));
  }

  logout() {
    sessionStorage.removeItem("token");
    this.router.navigate(['/digital-prompt-service/' + sessionStorage.getItem('uri')])
    .then(() => {
      window.location.reload();
    });
  }

  getTokenStruct() {
    const token: string = sessionStorage.getItem('token');
    let userLoginResponse: PatientCenterLoginResponse = token ? JSON.parse(token) : null;
    return userLoginResponse;
  }


  getTokenMenu() {
    // let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    // return userLoginResponse;
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem("token") == null ? false : true;
  }

  haveRolePermission(idRole: number): boolean {
    return false;
    // let hasRole = false;
    // let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));

    // userLoginResponse.listRole.forEach(element => {
    //   if(element.idRole == idRole){
    //     hasRole = true;
    //   }
    // });

    // return hasRole;
  }
}
