import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LookupResponse } from '../../responses/self-triage/lookup.response';

@Injectable({
    providedIn: 'root'
})

export class LookupService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    public getGender(): Observable<LookupResponse> {

        return this.httpClient.get<LookupResponse>(environment.urlApiSelfTriage + `Lookup`)
            .pipe(
                catchError(this.handleError)
            )
    }
}