import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetAvarageWaitingTimeAndLastAttendedTicketResponse } from '../../responses/queue/get-avarage-waiting-time-and-last-attended-ticket.response';

@Injectable({
    providedIn: 'root'
})

export class QueueUnService extends CommonService {

    constructor(private httpClient: HttpClient,) {
        super();
    }

    public GetAvarageWaitingTimeAndLastAttendedTicketInActualQueue(idEpisode: number): Observable<GetAvarageWaitingTimeAndLastAttendedTicketResponse> {
        let uri = `queueun/GetAvarageWaitingTimeAndLastAttendedTicketInActualQueue/idEpisode/${idEpisode}`;

        return this.httpClient.get<GetAvarageWaitingTimeAndLastAttendedTicketResponse>(environment.urlApiQueue + uri)
            .pipe(
                catchError(this.handleError)
            )
    }
}