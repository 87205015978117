<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                <h1>A consulta foi finalizada!</h1>

                <div class="margin-img">
                    <img class="img-class-mobile" *ngIf="isMobileVersion" src="assets/images/ended-query.png" />
                    <img class="img-class" *ngIf="!isMobileVersion" src="assets/images/ended-query.png" />
                </div>
            </div>
            <div class="section-sub-title">
                <p *ngIf="attendanceLocalEnum.triagemVirtual != this.attendanceLocal" class="description">Você receberá, por e-mail, um documento com todas as prescrições relacionadas ao seu atendimento.</p>
                <p *ngIf="attendanceLocalEnum.triagemVirtual == this.attendanceLocal" class="description">Continue os cuidados com a sua saúde e siga adequadamente as orientações dadas pelo Enfermeiro.</p>
            </div>
            <button class="forward-button" mat-flat-button color="primary" (click)="endedQuery()">
                <span>VOLTAR AO INÍCIO</span>
            </button>
        </div>
    </div>
</div>