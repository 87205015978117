import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SelfTriageRoutingModule } from './self-triage-routing.module';
import { SelfTriageComponent } from './pages/self-triage/self-triage.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PatientIdentificationComponent } from './pages/self-triage/patient-identification/patient-identification.component';
import { AdditionalDataComponent } from './pages/self-triage/additional-data/additional-data.component';
import { SearchPatientComponent } from './pages/self-triage/search-patient/search-patient.component';
import { CancelModalComponent } from './pages/self-triage/cancel-modal/cancel-modal.component';
import { ProtocolComponent } from './pages/self-triage/protocol/protocol.component';
import { FlowchartComponent } from './pages/self-triage/protocol/flowchart/flowchart.component';
import { QuestionComponent } from './pages/self-triage/protocol/question/question.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatDialogModule } from '@angular/material/dialog';
import { WelcomeComponent } from './pages/self-triage/welcome/welcome.component';
import { HeaderComponent } from './pages/self-triage/header/header.component';
import { OrientationsComponent } from './pages/self-triage/orientations/orietations.component';
import { PaginationComponent } from './pages/self-triage/pagination/pagination.component';
import { TermsOfUseModalComponent } from './pages/self-triage/patient-identification/terms-of-use-modal/terms-of-use-modal.component';
import { EmergencyWarningComponent } from './pages/self-triage/emergency-warning/emergency-warning.component';
import { CounselingComponent } from './pages/self-triage/counseling/counseling.component';
import { PhoneModalComponent } from './pages/self-triage/counseling/phone-modal/phone-modal.component';
import { ResumeModalComponent } from './pages/self-triage/counseling/resume-modal/resume-modal.component';
import { EndSelfTriage } from './pages/self-triage/end-self-triage/end-self-triage.component';
import { TelemedicineModalComponent } from './pages/self-triage/counseling/telemedicine-modal/telemedicine-modal/telemedicine-modal.component';
import { UrlModalComponent } from './pages/self-triage/counseling/url-modal/url-modal/url-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    SelfTriageComponent,
    HeaderComponent,
    PaginationComponent,
    WelcomeComponent,
    OrientationsComponent,
    PatientIdentificationComponent,
    TermsOfUseModalComponent,
    EmergencyWarningComponent,
    AdditionalDataComponent,
    SearchPatientComponent,
    CounselingComponent,
    PhoneModalComponent,
    CancelModalComponent,
    ProtocolComponent,
    FlowchartComponent,
    QuestionComponent,
    ResumeModalComponent,
    EndSelfTriage,
    TelemedicineModalComponent,
    UrlModalComponent,
  ],
  imports: [
    CommonModule,
    SelfTriageRoutingModule,
    SharedModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    MatDialogModule,
    MatAutocompleteModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
  ],
})
export class SelfTriageModule { }
