import { Injectable } from "@angular/core";
import { NpsMessageResponse } from "src/app/shared/services/responses/nps/nps-message.reponse";

@Injectable({
    providedIn: 'root'
})
export class UtilsNpsService {
    constructor() { }

    setNpsMessageResponse(data: NpsMessageResponse): void {
        sessionStorage.setItem("nps", JSON.stringify(data));
    }

    getNpsMessageResponse(): NpsMessageResponse {
        const data: string = sessionStorage.getItem("nps");
        return data ? JSON.parse(data) : null
    }

    removeNpsMessageResponse(): void {
        sessionStorage.removeItem("nps");
    }
}