import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { TelemedicineConfigStruct } from '../../services/structs/telemedicine/telemedicine-config.struct';

@Component({
  selector: 'app-attend-patient-end-modal',
  templateUrl: './attend-patient-end-modal.component.html',
  styleUrls: ['./attend-patient-end-modal.component.css']
})
export class AttendPatientEndModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public utilsTelemedicineService: UtilsTelemedicineService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AttendPatientEndModalComponent>,
    private router: Router,
  ) { this.matDialogRef.disableClose = true; }

  public seconds: any = 15;
  public isLoading: boolean;
  public interval: any;
  public uri: string;
  public telemedicineConfig: TelemedicineConfigStruct;

  ngOnInit(): void {

    if (this.data.hasTime) {
      this.startTime();
    }

    this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
    this.uri = this.telemedicineConfig.url;

  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);

    var seconds = this.seconds;
    var $this = this;
    function myMethod() {
      if (seconds > 0)
        seconds = seconds - 1;

      if (seconds == 0) {
        clearInterval($this.interval);
        $this.isLoading = true;
        $this.matDialogRef.close();
      }
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}