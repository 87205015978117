import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyDate } from 'src/app/shared/custom-validators/date.validator';
import { LookupService } from 'src/app/shared/services/API/patient-center/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Gender } from 'src/app/shared/services/models/patient-center/gender.model';
import { UserPatientRequest } from 'src/app/shared/services/requests/patient-center/user-patient.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { VerifyName } from 'src/app/shared/custom-validators/name.validator';
import { PhoneValidator } from 'src/app/shared/custom-validators/phone.validator';
import { UserPatient } from 'src/app/shared/services/models/patient-center/user-patient.model';
import { PatientCenterLoginResponse } from 'src/app/shared/services/responses/auth/patient-center-login.response';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserPatientCenterService } from 'src/app/shared/services/API/patient-center/user-patient-center.service';
@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnInit{
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private maskService: MaskService,
    public router: Router,
    public dialog: MatDialog,
    private lookupService: LookupService,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private userPatientCenterService: UserPatientCenterService,
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean;
  public colorCode: string = '#99C8D6';
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public listGender: Gender[];
  public userPatient: UserPatient;
  public tokenStruct: PatientCenterLoginResponse;

  ngOnInit(): void {
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.tokenStruct = this.authService.getTokenStruct();
    this.masks = this.maskService.getMasks();

    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    this.model = this.formBuilder.group({
      completeName: ['', [Validators.required, VerifyName()]],
      completeMotherName: ['', [Validators.required, VerifyName()]],
      cpf: ['', [Validators.required, VerifyCPF()]],
      phone: ['', [Validators.required, PhoneValidator()]],
      birthDate: ['', [Validators.required, VerifyDate()]],
      idGender: ['', [Validators.required]],
    });

    this.lookupGender();

    this.getPatient();
  }

  lookupGender() {
    this.lookupService.getAll().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listGender = response.listGender;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos em vermelho", AlertType.error);
      return;
    }
    this.isLoading = true;
    let config = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    let request: UserPatientRequest = new UserPatientRequest();

    request.idGender = this.model.get('idGender').value;
    request.userName = this.model.get('completeName').value;
    request.motherName = this.model.get('completeMotherName').value;
    request.cpf = this.model.get('cpf').value;
    request.phoneNumber = this.model.get('phone').value;
    let userBirthDate = this.model.get('birthDate').value;
    let birthDate = new Date(parseInt(userBirthDate.slice(4, 8)), parseInt(userBirthDate.slice(2, 4)) - 1, parseInt(userBirthDate.slice(0, 2)));
    request.userBirthDate = birthDate;
    request.isActive = true;
    request.idHealthUnit = this.tokenStruct.idHealthUnit;
    request.userEmail = this.userPatient.userEmail;
    request.password = this.userPatient.password;

    this.userPatientCenterService.updateBasicInfo(this.tokenStruct.idUserPatient, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.tokenStruct.userName = request.userName;
        sessionStorage.setItem("token", JSON.stringify(this.tokenStruct));
        this.alertService.show('Sucesso!', 'Dados alterados com sucesso!', AlertType.success);
        this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  cancel() {
    this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  getPatient() {
    this.userPatientCenterService.getUserPatientById(this.tokenStruct.idUserPatient, this.tokenStruct.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.userPatient = response.userPatient;
        this.populateValues();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateValues() {
      this.model.get('cpf').setValue(this.userPatient.cpf);
      this.model.get('completeName').setValue(this.userPatient.userName);
      this.model.get('completeMotherName').setValue(this.userPatient.motherName);
      this.model.get('idGender').setValue(this.userPatient.idGender);
      this.model.get('birthDate').setValue(this.formatDate(this.userPatient.userBirthDate));
      this.model.get('phone').setValue(this.userPatient.phoneNumber);
      this.model.get('cpf').disable();
  }

  private formatDate(date) {
    const birthDate = new Date(date);
    const birthDateYear = birthDate.getFullYear().toString();
    const birthDateMonth = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const birthDateDay = birthDate.getDate().toString().padStart(2, '0');
    return birthDateDay + birthDateMonth + birthDateYear;
  }
}