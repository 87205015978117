<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-cancel-modal">
            <h2>Realmente deseja voltar?</h2>
        </div>

        <div class="body-cancel-modal">
            <span>Todos os dados preenchidos até agora serão perdidos.</span>
        </div>
        <div class="footer-cancel-modal">
            <button mat-flat-button color="primary" class=" block" (click)="clickReload()" [ngStyle]="{'background-color': colorCode}">
                <span *ngIf="isLoading == false">Sim</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <a mat-stroked-button type="button" class="btn-secundary-flex" (click)="close()"
                [ngStyle]="{'border-color': colorCode,'color': colorCode}">
                Cancelar
            </a>
        </div>
    </div>
</div>