export const environment = {
  production: false,
  staging:true,
  hashAuthorization: "0d3990c46dbdd338b5037946d41cf3ec9d35f95285127acaf1c742bf9e93a215",
  urlApiBase: "https://api.clusterstaging.tolife.app/",
  urlApiUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-user/",
  urlApiAuth: "https://api.clusterstaging.tolife.app/tolifecloud-api-auth/",
  urlApiSrvEmail: "https://api.clusterstaging.tolife.app/tolifecloud-api-srvemail/",
  urlApiFlow: "https://api.clusterstaging.tolife.app/tolifecloud-api-flow/",
  urlApiTotem: "https://api.clusterstaging.tolife.app/tolifecloud-api-totem/",
  urlApiPanel: "https://api.clusterstaging.tolife.app/tolifecloud-api-panel/",
  urlWebsocket: "wss://api.clusterstaging.tolife.app/",
  urlApiOrchestratorTotem: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-totem/",
  urlApiOrchestratorQueue: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-queue/",
  urlApiOrchestratorPanel: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-panel/",
  urlApiQueue: "https://api.clusterstaging.tolife.app/tolifecloud-api-queue/",
  urlApiFrontDesk: "https://api.clusterstaging.tolife.app/tolifecloud-api-frontdesk/",
  urlApiPharmacy: "https://api.clusterstaging.tolife.app/tolifecloud-api-pharmacy/",
  urlApiMedicalRecord:"https://api.clusterstaging.tolife.app/tolifecloud-api-medical-record/",
  urlApiSADT: "https://api.clusterstaging.tolife.app/tolifecloud-api-sadt/",
  urlApiTotemPrinter: "http://localhost:49577/",
  urlApiOrchestratorPatient: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-patient/",
  urlApiOrchestratorSADT: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-sadt/",
  urlApiRiskClassification: "https://api.clusterstaging.tolife.app/tolifecloud-api-risk-classification/",
  urlApiSensors: "http://localhost:5001/",
  urlApiIntegration: "https://api.clusterstaging.tolife.app/tolifecloud-api-integration/",
  urlApiAudit: "https://api.clusterstaging.tolife.app/tolifecloud-api-audit/",
  urlApiOrchestratorAudit: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-audit/",
  urlApiBed: "https://api.clusterstaging.tolife.app/tolifecloud-api-bed/",
  urlWorkerSyncMeasurer:"http://localhost:7000/",
  urlApiSyncMeasurer: "https://api.clusterstaging.tolife.app/tolifecloud-api-sync-measurer/",
  urlApiSelfTriage: "https://api.clusterstaging.tolife.app/tolifecloud-api-self-triage/",
  urlApiTelemedicine: "https://api.clusterstaging.tolife.app/tolifecloud-api-telemedicine/",
  urlApiOrchestratorTelemedicine: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-telemedicine/",
  urlApiNps: "https://api.clusterstaging.tolife.app/tolifecloud-api-nps/",
  urlUIPatientBase: "https://patient.clusterstaging.tolife.app/",
  siteKey: "6LeYYPslAAAAAEXNecI1746a3uIXghD2oJOYLTjR",
  urlApiSchedule: "https://api.clusterstaging.tolife.app/tolifecloud-api-schedule/",
  urlApiOrchestratorSchedule: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-schedule/",
  urlApiPatientCenter: "https://api.clusterstaging.tolife.app/tolifecloud-api-patient-center/",
  urlApiDigitalPrompt: "https://api.clusterstaging.tolife.app/tolifecloud-api-digital-prompt/",
  hashEmailSmsAuthorization: "2d62ddbb4cef30c726b8e627a752041b",
  urlApiOrchestratorUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-user/",
  urlApiNotification: "https://api.clusterstaging.tolife.app/tolifecloud-api-notification/",
  urlApiOrchestratorClassification: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-classification/",
  urlApiPrivateBilling: "https://api.clusterstaging.tolife.app/tolifecloud-api-private-billing/",
};