import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/patient-center/forgot-password/forgot-password.component';
import { PatientCenterComponent } from './pages/patient-center/patient-center.component';
import { LoginComponent } from './pages/patient-center/login/login.component';
import { PatientRegisterComponent } from './pages/patient-center/patient-register/patient-register.component';
import { HubComponent } from './pages/patient-center/hub/hub.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { PatientAppointmentComponent } from './pages/patient-center/patient-appointment/patient-appointment.component';
import { AttendPatientVideoComponent } from './pages/patient-center/attend-patient-video/attend-patient-video.component';
import { SchedulingInformationComponent } from './pages/patient-center/scheduling-information/scheduling-information.component';
import { SchedulingDateComponent } from './pages/patient-center/scheduling-date/scheduling-date.component';
import { SchedulingConfirmComponent } from './pages/patient-center/scheduling-confirm/scheduling-confirm.component';
import { SchedulingEndComponent } from './pages/patient-center/scheduling-end/scheduling-end.component';
import { RecoverPasswordComponent } from './pages/patient-center/recover-password/recover-password.component';
import { ProfileInfoComponent } from './pages/patient-center/profile-info/profile-info.component';
import { HealthcareAgreementComponent } from './pages/patient-center/healthcare-agreement/healthcare-agreement.component';

const routes: Routes = [
  //Não autenticados
  { path: 'digital-prompt-service/:uri', component: PatientCenterComponent, pathMatch: 'full' },
  { path: 'digital-prompt-service/:uri/:guid', component: PatientCenterComponent, pathMatch: 'full' },
  { path: 'patient-center/:uri', component: LoginComponent },
  { path: 'patient-center/forgot-password/:uri', component: ForgotPasswordComponent },
  { path: 'patient-center/:uri/recover-password', component: RecoverPasswordComponent },
  { path: 'patient-center/patient-register/:uri', component: PatientRegisterComponent },

  //Autenticados
  { path: 'patient-center/hub/:uri', component: HubComponent, canActivate: [AuthGuard] },
  { path: 'patient-center/patient-appointment/:uri', component: PatientAppointmentComponent, canActivate: [AuthGuard] },
  { path: 'patient-center/healthcare-agreement/:uri', component: HealthcareAgreementComponent, canActivate: [AuthGuard]  },
  { path: 'patient-center/attend-patient/video/:uri', component: AttendPatientVideoComponent, canActivate: [AuthGuard]},
  { path: 'patient-center/scheduling-information/:uri', component: SchedulingInformationComponent, canActivate: [AuthGuard]  },
  { path: 'patient-center/scheduling-date/:uri', component: SchedulingDateComponent, canActivate: [AuthGuard]  },
  { path: 'patient-center/scheduling-confirm/:uri', component: SchedulingConfirmComponent, canActivate: [AuthGuard]  },
  { path: 'patient-center/scheduling-end/:uri', component: SchedulingEndComponent, canActivate: [AuthGuard]  },
  { path: 'patient-center/profile-info/:uri', component: ProfileInfoComponent, canActivate: [AuthGuard]  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientCenterRoutingModule { }
