<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-12">
                <h2>Dados inválidos!</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <p><b>Os dados que você informou não coincidem com os dados do paciente vinculado a senha!</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <button mat-flat-button color="primary" class=" block" type="button" (click)="openModalConfirmation()">
                        <span *ngIf="!isLoading">Quero tentar novamente</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-6">

                    <button mat-flat-button color="primary" class=" block" type="button" (click)="openModalAlert()">
                        <span *ngIf="!isLoading">Não quero tentar novamente</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>