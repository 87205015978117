import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetConfigResponse } from '../../responses/self-triage/get-config.response';

@Injectable({
    providedIn: 'root'
})

export class ClientSelfTriageConfigService extends CommonService {

    constructor(private httpClient: HttpClient) { super(); }

    public getClientConfig(uriClient: string): Observable<GetConfigResponse> {
        let uri = `ClientSelfTriageConfig/uri/${uriClient}`

        return this.httpClient.get<GetConfigResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderInternalToken())
            .pipe(catchError(this.handleError));
    }
}