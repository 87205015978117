import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { PatientEpisodeQueueService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-episode-queue.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { EpisodeData, PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { QueueUnStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/queue.struct';
import { EpisodeStatusEnum } from 'src/app/shared/enum/telemedicine/episode-status.enum';
import { QueueStatusEnum } from 'src/app/shared/enum/telemedicine/queue-status.enum';
import { MatDialog } from '@angular/material/dialog';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { LgpdModalComponent } from './lgpd-modal/lgpd-modal.component';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { SelfTriagePatientStruct } from 'src/app/shared/services/structs/telemedicine/self-triage-patient.struct';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';


@Component({
  selector: 'app-patient-identification',
  templateUrl: './patient-identification.component.html',
  styleUrls: ['./patient-identification.component.css'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }
  ]
})
export class PatientIdentificationComponent implements OnInit {

  @Input() telemedicineConfig: TelemedicineConfigStruct;
  @Input() colorCode: string = "";
  @Input() selfTriagePatient: SelfTriagePatientStruct;
  @Input() patientCurrent: PatientData;
  @Input() queue: QueueUnStruct;
  @Output() savedPatientData = new EventEmitter<PatientStruct>();
  @Output() next = new EventEmitter<any>();
  @Output() goToWaitingArea = new EventEmitter<number>();

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    public dialog: MatDialog,
    private PatientEpisodeQueueService: PatientEpisodeQueueService,
    private alertService: AlertService,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private patientAccessLeaving: PatientAccessLeavingService
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public flowEnum = TelemedicineFlowEnum.patientIdentification;
  public isLoading: boolean = false;
  public checkedPatient: boolean = false;
  public isLoadingCpf: boolean;
  public checkedPrivacy: boolean = false;
  public shadow: string = "none";
  public btnColor: string = "#4f7528";

  ngOnInit(): void {
    if (this.telemedicineConfig.useColor == true)
      document.documentElement.style.setProperty('--colorCodeTelemedicine', this.colorCode);

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      cpf: ['', [Validators.required, VerifyCPF()]],
    });

    this.model.get('cpf').markAsPristine();

    if (this.selfTriagePatient) {
      this.checkedPatient = true;
      this.model.get('cpf').setValue(this.selfTriagePatient.cpf);
      this.model.get('cpf').disable();
      this.checkedPrivacy = true;
    } else if (this.patientCurrent && this.patientCurrent.cpf) {
      this.checkedPatient = true;
      this.model.get('cpf').setValue(this.patientCurrent.cpf);
      this.model.get('cpf').disable();
      this.checkedPrivacy = true;
    }

  }

  submit() {
    if (this.isLoading){
      return;
    }    

    else if (this.model.invalid){
      this.alertService.show('Erro', "Insira um CPF válido", AlertType.error);
      return;
    }      
    
    else if (!this.checkedPrivacy){
      this.alertService.show('Erro', "Você precisa concordar com as Políticas de Privacidade", AlertType.error);
      return;
    }      

    this.searchByCPF();
  }

  searchByCPF() {
    this.isLoading = true;
    this.PatientEpisodeQueueService.getPatientEpisodeQueueByCPF(this.model.get('cpf').value.replace(' ', ''), this.telemedicineConfig.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError && response.errorDescription != "CPF não encontrado.") {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }
        this.isLoading = false;
        this.isLoadingCpf = false;

        if (!response.patient){
          this.next.emit();
        }

        else {
          this.checkedPatient = true;

          this.queue = response.queue;

          let patient: PatientStruct = response.patient ? response.patient : new PatientStruct();
          patient.cpf = this.model.get('cpf').value;
          
          this.utilsTelemedicineService.updatePatientData(this.mapToPatientData(patient));
          this.savedPatientData.emit(patient);

          if (response.episode?.idStatus == EpisodeStatusEnum.iniciado) {
            if (response.queue?.idStatusQueue == QueueStatusEnum.naoAtendido) {
              let episode = new EpisodeData();

              episode.idEpisode = response.episode.idEpisode;
              episode.idQueue = response.queue.idQueue;
              episode.actualTicket = response.episode.fullTicket;

              this.utilsTelemedicineService.createEpisodeData(episode);
              this.goToWaitingArea.emit();

              return;
            }

            else if (response.queue) {
              this.updateStatusPatient(response.episode.idEpisode, response.queue.idQueue);
            }
          } 
          
          else if (response.episode?.idStatus == EpisodeStatusEnum.finalizadoEvasao) {
            this.alertService.show('Alerta', 'Seu atendimento anterior foi finalizado automaticamente. Continue o processo para retornar a fila.', AlertType.warning);
          }

          this.next.emit();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.checkedPatient = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient(idEpisode: number, idQueue: number) {
    this.isLoading = true;
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = "Paciente evadido após sair da fila da telemedicina.";
    statusQueueRequest.idEpisode = idEpisode;
    statusQueueRequest.idHealthUnit = this.telemedicineConfig.idHealthUnit;
    statusQueueRequest.idQueue = idQueue;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;

    this.patientAccessLeaving.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openLGPDModal(checkbox: any) {
    const dialogRef = this.dialog.open(LgpdModalComponent, {
      data: {},
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result.accept) {
          this.shadow = "0 0 4px #065f19";
          this.checkedPrivacy = true;
          checkbox.isChecked = true;
        }
        else {
          this.shadow = "none";
          this.checkedPrivacy = false;
          checkbox.isChecked = false;
          window.location.reload();
        }
      },
      error: (error) => {
      }
    });
  }

  onSavedPatientData() {
    this.next.emit();
  }

  mapToPatientData(patient: PatientStruct): PatientData {
    let PatientDataOld: PatientData = this.utilsTelemedicineService.getPatientData();

    let idPatientTelemedicineIntegration: number = null;

    if (PatientDataOld && PatientDataOld.idPatientTelemedicineIntegration) {
      idPatientTelemedicineIntegration = PatientDataOld.idPatientTelemedicineIntegration;
    }

    let PatientData: PatientData = {
      birthDate: patient.birthDate,
      city: patient.city,
      cns: patient.cns,
      cpf: patient.cpf,
      email: patient.email,
      idPatientTelemedicineIntegration: idPatientTelemedicineIntegration,
      idGender: patient.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patient.idPatient,
      neighborhood: patient.neighborhood,
      patientName: patient.patientName,
      phone: patient.phone1 ? patient.phone1 : patient.phone2,
      state: patient.state,
      zipCode: patient.zipCode,
      datetimeinclusion: null,
      healthPlan: null,
      healthPlanExpirationDate: null,
      healthPlanNumber: null,
      idQueue: this.queue ? this.queue.idQueue : null,
      patientCenterAccessTelemedicine: false,
      street: patient.street,
      houseNumber: patient.houseNumber,
    };

    return PatientData;
  }
}