import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { PatientAppointmentStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-appointment.struct';

@Component({
  selector: 'app-out-of-hours-appointment-modal',
  templateUrl: './out-of-hours-appointment-modal.component.html',
  styleUrls: ['./out-of-hours-appointment-modal.component.css']
})
export class OutOfHoursAppointmentModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
    private alertService: AlertService,
    private patientAcessLeavingService: PatientAccessLeavingService,
    private formBuilder: UntypedFormBuilder,
    private utilDigitalPromptService: UtilsDigitalPromptServiceService,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;
  public text: string;
  public apppointment: PatientAppointmentStruct;
  public standardAttendance: number = NatureOfAttendanceEnum.presencial;
  public onlineAttendance: number = NatureOfAttendanceEnum.online;

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
    this.text = this.data.text;
  }

  confirm() {
    this.matDialogRef.close();
  }
}
