import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SelfTriageResultRequest } from '../../requests/self-triage/self-triage-result.request';
import { ProtocolCompleteResponse } from '../../responses/self-triage/protocol-complete.response';

@Injectable({
    providedIn: 'root'
})

export class ClientSelfTriageProtocolService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getClientProtocol(uriClient: string, birthdate: string): Observable<ProtocolCompleteResponse> {
        let uri = `ClientSelfTriageProtocol/uri/${uriClient}?birthDate=${birthdate}`

        return this.httpClient.get<ProtocolCompleteResponse>(environment.urlApiSelfTriage + uri)
            .pipe(
                catchError(this.handleError)
            )
    }

    public SaveSelfTriageResult(uriClient: string, body: SelfTriageResultRequest): Observable<ReturnStruct> {
        let uri = `ClientSelfTriageProtocol/uri/${uriClient}`

        return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorClassification + uri, body)
            .pipe(
                catchError(this.handleError)
            )
    }
}