import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyDate } from 'src/app/shared/custom-validators/date.validator';
import { emailValidator } from 'src/app/shared/custom-validators/email-domain.validator';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { LookupService } from 'src/app/shared/services/API/self-triage/lookup.service';
import { PatientService } from 'src/app/shared/services/API/self-triage/patient.service';
import { ClientHealthUnitCityService } from 'src/app/shared/services/API/user/client-health-unit-city.service';
import { ClientHealthUnitStateService } from 'src/app/shared/services/API/user/client-health-unit-state.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { Gender } from 'src/app/shared/services/models/self-triage/gender.model';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { HealthUnitCityModel } from 'src/app/shared/services/models/user/health-unit-city.model';
import { HealthUnitStateModel } from 'src/app/shared/services/models/user/health-unit-state.model';
import { PatientRequest } from 'src/app/shared/services/requests/self-triage/patient.request';
import { IbgeCityStruct } from 'src/app/shared/services/structs/self-triage/ibge-city.struct';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';

@Component({
  selector: 'app-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.css']
})

export class AdditionalDataComponent implements OnInit {
  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private patientService: PatientService,
    private alertService: AlertService,
    private lookupService: LookupService,
    private utilSelfTriageService: UtilsSelfTriageService,
    public router: Router,
    private clienthealthUnitStateService: ClientHealthUnitStateService,
    private clienthealthUnitCityService: ClientHealthUnitCityService,
    public viaCepService: ViaCepService,
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public checkedCpf: boolean = false;
  public isLoading: boolean;
  public isLoadingCep: boolean;

  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.personalData;

  public colorCode: string = '#99C8D6';

  public patient: Patient;
  public patientRegisterRequest: PatientRequest;
  public selfTriageConfig: SelfTriageConfigStruct;
  public listGender: Gender[];
  public listIbgeCity: IbgeCityStruct[];
  public listAllStates: HealthUnitStateModel[] = [];
  public listAllCities: HealthUnitCityModel[] = [];
  public preSelectState: HealthUnitStateModel[] = [];
  public preSelectCity: HealthUnitCityModel[] = [];
  public listFilteredStates: Observable<HealthUnitStateModel[]>;
  public listFilteredCities: Observable<HealthUnitCityModel[]>;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();

    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);
    else if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }

    this.patientRegisterRequest = new PatientRequest();
    this.patient = (this.utilSelfTriageService.getPatientData()) ? this.utilSelfTriageService.getPatientData() : new Patient();

    this.model = this.formBuilder.group({
      completeName: ['', [Validators.required]],
      cpf: ['', [Validators.required, VerifyCPF()]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, emailValidator()]],
      birthDate: ['', [Validators.required, VerifyDate()]],
      gender: ['', [Validators.required]],
      zipCode: [''],
      state: [''],
      city: [''],
      neighborhood: [''],
      street: [''],
      houseNumber: [''],
    });

    if (this.patient)
      this.populateAdditionalData();

    this.getListState();
    this.lookupGender();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (!this.model.get('gender').value) {
      this.alertService.show('Erro', "O campo gênero deve ser corretamente selecionado.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.patientRegisterRequest.patientName = this.model.get('completeName').value;
    this.patientRegisterRequest.cpf = this.model.get('cpf').value;
    this.patientRegisterRequest.phone = this.model.get('phone').value;
    this.patientRegisterRequest.email = this.model.get('email').value;
    this.patientRegisterRequest.birthDate = (this.formatDateBack(this.model.get('birthDate').value));
    this.patientRegisterRequest.idGender = this.model.get('gender').value;
    this.patientRegisterRequest.city = this.model.get('city').value;
    this.patientRegisterRequest.state = this.model.get('state').value;
    this.patientRegisterRequest.zipCode = this.model.get('zipCode').value;
    this.patientRegisterRequest.neighborhood = this.model.get('neighborhood').value;
    this.patientRegisterRequest.street = this.model.get('street').value;
    this.patientRegisterRequest.houseNumber = this.model.get('houseNumber').value ? parseInt(this.model.get('houseNumber').value) : null;
    this.patientRegisterRequest.idHealthUnit = this.selfTriageConfig.idHealthUnit;
    this.patientRegisterRequest.listIdHealthUnit = this.selfTriageConfig.listIdHealthUnit;

    this.createPatient();
  }

  createPatient() {
    this.patientService.createPatient(this.patientRegisterRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.patient.idPatient = response.idPatient;
        this.mapAndSavePatient();
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate([`self-triage/protocol/${this.utilSelfTriageService.getUri()}`]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapAndSavePatient() {
    this.patient.patientName = this.model.get('completeName').value;
    this.patient.cpf = this.model.get('cpf').value;
    this.patient.phone = this.model.get('phone').value;
    this.patient.email = this.model.get('email').value;
    this.patient.birthDate = (this.formatDateBack(this.model.get('birthDate').value));
    this.patient.idGender = this.model.get('gender').value;
    this.patient.zipCode = this.model.get('zipCode').value;
    this.patient.city = this.model.get('city').value;
    this.patient.state = this.model.get('state').value;
    this.patient.neighborhood = this.model.get('neighborhood').value;
    this.patient.street = this.model.get('street').value;
    this.patient.houseNumber = this.model.get('houseNumber').value ? parseInt(this.model.get('houseNumber').value) : null;
    this.utilSelfTriageService.updatePatientData(this.patient);
  }

  populateAdditionalData() {
    this.model.get('completeName').setValue(this.patient.patientName);
    this.model.get('cpf').setValue(this.patient.cpf);
    this.model.get('phone').setValue(this.patient.phone);
    this.model.get('email').setValue(this.patient.email);
    this.model.get('birthDate').setValue(this.dataAtualFormatada(this.patient.birthDate));
    this.model.get('gender').setValue(this.patient.idGender);
    this.model.get('zipCode').setValue(this.patient.zipCode);
    this.model.get('city').setValue(this.patient.city);
    this.model.get('state').setValue(this.patient.state);
    this.model.get('neighborhood').setValue(this.patient.neighborhood);
    this.model.get('street').setValue(this.patient.street);
    this.model.get('houseNumber').setValue(this.patient.houseNumber ? this.patient.houseNumber.toString() : '');
  }

  dataAtualFormatada(dateToFormat: Date) {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      return diaF + mesF + anoF;
    }

    return null;
  }

  formatDateBack(dateUnformatted: string) {
    if (dateUnformatted) {
      dateUnformatted = dateUnformatted.replaceAll("/", "");
      let dobj = new Date(parseInt(dateUnformatted.substring(4, 8)), parseInt(dateUnformatted.substring(2, 4)) - 1, parseInt(dateUnformatted.substring(0, 2)));
      return dobj;
    }

    return null;
  }

  lookupGender() {
    this.lookupService.getGender().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listGender = response.listGender;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListState() {
    this.clienthealthUnitStateService.getStates().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listAllStates = response.list;
        this.listFilteredStates = this.model.get('state').valueChanges.pipe(
          startWith(''),
          map(value => this.filterSate(value || '')),
        );
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  filterSate(value: string): HealthUnitStateModel[] {
    const filterValue = value.toLowerCase();

    return this.listAllStates.filter(state => state.stateName.toLowerCase().includes(filterValue));
  }

  getListCitiesByUf(idHealthUnitState: number) {
    this.clienthealthUnitCityService.ListHealthUnitCity(idHealthUnitState).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.model.get('city').enable();
        this.listAllCities = response.list;
        this.mapCities();

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapCities() {
    this.listFilteredCities = this.model.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCity(value || '')),
    );
  }

  filterCity(value: string): HealthUnitCityModel[] {
    const filterValue = value.toLowerCase();

    return this.listAllCities.filter(city => city.cityName.toLowerCase().includes(filterValue));
  }

  clearListCities() {
    if (this.listAllCities.length > 0){
      this.listAllCities = [];
      this.mapCities();
    }
  }

  private timeoutKeySearch: any = null;

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5 && event.target.value !== '00000000')
        $this.searchByCEP(event.target.value);
    },
      1000);
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        if (response.cep == null){
          this.isLoadingCep = false;
          this.isLoading = false;
          return this.alertService.show('Alerta', `Não foi encontrado nenhum endereço para o CEP ${cep}.`, AlertType.warning);
        }

        this.model.get('city').setValue(response.localidade);
        this.model.get('state').setValue(response.uf);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('street').setValue(response.logradouro);
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isLoadingCep = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}