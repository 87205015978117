<div mat-dialog-content>
    <div class="mat-dialog-content">

        <div class="section-title">
            <h1 [ngStyle]="{'color': colorCode}">Termo de Uso</h1>
        </div>
        <div class="row terms-of-use-content">
            <p>Ao utilizar esta plataforma para o atendimento online, serão tratados (a luz da Lei 13.709/18
                (LGPD))
                dados pessoais sensíveis.</p>

            <p>O uso destes dados tem por finalidade exclusiva o atendimento realizado por profissionais da área
                da
                saúde e está amparado juridicamente pelo artigo 11 - f.</p>

            <p>Ao se cadastrar nesta plataforma, o titular de dados declara estar ciente do tratamento e dá o
                seu
                consentimento expresso para o tratamento de seus dados pessoais, especificados em nossa <span
                    [ngStyle]="{'color': colorCode}"><a href="assets/documents/Política de Privacidade ToLife - Telemedicina.pdf"
                        target="_blank">Política
                        de Privacidade</a></span>.
            </p>
        </div>
        <div class="button-container">
            <button mat-flat-button color="primary" type="button" class="forward-button" (click)="clickAcept()">
                <span>ACEITO</span>
            </button>
            <button mat-flat-button class="btn-secundary-flex" type="button" (click)="clickReject()">
                <span>NÃO ACEITO</span>
            </button>
        </div>
        <div class="footer-white-container">
            <p [ngStyle]="{'color': colorCode}">Telemedicina</p>
        </div>
    </div>
</div>