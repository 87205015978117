import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PatientAppointmentStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-appointment.struct';
import { CancelAppointmentModalComponent } from './cancel-appointment-modal/cancel-appointment-modal.component';

@Component({
  selector: 'app-appointment-details-modal',
  templateUrl: './appointment-details-modal.component.html',
  styleUrls: ['./appointment-details-modal.component.css']
})
export class AppointmentDetailsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;
  public apppointment: PatientAppointmentStruct;
  public standardAttendance: number = NatureOfAttendanceEnum.presencial;
  public onlineAttendance: number = NatureOfAttendanceEnum.online;

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
    this.apppointment = this.data.appointment;
  }

  goBack() {
    this.matDialogRef.close({});
  }

  cancelAppointment() {
    const dialogRef = this.dialog.open(CancelAppointmentModalComponent, {
      data: {
        colorCode: this.colorCode,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.cancel) {
        this.matDialogRef.close({ cancel: true });
      }
    });

  }

  isFutureAppointment(datetimeStart: Date): boolean {
    const currentDate = new Date();
    const appointmentDate = new Date(datetimeStart);
    return appointmentDate > currentDate;
  }
}
