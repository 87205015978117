import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketNotificationService } from '../websocket-notification.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketNotificationUtilService {

    constructor(private websocketNotificationService: WebsocketNotificationService, private router: Router) { }
    socket: Socket;
    idUserPatient: number;
    onClientPatientCenter: Function;
    context: any;

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    blankFunction() {

    }

    setFunctions(onClientPatientCenter: Function) {
        this.onClientPatientCenter = onClientPatientCenter ? onClientPatientCenter : this.blankFunction;
    }

    setContext(thisParam: any) {
        this.context = thisParam;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
    }

    connectwebsocketRoom(idUserPatient: number, thisParam: any): void {
        this.idUserPatient = idUserPatient;
        this.context = thisParam;
        this.socket = this.websocketNotificationService.websocketConnection();
        this.socket.emit('join', `idUserPatient-${idUserPatient}`)
            .on('clientPatientCenter', (res) => {this.onClientPatientCenter(this.context, res) })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', `idUserPatient-${idUserPatient}`);
            });
    }
}