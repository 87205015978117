import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-activate-account-modal',
  templateUrl: './activate-account-modal.component.html',
  styleUrls: ['./activate-account-modal.component.css']
})
export class ActivateAccountModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ActivateAccountModalComponent>,) {
    this.matDialogRef.disableClose = true;
  }

  public colorCode: string;

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
  }


  ok() {
    this.matDialogRef.close({});
  }
}