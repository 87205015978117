import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendPatientVideoComponent } from './pages/attend-patient-video/attend-patient-video.component';
import { TelemedicineComponent } from './pages/telemedicine/telemedicine.component';
import { ForwardEpisodeComponent } from './pages/attend-patient-video/forward-episode/forward-episode.component';
import { WaitingAreaComponent } from '../shared/components/waiting-area/waiting-area.component';
import { TelemedicineIframeComponent } from './pages/telemedicine-iframe/telemedicine-iframe.component';
import { TelemedicinePatientCenterComponent } from './pages/telemedicine-patient-center/telemedicine-patient-center.component';
import { TelemedicineSelfTriageComponent } from './pages/telemedicine-self-triage/telemedicine-self-triage.component';
import { TelemedicineTelephoneClassificationComponent } from './pages/telemedicine-telephone-classification/telemedicine-telephone-classification.component';

const routes: Routes = [
  // { path: 'telemedicine/:uri/:guid', component: TelemedicineComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri', component: TelemedicineComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/iframe/:guid', component: TelemedicineIframeComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/patient-center', component: TelemedicinePatientCenterComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/self-triage/:guid', component: TelemedicineSelfTriageComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/telephone-classification/:guid', component: TelemedicineTelephoneClassificationComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/attend-patient/video', component: AttendPatientVideoComponent, pathMatch: 'full'},
  { path: 'telemedicine/:uri/attend-patient/forward-episode', component: ForwardEpisodeComponent, pathMatch: 'full'},
  { path: 'telemedicine/waiting/area/queue', component: WaitingAreaComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TelemedicineRoutingModule { }
