import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-telemedicine-pagination',
  templateUrl: './telemedicine-pagination.component.html',
  styleUrls: ['./telemedicine-pagination.component.css']
})
export class TelemedicinePaginationComponent implements OnInit {
  
  @Input() flowControl: number;
  
  public isLoading: boolean = false;
  
  ngOnInit(): void {
    
  }

}
