import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdditionalDataComponent } from './pages/self-triage/additional-data/additional-data.component';
import { CounselingComponent } from './pages/self-triage/counseling/counseling.component';
import { EmergencyWarningComponent } from './pages/self-triage/emergency-warning/emergency-warning.component';
import { EndSelfTriage } from './pages/self-triage/end-self-triage/end-self-triage.component';
import { OrientationsComponent } from './pages/self-triage/orientations/orietations.component';
import { PatientIdentificationComponent } from './pages/self-triage/patient-identification/patient-identification.component';
import { ProtocolComponent } from './pages/self-triage/protocol/protocol.component';
import { SearchPatientComponent } from './pages/self-triage/search-patient/search-patient.component';
import { SelfTriageComponent } from './pages/self-triage/self-triage.component';
import { WelcomeComponent } from './pages/self-triage/welcome/welcome.component';

const routes: Routes = [
  { path: 'self-triage/:uri', component: SelfTriageComponent, pathMatch: 'full'},
  { path: 'self-triage/welcome/:uri', component: WelcomeComponent},
  { path: 'self-triage/orientations/:uri', component: OrientationsComponent},
  { path: 'self-triage/patient-identification/:uri', component: PatientIdentificationComponent},
  { path: 'self-triage/emergency-warning/:uri', component: EmergencyWarningComponent},
  { path: 'self-triage/additional-data/:uri', component: AdditionalDataComponent},
  { path: 'self-triage/search-patient/:uri', component: SearchPatientComponent},
  { path: 'self-triage/protocol/:uri', component: ProtocolComponent},
  { path: 'self-triage/counseling/:uri', component: CounselingComponent},
  { path: 'self-triage/end/:uri', component: EndSelfTriage},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfTriageRoutingModule { }
