<div class="container-box">
    <div class="container orientations-session">
        <div class="mat-dialog-container-remake-queue-modal" *ngIf="!isLoading">
            <h1 [ngStyle]="{'color': colorCode}">Episódio evadido!</h1>
            <h2>Para retornar à fila, clique no botão abaixo dentro de </h2>
            <p class="text" id="segundo" [ngStyle]="{'color': colorCode}">{{time}}</p>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 forward-button">
        <button mat-flat-button color="primary" class="-flex" (click)="clickConfirm()">Voltar à fila</button>
        <button mat-flat-button class="btn-secundary-flex" type="button" (click)="clickCancel()">Encerrar</button>
    </div>
</div>