<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">

            <div class="white-container">
                
                <section class="list-healthcare-agreement">
                    <h1 class="patient-center-title">Meus convênios</h1>
                    <hr />
                    <h2 class="patient-center-subtitle">Convênios adicionados</h2>
                
                    <app-loading-list *ngIf="loadingHealthcareAgreement"></app-loading-list>

                    <div class="empty-list" *ngIf="(listPatientHealthcareAgreement.length == 0 || !listPatientHealthcareAgreement) && !loadingHealthcareAgreement">
                        <mat-icon aria-hidden="false" aria-label="warning">warning_amber</mat-icon>
                        <p>Nenhum convênio adicionado</p>
                    </div>

                    <div class="card-list" *ngIf="listPatientHealthcareAgreement != null && listPatientHealthcareAgreement.length > 0 && !loadingHealthcareAgreement">
                        <div class="row">
                            <div class="col-12 col-sm-6" *ngFor="let item of listPatientHealthcareAgreement">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary primary-title">{{item.nameHealthcareAgreement}}</span>
                                    
                                        <span class="secundary" matTooltip="{{item.planName}}">Nome do Plano:
                                            <span class="span-info-value">{{item.planName}}</span>
                                        </span>
                                        <span class="secundary" matTooltip="{{item.healthcareAgreementCardNumber}}">Número da carteirinha
                                            <span class="span-info-value">{{item.healthcareAgreementCardNumber}}</span>
                                        </span>
                                        <span class="secundary" matTooltip="{{item.healthcareAgreementExpirationDate}}">Data de expiração:
                                            <span class="span-info-value">{{item.healthcareAgreementExpirationDate | date: 'dd/MM/yyy' }}</span>
                                        </span>
                                    </div>
                                    <div class="actions">
                                        <div type="button" class="button-delete" (click)="openUpdateModal(item)">
                                            <mat-icon class="edit-icon" aria-hidden="false" aria-label="Editar">edit</mat-icon>
                                        </div>
                                        <div type="button" class="button-delete" (click)="openDeleteModal(item.idPatientHealthcareAgreement)">
                                            <mat-icon aria-hidden="false" aria-label="Editar">delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <form class="form" [formGroup]="model" #formDirective="ngForm" (ngSubmit)="submit(formDirective)">
                    <hr/>
                    <h2 class="patient-center-subtitle">Adicionar convênio</h2>
                    <div class="row">
                        <div class="col-12 add-healthcare-agreement">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreement').errors || !model.get('healthcareAgreement').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreement').errors && model.get('healthcareAgreement').touched}">
                                <mat-label>Qual é o seu convênio?</mat-label>
                                <mat-select formControlName="healthcareAgreement" (selectionChange)="getPlans()">
                                    <mat-option *ngFor="let item of listActiveHealthcareAgreement" [value]="item.idHealthcareAgreement">
                                        {{item.nameHealthcareAgreement}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('healthcareAgreement').invalid">Campo de preenchimento obrigatório</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 add-healthcare-agreement">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementPlan').errors || !model.get('healthcareAgreementPlan').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementPlan').errors && model.get('healthcareAgreementPlan').touched}">
                                <mat-label>Qual é o seu plano?</mat-label>
                                <mat-select formControlName="healthcareAgreementPlan">
                                    <mat-option *ngFor="let item of listHealthcareAgreementPlan" [value]="item.idPlan">
                                        {{item.planName}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('healthcareAgreementPlan').invalid">Campo de preenchimento obrigatório</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementCardNumber').errors || !model.get('healthcareAgreementCardNumber').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementCardNumber').errors && model.get('healthcareAgreementCardNumber').touched}">
                                <mat-label class="input-label">Número da carteirinha</mat-label>
                                <input matInput inputmode="numeric" type="text" formControlName="healthcareAgreementCardNumber" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                    min="0">
                                <mat-error *ngIf="model.get('healthcareAgreementCardNumber').invalid">carteirinha inválida</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('healthcareAgreementExpirationDate').errors || !model.get('healthcareAgreementExpirationDate').touched,'outline-spinner form-field-patient-center-error': model.get('healthcareAgreementExpirationDate').errors && model.get('healthcareAgreementExpirationDate').touched}">
                                <mat-label class="input-label">Data de expiração da carteirinha</mat-label>
                                <input matInput inputmode="numeric" type="text" [mask]="masks.date" formControlName="healthcareAgreementExpirationDate" >
                                <mat-error *ngIf="model.get('healthcareAgreementExpirationDate').invalid">Data de expiração inválida</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-flat-button color="primary" class="button-submit" [disabled]="model.invalid">
                        <span *ngIf="isLoading == false">Adicionar convênio</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </form>
                
                <button *ngIf="!backToScheduling" mat-flat-button type="button" color="secundary" class="button-back" (click)="backPage()">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button *ngIf="backToScheduling" mat-flat-button type="button" color="secundary" class="button-back" (click)="backToSchedulingPage()">
                    <span *ngIf="isLoading == false">Voltar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                
                <div class="footer-white-container">
                    <p>Central do paciente</p>
                </div>
            </div>
        </div>
    </body>
</div>