import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';

@Component({
  selector: 'app-telemedicine-header',
  templateUrl: './telemedicine-header.component.html',
  styleUrls: ['./telemedicine-header.component.css']
})

export class TelemedicineHeaderComponent implements OnInit {
  @Input() telemedicineConfig: TelemedicineConfigStruct;

  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;

  public isLogoUploaded: boolean = false;
  public useTolifeLogo: boolean = false;
  public clientLogo: any;
  public sanitezedLogo: any;
  public logoString64: string;

  ngOnInit(): void {
    this.useTolifeLogo = this.telemedicineConfig.useTolifeLogo;

    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);

    // this.telemedicineConfig = this.utilTelemedicineService.getTelemdicineConfig();

    if (this.telemedicineConfig.isLogoUploaded == true) {
      this.isLogoUploaded = true;
      this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.telemedicineConfig.logoString64}`);
    }

    if (this.telemedicineConfig.useColor == true)
      this.colorCode = this.telemedicineConfig.colorCode;
    else
      this.colorCode = "#d4d4d4";
  }
}