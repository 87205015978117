import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-login-verify-modal',
  templateUrl: './login-verify-modal.component.html',
  styleUrls: ['./login-verify-modal.component.css']
})
export class LoginVerifyModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LoginVerifyModalComponent>,
    private alertService: AlertService) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = true;
  }

  close() {
    this.matDialogRef.close();
  }

}