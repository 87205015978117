import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VerifyCNS } from 'src/app/shared/custom-validators/cns.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { ExternalSearchFieldEnum } from 'src/app/shared/enum/self-triage/external-search-field.enum';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { ClientTelemedicineService } from 'src/app/shared/services/API/telemedicine/client-telemedicine.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { TelemedicineExternalSearchRequest } from 'src/app/shared/services/requests/telemedicine/external-search.request';
import { ExternalSearchResponse } from 'src/app/shared/services/responses/telemedicine/external-search.response';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/episode.struct';
import { QueueUnStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/queue.struct';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { LgpdModalComponent } from '../patient-identification/lgpd-modal/lgpd-modal.component';

@Component({
  selector: 'app-external-search',
  templateUrl: './external-search.component.html',
  styleUrls: ['./external-search.component.css']
})
export class ExternalSearchComponent implements OnInit {
  @Input() telemedicineConfig: TelemedicineConfigStruct;
  @Input() colorCode: string = "";
  @Input() externalPatient: PatientData;
  @Input() queue: QueueUnStruct;
  @Output() savedPatientData = new EventEmitter<PatientStruct>();
  @Output() next = new EventEmitter<any>();
  @Output() goToWaitingArea = new EventEmitter<number>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private clientTelemedicineService: ClientTelemedicineService,
  ) { }

  public model: UntypedFormGroup;
  public masks: Masks;
  public searchFieldMask: string;
  public flowEnum = TelemedicineFlowEnum.patientIdentification;
  public episode: EpisodeStruct;
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public checkedPrivacy: boolean = false;

  ngOnInit(): void {
    if (this.telemedicineConfig.useColor == true)
      document.documentElement.style.setProperty('--colorCodeTelemedicine', this.colorCode);

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      searchField: ['', [Validators.required]],
    });
    this.generateForm(this.telemedicineConfig.idExternalSearchField);

    this.isInitialized = true;
  }

  generateForm(externalSearchField: number): void {
    switch (externalSearchField) {
      case ExternalSearchFieldEnum.cpf:
        this.model.get('searchField').addValidators(VerifyCPF());
        this.searchFieldMask = this.masks.cpf;
        break;
      case ExternalSearchFieldEnum.cns:
        this.model.get('searchField').addValidators(VerifyCNS());
        this.searchFieldMask = this.masks.cns;
        break;
    }
  }

  mapToPatientData(patient: PatientStruct): PatientData {
    let PatientData: PatientData = {
      birthDate: patient.birthDate,
      city: patient.city,
      cns: patient.cns,
      cpf: patient.cpf,
      email: patient.email,
      idPatientTelemedicineIntegration: patient.idPatientTelemedicineIntegration,
      idGender: patient.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patient.idPatient,
      neighborhood: patient.neighborhood,
      patientName: patient.patientName,
      phone: patient.phone1 ? patient.phone1 : patient.phone2,
      state: patient.state,
      zipCode: patient.zipCode,
      datetimeinclusion: null,
      healthPlan: null,
      healthPlanExpirationDate: null,
      healthPlanNumber: null,
      idQueue: this.queue ? this.queue.idQueue : null,
      patientCenterAccessTelemedicine: false,
      street: patient.street,
      houseNumber: patient.houseNumber,
    };

    return PatientData;
  }

  submit() {
    if (this.model.invalid || this.isLoading || !this.checkedPrivacy)
      return;
    let request: TelemedicineExternalSearchRequest = new TelemedicineExternalSearchRequest();
    request.idHealthUnit = this.telemedicineConfig.idHealthUnit;
    request.searchKey = this.model.get('searchField').value;

    this.isLoading = true;
    this.clientTelemedicineService.externalSearch(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let patient: PatientStruct = this.MapPatientProperties(response);
        this.utilsTelemedicineService.updatePatientData(this.mapToPatientData(patient));
        this.savedPatientData.emit(patient);
        this.next.emit();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  public MapPatientProperties(response: ExternalSearchResponse): PatientStruct  {
    let patient: PatientStruct = new PatientStruct ();
    patient.cpf = response.cpf;
    patient.patientName = response.name;
    patient.birthDate = response.birthDate;
    patient.email = response.email;
    patient.phone1 = response.phone;
    patient.idGender = response.idGender;
    patient.genderName = response.genderName;
    patient.city = response.city;
    patient.neighborhood = response.neighborhood;

    return patient;
  }

  openLGPDModal(checkbox: any) {
    const dialogRef = this.dialog.open(LgpdModalComponent, {
      data: {},
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result.accept) {
          this.checkedPrivacy = true;
          checkbox.isChecked = true;
        }
        else {
          this.checkedPrivacy = false;
          checkbox.isChecked = false;
          window.location.reload();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}
