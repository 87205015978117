import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListPatientAppointmentResponse } from "../../responses/schedule/list-patient-appointment.response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { AppointmentDataResponse } from "../../responses/orchestrator-schedule/appointment-data.response";

@Injectable({
    providedIn: 'root'
})
export class CreateAppointmentCallService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public GetTokenTwilioVideo(idPatientAppointment: number): Observable<AppointmentDataResponse> {

        let uri = `CreateAppointmentCall/idPatientAppointment/${idPatientAppointment}`;

        return this.httpClient.get<AppointmentDataResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}