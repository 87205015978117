<div>
    <app-header></app-header>
    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        
        <div class="body-content">

            <div class="white-container">
                <h1 class="self-triage-title">Validação</h1>
                <hr>
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <mat-form-field appearance="outline"  [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('externalSearchField').errors || !model.get('externalSearchField').touched,'outline-spinner form-field-self-triage-error': model.get('externalSearchField').errors && model.get('externalSearchField').touched}">
                        <mat-label class="input-label">Digite o número do {{this.externalSearchFieldDescription}}</mat-label>
                        <input matInput type="text" [mask]="fieldMask" formControlName="externalSearchField">                
                        <mat-error class="alert-search-patient-modal" *ngIf="model.get('externalSearchField').invalid">Informe o {{this.externalSearchFieldDescription}}</mat-error>
                    </mat-form-field>                
                </form>  
                <button mat-flat-button color="primary" class="button-go" [disabled]="model.get('externalSearchField').invalid" (click)="submit()">
                    <span *ngIf="isLoading == false">Próximo</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>            
                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>          
            </div>         
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>
            
        </div>
    </body>
</div>