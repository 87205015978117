import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function VerifyDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isValid: boolean = true;

    if (!value) {
      return null;
    }

    var objDate,  // date object initialized from the ExpiryDate string 
        mSeconds, // ExpiryDate in milliseconds 
        day,      // day 
        month,    // month 
        year;     // year 

    // date length should be 10 characters (no more no less) 
    if (value.length !== 8) { 
      isValid = false; 
    } 
    // third and sixth character should be '/' 
    // if (value.substring(2, 3) !== '/' || value.substring(5, 6) !== '/') { 
    //   isValid = false; 
    // } 
    // extract month, day and year from the value (expected format is mm/dd/yyyy) 
    // subtraction will cast variables to integer implicitly (needed 
    // for !== comparing) 
    day = value.substring(0, 2) - 0; 
    month = value.substring(2, 4) - 1; // because months in JS start from 0 
    year = value.substring(4, 8) - 0; 
    // test year range 
    if (year < 1000 || year > 3000) { 
      isValid = false; 
    } 
    // convert value to milliseconds 
    mSeconds = (new Date(year, month, day)).getTime(); 
    // initialize Date() object from calculated milliseconds 
    objDate = new Date(); 
    objDate.setTime(mSeconds); 
    
    //compare input date with today
    let today = new Date()
    today.setHours(0,0,0,0);

    if(objDate.getTime() > today.getTime()){
     isValid = false; 
   }
    // compare input date and parts from Date() object 
    // if difference exists then date isn't valid   

    if (objDate.getFullYear() !== year || 
        objDate.getMonth() !== month || 
        objDate.getDate() !== day) { 
        isValid = false; 
    } else {
      control.setErrors(null);
    }
    console.log()

    return !isValid ? {invalidDate: true} : null;
  };
}

export function VerifyMinDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isValid: boolean = true;

    if (!value) {
      return null;
    }

    var objDate,  // date object initialized from the ExpiryDate string 
        mSeconds, // ExpiryDate in milliseconds 
        day,      // day 
        month,    // month 
        year;     // year 

    // date length should be 10 characters (no more no less) 
    if (value.length !== 8) { 
      isValid = false; 
    } 
    // third and sixth character should be '/' 
    // if (value.substring(2, 3) !== '/' || value.substring(5, 6) !== '/') { 
    //   isValid = false; 
    // } 
    // extract month, day and year from the value (expected format is mm/dd/yyyy) 
    // subtraction will cast variables to integer implicitly (needed 
    // for !== comparing) 
    day = value.substring(0, 2) - 0; 
    month = value.substring(2, 4) - 1; // because months in JS start from 0 
    year = value.substring(4, 8) - 0; 
    // test year range 
    if (year < 1000 || year > 3000) { 
      isValid = false; 
    } 
    // convert value to milliseconds 
    mSeconds = (new Date(year, month, day)).getTime(); 
    // initialize Date() object from calculated milliseconds 
    objDate = new Date(); 
    objDate.setTime(mSeconds); 
    
    //compare input date with today
    let today = new Date()
    today.setHours(0,0,0,0);

    if(objDate.getTime() < today.getTime()){
      isValid = false; 
    }
    // compare input date and parts from Date() object 
    // if difference exists then date isn't valid   

    if (objDate.getFullYear() !== year || 
        objDate.getMonth() !== month || 
        objDate.getDate() !== day) { 
        isValid = false; 
    } else {
      control.setErrors(null);
    }
    console.log()

    return !isValid ? {invalidDate: true} : null;
  };
}