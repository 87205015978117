<form class="form" [formGroup]="model" (ngSubmit)="submit()">
    <h2>{{currentQuestion.statement}}</h2>
    <div *ngFor="let item of this.currentQuestion.answerStructs">
        <div class="row">
            <div class="col-12">
                <div class="col-12 input-radio-list">
                    <input value="{{item.idAnswer}}" type="radio" id="radio-{{item.idAnswer}}" formControlName="selectedChoice" (click)="selection(item)">
                    <label for="radio-{{item.idAnswer}}">{{item.answer}}</label>
                </div> 
            </div>
        </div>
    </div>
    <div class="row step-buttons">
        <div class="col-6">
            <button mat-flat-button color="secundary" class="button-back" type="button" (click)="btnBack()" >
                <span *ngIf="isLoading == false">Retornar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button> 
        </div>
        <div class="col-6">
            <button mat-flat-button color="primary" type="submit" class="button-go" [disabled]="!this.model.valid">
                <span *ngIf="isLoading == false">Próximo</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button> 
        </div>
    </div>
</form>