import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';

@Component({
  selector: 'app-welcome-telemedicine',
  templateUrl: './welcome-telemedicine.component.html',
  styleUrls: ['./welcome-telemedicine.component.css']
})
export class WelcomeTelemedicineComponent implements OnInit {

  @Input() colorCode: string;
  @Input() preview: boolean = false;
  @Output() next = new EventEmitter<any>();

  public flowEnum = TelemedicineFlowEnum.welcome;
  ngOnInit(): void {

  }

  forward() {

    if(this.preview)
      return

    this.next.emit();
  }
}
