import { Injectable } from "@angular/core";
import { SelfTriageConfigStruct } from "../shared/services/structs/self-triage/self-triage-config.struct";
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { GetConfigResponse } from "../shared/services/responses/self-triage/get-config.response";
import { ExternalSearchField } from "../shared/services/models/self-triage/external-search-field.model";
import { GetProtocolSessionResponse } from "../shared/services/responses/self-triage/get-protocol-session.response";

@Injectable({
    providedIn: 'root'
})
export class UtilsSelfTriageService {
    constructor() { }

    updateUri(uri: string) {
        sessionStorage.setItem("uri", uri);
    }

    removeUri() {
        sessionStorage.removeItem("uri");
    }

    getUri() {
        let uri: string = sessionStorage.getItem("uri");
        return uri;
    }

    updateFullUrl(uri: string) {
        sessionStorage.setItem("fullUrl", uri);
    }

    removeFullUrl() {
        sessionStorage.removeItem("fullUrl");
    }

    getFullUrl() {
        let uri: string = sessionStorage.getItem("fullUrl");
        return uri;
    }

    setSelfTriageConfigResponse(data: SelfTriageConfigStruct): void {
        sessionStorage.setItem("configStruct", JSON.stringify(data));
    }

    getSelfTriageConfigStruct(): SelfTriageConfigStruct {
        const data: string = sessionStorage.getItem("configStruct");
        return data ? JSON.parse(data) : null
    }

    setSelfTriageConfig(data: SelfTriageConfigStruct): void {
        sessionStorage.setItem("selfTriageConfig", JSON.stringify(data));
    }

    getSelfTriageConfig(): SelfTriageConfigStruct {
        const data: string = sessionStorage.getItem("selfTriageConfig");
        return data ? JSON.parse(data) : null
    }

    removeSelfTriageConfig(): void {
        sessionStorage.removeItem("selfTriageConfig");
    }

    setSelfTriageListExternalSearchField(data: ExternalSearchField[]): void {
        sessionStorage.setItem("externalSearchField", JSON.stringify(data));
    }

    getSelfTriageListExternalSearchField(): ExternalSearchField[] {
        const data: string = sessionStorage.getItem("externalSearchField");
        return data ? JSON.parse(data) : null
    }

    removeSelfTriageListExternalSearchField(): void {
        sessionStorage.removeItem("externalSearchField");
    }

    updatePatientData(patientData: Patient) {
        sessionStorage.setItem("patientData", JSON.stringify(patientData));
    }

    removePatientData() {
        sessionStorage.removeItem("patientData");
    }

    getPatientData() {
        let patientData: Patient = JSON.parse(sessionStorage.getItem("patientData"))
        return patientData;
    }

    setProtocolResponse(data: GetProtocolSessionResponse): void {
        sessionStorage.setItem("protocolResponse", JSON.stringify(data));
    }

    getProtocolResponse() {
        let data: GetProtocolSessionResponse = JSON.parse(sessionStorage.getItem("protocolResponse"))
        return data;
    }

    setForwardMedic(data: boolean): void {
        sessionStorage.setItem("forwardMedic", JSON.stringify(data));
    }

    getForwardMedic(): boolean {
        const data: string = sessionStorage.getItem("forwardMedic");
        return data ? JSON.parse(data) : false
    }

    setOldFlowControl(data: number): void {
        sessionStorage.setItem("oldFlowControl", JSON.stringify(data));
    }

    getOldFlowControl(): number | null {
        const storedData = sessionStorage.getItem("oldFlowControl");

        if (storedData !== null)
            return JSON.parse(storedData);

        return null;
    }

    removeOldFlowControl() {
        sessionStorage.removeItem("oldFlowControl");
    }

    setSelfTriageAccessSelfTriage(data: boolean): void {
        sessionStorage.setItem("patientCenterAccessSelfTriage", JSON.stringify(data));
    }

    getSelfTriageAccessSelfTriage(): boolean {
        const data: string = sessionStorage.getItem("patientCenterAccessSelfTriage");
        return data ? JSON.parse(data) : false;
      }

    setCelerusAccessSelfTriage(data: boolean): void {
        sessionStorage.setItem("celerusAccessSelfTriage", JSON.stringify(data));
    }

    getCelerusAccessSelfTriage(): boolean {
        const data: string = sessionStorage.getItem("celerusAccessSelfTriage");
        return data ? JSON.parse(data) : false
    }
}