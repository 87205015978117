import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { EligibilityCheckPatientScheduleRequest } from "../../requests/orchestrator-schedule/eligibility-check-patient-schedule.request";
import { EligibilityCheckPatientScheduleResponse } from "../../responses/orchestrator-schedule/eligibility-check-patient-schedule.response";

@Injectable({
    providedIn: 'root'
})
export class EligibilityCheckPatientScheduleService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public EligibilityCheckPatient(request: EligibilityCheckPatientScheduleRequest, idUserPatient: number): Observable<EligibilityCheckPatientScheduleResponse> {
        let uri = `EligibilityCheckPatientSchedule/idUserPatient/${idUserPatient}`;

        return this.httpClient.post<EligibilityCheckPatientScheduleResponse>(environment.urlApiOrchestratorSchedule + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}