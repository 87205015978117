import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { ClientTelemedicineService } from 'src/app/shared/services/API/telemedicine/client-telemedicine.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { EpisodeData, PatientData, UtilsTelemedicineService } from '../../utils.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/episode.struct';
import { QueueUnStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/queue.struct';
import { HealthIdentificationEnum } from 'src/app/shared/enum/health-identification.enum'
import { PatientDataRequest } from 'src/app/shared/services/requests/orchestrator-telemedicine/patient-data.request';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-telemedicine/episode.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { EpisodePreEvaluation } from 'src/app/shared/services/models/telemedicine/episode-pre-evaluation.model';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { TelemedicineLookupService } from 'src/app/shared/services/API/telemedicine/lookup.service';
import { SymptomDuration } from 'src/app/shared/services/models/telemedicine/symptom-duration.model';
import { EpisodePreEvaluationRequest } from 'src/app/shared/services/requests/telemedicine/episode-pre-evaluation.request';
import { EpisodePreEvaluationService } from 'src/app/shared/services/API/telemedicine/episode-pre-evaluation.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-telemedicine-patient-center',
  templateUrl: './telemedicine-patient-center.component.html',
  styleUrls: ['./telemedicine-patient-center.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class TelemedicinePatientCenterComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private telemedicineLookupService: TelemedicineLookupService,
    private episodePreEvaluationService: EpisodePreEvaluationService,
    private formBuilder: FormBuilder,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private clientConfigService: ClientTelemedicineService,
    private episodeOrchestratorTelemedicine: EpisodeService,
    public patientAccessLeaving: PatientAccessLeavingService,
  ) { }

  public healthIdentificationEnum: HealthIdentificationEnum;
  public patientDataRequest: PatientDataRequest = new PatientDataRequest();
  public colorCode: string = "";
  public logoString64: string;
  public uri: string;
  public checkedPatient: boolean = false;
  public patientName: string;
  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public telemedicineConfig: TelemedicineConfigStruct;
  public isLogoUploaded: boolean = false;
  public isLoading: boolean;
  public idEpisode: number;
  public idStatusQueue: number;
  public clientLogo: any;
  public patient: PatientStruct;
  public queue: QueueUnStruct;
  public episode: EpisodeStruct;
  public episodeData: EpisodeData;
  public patientData: PatientData = new PatientData();
  public isLoadingCpf: boolean;
  public actualTicket: string = "-";
  public episodePreEvaluation: EpisodePreEvaluation = new EpisodePreEvaluation();
  public isMobileVersion: boolean;
  public symptomDurationList: SymptomDuration[];
  public preview: boolean = false;
  public celerusAccessTelemedicine: boolean = false;
  public patientCenterAccessTelemedicine: boolean = false;
  public identificationModel: FormGroup;
  public additionalDataModel: FormGroup;
  public orientationsModel: FormGroup;

  //Variáveis de fluxo
  public flowControl: number = TelemedicineFlowEnum.welcome;
  public welcome: number = TelemedicineFlowEnum.welcome;
  public patientIdentification: number = TelemedicineFlowEnum.patientIdentification;
  public emergencyCheck: number = TelemedicineFlowEnum.emergencyCheck;
  public preEvaluation: number = TelemedicineFlowEnum.preEvaluation;
  public additionalData: number = TelemedicineFlowEnum.additionalData;
  public orientations: number = TelemedicineFlowEnum.orientations;
  public waitingArea: number = TelemedicineFlowEnum.waitingArea;
  public guid: string;
  public symptomDescriptionByIntegration: string = ``;

  ngOnInit(): void {
    this.isMobile();
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.patientCenterAccessTelemedicine = this.patientData ? this.patientData.patientCenterAccessTelemedicine : false;
    this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
    this.utilsTelemedicineService.setSelfTriageAcessTelemedicine(false);
    this.utilsTelemedicineService.setTelephoneClassificationTelemedicine(false);

    this.createFormGroupPopulate();

    if(this.episodeData) {
      this.flowControl = TelemedicineFlowEnum.waitingArea;
    }

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.utilsTelemedicineService.updateUri(this.uri);
      this.configClient();
      this.telemedcineLookup();
    }
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet)
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }

  telemedcineLookup() {
    this.telemedicineLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.symptomDurationList = response.listSymptomDuration;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  configClient() {
    this.clientConfigService.getClientConfig(this.uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('preview')) {
          this.preview = (this.activatedRoute.snapshot.queryParamMap.get('preview') == 'true') ? true : false;
          this.utilsTelemedicineService.setPreview(this.preview);
        }


        this.telemedicineConfig = response.telemedicineConfig;
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);
        this.telemedicineConfig = response.telemedicineConfig;
        this.mapperTelemedicineConfig();
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);
        
      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToPatientDataRequest(patientData: PatientData): PatientDataRequest {
    let patientDataRequest: PatientDataRequest = {
      birthDate: patientData.birthDate,
      city: patientData.city,
      cns: patientData.cns,
      cpf: patientData.cpf,
      email: patientData.email,
      idGender: patientData.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patientData.idPatient,
      neighborhood: patientData.neighborhood,
      patientName: patientData.patientName,
      phone: patientData.phone ? patientData.phone : null,
      zipCode: patientData.zipCode,
      state: patientData.state,
      datetimeinclusion: null,
      healthPlan: patientData.healthPlan,
      healthPlanExpirationDate: patientData.healthPlanExpirationDate,
      healthPlanNumber: patientData.healthPlanNumber,
      idPatientTelemedicineIntegration: patientData.idPatientTelemedicineIntegration,
      street: '',
      houseNumber: 0
    };

    return patientDataRequest;
  }

  mapToEpisodeData(): EpisodeData {
    let episodeData: EpisodeData = {
      idEpisode: this.episode.idEpisode,
      idQueue: this.episode.idEpisode,
      actualTicket: this.episode.fullTicket,
      lastTicket: this.episode.lastTicket,
      waitingTime: this.episode.waitingTime,
    };

    return episodeData;
  }

  savePatient() {
    this.isLoading = true;
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.patientDataRequest = this.mapToPatientDataRequest(this.patientData);

    this.episodeOrchestratorTelemedicine.createEpisode(this.patientDataRequest, this.patientData.idHealthUnit, NatureOfAttendanceEnum.online).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.idEpisode = response.idEpisode;

        let preEvaluationRequest: EpisodePreEvaluationRequest = new EpisodePreEvaluationRequest();
        preEvaluationRequest.idEpisode = this.idEpisode;
        preEvaluationRequest.idSymptomDuration = this.episodePreEvaluation.idSymptomDuration;
        preEvaluationRequest.symptomDescription = this.episodePreEvaluation.symptomDescription;

        this.savePreEvaluation(preEvaluationRequest);

        this.actualTicket = `${response.ticketInitials} ${response.ticketSequence}`;

        let episode = new EpisodeData();
        episode.idEpisode = response.idEpisode;
        episode.idQueue = response.idQueue;
        episode.actualTicket = response.ticketInitials + response.ticketSequence;

        this.utilsTelemedicineService.createEpisodeData(episode);
        this.patientData.idQueue = response.idQueue;
        if (!this.patientData.idPatient)
          this.patientData.idPatient = this.patient.idPatient;

        this.utilsTelemedicineService.updatePatientData(this.patientData);

        if (this.flowControl == this.orientations)
          this.flowControl += 1;

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  savePreEvaluation(request: EpisodePreEvaluationRequest) {
    this.episodePreEvaluationService.saveEpisodePreEvaluation(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  next() {
    if (this.patientCenterAccessTelemedicine && this.flowControl === this.welcome)
      this.flowControl = this.emergencyCheck;
    else if (this.patientCenterAccessTelemedicine && this.flowControl === this.preEvaluation)
      this.flowControl = this.orientations;
    else if ((this.flowControl != this.orientations && this.flowControl != this.additionalData))
      this.flowControl += 1;
    else
      this.savePatient();
  }

  mapperTelemedicineConfig() {
    if (this.telemedicineConfig != null && this.telemedicineConfig.isActive) {
      this.checkedUri = true;
      if (this.preview && this.telemedicineConfig.colorCodePreview) {
        this.telemedicineConfig.useColor = true;
        this.telemedicineConfig.colorCode = this.telemedicineConfig.colorCodePreview;
        this.colorCode = this.telemedicineConfig.colorCodePreview;
      }
      else if (this.telemedicineConfig.useColor == true) {
        this.colorCode = this.telemedicineConfig.colorCode;
      }
      if (this.preview && this.telemedicineConfig.logoNamePreview) {
        this.telemedicineConfig.isLogoUploaded == true;
        this.telemedicineConfig.logoString64 = this.telemedicineConfig.logoString64Preview;
      }
      else if (this.telemedicineConfig.isLogoUploaded == true) {
        this.isLogoUploaded = true;
        this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.telemedicineConfig.logoString64}`);
      }
      this.telemedicineConfig.logoString64Preview = null;
      this.telemedicineConfig.colorCodePreview = null;
    }
    this.checkedLoading = true;
  }

  updatePatient(patient: PatientStruct) {
    this.patient = patient;
  }

  updatePreEvaluation(preEvaluation: EpisodePreEvaluation) {
    this.episodePreEvaluation = preEvaluation;
  }

  createFormGroupPopulate() {
    this.identificationModel = this.formBuilder.group({
      cpf: [this.patientData.cpf, [
        Validators.required,
        VerifyCPF()]],
      privacyPolicy: [true, [Validators.requiredTrue]],
    });

    this.additionalDataModel = this.formBuilder.group({
      completeName: [this.patientData.patientName, [Validators.required]],
      cpf: [this.patientData.cpf, [Validators.required, VerifyCPF()]],
      phone: [this.patientData.phone, [Validators.required]],
      email: [this.patientData.email, [Validators.required, Validators.email]],
      birthDate: [this.patientData.birthDate, [Validators.required, this.validateBirthDate]],
      gender: [this.patientData.idGender, [Validators.required]],
      zipCode: [this.patientData.zipCode],
      state: [this.patientData.state],
      city: [this.patientData.city],
      neighborhood: [this.patientData.neighborhood],
      street: [this.patientData.street],
      houseNumber: [this.patientData.houseNumber ? this.patientData.houseNumber.toString() : ''],
      cns: [this.patientData.cns],
      healthPlan: [this.patientData.healthPlan],
      healthPlanNumber: [this.patientData.healthPlanNumber],
      healthPlanExpirationDate: [this.patientData.healthPlanExpirationDate]
    });

    this.orientationsModel = this.formBuilder.group({
      place: [true, [Validators.requiredTrue]],
      drive: [true, [Validators.requiredTrue]],
      dressed: [true, [Validators.requiredTrue]],
    });
  }

  validateBirthDate(control: FormControl) {
    if (control.value != null) {
      const value = control.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
      if (!value)
        return null;

      // Verifica se a data é válida
      const date = new Date(value.split('/').reverse().join('/'));
      if (!isNaN(date.getTime())) {
        // Verifica se a data é menor ou igual à data atual
        if (date <= new Date() && date >= new Date("01/01/1500"))
          return null;
      }
    }

    return { invalidDate: true };
  }

  reload() {
    this.flowControl = this.welcome;
  }
}