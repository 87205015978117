<app-no-episode *ngIf="GetEpisodeErro"></app-no-episode>

<app-alert-header *ngIf="!GetEpisodeErro" [healthUnitName]="healthUnitName" [idEpisode]="idEpisode"></app-alert-header>
<div *ngIf="!GetEpisodeErro && isVerify" class="client-body">
    <div class="white-body">
        <div class="row">
            <div class="col-12">
                <p><b>Nome: </b>{{this.patientName}}</p>
            </div>
            <div class="col-12">
                <p><b>Senha: </b>{{this.ticketInitials}}{{this.ticketSequence}}</p>
            </div>
            <div class="col-12">
                <p><b>Localização: </b>{{this.curentLocationName}}</p>
            </div>
            <div *ngIf="idStatusQueue == idStatusAguardando" class="col-12">
                <p><b>Status: Aguardando/Em espera</b></p>
            </div>
            <div *ngIf="idStatusQueue == idStatusEvadido" class="col-12">
                <p><b>Status: Evadido</b></p>
            </div>
            <div *ngIf="idStatusQueue == idStatusAtendido" class="col-12">
                <p><b>Status: Atendido</b></p>
            </div>
            <div *ngIf="idStatusQueue == idStatusChamando" class="col-12">
                <p><b>Status: Chamando</b></p>
            </div>
            <div *ngIf="idStatusQueue == idStatusEmAtendimento" class="col-12">
                <p><b>Status: Em atendimento</b></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Histórico</h2>
            </div>
        </div>
        <div *ngFor="let item of patientHistory" class="row list-history">
            <div class="col-12 col-sm-12 col-md-6">
                <p><b>Local de atendimento:</b> {{item.locationName}}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <p><b>Data e hora da chegada no local:</b>{{item.dateTimeQueueArrival | date:'dd/MM/yyyy HH:mm'}}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <p><b>Começo do atendimento:</b>{{item.dateTimeQueueAttendance | date:'dd/MM/yyyy HH:mm'}}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <p><b>Atendido por:</b>{{item.userAttendanceName}}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <p *ngIf="item.idStatusQueue == idStatusAguardando"><b>Status do atendimento:</b> Aguardando/Em espera</p>

                <p *ngIf="item.idStatusQueue == idStatusEvadido"><b>Status do atendimento:</b> Evadido</p>

                <p *ngIf="item.idStatusQueue == idStatusAtendido"><b>Status do atendimento:</b> Atendido</p>

                <p *ngIf="item.idStatusQueue == idStatusChamando"><b>Status do atendimento:</b> Chamando</p>

                <p *ngIf="item.idStatusQueue == idStatusEmAtendimento"><b>Status do atendimento:</b> Em atendimento</p>
            </div>
        </div>
    </div>
</div>