import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { UserPatientRequest } from '../../requests/patient-center/user-patient.request';
import { UserPatientResponse } from '../../responses/patient-center/user-patient.response';

@Injectable({
  providedIn: 'root'
})

export class UserPatientCenterService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }
  public getUserPatientById(idUserPatient: number, idHealthUnit: number): Observable<UserPatientResponse> {
    let uri = `UserPatientCenter`;
    uri = uri + `/idUserPatient/${idUserPatient}/idHealthUnit/${idHealthUnit}`;

    return this.httpClient.get<UserPatientResponse>(environment.urlApiPatientCenter + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateBasicInfo(idPatientUser: number, request: UserPatientRequest): Observable<ReturnStruct> {
    let uri = `UserPatientCenter`;
    uri = uri + `/idPatientUser/${idPatientUser}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiPatientCenter + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}