<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                <h1> Informe seus dados adicionais</h1>
            </div>
            <form class="form additional-data-form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row form-group">
                    <div class="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('completeName').errors || !model.get('completeName').touched,'outline-spinner form-field-telemedicine-error': model.get('completeName').errors && model.get('completeName').touched}">
                            <mat-label>Nome completo</mat-label>
                            <input matInput type="text" formControlName="completeName">
                            <mat-error *ngIf="model.controls['completeName'].errors">Informe o nome completo</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-6 col-xl-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('cpf').errors || !model.get('cpf').touched,'outline-spinner form-field-telemedicine-error': model.get('cpf').errors && model.get('cpf').touched}">
                            <mat-label>CPF: </mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                            <mat-error *ngIf="model.controls['cpf'].errors">Informe o CPF</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('phone').errors || !model.get('phone').touched,'outline-spinner form-field-telemedicine-error': model.get('phone').errors && model.get('phone').touched}">
                            <mat-label>Telefone: </mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            <mat-error *ngIf="model.controls['phone'].errors">Informe o Telefone</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('email').errors || !model.get('email').touched,'outline-spinner form-field-telemedicine-error': model.get('email').errors && model.get('email').touched}">
                            <mat-label>E-mail: </mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error *ngIf="model.controls['email'].errors">Informe o e-mail</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('birthDate').errors || !model.get('birthDate').touched,'outline-spinner form-field-telemedicine-error': model.get('birthDate').errors && model.get('birthDate').touched}">
                            <mat-label>Data de nascimento: </mat-label>
                            <input matInput formControlName="birthDate" [mask]="masks.date">
                            <mat-error *ngIf="model.controls['birthDate'].errors">Informe a data de nascimento</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="separator col-12 col-sm-12 col-md-12 col-xl-12">
                        <mat-radio-group class="additional-data-radio-button input-telemedicine"
                                formControlName="gender">
                                <mat-label class="input-title">Gênero:</mat-label>
                                <mat-radio-button *ngFor="let item of listGender"
                                    [value]="item.idGender">{{item.genderName}}</mat-radio-button>
                            </mat-radio-group>
                    </div>
                    <div class="col-12 col-sm-4">
                        <mat-form-field appearance="outline" class="outline-spinner"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('zipCode').errors || !model.get('zipCode').touched,'outline-spinner form-field-telemedicine-error': model.get('zipCode').errors && model.get('zipCode').touched}">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)"
                                [mask]="masks.cep">
                            <app-loading-list *ngIf="isLoadingCep"></app-loading-list>
                            <mat-error *ngIf="model.get('zipCode').invalid">CEP inválido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-8">
                        <mat-form-field appearance="outline" [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('street').errors || !model.get('street').touched,'outline-spinner form-field-telemedicine-error': model.get('street').errors && model.get('street').touched}">
                            <mat-label class="input-label">Logradouro</mat-label>
                            <input matInput inputmode="text" type="text" formControlName="street"
                                placeholder="Digite seu logradouro">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4">
                        <mat-form-field appearance="outline" [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('houseNumber').errors || !model.get('houseNumber').touched,'outline-spinner form-field-telemedicine-error': model.get('houseNumber').errors && model.get('houseNumber').touched}">
                            <mat-label class="input-label">Número</mat-label>
                            <input matInput inputmode="text" type="text" formControlName="houseNumber"
                                placeholder="Digite o número do seu endereço" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-8">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('neighborhood').errors || !model.get('neighborhood').touched,'outline-spinner form-field-telemedicine-error': model.get('neighborhood').errors && model.get('neighborhood').touched}">
                            <mat-label>Bairro: </mat-label>
                            <input matInput type="text" formControlName="neighborhood">
                            <mat-error *ngIf="model.controls['neighborhood'].errors">Informe o bairro</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('city').errors || !model.get('city').touched,'outline-spinner form-field-telemedicine-error': model.get('city').errors && model.get('city').touched}">
                            <mat-label>Cidade</mat-label>
                            <input matInput type="text" formControlName="city" [matAutocomplete]="autoCity">
                            <mat-autocomplete #autoCity="matAutocomplete" >
                                <mat-option *ngFor="let item of (listFilteredCities | async)" value="{{item.cityName}}">{{item.cityName}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('state').errors || !model.get('state').touched,'outline-spinner form-field-telemedicine-error': model.get('state').errors && model.get('state').touched}">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state" [matAutocomplete]="autoState" (keyup)="clearListCities()">
                            <mat-autocomplete #autoState="matAutocomplete" >
                                <mat-option *ngFor="let item of (listFilteredStates | async)" value="{{item.stateName}}" (click)="getListCitiesByUf(item.idState)">{{item.stateName}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="this.idHealthIdentification == this.idCNS">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('cns').errors || !model.get('cns').touched,'outline-spinner form-field-telemedicine-error': model.get('cns').errors && model.get('cns').touched}">
                            <mat-label>CNS: </mat-label>
                            <input matInput type="text" formControlName="cns">
                            <mat-error *ngIf="model.controls['cns'].errors">Informe o CNS</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6"
                        *ngIf="this.idHealthIdentification == this.idHealthPlan">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('healthPlan').errors || !model.get('healthPlan').touched,'outline-spinner form-field-telemedicine-error': model.get('healthPlan').errors && model.get('healthPlan').touched}">
                            <mat-label>Plano de Saúde: </mat-label>
                            <input matInput type="text" formControlName="healthPlan">
                            <mat-error *ngIf="model.controls['healthPlan'].errors">Informe o Plano de Saúde</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6"
                        *ngIf="this.idHealthIdentification == this.idHealthPlan">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('healthPlanNumber').errors || !model.get('healthPlanNumber').touched,'outline-spinner form-field-telemedicine-error': model.get('healthPlanNumber').errors && model.get('healthPlanNumber').touched}">
                            <mat-label>Número da carteirinha: </mat-label>
                            <input matInput type="text" formControlName="healthPlanNumber">
                            <mat-error *ngIf="model.controls['healthPlanNumber'].errors">Informe o Número da
                                carteirinha</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6"
                        *ngIf="this.idHealthIdentification == this.idHealthPlan">
                        <mat-form-field appearance="outline"
                            [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('healthPlanExpirationDate').errors || !model.get('healthPlanExpirationDate').touched,'outline-spinner form-field-telemedicine-error': model.get('healthPlanExpirationDate').errors && model.get('healthPlanExpirationDate').touched}">
                            <mat-label>Data de validade da carteirinha: </mat-label>
                            <input matInput formControlName="healthPlanExpirationDate" [mask]="masks.date">
                            <mat-error *ngIf="model.controls['healthPlanExpirationDate'].errors">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="button-container">
                    <button mat-flat-button color="primary" class="forward-button" type="submit"
                        [disabled]="model.invalid">
                        <span *ngIf="isLoading == false">CONFIRMAR</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button class="btn-secundary-flex" type="button" (click)="clickCancel()">
                        <span *ngIf="isLoading == false">CANCELAR</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </form>
            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>