import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-appointment-modal',
  templateUrl: './cancel-appointment-modal.component.html',
  styleUrls: ['./cancel-appointment-modal.component.css']
})
export class CancelAppointmentModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CancelAppointmentModalComponent>,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;

  ngOnInit(): void {
    this.colorCode = this.data.colorCode;
  }

  cancelAppointment() {
    this.matDialogRef.close({ cancel: true });
  }

  goBack() {
    this.matDialogRef.close({});
  }
}
