<div>
    <app-header></app-header>

    <body class="body-container">
        <div class="column-separation">
            <div class="column-left">
                <h1 [style.color]="colorCode" class="patient-center-title">Acesso</h1>
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field
                                [ngClass]="{ 'outline-spinner border-field': !model.get('cpf').errors || !model.get('cpf').touched,
                                'outline-spinner form-field-border-field-error': model.get('cpf').errors && model.get('cpf').touched}"
                                appearance="outline">
                                <mat-label class="input-label">CPF</mat-label>
                                <input inputmode="numeric" type="text" matInput formControlName="cpf" [mask]="masks.cpf"
                                    required>
                                <mat-error *ngIf="model.get('cpf').invalid">{{getCpfErrorMessage()}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="border-field" appearance="outline">
                                <mat-label>Senha</mat-label>
                                <mat-icon matSuffix (click)="hide = !hide" [style.color]="colorCode">{{hide ?
                                    'visibility' :
                                    'visibility_off'}}</mat-icon>
                                <input matInput #password type="password" [type]="hide ? 'text' : 'password'"
                                    formControlName="password" required>
                                <mat-error *ngIf="model.get('password').invalid">Informe a senha</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="remember-and-password">
                            <div class="remember-me">
                                <mat-checkbox formControlName="rememberMe">Lembrar-me</mat-checkbox>
                            </div>
                            <div class="forgout-you-password">
                                <button mat-flat-button type="button" (click)="forgoutPassword()" color="accent"
                                    class="btn btn-outline-primary btn-sm remove-border">
                                    Esqueceu sua senha?
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="button-login">
                        <button mat-flat-button color="primary" type="submit" class="button-go">
                            <span *ngIf="isLoading == false">Entrar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="row">
                        <h2>Novo por aqui? <a class="link-register" (click)="goRegister()"> Faça seu cadastro</a></h2>
                    </div>
                </form>
            </div>
            <div class="column-right">
                <div class="second-container">
                    <div class="login-logo-image">
                        <img src="assets/images/patient-center/login-patient-center.png" />
                    </div>
                </div>
            </div>
        </div>
    </body>
</div>