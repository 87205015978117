<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">
                <h1 class="self-triage-title">Aconselhamentos e direcionamentos</h1>
                <hr />
                <div class="row counseling">
                    <div class="transparency">
                        <div class="col-2 div-icon">
                            <mat-icon class="counseling-icon" aria-label="assistant">assistant</mat-icon>
                        </div>
                        <div class="col-10 counseling-description">
                            <p><b>Aconselhamento:</b></p>
                            <p>{{counseling.counseling}}</p>
                        </div>
                    </div>
                </div>
                <span class="selection-span">Selecione a opção desejada:</span>
                <div class="counseling-buttons" *ngFor="let item of listButtons">
                    <!-- <button  mat-flat-button type="button" class="button-selection" id="{{'button' + i }}" (click)="selection(item, $event)" >
                            <span  *ngIf="isLoading == false">{{item.name}}</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>  -->

                    <button *ngIf="item.idServiceType == serviceTypeTelephone" mat-flat-button type="button"
                        class="button-selection" (click)="openModal(item)">
                        <mat-icon class="icon-call" aria-hidden="false"
                            aria-label="call">call</mat-icon>{{item.buttonName}}
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button *ngIf="item.idServiceType == serviceTypeLink" mat-flat-button class="button-selection"
                        (click)="openUrlModal(item)">
                        <span>{{item.buttonName}}</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>

                    <button *ngIf="item.idServiceType == serviceTypeTelemedicine" mat-flat-button
                        class="button-selection" (click)="telemedicineRedirect(item)">
                        <span>{{item.buttonName}}</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="row step-buttons">
                    <div class="col-6">
                        <button mat-flat-button color="secundary" class="button-back" type="button"
                            (click)="openResume()">
                            <span *ngIf="isLoading == false">Resumo</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button color="secundary" type="button" class="button-back button-leave"
                            (click)="goEndSelfTriage()">
                            <span *ngIf="isLoading == false">Sair</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>

                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>

            </div>
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>

        </div>
    </body>
</div>














<!-- 
    
    <div class="container form-group">
        
        <div class="counseling-container">
            <h1>Aconselhamento</h1>
            {{counseling.selfTriageCounselingName}}
        </div>
        
        <div class="direction-button-container">
            <p class="couseling-description">{{counseling.counseling}}</p>
            <div class="col-12" *ngIf="!isLoading">
                
                <div class="buttons col-12 col-sm-6 col-md-12" *ngFor="let item of listButtons">
                    
                    
                    
                    
                    <a *ngIf="item.idServiceType == serviceTypeTelemedicine" mat-flat-button
                    color="primary" class=" direction-buttons" (click)="telemedicineRedirect()"
                    [ngStyle]="{'border-color': colorCode}">
                    <span>{{item.buttonName}}</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </a>
            </div>
        </div>
    </div>
    
</div> -->