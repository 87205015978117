import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsNpsService } from '../utils.service';
import { NpsMessageResponse } from 'src/app/shared/services/responses/nps/nps-message.reponse';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    public sanitizer: DomSanitizer,
    public utilsNpsService: UtilsNpsService,
  ) { }

  public isLoading: boolean = false;
  public colorCode: string;
  public npsMessageResponse: NpsMessageResponse;
  public isLogoUploaded: boolean = false;
  public clientLogo: any;
  public sanitezedLogo: any;
  public logoString64: string;


  ngOnInit(): void {
    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);

    this.npsMessageResponse = this.utilsNpsService.getNpsMessageResponse();

    if (this.npsMessageResponse.npsScreenConfig.isLogoUploaded) {
      this.isLogoUploaded = true;
      this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.npsMessageResponse.logoString64}`);
    }

    if (this.npsMessageResponse.npsScreenConfig.useColor)
      this.colorCode = this.npsMessageResponse.npsScreenConfig.colorCode;
    else
      this.colorCode = "#d4d4d4";
  }
}