import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelemedicineRoutingModule } from './telemedicine-routing.module';
import { TelemedicineComponent } from './pages/telemedicine/telemedicine.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { EndedQueryComponent } from './pages/attend-patient-video/ended-query/ended-query.component';
import { AttendPatientVideoComponent } from './pages/attend-patient-video/attend-patient-video.component';
import { TelemedicineHeaderComponent } from './pages/telemedicine-header/telemedicine-header.component';
import { ForwardEpisodeComponent } from './pages/attend-patient-video/forward-episode/forward-episode.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TelemedicineIframeComponent } from './pages/telemedicine-iframe/telemedicine-iframe.component';
import { PreEvaluationIframeComponent } from './pages/telemedicine-iframe/pre-evaluation-iframe/pre-evaluation-iframe.component';
import { TelemedicinePatientCenterComponent } from './pages/telemedicine-patient-center/telemedicine-patient-center.component';
import { WaitingAreaPatientCenterComponent } from './pages/telemedicine-patient-center/waiting-area-patient-center/waiting-area-patient-center.component';
import { TelemedicineSelfTriageComponent } from './pages/telemedicine-self-triage/telemedicine-self-triage.component';
import { TelemedicineTelephoneClassificationComponent } from './pages/telemedicine-telephone-classification/telemedicine-telephone-classification.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    TelemedicineComponent,
    TelemedicineIframeComponent,
    AttendPatientVideoComponent,
    EndedQueryComponent,
    TelemedicineHeaderComponent,
    ForwardEpisodeComponent,
    PreEvaluationIframeComponent,
    TelemedicinePatientCenterComponent,
    WaitingAreaPatientCenterComponent,
    TelemedicineSelfTriageComponent,
    TelemedicineTelephoneClassificationComponent,
  ],
  imports: [
    CommonModule,
    TelemedicineRoutingModule,
    SharedModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NgxMatFileInputModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    CdkAccordionModule,
    MatDialogModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AuthGuard,
  ],
})
export class TelemedicineModule { }
