import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { PatientAccessLeavingService } from '../../services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { StatusQueueRequest } from '../../services/requests/queue/status-queue.request';

@Component({
  selector: 'app-evade-patient-modal',
  templateUrl: './evade-patient-modal.component.html',
  styleUrls: ['./evade-patient-modal.component.css']
})
export class EvadePatientModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
    private alertService: AlertService,
    private patientAcessLeavingService: PatientAccessLeavingService,
    private formBuilder: UntypedFormBuilder,
    private utilsTelemedicineService: UtilsTelemedicineService,
  ) { }


  public isLoading: boolean = false;
  public model: UntypedFormGroup;
  public colorCode: string;
  public selfTriageAccessTelemedicine: boolean = false;
  public telephoneClassificationAccessTelemedicine: boolean = false;
  public patientCenterAccessTelemedicine: boolean = false;
  public patientData: PatientData;

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.telephoneClassificationAccessTelemedicine = this.utilsTelemedicineService.getTelephoneClassificationTelemedicine();
    this.selfTriageAccessTelemedicine = this.utilsTelemedicineService.getSelfTriageAcessTelemedicine();
    this.patientData = this.utilsTelemedicineService.getPatientData();

    this.isLoading = false;

    this.colorCode = this.data.colorCode ? this.data.colorCode : '#85C443';

    this.model = this.formBuilder.group({
      conclusionNote: ['', [Validators.required]],
    });
  }

  close() {
    this.matDialogRef.close({ isEvade: false });
  }

  clickOk() {
    this.isLoading = true;
    this.updateStatusPatient();
  }

  clickCancel() {
    this.matDialogRef.close({ isEvade: false });
  }

  updateStatusPatient() {
    this.isLoading = true;
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = "Paciente evadido após sair da fila da telemedicina.";
    statusQueueRequest.idEpisode = this.data.idEpisode;
    statusQueueRequest.idHealthUnit = this.data.idHealthUnit;
    statusQueueRequest.idQueue = this.data.idQueue;
    statusQueueRequest.idUser = this.data.idAttendantUser;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;

    if (!this.patientData.patientCenterAccessTelemedicine){
      this.utilsTelemedicineService.clearTelemdicineConfig();
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removePatientData();
    }

    this.utilsTelemedicineService.removeOldFlowControl();

    if (this.telephoneClassificationAccessTelemedicine || this.selfTriageAccessTelemedicine) {
      let currentUrl = window.location.href;
      let desiredUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));

      window.history.pushState({}, document.title, desiredUrl);
    }

    this.patientAcessLeavingService.updateStatus(statusQueueRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.matDialogRef.close({ isEvade: true });
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.matDialogRef.close({ isEvade: true });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

