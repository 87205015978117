import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { NpsMessage } from '../../models/nps/nps-message.model';
import { NpsMessageResponse } from '../../responses/nps/nps-message.reponse';

@Injectable({
  providedIn: 'root'
})
export class ClientNpsMessageService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public getNpsMessageByGuid(guid: string): Observable<NpsMessageResponse> {

    let uri = `ClientNpsMessage/guid/${guid}`;

    return this.httpClient.get<NpsMessageResponse>(environment.urlApiNps + uri)
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateMessage(body: NpsMessage): Observable<ReturnStruct> {

    let uri = `ClientNpsMessage`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiNps + uri, body)
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    