import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowchartCompleteStruct } from 'src/app/shared/services/structs/self-triage/flowchart-complete.struct';
import { ProtocolCompleteStruct } from 'src/app/shared/services/structs/self-triage/protocol-complete.struct';
import { QuestionStruct } from 'src/app/shared/services/structs/self-triage/question.struct';

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.css']
})
export class FlowchartComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    ) { }
    
    @Input() protocol: ProtocolCompleteStruct;
    @Input() colorCode: string;
    @Output() selectedFlowchart: EventEmitter<number> = new EventEmitter<number>();
    public model: UntypedFormGroup;
    public isLoading: boolean = false;
    public oldRadioButtonSelected: string;
    
    ngOnInit(): void {
      this.model = this.formBuilder.group({
        idFlowchart: ['', [Validators.required]],
      });
    }

    submit(){
      this.isLoading = true;

      let idFlowchart: number = this.model.get('idFlowchart').value;
      let flowchart: FlowchartCompleteStruct = this.protocol.listFlowchart.find(x => x.idFlowchart == idFlowchart);
      if (!flowchart.question.answerStructs || flowchart.question.answerStructs.length == 0){
        this.alertService.show("Erro", "Fluxograma não foi configurado corretamente!", AlertType.error);
        this.isLoading = false;
      } 
      else{
        this.selectedFlowchart.emit(idFlowchart);
      }   
    }
  }
  