import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';

@Injectable({
    providedIn: 'root'
})

export class TriggerWebsocketService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public triggerAction(idEpisode: number, websocketAction: number): Observable<ReturnStruct>{
    let uri = `TriggerWebsocket/idEpisode/${idEpisode}/websocketAction/${websocketAction}`;
    
    return this.httpClient.get<ReturnStruct>(environment.urlApiOrchestratorTelemedicine + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}