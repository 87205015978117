<div *ngIf="!checkedLoading" class="page-loading">

    <div class="c-loader"></div>
    <h1>Carregando...</h1>
</div>
<div *ngIf="!checkedUri && checkedLoading" class="page-not-found">
    <h1>
        <mat-icon class="icon-page" aria-hidden="false" aria-label="web_asset_off">web_asset_off</mat-icon>Página não
        encontrada
    </h1>
</div>
<div *ngIf="checkedUri && checkedLoading" class="main-container">
    <app-telemedicine-header [telemedicineConfig]="telemedicineConfig"></app-telemedicine-header>

    <app-welcome-telemedicine *ngIf="flowControl == welcome" [preview]="preview" [colorCode]="colorCode"
        (next)="next()"></app-welcome-telemedicine>

    <app-patient-identification
        *ngIf="flowControl == patientIdentification && !telemedicineConfig.isExternalSearchEnabled"
        [colorCode]="colorCode" [telemedicineConfig]="telemedicineConfig" [queue]="queue"
        (savedPatientData)="updatePatient($event)" [patientCurrent]="patientData" (goToWaitingArea)="goToWaitingArea()"
        (next)="next()">
    </app-patient-identification>

    <app-external-search *ngIf="flowControl == patientIdentification && telemedicineConfig.isExternalSearchEnabled"
        [colorCode]="colorCode" [telemedicineConfig]="telemedicineConfig" [queue]="queue"
        (savedPatientData)="updatePatient($event)" (goToWaitingArea)="goToWaitingArea()" (next)="next()">
    </app-external-search>

    <app-pre-evaluation-iframe *ngIf="flowControl == preEvaluation" [colorCode]="colorCode" (next)="next()"
    (updatePreEvaluation)="updatePreEvaluation($event)" [episodePreEvaluation]="episodePreEvaluation"
    [symptomDurationList]="symptomDurationList" [symptomDescriptionByIntegration]="symptomDescriptionByIntegration">
    </app-pre-evaluation-iframe>

    <app-orientations [colorCode]="colorCode" (next)="next()" *ngIf="flowControl == orientations">
    </app-orientations>

    <app-emergency-check *ngIf="flowControl == emergencyCheck" [colorCode]="colorCode" (next)="next()"
        (reload)="reload()">
    </app-emergency-check>

    <app-additional-data *ngIf="flowControl == additionalData"
        [telemedicineConfig]="telemedicineConfig" [patient]="patientData" [colorCode]="colorCode" (next)="next()">
    </app-additional-data>

    <app-waiting-area *ngIf="this.flowControl == this.waitingArea" [isLoading]="isLoading" [colorCode]="colorCode"
        [preview]="preview" (next)="next()">
    </app-waiting-area>

</div>