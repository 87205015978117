import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListCityResponse } from '../../responses/user/list-city.response';

@Injectable({
  providedIn: 'root'
})
export class ClientHealthUnitCityService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public ListHealthUnitCity(idHealthUnitState?: number): Observable<ListCityResponse> {

    let uri = 'ClientHealthUnitCity';

    if(idHealthUnitState)
      uri += `/idHealthUnitState/${idHealthUnitState}`

    return this.httpClient.get<ListCityResponse>(environment.urlApiUser + uri)
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    