import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsNpsService } from '../utils.service';
import { NpsMessageResponse } from 'src/app/shared/services/responses/nps/nps-message.reponse';

@Component({
  selector: 'app-end-nps',
  templateUrl: './end-nps.component.html',
  styleUrls: ['./end-nps.component.css']
})
export class EndNpsComponent implements OnInit {

  constructor(
    public router: Router,
    private utilsNpsService: UtilsNpsService,
  ) { }

  public isLoading: boolean = false;
  public npsMessageResponse: NpsMessageResponse;
  public colorCode: string = "";

  ngOnInit(): void {
    this.npsMessageResponse = this.utilsNpsService.getNpsMessageResponse();

    if (this.npsMessageResponse.npsScreenConfig.useColor) {
      this.colorCode = this.npsMessageResponse.npsScreenConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeNps', this.colorCode);
    }
  }
}