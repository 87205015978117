export class PatientRequest {
    state: string;
    city: string; 
    zipCode: string; 
    neighborhood: string;
    street: string;
    houseNumber: number;
    phone: string;
    cpf: string;
    patientName: string;
    email: string;
    birthDate: Date;
    healthInsuranceNumber: string;
    idGender: number;
    idHealthUnit: number;
    listIdHealthUnit: number[] = [];
}