import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientAccessLeavingService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-access-leaving.service';
import { ClientTelemedicineService } from 'src/app/shared/services/API/telemedicine/client-telemedicine.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { EpisodeData, PatientData, UtilsTelemedicineService } from '../../utils.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/episode.struct';
import { QueueUnStruct } from 'src/app/shared/services/structs/orchestrator-telemedicine/queue.struct';
import { HealthIdentificationEnum } from 'src/app/shared/enum/health-identification.enum'
import { PatientDataRequest } from 'src/app/shared/services/requests/orchestrator-telemedicine/patient-data.request';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-telemedicine/episode.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { EpisodePreEvaluation } from 'src/app/shared/services/models/telemedicine/episode-pre-evaluation.model';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { TelemedicineLookupService } from 'src/app/shared/services/API/telemedicine/lookup.service';
import { SymptomDuration } from 'src/app/shared/services/models/telemedicine/symptom-duration.model';
import { EpisodePreEvaluationRequest } from 'src/app/shared/services/requests/telemedicine/episode-pre-evaluation.request';
import { EpisodePreEvaluationService } from 'src/app/shared/services/API/telemedicine/episode-pre-evaluation.service';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { IntegrationTelemedicineService } from 'src/app/shared/services/API/orchestrator-telemedicine/integration-telemedicine.service';

@Component({
  selector: 'app-telemedicine-iframe',
  templateUrl: './telemedicine-iframe.component.html',
  styleUrls: ['./telemedicine-iframe.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class TelemedicineIframeComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private telemedicineLookupService: TelemedicineLookupService,
    private episodePreEvaluationService: EpisodePreEvaluationService,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private clientConfigService: ClientTelemedicineService,
    private episodeOrchestratorTelemedicine: EpisodeService,
    public patientAccessLeaving: PatientAccessLeavingService,
    private integrationTelemedicineService: IntegrationTelemedicineService,
  ) { }

  public healthIdentificationEnum: HealthIdentificationEnum;
  public patientDataRequest: PatientDataRequest = new PatientDataRequest();
  public colorCode: string = "";
  public logoString64: string;
  public uri: string;
  public checkedPatient: boolean = false;
  public patientName: string;
  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public telemedicineConfig: TelemedicineConfigStruct;
  public isLogoUploaded: boolean = false;
  public isLoading: boolean;
  public idEpisode: number;
  public idStatusQueue: number;
  public clientLogo: any;
  public queue: QueueUnStruct;
  public episode: EpisodeStruct;
  public episodeData: EpisodeData;
  public patientData: PatientData = new PatientData();
  public isLoadingCpf: boolean;
  public actualTicket: string = "-";
  public episodePreEvaluation: EpisodePreEvaluation = new EpisodePreEvaluation();
  public isMobileVersion: boolean;
  public symptomDurationList: SymptomDuration[];
  public preview: boolean = false;
  public telephoneClassificationAccessTelemedicine: boolean = false;

  //Variáveis de fluxo
  public flowControl: number = TelemedicineFlowEnum.welcome;
  public welcome: number = TelemedicineFlowEnum.welcome;
  public patientIdentification: number = TelemedicineFlowEnum.patientIdentification;
  public emergencyCheck: number = TelemedicineFlowEnum.emergencyCheck;
  public preEvaluation: number = TelemedicineFlowEnum.preEvaluation;
  public additionalData: number = TelemedicineFlowEnum.additionalData;
  public orientations: number = TelemedicineFlowEnum.orientations;
  public waitingArea: number = TelemedicineFlowEnum.waitingArea;
  public guid: string;
  public symptomDescriptionByIntegration: string = ``;

  ngOnInit(): void {
    this.isMobile();
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
    this.episodeData = this.utilsTelemedicineService.getEpisodeData();
    this.telephoneClassificationAccessTelemedicine = this.utilsTelemedicineService.getTelephoneClassificationTelemedicine();
    this.utilsTelemedicineService.setSelfTriageAcessTelemedicine(false);
    this.utilsTelemedicineService.setTelephoneClassificationTelemedicine(false);
    this.utilsTelemedicineService.setCelerusAcessTelemedicine(false);

    if (!this.patientData || !this.episodeData) {
      this.utilsTelemedicineService.setCelerusAcessTelemedicine(true);
      this.utilsTelemedicineService.clearTelemdicineConfig();
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removePatientData();
    }
    else {
      this.flowControl = TelemedicineFlowEnum.waitingArea;
    }

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.utilsTelemedicineService.updateUri(this.uri);
      this.configClient();
      this.telemedcineLookup();
    }
  }

  iframeGuid(guid) {
    this.integrationTelemedicineService.getPatientDataGuid(this.telemedicineConfig.idHealthUnit, guid).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.guid = null;
          return;
        }

        let patient = new PatientStruct();
        patient.idPatient = response.idPatient;
        patient.patientName = response.patientName;
        patient.socialName = response.socialName;
        patient.cpf = response.cpf;
        patient.birthDate = response.birthDate;
        patient.cns = response.cns;
        patient.idGender = response.idGender;
        patient.neighborhood = response.neighborhood;
        patient.city = response.city;
        patient.state = response.state;
        patient.street = response.street;
        patient.houseNumber = response.houseNumber;
        patient.phone1 = response.phone;
        patient.email = response.email;
        patient.zipCode = response.zipCode;

        this.patientData = this.mapToPatientData(patient);

        this.flowControl = this.welcome;
        if (response.triage)
          this.symptomDescriptionByIntegration += `${response.triage}\n`;
        if (response.temperature)
          this.symptomDescriptionByIntegration += `Temperatura: ${response.temperature}\n`;
        if (response.respiratoryFrequency)
          this.symptomDescriptionByIntegration += `Frequência respiratória: ${response.respiratoryFrequency}\n`;
        if (response.heartRate)
          this.symptomDescriptionByIntegration += `Frequência cardíaca: ${response.heartRate}\n`;
        if (response.glucose)
          this.symptomDescriptionByIntegration += `Glucose: ${response.glucose}\n`;
        if (response.saturation)
          this.symptomDescriptionByIntegration += `Saturação: ${response.saturation}\n`;
        if (response.bloodPressure)
          this.symptomDescriptionByIntegration += `Pressão arterial: ${response.bloodPressure}`;
      },
      error: (error) => {
        this.guid = null;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  goToWaitingArea() {
    this.flowControl = TelemedicineFlowEnum.waitingArea;
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet)
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }

  telemedcineLookup() {
    this.telemedicineLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.symptomDurationList = response.listSymptomDuration;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient(idEpisode: number, idQueue: number) {
    this.isLoading = true;
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = "Paciente evadido após sair da fila da telemedicina.";
    statusQueueRequest.idEpisode = idEpisode;
    statusQueueRequest.idHealthUnit = this.patientData.idHealthUnit;
    statusQueueRequest.idQueue = idQueue;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;

    this.patientAccessLeaving.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  configClient() {
    this.clientConfigService.getClientConfig(this.uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('preview')) {
          this.preview = (this.activatedRoute.snapshot.queryParamMap.get('preview') == 'true') ? true : false;
          this.utilsTelemedicineService.setPreview(this.preview);
        }


        this.telemedicineConfig = response.telemedicineConfig;
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);

        this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
        this.iframeGuid(this.guid);
        this.telemedicineConfig = response.telemedicineConfig;
        this.mapperTelemedicineConfig();
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);

      },
      error: (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToPatientData(patient: PatientStruct): PatientData {
    let PatientData: PatientData = {
      birthDate: patient.birthDate,
      city: patient.city,
      cns: patient.cns,
      cpf: patient.cpf,
      email: patient.email,
      idGender: patient.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patient.idPatient,
      neighborhood: patient.neighborhood,
      patientName: patient.patientName,
      idPatientTelemedicineIntegration: patient.idPatientTelemedicineIntegration,
      phone: patient.phone1 ? patient.phone1 : patient.phone2,
      state: patient.state,
      zipCode: patient.zipCode,
      datetimeinclusion: null,
      healthPlan: patient.healthInsurance,
      healthPlanExpirationDate: patient.healthInsuranceExpirationDate,
      healthPlanNumber: patient.healthInsuranceRegistration,
      idQueue: this.queue?.idQueue,
      patientCenterAccessTelemedicine: false,
      street: patient.street,
      houseNumber: patient.houseNumber
    };
    return PatientData;
  }

  mapToPatientDataRequest(patientData: PatientData): PatientDataRequest {
    let patientDataRequest: PatientDataRequest = {
      birthDate: patientData.birthDate,
      city: patientData.city,
      cns: patientData.cns,
      cpf: patientData.cpf,
      email: patientData.email,
      idGender: patientData.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit,
      idPatient: patientData.idPatient,
      neighborhood: patientData.neighborhood,
      patientName: patientData.patientName,
      phone: patientData.phone ? patientData.phone : null,
      zipCode: patientData.zipCode,
      state: patientData.state,
      datetimeinclusion: null,
      healthPlan: patientData.healthPlan,
      healthPlanExpirationDate: patientData.healthPlanExpirationDate,
      healthPlanNumber: patientData.healthPlanNumber,
      idPatientTelemedicineIntegration: patientData.idPatientTelemedicineIntegration,
      street: patientData.street,
      houseNumber: patientData.houseNumber
    };

    return patientDataRequest;
  }

  mapToEpisodeData(): EpisodeData {
    let episodeData: EpisodeData = {
      idEpisode: this.episode.idEpisode,
      idQueue: this.episode.idEpisode,
      actualTicket: this.episode.fullTicket,
      lastTicket: this.episode.lastTicket,
      waitingTime: this.episode.waitingTime,
    };

    return episodeData;
  }

  savePatient() {
    this.isLoading = true;
    this.patientData = this.utilsTelemedicineService.getPatientData();
    this.patientDataRequest = this.mapToPatientDataRequest(this.patientData);

    this.episodeOrchestratorTelemedicine.createEpisode(this.patientDataRequest, this.patientData.idHealthUnit, NatureOfAttendanceEnum.online).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.idEpisode = response.idEpisode;

        let preEvaluationRequest: EpisodePreEvaluationRequest = new EpisodePreEvaluationRequest();
        preEvaluationRequest.idEpisode = this.idEpisode;
        preEvaluationRequest.idSymptomDuration = this.episodePreEvaluation.idSymptomDuration;
        preEvaluationRequest.symptomDescription = this.episodePreEvaluation.symptomDescription;

        this.savePreEvaluation(preEvaluationRequest);

        this.actualTicket = `${response.ticketInitials} ${response.ticketSequence}`;

        let episode = new EpisodeData();
        episode.idEpisode = response.idEpisode;
        episode.idQueue = response.idQueue;
        episode.actualTicket = response.ticketInitials + response.ticketSequence;

        this.utilsTelemedicineService.createEpisodeData(episode);
        this.patientData.idQueue = response.idQueue;

        this.utilsTelemedicineService.updatePatientData(this.patientData);

        if (this.flowControl == this.additionalData) {
          this.flowControl += 2;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  savePreEvaluation(request: EpisodePreEvaluationRequest) {
    this.episodePreEvaluationService.saveEpisodePreEvaluation(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  next() {
    if (this.flowControl != this.additionalData)
      this.flowControl += 1;
    else
      this.savePatient();
  }

  mapperTelemedicineConfig() {
    if (this.telemedicineConfig != null && this.telemedicineConfig.isActive) {
      this.checkedUri = true;
      if (this.preview && this.telemedicineConfig.colorCodePreview) {
        this.telemedicineConfig.useColor = true;
        this.telemedicineConfig.colorCode = this.telemedicineConfig.colorCodePreview;
        this.colorCode = this.telemedicineConfig.colorCodePreview;
      }
      else if (this.telemedicineConfig.useColor == true) {
        this.colorCode = this.telemedicineConfig.colorCode;
      }
      if (this.preview && this.telemedicineConfig.logoNamePreview) {
        this.telemedicineConfig.isLogoUploaded == true;
        this.telemedicineConfig.logoString64 = this.telemedicineConfig.logoString64Preview;
      }
      else if (this.telemedicineConfig.isLogoUploaded == true) {
        this.isLogoUploaded = true;
        this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.telemedicineConfig.logoString64}`);
      }
      this.telemedicineConfig.logoString64Preview = null;
      this.telemedicineConfig.colorCodePreview = null;
    }
    this.checkedLoading = true;
  }

  updatePatient(patient: PatientStruct) {
    this.patientData = this.mapToPatientData(patient);
  }

  updatePreEvaluation(preEvaluation: EpisodePreEvaluation) {
    this.episodePreEvaluation = preEvaluation;
  }

  reload() {
    this.flowControl = this.welcome;
  }
}