import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientData, UtilsTelemedicineService, VideoCallData } from 'src/app/telemedicine/utils.service';
import { createLocalAudioTrack, LocalAudioTrack, LocalTrack, LocalVideoTrack, RemoteParticipant, Room } from 'twilio-video';
import { LeaveRoomConfirmModalComponent } from './pages/participant/leave-room-confirm-modal/leave-room-confirm-modal.component';
import { ParticipantComponent } from './pages/participant/participant.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TwilioUtilService } from './twilio-util.service';

@Component({
    selector: 'app-twilio-video',
    templateUrl: './twilio-video.component.html',
    styleUrls: ['./twilio-video.component.css']
})

export class TwilioVideoComponent implements OnInit, OnDestroy {

    @ViewChild('settings') settings: SettingsComponent;
    @ViewChild('participants') participants: ParticipantComponent;
    @Output() leaveRoom = new EventEmitter<boolean>();
    @Input() isMobileVersion: boolean;
    @Input() colorCode: string;
    public devices: MediaDeviceInfo[] = [];

    constructor(
        public utilsTelemedicineService: UtilsTelemedicineService,
        private twilioUtilService: TwilioUtilService,
        public dialog: MatDialog,
    ) { }

    public activeRoom: Room;
    public isMuted: boolean = false;
    public isVideoOff: boolean = false;
    public patientScreenIsLarger: boolean = false;
    public doctorScreenIsLarger: boolean = true;
    public videoCallData: VideoCallData;
    public patientCallData: PatientData;
    public isLocalParticipantAlone: boolean = true;

    async ngOnInit() {
        this.videoCallData = this.utilsTelemedicineService.getVideoCallData();
        this.patientCallData = this.utilsTelemedicineService.getPatientData();
        if (!this.colorCode)
            this.colorCode = this.utilsTelemedicineService.getTelemdicineConfig().colorCode;
    }


    ngOnDestroy(): void {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.audioTracks.forEach(track => {
                track.track.stop();
            });

            this.activeRoom.localParticipant.videoTracks.forEach(track => {
                track.track.stop();
            });
        }
        this.stopTracks();
        this.dialog.closeAll();
    }

    async onLeaveRoom() {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.tracks.forEach(async publication => {
                await this.activeRoom.localParticipant.unpublishTrack(publication.track);
                await this.detachLocalTrack(publication.track);
            });
            this.activeRoom.disconnect();
            this.activeRoom = null;            
        }

        const videoDevice = this.settings.hidePreviewCamera();

        this.isMuted = false;
        this.isVideoOff = false;
        this.stopTracks();
        this.participants.clear();
    }

    onMute() {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.audioTracks.forEach(track => {
                if (track.track.isEnabled) {
                    track.track.disable();
                }
                else {
                    track.track.enable();
                }
            });

            this.isMuted = !this.isMuted
        }
    }

    onHideVideo() {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.videoTracks.forEach(track => {
                if (track.track.isEnabled) {
                    track.track.disable();
                }
                else {
                    track.track.enable();
                }
            });

            this.isVideoOff = !this.isVideoOff
        }
    }

    async onRoomChanged(token: string) {
        this.twilioUtilService.isGrantedMediaPermissions();
        if (token) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }

            await this.settings.hidePreviewCamera();

            const tracks = await Promise.all([
                await createLocalAudioTrack(),
                await this.settings.showPreviewCamera(),
            ]);

            this.activeRoom = await this.twilioUtilService.joinOrCreateRoom(token, tracks);

            this.participants.initialize(this.activeRoom.participants);
            this.registerRoomEvents();

            let room = this.activeRoom;

            return room;
        }
    }

    private registerRoomEvents() {
        this.activeRoom
            .on('disconnected', (room: Room) => {
                room.localParticipant.tracks.forEach(async publication => {
                    await room.localParticipant.unpublishTrack(publication.track);
                    await this.detachLocalTrack(publication.track);
                    await this.stopTracks();
                });
            })
            .on('participantConnected',
                (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private async detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {
            track.stop();
            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }

    async stopTracks() {
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        }).then(stream => {
            stream.getTracks().forEach((track) => {
                track.stop();
            })
        });
    }

    functionToSetPatientScreenIsLarger(patientScreenIsLarger: boolean) {
        this.patientScreenIsLarger = patientScreenIsLarger;
        this.doctorScreenIsLarger = false;
    }

    functionToSetDoctorScreenIsLarger(doctorScreenIsLarger: boolean) {
        this.doctorScreenIsLarger = doctorScreenIsLarger;
        this.patientScreenIsLarger = false;
    }

    functionToSetIsAlone(isAlone: boolean) {
        this.isLocalParticipantAlone = isAlone;
        if (this.isLocalParticipantAlone) {
            this.doctorScreenIsLarger = true;
            this.patientScreenIsLarger = false;
        }
    }

    openLeaveRoomConfirmModal() {
        const dialogRef = this.dialog.open(LeaveRoomConfirmModalComponent, {
            data: {

            },
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result && result.leave) {                    
                    this.leaveRoom.emit(true);
                    this.onLeaveRoom();
                }
            }
        });
    }

}
