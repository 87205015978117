export class Patient {
    public idPatient: number;
    public patientName: string;
    public cpf: string;
    public phone: string;
    public email: string;
    public birthDate: Date;
    public healthInsuranceNumber: string;
    public idGender: number;
    public zipCode: string;
    public state: string;
    public city: string;
    public neighborhood: string;
    public street: string;
    public houseNumber: number;
    public datetimeinclusion: Date;
    public patientCenterAccessSelfTriage: boolean;
}