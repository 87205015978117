<div>
    <app-header></app-header>
    <body class="body-container">
        <div class="body-content">
            
            <div class="white-container">
    
                <h1 class="nps-title">Agradecemos sua avaliação!</h1>
                <img class="end-img" src="assets/images/nps-end.png" />
                <hr>
    
                <p class="message-bye">
                    Sua resposta foi recebida e registrada com sucesso!
                </p>
    
                <div class="footer-white-container">
                    <p>NET PROMOTER SCORE (NPS)</p>
                </div>

            </div>
        </div>
    </body>
</div>