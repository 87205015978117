<div mat-dialog-container class="content">
    <ngContainer *ngIf="!isLoading">
        <div class="mat-dialog-container-medic-hold-modal">
            <h1 class="txtCenter" [ngClass]="{'color': colorCode}">O profissional precisou finalizar a consulta!</h1>
            <div [ngClass]="{'d-none': isAttendPatientCenter}">
                <h2 class="txtCenter"> Você será redirecionado para a fila de atendimento</h2>
                <div class="c-loader"></div>
                <h2 class="txtCenter">Aguarde!</h2>
            </div>
            <div *ngIf="isAttendPatientCenter">
                <h2 class="txtCenter">Por favor, acesse a Central do Paciente e agende uma nova consulta.</h2>
            </div>

        </div>
    </ngContainer>
    <div class="mat-dialog-container-medic-hold-modal-after">
        <button mat-flat-button class="btn-secundary block" (click)="clickCancel()">FECHAR</button>
    </div>
</div>