<app-alert-header></app-alert-header>

<div class="client-body">
    <div class="white-body">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <p><b>Nome:</b></p>
                </div>
                <div class="row">
                    <p><b>Senha:</b></p>
                </div>
                <div class="row">
                    <p><b>Localização:</b></p>
                </div>
                <div class="row">
                    <div class="col-8">
                        <p></p>
                    </div>
                    <div class="col-4">
                        <p></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <p></p>
                    </div>
                    <div class="col-4">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row">
                <div class="col-11">
                    <h2>Histórico</h2>
                </div>
            </div>
        </div>
    </div>
</div>