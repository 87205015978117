<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">
            <div class="white-container">
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <h1 class="patient-center-title">Informações pessoais</h1>
                    <hr />
                    <div class="row">
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('cpf').errors || !model.get('cpf').touched,'outline-spinner form-field-patient-center-error': model.get('cpf').errors && model.get('cpf').touched}">
                                <mat-label class="input-label">CPF</mat-label>
                                <input autofocus (keyup)="searchByCpf()" matInput inputmode="numeric" type="text"
                                    formControlName="cpf" [mask]="masks.cpf" placeholder="000.000.000-00" required>
                                <mat-error *ngIf="model.get('cpf').invalid">CPF inválido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('completeName').errors || !model.get('completeName').touched,'outline-spinner form-field-patient-center-error': model.get('completeName').errors && model.get('completeName').touched}">
                                <mat-label class="input-label">Nome completo</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="completeName"
                                    placeholder="Digite seu nome completo" required>
                                <mat-error *ngIf="model.get('completeName').invalid">Informe o nome completo</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('birthDate').errors || !model.get('birthDate').touched,'outline-spinner form-field-patient-center-error': model.get('birthDate').errors && model.get('birthDate').touched}">
                                <mat-label class="input-label">Data de nascimento</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="birthDate"
                                    [mask]="masks.date" placeholder="dd/mm/aaaa" required>
                                <mat-error *ngIf="model.get('birthDate').invalid">Data de
                                    nascimento inválida</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('completeMotherName').errors || !model.get('completeMotherName').touched,'outline-spinner form-field-patient-center-error': model.get('completeMotherName').errors && model.get('completeMotherName').touched}">
                                <mat-label class="input-label">Nome completo da mãe</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="completeMotherName"
                                    placeholder="Digite o nome da sua mãe" required>
                                <mat-error *ngIf="model.get('completeMotherName').invalid">Informe o nome
                                    completo</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('phone').errors || !model.get('phone').touched,'outline-spinner form-field-patient-center-error': model.get('phone').errors && model.get('phone').touched}">
                                <mat-label class="input-label">Celular</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="phone"
                                    [mask]="masks.phone" placeholder="(31)99999-9999" required>
                                <mat-error *ngIf="model.get('phone').invalid">Celular inválido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('idGender').errors || !model.get('idGender').touched,'outline-spinner form-field-patient-center-error': model.get('idGender').errors && model.get('idGender').touched}">
                                <mat-label>Gênero</mat-label>
                                <mat-select formControlName="idGender">
                                    <mat-option *ngFor="let item of listGender"
                                        [value]="item.idGender">{{item.genderName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('idGender').invalid">Selecione um gênero</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <h1 class="patient-center-title">Informações de acesso</h1>
                    <hr />
                    <div class="row">
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('email').errors || !model.get('email').touched,'outline-spinner form-field-patient-center-error': model.get('email').errors && model.get('email').touched}">
                                <mat-label class="input-label">E-mail</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="email"
                                    (keyup)="validateEmail()" placeholder="Digite seu e-mail" required>
                                <mat-error *ngIf="model.get('email').invalid">E-mail inválido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('emailConfirm').errors || !model.get('emailConfirm').touched,'outline-spinner form-field-patient-center-error': model.get('emailConfirm').errors && model.get('emailConfirm').touched}">
                                <mat-label class="input-label">Confirme seu e-mail</mat-label>
                                <input onpaste="return false;" matInput (ngModelChange)="validateEmailConfirm()"
                                    inputmode="text" type="text" formControlName="emailConfirm"
                                    placeholder="Confirme seu e-mail" required>
                                <mat-error *ngIf="model.get('email').value != model.get('emailConfirm').value">Os
                                    e-mails não conferem</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('password').errors || !model.get('password').touched,'outline-spinner form-field-patient-center-error': model.get('password').errors && model.get('password').touched}">
                                <mat-label class="input-label">Senha</mat-label>
                                <mat-icon matSuffix (click)="hidePassword = !hidePassword"
                                    [style.color]="colorCode">{{hidePassword ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <input matInput inputmode="text" [type]="hidePassword ? 'password' : 'text'" matInput
                                    (keyup)="validatePasswordConfirm()" formControlName="password"
                                    placeholder="Digite sua senha" required>
                                <mat-error *ngIf="model.get('password').invalid">Senha inválida</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 margin">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-patient-center': !model.get('passwordConfirm').errors || !model.get('passwordConfirm').touched,'outline-spinner form-field-patient-center-error': model.get('passwordConfirm').errors && model.get('passwordConfirm').touched}">
                                <mat-label class="input-label">Confirme sua senha</mat-label>
                                <mat-icon matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm"
                                    [style.color]="colorCode">{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <input onpaste="return false;" matInput (keyup)="validatePasswordConfirm()"
                                    inputmode="text" [type]="hidePasswordConfirm ? 'password' : 'text'"
                                    formControlName="passwordConfirm" placeholder="Confirme seu senha" required>
                                <mat-error *ngIf="model.get('passwordConfirm').value != model.get('password').value">As
                                    senhas não conferem</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12"
                        *ngIf="model.get('password').invalid && model.get('passwordConfirm').invalid || model.get('password').invalid">
                        <p [ngStyle]="{ 'color': model.get('password').invalid ? '#fc0303' : '#000000' }">* Use
                            entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras
                            minúsculas, números e caracteres especiais.</p>
                    </div>
                    <button [disabled]="model.invalid" mat-flat-button color="primary" class="button-go">
                        <span *ngIf="isLoading == false">Prosseguir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button color="secundary" class="button-go btn-return" (click)="cancel()">
                        <span *ngIf="!isLoading">Cancelar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </form>
                <div class="footer-white-container">
                    <p>Central do paciente</p>
                </div>
            </div>
        </div>
    </body>
</div>