export class PatientStruct {
    public idPatient: number;
    public patientName: string;
    public cpf: string;
    public birthDate: Date;
    public cns: string;
    public socialName: string;
    public idGender: number;
    public genderName: string;
    public neighborhood: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public street: string;
    public houseNumber: number;
    public phone1: string;
    public phone2: string;
    public email: string;
    public healthInsurance: string;
    public healthInsuranceExpirationDate: Date;
    public healthInsuranceRegistration: string;
    public idPatientTelemedicineIntegration: number;
} 