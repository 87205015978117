import { EpisodePreEvaluation } from "../../models/telemedicine/episode-pre-evaluation.model";

export class PatientDataRequest {
    public idPatient: number;
    public patientName: string;
    public cpf: string;
    public phone: string;
    public email: string;
    public birthDate: Date;
    public idHealthUnit: number;
    public idGender: number;
    public zipCode: string;
    public state: string;
    public city: string;
    public neighborhood: string;
    public street: string;
    public houseNumber: number;
    public cns: string;
    public healthPlan: string;
    public healthPlanNumber: string;
    public healthPlanExpirationDate: Date;
    public datetimeinclusion: Date;
    public idPatientTelemedicineIntegration: number;
}