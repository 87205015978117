<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">

                <h1 class="self-triage-title">A consulta foi finalizada!</h1>
                <img class="end-img" src="assets/images/self-triage/end.png" />
                <hr>

                <p class="text-end-self-triage">Conte com os nossos profissionais para cuidar da sua saúde e auxiliar na sua recuperação!</p>

                <button mat-flat-button color="primary" class="button-go" (click)="clickGo()">
                    <span *ngIf="isLoading == false">Voltar para o início</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>

                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>
            </div>

            <app-pagination [screenStage]="this.thisScreen"></app-pagination>

        </div>
    </body>
</div>