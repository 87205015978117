export class SelfTriageConfig {
    public idSelfTriageConfig: number;
    public idHealthUnit: number;
    public useColor: boolean;
    public colorCode: string;
    public colorCodePreview: string;
    public isLogoUploaded: boolean;
    public useTolifeLogo: boolean;
    public logoName: string;
    public logoNamePreview: string;
    public url: string;
    public urlName: string;
    public isActive: boolean;
    public datetimeInclusion: Date;
    public titlePage: string;
    public descriptionPage: string; 
    public emergency: string;
    public symptoms: string;   
    public isActiveTitleDescriptionAlteration: boolean;
}