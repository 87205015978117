<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            <div class="white-container">
                <div class="row white-container-body">
                    <div class="col-12">
                        <h1 class="patient-center-title">Resumo de Agendamento:</h1>
                    </div>  
                    <div class="row user-and-datetime">
                        <div class="col-12 col-md-6 user-data-and-icon">                            
                            <mat-icon>account_circle</mat-icon>
                            <div class="user">
                                <h1 class="patient-center-title">Doutor(a):</h1>
                                <p class="information-of-appointment">{{this.patientAppointmentSession.userName}}</p>
                                <p class="information-of-appointment" *ngIf="this.patientAppointmentSession.crmUser" style="font-size: 13px">
                                    <span *ngIf="this.patientAppointmentSession.userCouncilUF">
                                        CRM - {{this.patientAppointmentSession.userCouncilUF}}: {{this.patientAppointmentSession.crmUser}}
                                    </span>
                                    <span *ngIf="!this.patientAppointmentSession.userCouncilUF">
                                        CRM: {{this.patientAppointmentSession.crmUser}}
                                    </span>
                                </p>
                            </div>                            
                        </div>
                        <div class="col-12 col-md-6 datetime">
                            <div>
                                <h1 class="patient-center-title">Data do atendimento:</h1>
                                <p class="information-of-appointment">{{dateAppointment}}</p>
                            </div>
                            <div>
                                <h1 class="patient-center-title">Horário</h1>
                                <p class="information-of-appointment">{{timeAppointment}}</p>
                            </div>                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2 class="patient-center-subtitle">Especialidade:</h2>
                            <p class="information-of-appointment">{{this.patientAppointmentSession.medicalSpecialtyName}}</p>
                        </div>
                        <div class="col-12">
                            <h2 class="patient-center-subtitle">Tipo de atendimento:</h2>
                            <p class="information-of-appointment">{{this.patientAppointmentSession.typeOfCareName}}</p>
                        </div>
                        <div class="col-12">
                            <h2 class="patient-center-subtitle">Unidade de saúde:</h2>
                            <p class="information-of-appointment">{{this.patientAppointmentSession.healthUnitName}}</p>
                        </div>
                        <div *ngIf="this.patientAppointmentSession.idPatientHealthcareAgreement" class="col-12">
                            <h2 class="patient-center-subtitle">Convênio:</h2>
                            <p class="information-of-appointment">{{this.patientAppointmentSession.nameHealthcareAgreement}}</p>
                        </div>
                    </div>
                    
                    <button mat-flat-button color="primary" class="button-submit col-12" (click)="submit()">
                        <span *ngIf="isLoading == false">Seguir com o agendamento</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button type="button" color="secundary" class="button-back col-12" (click)="backBegin()">
                        <span *ngIf="isLoading == false">Voltar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    
                    
                    <div class="footer-white-container">
                        <p>Central do paciente</p>
                    </div>                   
                </div>                
            </div>
        </div>
    </body>
</div>