<div>
    <app-header></app-header>

    <body class="body-container">
        <div class="body-content">
            <div class="white-container">
                <div style="width: 100%;">
                    <h2>Redefinir senha</h2>
                    <hr />
                </div>
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <p>Insira o CPF associado à sua conta para receber um link de redefinição de senha.</p>
                    <div class="row" *ngIf="state">
                        <div class="col-md-12">
                            <mat-form-field class="border-field-forgot" appearance="outline">
                                <mat-label>Digite o seu CPF</mat-label>
                                <input matInput inputmode="numeric" type="text" formControlName="cpf" [mask]="masks.cpf"
                                    placeholder="000.000.000-00" required>
                                <mat-error *ngIf="model.get('cpf').invalid">{{getCpfErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="state">
                        <div class="col-md-12">
                            <p>Selecione um canal para receber o link:</p>
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="recoverOption"
                                [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="1">Email</mat-radio-button>
                                <mat-radio-button value="2">SMS</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 button-login" *ngIf="state">
                            <button mat-flat-button color="primary" class="btn-block btn-avanced"
                                [disabled]="model.invalid || cpfNotEncountred" (click)="checkCpf()">
                                <span *ngIf="isLoading == false">Avançar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                        <div class="col-md-12">
                            <button mat-flat-button type="button" routerLink="/patient-center/login" color="accent"
                                class="btn-block btn-return">
                                <span>Voltar</span>
                            </button>
                        </div>
                    </div>
                </form>

                <div class="footer-white-container">
                    <p>Central do Paciente</p>
                </div>

            </div>
        </div>
    </body>
</div>