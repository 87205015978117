import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ValidateAccountResponse } from '../../responses/patient-center/validate-account.response';
import { ValidateUserPatientRequest } from '../../requests/patient-center/validate-user-patient.request';

@Injectable({
    providedIn: 'root'
})

export class UserPatientService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    public validateAccount(guid: string, listIdHealthUnit, idHealthUnit: number): Observable<ValidateAccountResponse> {
        let uri = `UserPatient`;

        let request = new ValidateUserPatientRequest(guid, listIdHealthUnit, idHealthUnit);

        return this.httpClient.post<ValidateAccountResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderInternalToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}