import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { Room } from 'twilio-video';
import { TwilioUtilService } from '../../twilio-util.service';
import { CameraComponent } from '../camera/camera.component';
import { DeviceSelectComponent } from './device-select/device-select.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    @Input() devices: MediaDeviceInfo[] = [];
    @Input() isMobileVersion: boolean;
    @Input() doctorScreenIsLarger: boolean;
    @Input() isLocalParticipantAlone: boolean;
    @Output('setPatientScreenIsLarger') setPatientScreenIsLarger = new EventEmitter<boolean>();
    private subscription: Subscription;
    private videoDeviceId: string;
    
    get hasAudioInputOptions(): boolean {
        return this.devices && this.devices.filter(d => d.kind === 'audioinput').length > 0;
    }
    get hasAudioOutputOptions(): boolean {
        return this.devices && this.devices.filter(d => d.kind === 'audiooutput').length > 0;
    }
    get hasVideoInputOptions(): boolean {
        return this.devices && this.devices.filter(d => d.kind === 'videoinput').length > 0;
    }

    @ViewChild('camera') camera: CameraComponent;
    @ViewChild('videoSelect') video: DeviceSelectComponent;

    @Input('isPreviewing') isPreviewing: boolean;
    @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();

    constructor(
        private twilioUtilService: TwilioUtilService,
        private changeDetector: ChangeDetectorRef
    ) { }

    async ngOnInit() {
        this.devices = await this.twilioUtilService.tryGetDevices();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
        this.settingsChanged.emit(deviceInfo);
    }

    async showPreviewCamera() {
        this.changeDetector.detectChanges();
        this.isPreviewing = true;
            this.camera.finalizePreview();
            if (!this.camera.videoTrack || this.videoDeviceId !== this.video.selectedId) {
                this.videoDeviceId = this.video.selectedId;
                const videoDevice = this.devices.find(d => d.deviceId === this.video.selectedId);
                await this.camera.initializePreview(videoDevice.deviceId);
            }

            return this.camera.videoTrack;
    }

    hidePreviewCamera() {
        this.isPreviewing = false;
        this.camera.finalizePreview();
        return this.devices.find(d => d.deviceId === this.video.selectedId);
    }

    functionToSetPatientScreenIsLarger(patientScreenIsLarger: boolean) { 
        this.setPatientScreenIsLarger.emit(patientScreenIsLarger);
    }

    private handleDeviceAvailabilityChanges() {
        if (this.devices && this.devices.length && this.video && this.video.selectedId) {
            let videoDevice = this.devices.find(d => d.deviceId === this.video.selectedId);
            if (!videoDevice) {
                videoDevice = this.devices.find(d => d.kind === 'videoinput');
                if (videoDevice) {
                    this.video.selectedId = videoDevice.deviceId;
                    this.onSettingsChanged(videoDevice);
                }
            }
        }
    }
}