export class EpisodePreEvaluation {

    public idEpisodePreEvaluation: number;

    public idEpisode: number;

    public symptomDescription: string;

    public idSymptomDuration: number;

    public datetimeInclusion: Date;
}