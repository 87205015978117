import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-forward-episode',
  templateUrl: './forward-episode.component.html',
  styleUrls: ['./forward-episode.component.css']
})
export class ForwardEpisodeComponent implements OnInit {

  @Input() colorCode: string;
  @Input() isMobileVersion: boolean;
  @Output() forwardEpisode = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public utilsTelemedicineService: UtilsTelemedicineService,
    public sanitizer: DomSanitizer,
    private router: Router,
  ) { }

  public uri: string;
  public telemedicineConfig: TelemedicineConfigStruct;
  public logoString64: string;
  public sanitizedLogo: any;

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
      this.colorCode = this.telemedicineConfig.colorCode;
    }

    this.isMobile();
    this.sanitizedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);
    setTimeout(() => this.forwardWaitingAreaMedic(), 5000)
  }
  
  forwardWaitingAreaMedic() {
    this.utilsTelemedicineService.setForwardMedic(true);

    this.router.navigate([`telemedicine/${this.uri}`]);
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || screen.orientation.type == "portrait-primary")
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }
}