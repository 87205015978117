<div>
    <app-header></app-header>
    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        
        <div class="body-content">
            <div class="white-container">
    
                <h1 class="self-triage-title">Seja bem-vindo(a) à</h1>
                <h1 class="welcome-itriage">Autotriagem</h1>
                <img class="welcome-img" src="assets/images/self-triage/welcome.png" />
    
                <button mat-flat-button color="primary" class="button-go" (click)="clickGo()">
                    <span *ngIf="isLoading == false">Prosseguir</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
    
                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>
            </div>
    
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>
        </div>
        
    </body>
</div>