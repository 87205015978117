import { HealthPlan } from "../../models/telemedicine/health-plan.model";
import { TelemedicineConfig } from "../../models/telemedicine/telemedicine-config.models";

export class TelemedicineConfigStruct extends TelemedicineConfig  {
    public listHealthPlans: HealthPlan[];
    public logoString64: string;
    public logoString64Preview: string;
    public completeUrl: string;
    public guid: string;
    public externalSearchFieldDescription: string;
}