<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                <h2 class="identification">Identificação</h2>
            </div>
            <form class="form identification-form" [formGroup]="model" (ngSubmit)="submit()">
                <mat-form-field appearance="outline"
                    [ngClass]="{ 'outline-spinner form-field-telemedicine': !model.get('cpf').errors || !model.get('cpf').touched,'outline-spinner form-field-telemedicine-error': model.get('cpf').errors && model.get('cpf').touched}">
                    <mat-label>Informe seu CPF</mat-label>
                    <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                    <mat-error *ngIf="model.get('cpf').invalid">CPF inválido</mat-error>
                </mat-form-field>
                <mat-checkbox class="ckeck-privacy-policy-telemedicine" #checkbox (click)="openLGPDModal(checkbox)" [checked]="checkedPrivacy">
                    <div class="div-flex">
                        Afirmo que li e aceito os <span class="eula"> Termos de Uso</span> da plataforma
                    </div>
                </mat-checkbox>
                <button mat-flat-button color="primary" class="forward-button" type="submit"
                    [disabled]="!checkedPrivacy || model.invalid">
                    <span *ngIf="isLoading == false">PRÓXIMO</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </form>
            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>