<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content">
        <div class="white-container">
            <div *ngIf="!checkedLoading" class="page-loading">

                <div class="c-loader"></div>
                <h1>Carregando...</h1>
            </div>
            <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
            <div *ngIf="!isLoading" class="container-info">
                <div class="info-queue-ticket" [ngStyle]="{'background-color': colorCode}">
                    <div class="password-ticket">
                        <mat-icon class="person" aria-hidden="false" aria-label="person">person</mat-icon>
                        <div class="border-password">
                            <h3>SUA SENHA É:</h3>
                        </div>
                        <p class="current-ticket"><b>{{this.actualTicket}}</b></p>
                    </div>
                </div>
                <div class="info-queue-past">
                    <div class="info-queue-group" [ngStyle]="{'background-color': colorCode}">
                        <mat-icon class="groups_2 img-container" aria-hidden="false" aria-label="groups_2">
                            groups_2</mat-icon>
                        <span class="text-last-password-time">
                            <p>Última senha chamada:</p>
                            <p class="last-ticket">{{this.lastTicket}}</p>
                        </span>
                    </div>
                    <div class="info-queue-schedule" [ngStyle]="{'background-color': colorCode}">
                        <mat-icon class="schedule img-container" aria-hidden="false" aria-label="schedule">
                            schedule</mat-icon>
                        <span class="text-last-password-time">
                            <p>Tempo médio de espera:</p>
                            <p>{{this.waitingTime}}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button mat-flat-button class="forward-button" color="primary" (click)="evadePatient()">
                    SAIR DA FILA
                </button>
            </div>
            <div class="footer-white-container">
                <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>
</div>