import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { MinutesEnum } from 'src/app/shared/enum/minutes.enum';
import { CreateAppointmentCallService } from 'src/app/shared/services/API/orchestrator-schedule/create-appointment-call.service';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PatientCenterAppointmentService } from 'src/app/shared/services/API/orchestrator-schedule/orchestratort-patient-appointment.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { UserPatient } from 'src/app/shared/services/models/patient-center/user-patient.model';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { AppointmentDataStruct } from 'src/app/shared/services/structs/schedule/appointment-data.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { AppointmentDetailsModalComponent } from '../patient-appointment/appointment-details-modal/appointment-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OutOfHoursAppointmentModalComponent } from 'src/app/shared/components/out-of-hours-appointment-modal/out-of-hours-appointment-modal.component';
import { PatientPreAppointmentCenterService } from 'src/app/shared/services/API/orchestrator-schedule/patient-pre-appointment-center.service';
import { HasPreAppointmentModalComponent } from './has-pre-appointment-modal/has-pre-appointment-modal.component';
import { PatientPreAppointmentResponse } from 'src/app/shared/services/responses/orchestrator-schedule/patient-pre-appointment.response';
import { PatientAppointmentSessionStruct } from 'src/app/shared/structs/patient-center/patient-appointment-session.struct';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MessagePatientCenterService } from 'src/app/shared/services/API/notification/message-patient-center.service';
import { MessagePatientCenterStruct } from 'src/app/shared/services/structs/notification/message-patient-center.struct';
import { WebsocketNotificationUtilService } from 'src/app/shared/services/websocket-util/websocket-notification-util.service';
import { PatientCenterLoginResponse } from 'src/app/shared/services/responses/auth/patient-center-login.response';
import { WebsocketNotificationActionEnum } from 'src/app/shared/components/enum/websocket-notification-action.enum';
import { FormGroup } from '@angular/forms';
import { PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { PatientCenterAccessTelemedicineService } from 'src/app/shared/services/API/orchestrator-telemedicine/patient-center-access-telemedicine.service';
import { PatientCenterAccessSelfTriageService } from 'src/app/shared/services/API/orchestrator-patient/patient-center-access-self-triage.service';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { AppointmentStatusEnum } from 'src/app/shared/enum/appointment-status.enum';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.css']
})
export class HubComponent implements OnInit {
  constructor(private authService: AuthService,
    private maskService: MaskService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private patientAppointmentService: PatientAppointmentService,
    private patientCenterAppointmentService: PatientCenterAppointmentService,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private utilService: UtilService,
    private createAppointmentCallService: CreateAppointmentCallService,
    private patientPreAppointmentCenterService: PatientPreAppointmentCenterService,
    private messagePatientCenterService: MessagePatientCenterService,
    private websocketNotificationUtilService: WebsocketNotificationUtilService,
    private utilsTelemedicineService: UtilsTelemedicineService,
    private utilsSelfTriageService: UtilsSelfTriageService,
    private patientCenterAccessTelemedicineService: PatientCenterAccessTelemedicineService,
    private patientCenterAccessSelfTriageService: PatientCenterAccessSelfTriageService,
  ) { }

  public model: FormGroup;
  public masks: Masks;

  public isVisibleNotification: boolean;
  public showUserOptions: boolean = false;
  public isLoading: boolean = false;
  public isLoadingNotification: boolean;
  public useTelemedicineInPatientCenter: boolean = false;
  public useSelfTriageInPatientCenter: boolean = false;
  public loadingAccessTelemedicine: boolean = false;
  public loadingAccessSelfTriage: boolean = false;

  public listMessagePatientCenter: MessagePatientCenterStruct[] = [];
  public listPatientAppointment: PatientAppointment[];
  public patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
  public telemedicineConfig: TelemedicineConfigStruct = new TelemedicineConfigStruct;
  public selfTriageConfig: SelfTriageConfigStruct = new SelfTriageConfigStruct;
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public tokenStruct: PatientCenterLoginResponse;
  public patient: UserPatient;
  public healthUnitStruct: HealthUnitStruct;
  public patientStruct: PatientStruct;

  public colorCode: string = '#99C8D6';
  public patientName: string;
  public uri: string;

  public standardAttendance: number = NatureOfAttendanceEnum.presencial;
  public onlineAttendance: number = NatureOfAttendanceEnum.online;
  public timeoutValue: number = 0;
  public unreadMessages: number;
  public idHealthUnit: number;
  public idUserPatient: number;

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.masks = this.maskService.getMasks();
    this.patientName = this.utilService.getPatientName()?.split(" ")[0];
    this.tokenStruct = this.authService.getTokenStruct();
    this.idHealthUnit = this.utilService.getIdHealthUnit();

    if (!this.tokenStruct)
      this.logout();

    this.idUserPatient = this.tokenStruct.idUserPatient;

    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    this.healthUnitStruct = this.utilsDigitalPromptServiceService.getHealthUnit();
    if (!this.healthUnitStruct)
      this.router.navigate([`digital-prompt-service/${this.utilsDigitalPromptServiceService.getUri()}`]);

    this.patientAppointmentSession = this.utilsDigitalPromptServiceService.getSchedulingInformations();
    if (this.patientAppointmentSession)
      this.utilsDigitalPromptServiceService.removeSchedulingInformations();

    this.getPatientAppointment();
    this.startWebsocket();
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketNotificationUtilService.isConnect())
      this.websocketNotificationUtilService.connectwebsocketRoom(this.tokenStruct.idUserPatient, this);

    this.populateNotification();
    this.websocketNotificationUtilService.setContext(this);
    this.websocketNotificationUtilService.setFunctions(this.verifyTrigger);
  }

  verifyTrigger(thisParam: any, client: any) {
    thisParam.socketDisconneted = false;
    if (client.action == WebsocketNotificationActionEnum.NewNotificationToPatient)
      thisParam.populateNotification();
  }

  ngOnDestroy() {
    this.websocketNotificationUtilService.clearSocket();
  }

  getPatientAppointment() {
    this.isLoading = true;
    this.patientCenterAppointmentService.getPatientAppointment().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let now = new Date()
        this.listPatientAppointment = response.listPatientAppointment.filter(x => now.getTime() <= new Date(x.datetimeEnd).getTime() && !x.idEpisode).slice(0, 2);
        this.useTelemedicineInPatientCenter = response.useTelemedicineInPatientCenter;
        this.useSelfTriageInPatientCenter = response.useSelfTriageInPatientCenter;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  startAppointment(patientAppointment: PatientAppointment) {
    this.isLoading = true;
    let diff = (this.verifyDifferenceInMinutes(patientAppointment.datetimeStart));
    let newDate = new Date();
    if ((diff <= MinutesEnum.cincoMinutos) && (newDate <= new Date(patientAppointment.datetimeEnd))) {
      this.createAppointmentCallService.GetTokenTwilioVideo(patientAppointment.idPatientAppointment).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          let appointmentData: AppointmentDataStruct = new AppointmentDataStruct;
          appointmentData = response.appointmentDataStruct;
          this.utilsDigitalPromptServiceService.setAppointmentDataToTwilioSession(appointmentData);
          this.utilsDigitalPromptServiceService.setAppointmentDataSession(patientAppointment);
          this.router.navigate([`patient-center/attend-patient/video/${this.uri}`]);

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
    else if (newDate < new Date(patientAppointment.datetimeStart)) {
      var modalText = "Você só pode entrar na consulta, no mínimo, 5 minutos antes do horário agendado."
      this.showOutOfHoursAppointmentModal(modalText);
    }
    else if (newDate > new Date(patientAppointment.datetimeStart)) {
      var modalText = "A consulta já foi encerrada."
      this.showOutOfHoursAppointmentModal(modalText);
    }
  }

  showOutOfHoursAppointmentModal(modalText: string) {
    const dialogRef = this.dialog.open(OutOfHoursAppointmentModalComponent, {
      data: {
        colorCode: this.colorCode,
        text: modalText
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  verifyDifferenceInMinutes(datetimeStart: Date) {
    let todayDateLocal: Date = new Date();
    let todayDateUtc: Date = new Date(todayDateLocal.getTime() + (todayDateLocal.getTimezoneOffset() * 60000));

    let dateStart: Date = new Date(datetimeStart);
    dateStart = new Date(dateStart.getTime() - (this.healthUnitStruct.timeZoneValue * 3600000));

    var diff = (dateStart.getTime() - todayDateUtc.getTime()) / 1000;
    diff /= 60;

    return diff;
  }

  showOrHideOptions() {
    this.showUserOptions = !this.showUserOptions;
  }

  logout() {
    this.authService.logout();
  }

  showDetails(appointment: PatientAppointment) {
    const dialogRef = this.dialog.open(AppointmentDetailsModalComponent, {
      data: {
        colorCode: this.colorCode,
        appointment: appointment,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.cancel)
          this.cancelAppointment(appointment.idPatientAppointment);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  cancelAppointment(idPatientAppointment: number) {
    this.isLoading = true;
    this.patientAppointmentService.deletePatientAppointment(idPatientAppointment, AppointmentStatusEnum.AgendamentoCancelado).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.getPatientAppointment();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  navigatePatientAppointments() {
    this.router.navigate([`patient-center/patient-appointment/${this.uri}`]);
  }

  navigatePatientScheduling() {
    this.isLoading = true;
    this.patientPreAppointmentCenterService.getPatientPreAppointmentData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.patientPreAppointment)
          this.openHasPreAppointmentModal(response);
        else
          this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  navigatePatientAccessTelemedicine() {
    this.isLoading = true;
    this.loadingAccessTelemedicine = true;

    this.patientCenterAccessTelemedicineService.getPatientCenterAccessTelemedicine(this.idUserPatient, this.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.patientStruct = response.patient;
        this.patient = response.userPatient;
        this.telemedicineConfig = response.telemedicineConfig;
        this.utilsTelemedicineService.updatePatientData(this.mapToPatientData());
        this.utilsTelemedicineService.setTelemedicineConfig(response.telemedicineConfig);
        window.open(response.telemedicineConfig.completeUrl +"/patient-center", "_blank");
        this.isLoading = false;
        this.loadingAccessTelemedicine = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  navigatePatientAccessSelfTriage() {
    this.isLoading = true;
    this.loadingAccessSelfTriage = true;

    this.patientCenterAccessSelfTriageService.getPatientCenterAccessSelfTriage(this.idUserPatient, this.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.patientStruct = response.patient;
        this.selfTriageConfig = response.selfTriageConfig;
        this.utilsSelfTriageService.updatePatientData(this.mapToPatient());
        this.utilsSelfTriageService.setSelfTriageConfig(response.selfTriageConfig);
        window.open(response.selfTriageConfig.completeUrl, "_blank");
        this.isLoading = false;
        this.loadingAccessSelfTriage = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  navigatePatientHealthcareAgreement() {
    this.router.navigate([`patient-center/healthcare-agreement/${this.uri}`]);
  }

  openHasPreAppointmentModal(patientPreAppointmentResponse: PatientPreAppointmentResponse) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(HasPreAppointmentModalComponent, {
      data: {
        colorCode: this.colorCode,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.continuePreAppointment) {
          let patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
          patientAppointmentSession.idSchedule = patientPreAppointmentResponse.patientPreAppointment.idSchedule;
          patientAppointmentSession.datetimeStart = patientPreAppointmentResponse.patientPreAppointment.datetimeStart;
          patientAppointmentSession.datetimeEnd = patientPreAppointmentResponse.patientPreAppointment.datetimeEnd;
          patientAppointmentSession.idUser = patientPreAppointmentResponse.patientPreAppointment.idUser;
          patientAppointmentSession.idPatientHealthcareAgreement = patientPreAppointmentResponse.patientPreAppointment.idPatientHealthcareAgreement;
          patientAppointmentSession.idTypeOfCare = patientPreAppointmentResponse.patientPreAppointment.idTypeOfCare;
          patientAppointmentSession.idMedicalSpecialty = patientPreAppointmentResponse.patientPreAppointment.idMedicalSpecialty;
          patientAppointmentSession.healthUnitName = patientPreAppointmentResponse.healthUnitName;
          patientAppointmentSession.medicalSpecialtyName = patientPreAppointmentResponse.medicalSpecialtyName;
          patientAppointmentSession.typeOfCareName = patientPreAppointmentResponse.typeOfCareName;
          patientAppointmentSession.nameHealthcareAgreement = patientPreAppointmentResponse.healthPlanName;
          patientAppointmentSession.userName = patientPreAppointmentResponse.userName;
          patientAppointmentSession.idHealthcareAgreement = patientPreAppointmentResponse.patientPreAppointment.idHealthcareAgreement;
          patientAppointmentSession.idHealthcareAgreementPlan = patientPreAppointmentResponse.patientPreAppointment.idHealthcareAgreementPlan;
          patientAppointmentSession.healthcareAgreementCardNumber = patientPreAppointmentResponse.patientPreAppointment.healthcareAgreementCardNumber;
          patientAppointmentSession.healthcareAgreementExpirationDate = patientPreAppointmentResponse.patientPreAppointment.healthcareAgreementExpirationDate;

          this.router.navigate([`patient-center/scheduling-confirm/${this.uri}`]);
          this.isLoading = false;
        }
        else if (result && !result.continuePreAppointment) {
          this.router.navigate([`patient-center/scheduling-information/${this.uri}`]);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  timeoutLogout() {
    this.alertService.show('Erro', 'Você foi deslogado por inatividade', AlertType.error);
    this.authService.logout();
  }

  openNotification() {
    this.isVisibleNotification = true;
  }

  closeNotification() {
    this.isVisibleNotification = false;
  }

  populateNotification() {
    this.isLoadingNotification = true;
    this.messagePatientCenterService.listNotificationByUser(this.healthUnitStruct.timeZoneValue).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingNotification = false;
          return;
        }

        if (response.listMessagePatientCenter) {
          this.listMessagePatientCenter = response.listMessagePatientCenter;
          this.unreadMessages = response.listMessagePatientCenter.filter(message => !message.isRead).length;
        }

        this.isLoadingNotification = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoadingNotification = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToPatientData(): PatientData {
    // this.patientStruct = null;
    let PatientData: PatientData = {
      birthDate: this.patientStruct?.birthDate,
      city: this.patientStruct?.city,
      cns: this.patientStruct?.cns,
      cpf: this.patientStruct?.cpf,
      email: this.patientStruct?.email,
      idPatientTelemedicineIntegration: null,
      idGender: this.patientStruct?.idGender,
      idHealthUnit: this.telemedicineConfig.idHealthUnit ?? this.selfTriageConfig.idHealthUnit,
      idPatient: this.patientStruct?.idPatient,
      neighborhood: this.patientStruct?.neighborhood,
      patientName: this.patientStruct?.patientName,
      phone: this.patientStruct?.phone1 ? this.patientStruct?.phone1 : this.patientStruct?.phone2,
      state: this.patientStruct?.state,
      zipCode: this.patientStruct?.zipCode,
      street: this.patientStruct?.street,
      houseNumber: this.patientStruct?.houseNumber,
      datetimeinclusion: null,
      healthPlan: null,
      healthPlanExpirationDate: null,
      healthPlanNumber: null,
      idQueue: null,
      patientCenterAccessTelemedicine: true
    };
    console.log(PatientData)
    return PatientData;
  }

  mapToPatient(): Patient {
    let Patient: Patient = {
      birthDate: this.patientStruct.birthDate,
      city: this.patientStruct.city,
      cpf: this.patientStruct.cpf,
      email: this.patientStruct.email,
      idGender: this.patientStruct.idGender,
      idPatient: this.patientStruct.idPatient,
      neighborhood: this.patientStruct.neighborhood,
      patientName: this.patientStruct.patientName,
      phone: this.patientStruct.phone1 ? this.patientStruct.phone1 : this.patientStruct.phone2,
      state: this.patientStruct.state,
      zipCode: this.patientStruct.zipCode,
      street: this.patientStruct.street,
      houseNumber: this.patientStruct.houseNumber,
      datetimeinclusion: null,
      healthInsuranceNumber: null,
      patientCenterAccessSelfTriage: true
    };

    return Patient;
  }

  alterRegister() {
    this.router.navigate([`patient-center/profile-info/${this.uri}`]);
  }
}