import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { PatientPreAppointmentResponse } from "../../responses/orchestrator-schedule/patient-pre-appointment.response";

@Injectable({
    providedIn: 'root'
})
export class PatientPreAppointmentCenterService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getPatientPreAppointmentData(): Observable<PatientPreAppointmentResponse> {

        let uri = `PatientPreAppointmentPatientCenter/getAllByTokenIdUser`;

        return this.httpClient.get<PatientPreAppointmentResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}