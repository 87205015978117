import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListPatientAppointmentResponse } from "../../responses/schedule/list-patient-appointment.response";
import { GetListOfAvailableTimesResponse } from "../../responses/orchestrator-schedule/get-list-of-available-times.response";

@Injectable({
    providedIn: 'root'
})
export class GetListOfAvailableTimesService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public  getListOfAvailableSchedulingTimes(idSpecialty: number, dateToFilter: string): Observable<GetListOfAvailableTimesResponse> {

        let uri = `GetListOfAvailableTimes/idSpecialty/${idSpecialty}/dateToFilter/${dateToFilter}`;

        return this.httpClient.get<GetListOfAvailableTimesResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}