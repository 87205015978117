import { Component, Input, OnInit } from '@angular/core';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() screenStage: number;

  constructor(
    private utilSelfTriaveService: UtilsSelfTriageService,
  ) { }

  public isLoading: boolean = false;

  ngOnInit(): void {

  }

}
