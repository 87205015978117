<div class="container-box">
    <div class="container orientations-session">

        <div class="section-title">
            <h3 [ngStyle]="{'color': colorCode}">Cadastro realizado com sucesso!</h3>
        </div>
        <h2>Você receberá um email com instruções para a ativação de sua conta </h2>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="ok()">
            <span>Ok</span>
        </button>
    </div>
</div>