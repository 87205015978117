<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-modal">
            <h1>{{this.data.title}}</h1>
        </div>
        <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
        <div class="body-modal">
            {{this.data.description}}
        </div>
        <div class="btn-container">
            <button mat-flat-button color="primary" class="button-close-modal" (click)="close()">Ok</button>
        </div>
    </div>
</div>
