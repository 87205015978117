<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Confirme o dado a seguir!</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="search()">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field *ngIf="idLoginPassType == patientTypeBirthDate.valueOf()" appearance="outline">
                        <mat-label>Data de Nascimento: </mat-label>
                        <input matInput [mask]="masks.date" type="text" formControlName="birthDate">
                    </mat-form-field>
                    <mat-form-field *ngIf="idLoginPassType == patientTypeCPF.valueOf()" appearance="outline">
                        <mat-label>CPF: </mat-label>
                        <input matInput [mask]="masks.cpf" type="text" formControlName="CPF">
                    </mat-form-field>
                    <mat-form-field *ngIf="idLoginPassType == patientTypeMotherName.valueOf()" appearance="outline">
                        <mat-label>Nome da mãe: </mat-label>
                        <input matInput type="text" formControlName="motherName">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <button mat-flat-button color="primary" class=" btn-block" type="submit">
                        <span *ngIf="!isLoading">Confirmar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>