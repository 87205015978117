import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { PatientCenterAccessTelemedicineResponse } from "../../responses/orchestrator-telemedicine/patient-center-access-telemedicine.response";

@Injectable({
    providedIn: 'root'
})
export class PatientCenterAccessTelemedicineService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public getPatientCenterAccessTelemedicine(idUserPatient: number, idHealthUnit: number): Observable<PatientCenterAccessTelemedicineResponse> {
        let uri = `PatientCenterAccessTelemedicine/idUserPatient/${idUserPatient}/idHealthUnit/${idHealthUnit}`;

        return this.httpClient.get<PatientCenterAccessTelemedicineResponse>(environment.urlApiOrchestratorTelemedicine + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}