import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Participant, RemoteParticipant, RemoteTrackPublication, RemoteTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

@Component({
    selector: 'app-participant',
    templateUrl: './participant.component.html',
    styleUrls: ['./participant.component.css']
})
export class ParticipantComponent implements OnInit, OnDestroy, OnChanges {
    
    @ViewChild('list') listRef: ElementRef;
    @ViewChild('remoteBody') remoteBody: ElementRef;
    @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
    @Output('toggleMic') toggleMic = new EventEmitter<boolean>();
    @Output('toggleVid') toggleVid = new EventEmitter<boolean>();
    @Output('setDoctorScreenIsLarger') setDoctorScreenIsLarger = new EventEmitter<boolean>();
    @Output('setIsAlone') setIsAlone = new EventEmitter<boolean>();
    @Input('activeRoomName') activeRoomName: string;
    @Input() isVideoOff: boolean = false;
    @Input() isMuted: boolean = false;
    @Input() isMobileVersion: boolean;
    @Input() patientScreenIsLarger: boolean;
    @Input() colorCode: string;
    
    public track: RemoteTrack
    public doctorScreenIsLarger: boolean = true;
    public lastDifferentValueOfIsAlone: boolean;
    
    get participantCount() {
        return !!this.participants ? this.participants.size : 0;
    }
    
    get isAlone() {
        let isAlone: boolean = this.participantCount === 0;
        if(this.lastDifferentValueOfIsAlone !== isAlone){
            this.lastDifferentValueOfIsAlone = isAlone;
            
        }        
        return isAlone;
        // return true;
    }
    
    private participants: Map<Participant.SID, RemoteParticipant>;
    private dominantSpeaker: RemoteParticipant;
    
    constructor(
        private readonly renderer: Renderer2,
        public dialog: MatDialog,
        ) { }
        
        ngOnInit(): void {    
        }    

        ngOnChanges(changes: SimpleChanges): void {
            if (changes.patientScreenIsLarger) {
                if (changes.patientScreenIsLarger.previousValue == undefined ) {
                    return;
                }
                if (changes.patientScreenIsLarger.currentValue !== changes.patientScreenIsLarger.previousValue && changes.patientScreenIsLarger.currentValue == true) {
                    this.changeDoctorScreenToSmaller();
                }
            }
        }
        
        ngOnDestroy() {
            if (this.participants){
                this.participants.forEach(x =>{
                    x.videoTracks.forEach(track=>{
                        track.track.detach().forEach(el => el.remove());
                    })
                });
            this.participants.clear();
            }
        }
        
        clear() {
            if (this.participants) {
                this.participants.clear();
                this.setIsAlone.emit(this.isAlone)
            }
        }
        
        initialize(participants: Map<Participant.SID, RemoteParticipant>) {
            this.participants = participants;
            if (this.participants) {
                this.participants.forEach(participant => this.registerParticipantEvents(participant));
            }
            this.setIsAlone.emit(this.isAlone)
        }
        
        add(participant: RemoteParticipant) {
            if (this.participants && participant) {
                this.participants.set(participant.sid, participant);
                this.registerParticipantEvents(participant);
            }
            this.setIsAlone.emit(this.isAlone)
        }
        
        remove(participant: RemoteParticipant) {
            if (this.participants && this.participants.has(participant.sid)) {
                this.participants.delete(participant.sid);
            }
            this.setIsAlone.emit(this.isAlone)
        }
        
        loudest(participant: RemoteParticipant) {
            this.dominantSpeaker = participant;
        }
        
        toggleMicrophone(){
            this.toggleMic.emit();
        }
        
        toggleVideo(){
            this.toggleVid.emit();
        }
        
        private registerParticipantEvents(participant: RemoteParticipant) {
            if (participant) {
                participant.tracks.forEach(publication => this.subscribe(publication));
                participant.on('trackPublished', publication => this.subscribe(publication));
                participant.on('trackUnpublished',
                publication => {
                    if (publication && publication.track) {
                        this.detachRemoteTrack(publication.track);
                    }
                });
            }
        }
        
        private subscribe(publication: RemoteTrackPublication | any) {
            if (publication && publication.on) {
                publication.on('subscribed',
                (track: RemoteTrack) => {
                    this.attachRemoteTrack(track);
                    this.track = track;
                });
                publication.on('unsubscribed',
                (track: RemoteTrack) => this.detachRemoteTrack(track));
                
            }
        }
        
        private attachRemoteTrack(track: RemoteTrack, teste?: boolean) {
            if (this.isAttachable(track)) {
                if(!this.patientScreenIsLarger){
                    this.doctorScreenIsLarger = true;
                }
                const element = track.attach();
                element.style.transform = 'scale(-1,1)';
                this.renderer.data.id = track.sid;
                if(this.isMobileVersion){
                    this.listRef.nativeElement.setAttribute('style', 'width: 100%; height: 100%; margin-bottom: 0; margin-right: 0; z-index: 0');
                    this.remoteBody.nativeElement.setAttribute('style', 'z-index: 0');
                    if (element.nodeName === "VIDEO"){
                        element.id = "remote-video";
                    }
                    
                    this.renderer.setStyle(element, 'width', '100%');
                    this.renderer.setStyle(element, 'height', '100%');  
                    this.renderer.setStyle(element, 'object-fit', 'cover'); 
                    this.renderer.setStyle(element, 'border-radius', '0');      
                }
                else{
                    this.renderer.setStyle(element, 'height', '100%');  
                    this.renderer.setStyle(element, 'width', '100%'); 
                    this.renderer.setStyle(element, 'border-radius', '10px');   
                }          
                
                this.renderer.appendChild(this.listRef.nativeElement, element);
                this.participantsChanged.emit(true);
            }
        }
        
        private detachRemoteTrack(track: RemoteTrack) {
            if (this.isDetachable(track)) {
                track.detach().forEach(el => el.remove());
                this.participantsChanged.emit(true);
            }
        }
        
        private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
            return !!track &&
            ((track as RemoteAudioTrack).attach !== undefined ||
            (track as RemoteVideoTrack).attach !== undefined);
        }
        
        private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
            return !!track &&
            ((track as RemoteAudioTrack).detach !== undefined ||
            (track as RemoteVideoTrack).detach !== undefined);
        }
        
        async changeDoctorScreenToLarger(){
            if(this.isMobileVersion){                
                if(!this.doctorScreenIsLarger){
                    const videoElement = document.getElementById('remote-video');
                    this.listRef.nativeElement.setAttribute('style', 'width: 100%; height: 100%; margin-bottom: 0; margin-right: 0; z-index: 0');
                    this.remoteBody.nativeElement.setAttribute('style', 'z-index: 0');
                    videoElement.style.transform = 'scale(-1,1)'; 
                    this.renderer.setStyle(videoElement, 'width', '100%');
                    this.renderer.setStyle(videoElement, 'height', '100%');  
                    this.renderer.setStyle(videoElement, 'object-fit', 'cover'); 
                    this.renderer.setStyle(videoElement, 'border-radius', '10px');     
                    this.renderer.appendChild(this.listRef.nativeElement,videoElement);
                    
                    this.doctorScreenIsLarger = true;
                    this.setDoctorScreenIsLarger.emit(this.doctorScreenIsLarger);
                }
            }     
        }
        
        async changeDoctorScreenToSmaller(){
            if(this.isMobileVersion){                
                if(this.doctorScreenIsLarger)  
                {   
                    this.doctorScreenIsLarger = false;
                    const videoElement = document.getElementById('remote-video');
                    this.listRef.nativeElement.setAttribute('style', 'width: 20%; min-width: 140px; margin-bottom: 63px; margin-right: 10px; z-index: 1');  
                    this.remoteBody.nativeElement.setAttribute('style', 'z-index: 1');      
                    videoElement.style.transform = 'scale(-1,1)';
                    this.renderer.setStyle(videoElement, 'width', '100%');
                    this.renderer.setStyle(videoElement, 'height', '100%');   
                    this.renderer.setStyle(videoElement, 'border-radius', '10px');       
                    this.renderer.appendChild(this.listRef.nativeElement, videoElement); 
                }    
            }
        }
    }