<div>
    <app-header></app-header>
    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            
            <div class="white-container">
    
                <h1 class="patient-center-title">Tudo certo!</h1>
                <img class="end-img" src="assets/images/patient-center/scheduling-end.png" />
                <hr>
    
                <h2 class="patient-center-subtitle">Seu agendamento foi realizado com sucesso!</h2>
    
                <button mat-flat-button color="primary" class="button-submit" (click)="clickGo()">
                    <span *ngIf="isLoading == false">VOLTAR À TELA INICIAL</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button type="button" color="secundary" class="button-back" (click)="navigatePatientScheduling()">
                    <span *ngIf="isLoading == false">AGENDAR OUTRA CONSULTA</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>   
                <div class="footer-white-container">
                    <p>Central do paciente</p>
                </div>
            </div>
    
            
        </div>
    </body>
</div>