<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
        <div class="title-modal">
            <h1>{{this.data.title}}</h1>
        </div>
        <div class="body-modal">
            {{this.data.description}}
        </div>
        <div class="btn-container-modal">
            <div *ngIf="this.isLastWarning" class="col-12">
                <button mat-flat-button color="primary" class="button-close" (click)="close()">Ok</button>
            </div>
            <div *ngIf="!this.isLastWarning" class="col-12 col-md-5">
                <button mat-flat-button color="secundary" class="button-finish" (click)="close()">Encerrar a consulta</button>
            </div>
            <div *ngIf="!this.isLastWarning" class="col-12 col-md-5">
                <button mat-flat-button color="primary" class="button-wait" (click)="keepWaiting()">Aguardar o médico</button>
            </div>
        </div>
    </div>
</div>
