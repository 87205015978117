import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validateBirthDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const value = control.value;
        
        if (control.value != null) {
            const value = control.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
            if (!value)
                return null;
            
            // Verifica se a data é válida
            const date = new Date(value.split('/').reverse().join('/'));
            if (!isNaN(date.getTime())) {
                // Verifica se a data é menor ou igual à data atual
                if (date <= new Date() && date >= new Date("01/01/1500"))
                    return null;
            }
        }
    }
}