<div>
    <app-header></app-header>
    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">
    
                <h1 class="self-triage-title">Veja se não é uma emergência</h1>
                <hr>
                <h1 class="emergency-warning-title">{{this.emergency}}</h1>
                <div class="emergency-warning-description">
                    <pre><div class="symptoms">{{this.symptoms}}</div></pre>
                </div>
    
                <button mat-flat-button color="primary" class="button-go" (click)="clickGo()">
                    <span *ngIf="isLoading == false">Não tenho nenhum desses</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="secundary" class="button-back" (click)="clickBack()">
                    <span *ngIf="isLoading == false">Encerrar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
    
                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>
    
            </div>
            
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>
        </div>
    </body>
</div>