import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.cns = "0000 0000 0000 000";
    masks.creditCardNumber = "0000 0000 0000 0000,";
    masks.date = "00/00/0000";
    masks.monthYear = "00/0000";
    masks.age = "000a00m00d";
    masks.temperature = "00,0";
    masks.pressure = "000/000||00/00||000/00";
    masks.threeDigits = "000||00";
    masks.height = "0,00";
    masks.cnes = "0000000";
    masks.time = "00:00";
    masks.cep = "00000-000";
    return masks;
  }

  clearMaskPhone(val: string): string {
    return val.replace('(', '').replace(')', '').replace(' ', '');
  }

  clearMaskCpf(val: string): string {
    return val.replace('.', '').replace('.', '').replace('-', '');
  }

  clearMaskCreditCardNumber(val: string): string {
    return val.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '');
  }

  clearMaskDate(val: string): Date {
    let aux: string[] = val.split('/');
    return new Date(aux[2] + ' ' + aux[1] + ' ' + aux[0]);
  }

  formatStringToDate(dateUnformatted: string): Date {
    if (dateUnformatted) {                 //  0    1    2    3    4    5    6    7
      let dateString = dateUnformatted.toString().replaceAll("/", "");
      let darr = dateString.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]
      let dobj = new Date(parseInt(darr[4] + darr[5] + darr[6] + darr[7]), parseInt(darr[2] + darr[3]) - 1, parseInt(darr[0] + darr[1]));
      return dobj;
    }
    return null;
  }

  formatDateToString(dateToFormat: Date, needSeparator: boolean = true, americanStyle: boolean = false): string {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      if (americanStyle) {
        return anoF + "-" + mesF + "-" + diaF;
      } else if (needSeparator) {
        return diaF + "/" + mesF + "/" + anoF;
      } else {
        return diaF + mesF + anoF;
      }
    }
    return null;
  }
}

export class Masks {
  phone;
  cpf;
  cnpj;
  creditCardNumber;
  date;
  temperature;
  pressure;
  threeDigits;
  age;
  height;
  monthYear;
  cnes;
  cns;
  time;
  cep;
}