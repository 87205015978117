import { Component, OnInit } from '@angular/core';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(private utilSelfTriageService: UtilsSelfTriageService,
    public sanitizer: DomSanitizer,
  ) { }

  public isLoading: boolean = false;
  public isLogoUploaded: boolean = false;
  public patientCenterAccessSelfTriage: boolean = false;
  public useTolifeLogo: boolean = false;

  public colorCode: string = "#d4d4d4";
  public logoString64: string;

  public selfTriageConfig: SelfTriageConfigStruct;
  public clientLogo: any;
  public sanitezedLogo: any;

  ngOnInit(): void {
    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);

    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();
    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();
    else
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();

    if (this.selfTriageConfig.isLogoUploaded == true) {
      this.isLogoUploaded = true;
      this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.selfTriageConfig.logoString64}`);
    }
    else if (this.selfTriageConfig.useColor == true)
      this.colorCode = this.selfTriageConfig.colorCode;

    this.useTolifeLogo = this.selfTriageConfig.useTolifeLogo;
  }
}