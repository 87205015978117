import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingListComponent } from './components/loading-list/loading-list.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { AlertHeaderComponent } from './components/alert-header/alert-header.component';
import { SelectComponent } from './components/select/select.component';
import { Select2Component } from './components/select/select2/select2.component';
import { TwilioVideoComponent } from './components/twilio-video/twilio-video.component';
import { CameraComponent } from './components/twilio-video/pages/camera/camera.component';
import { ParticipantComponent } from './components/twilio-video/pages/participant/participant.component';
import { SettingsComponent } from './components/twilio-video/pages/settings/settings.component';
import { DeviceSelectComponent } from './components/twilio-video/pages/settings/device-select/device-select.component';
import { EvadePatientModalComponent } from './components/evade-patient-modal/evade-patient-modal.component';
import { LeaveRoomConfirmModalComponent } from './components/twilio-video/pages/participant/leave-room-confirm-modal/leave-room-confirm-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatDialogModule } from '@angular/material/dialog';
import { AttendPatientEndModalComponent } from './components/attend-patient-end-modal/attend-patient-end-modal.component';
import { WarnPatientDoctorEvadeComponent } from './components/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { DeleteItemModalComponent } from './components/delete-item-modal/delete-item-modal.component';
import { OutOfHoursAppointmentModalComponent } from './components/out-of-hours-appointment-modal/out-of-hours-appointment-modal.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AdditionalDataComponent } from './components/additional-data/additional-data.component';
import { CancelModalComponent } from './components/cancel-modal/cancel-modal.component';
import { EmergencyCheckComponent } from './components/emergency-check/emergency-check.component';
import { ExternalSearchComponent } from './components/external-search/external-search.component';
import { OrientationsComponent } from './components/orientations/orientations.component';
import { PatientIdentificationComponent } from './components/patient-identification/patient-identification.component';
import { LgpdModalComponent } from './components/patient-identification/lgpd-modal/lgpd-modal.component';
import { PreEvaluationComponent } from './components/pre-evaluation/pre-evaluation.component';
import { TelemedicinePaginationComponent } from './components/telemedicine-pagination/telemedicine-pagination.component';
import { WaitingAreaComponent } from './components/waiting-area/waiting-area.component';
import { PatientHoldModalComponent } from './components/waiting-area/patient-hold-modal/patient-hold-modal.component';
import { RemakeQueueModalComponent } from './components/waiting-area/remake-queue-modal/remake-queue-modal.component';
import { WelcomeTelemedicineComponent } from './components/welcome-telemedicine/welcome-telemedicine.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    LoadingListComponent,
    LoadingProgressBarComponent,
    AlertHeaderComponent,
    SelectComponent,
    Select2Component,
    TwilioVideoComponent,
    CameraComponent,
    ParticipantComponent,
    SettingsComponent,
    DeviceSelectComponent,
    EvadePatientModalComponent,
    LeaveRoomConfirmModalComponent,
    AttendPatientEndModalComponent,
    WarnPatientDoctorEvadeComponent,
    DeleteItemModalComponent,
    OutOfHoursAppointmentModalComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    AdditionalDataComponent,
    CancelModalComponent,
    EmergencyCheckComponent,
    ExternalSearchComponent,
    OrientationsComponent,
    PatientIdentificationComponent,
    LgpdModalComponent,
    PreEvaluationComponent,
    TelemedicinePaginationComponent,
    WaitingAreaComponent,
    PatientHoldModalComponent,
    RemakeQueueModalComponent,
    WelcomeTelemedicineComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NgxMatFileInputModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    CdkAccordionModule,
    MatDialogModule,
    MatAutocompleteModule,
    CommonModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    CdkAccordionModule,
    MatProgressBarModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    LoadingListComponent,
    LoadingProgressBarComponent,
    AlertHeaderComponent,
    SelectComponent,
    Select2Component,
    TwilioVideoComponent,
    WarnPatientDoctorEvadeComponent,
    DeleteItemModalComponent,
    OutOfHoursAppointmentModalComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    AdditionalDataComponent,
    CancelModalComponent,
    EmergencyCheckComponent,
    ExternalSearchComponent,
    OrientationsComponent,
    PatientIdentificationComponent,
    PreEvaluationComponent,
    TelemedicinePaginationComponent,
    WaitingAreaComponent,
    WelcomeTelemedicineComponent,
  ],
})
export class SharedModule { }
