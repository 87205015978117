import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PatientDataRequest } from '../../requests/orchestrator-telemedicine/patient-data.request';
import { GenerateTelemedicineEpisodeResponse } from '../../responses/orchestrator-telemedicine/generate-telemedicine-episode.response';

@Injectable({
    providedIn: 'root'
})

export class EpisodeService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public createEpisode(patientDataRequest: PatientDataRequest, idHealthUnit: number, idNatureOfAttendance: number): Observable<GenerateTelemedicineEpisodeResponse>{
    let uri = `Episode/idHealthUnit/${idHealthUnit}/idNatureOfAttendance/${idNatureOfAttendance}`;
    
    return this.httpClient.post<GenerateTelemedicineEpisodeResponse>(environment.urlApiOrchestratorTelemedicine + uri, patientDataRequest)
    .pipe(
      catchError(this.handleError)
    )
  }
}