<div class="body-container" [ngStyle]="{'background-color': colorCode}">
    <div class="body-content" [ngStyle]="{'background-color': colorCode}">
        <div class="white-container">
            <div class="section-title" [ngStyle]="{'color': colorCode}">
                <h2>Veja se não é uma emergência</h2>
            </div>
            <div class="section-subtitle" *ngIf="!customOrientations">
                Procure atendimento presencial urgente se você apresentar algum destes sintomas:
            </div>
            <div class="section-subtitle" *ngIf="customOrientations">
                {{orientationsTitle}}
            </div>
            <div class="emergency-check-description">
                <ul *ngIf="!customOrientations">
                    <li>Chiado, falta de ar ou dificuldade de respirar</li>
                    <li>Dor no peito</li>
                    <li>Fraturas deslocadas ou abertas da ferida</li>
                    <li>Desmaios ou tonturas</li>
                    <li>Dormência ou fraqueza repentina</li>
                    <li>Sangramento que não pode ser parado</li>
                    <li>Dor abdominal - especialmente dor intensa localizada</li>
                    <li>Febre com convulsões ou qualquer febre em crianças menores de 3 meses</li>
                    <li>Confusão ou alterações no estado mental</li>
                    <li>Tosse ou vômito com sangue</li>
                    <li>Dor de cabeça severa ou lesão na cabeça, especialmente se o indivíduo estiver usando aspirina ou
                        anticoagulantes</li>
                    <li>Sangue na urina ou diarréia com sangue</li>
                    <li>Súbita incapacidade de falar, ver, andar ou se mover</li>
                </ul>
            </div>

            <div *ngIf="customOrientations" class="emergency-check-description">
                <div class="symptoms">{{this.orientationsDescription}}</div>
            </div>

            <div class="button-container">
                <button mat-flat-button class="forward-button" type="button" (click)="forward()">
                    <span>NÃO TENHO NENHUM DESSES</span>
                </button>
                <button mat-flat-button class="btn-secundary-flex" type="button" (click)="finish()">ENCERRAR</button>
                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Telemedicina by ToLife®</p>
                </div>
            </div>
        </div>
        <app-telemedicine-pagination [flowControl]="this.flowEnum"></app-telemedicine-pagination>
    </div>

</div>