   
<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h1>{{this.data.messagePatientCenter.messagePatientCenterTitle}}</h1>
        </div>
            
        <div class="body-modal">            
            <h3>Mensagem</h3>
            <p>{{this.data.messagePatientCenter.messagePatientCenterContent}}</p>
        </div>
        <div class="footer-modal">            
            <button mat-flat-button color="primary" class="btn-block btn-confirm" (click)="close()">
                <span>Voltar</span>
            </button>
        </div>
    </div>
</div>

