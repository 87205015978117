import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { PatientCenterAccessSelfTriageResponse } from "../../responses/orchestrator-patient/patient-center-access-self-triage.response";

@Injectable({
    providedIn: 'root'
})
export class PatientCenterAccessSelfTriageService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public getPatientCenterAccessSelfTriage(idUserPatient: number, idHealthUnit: number): Observable<PatientCenterAccessSelfTriageResponse> {
        let uri = `PatientCenterAccessSelfTriage/idUserPatient/${idUserPatient}/idHealthUnit/${idHealthUnit}`;

        return this.httpClient.get<PatientCenterAccessSelfTriageResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}