import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MedicalSpecialtyPatientCenterService } from 'src/app/shared/services/API/digital-prompt-service/medical-specialty-patient-center.service';
import { LookupMedicalRecordService } from 'src/app/shared/services/API/medical-record/lookup-medical-record.service';
import { EligibilityCheckPatientScheduleService } from 'src/app/shared/services/API/orchestrator-schedule/eligibility-check-patient-schedule.service';
import { PatientHealthcareAgreementService } from 'src/app/shared/services/API/orchestrator-schedule/patient-healthcare-agreement.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { NatureOfAttendance } from 'src/app/shared/services/models/medical-record/nature-of-attendance';
import { EligibilityCheckPatientScheduleRequest } from 'src/app/shared/services/requests/orchestrator-schedule/eligibility-check-patient-schedule.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { MedicalSpecialtyCompletedStruct } from 'src/app/shared/services/structs/digital-prompt-service/medical-specialty-completed.struct';
import { MedicalSpecialtyStruct } from 'src/app/shared/services/structs/digital-prompt-service/medical-specialty.struct';
import { EligibilityCheckPatientScheduleWebhookStruct } from 'src/app/shared/services/structs/orchestrator-schedule/eligibility-check-patient-schedule-webhook.struct';
import { PatientHealthcareAgreementStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-healthcare-agreement.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { PatientAppointmentSessionStruct } from 'src/app/shared/structs/patient-center/patient-appointment-session.struct';

@Component({
  selector: 'app-scheduling-information',
  templateUrl: './scheduling-information.component.html',
  styleUrls: ['./scheduling-information.component.css']
})
export class SchedulingInformationComponent implements OnInit {
  constructor(
    public router: Router,
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private lookupMedicalRecordService: LookupMedicalRecordService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private patientHealthcareAgreementService: PatientHealthcareAgreementService,
    private medicalSpecialtyPatientCenterService: MedicalSpecialtyPatientCenterService,
    private healthUnitService: HealthUnitService,
    private eligibilityCheckPatientScheduleService: EligibilityCheckPatientScheduleService,
  ) { }

  public model: FormGroup;
  public masks: Masks = this.maskService.getMasks();
  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public colorCode: string = '#99C8D6';
  public uri: string = this.activatedRoute.snapshot.paramMap.get('uri');
  public digitalPromptServiceConfig: DigitalPromptConfigStruct;
  public listPatientHealthcareAgreement: PatientHealthcareAgreementStruct[] = [];
  public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listMedicalSpecialty: MedicalSpecialtyStruct[] = [];
  public listMedicalSpecialtyCompleted: MedicalSpecialtyCompletedStruct[] = [];
  public listNatureOfAttendance: NatureOfAttendance[] = [];
  public listNatureOfAttendanceToDisplay: NatureOfAttendance[] = [];
  public healthUnitName: string;
  public patientAppointmentSession: PatientAppointmentSessionStruct = new PatientAppointmentSessionStruct;
  public isCheckedNoHealthPlan: boolean = false;

  ngOnInit(): void {
    this.digitalPromptServiceConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();
    this.patientAppointmentSession = this.utilsDigitalPromptServiceService.getSchedulingInformations();

    if (!this.digitalPromptServiceConfig)
      this.router.navigate([`digital-prompt-service/${this.utilsDigitalPromptServiceService.getUri()}`]);

    if (this.digitalPromptServiceConfig && this.digitalPromptServiceConfig.useColor) {
      this.colorCode = this.digitalPromptServiceConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
    }

    if (!this.patientAppointmentSession)
      this.patientAppointmentSession = new PatientAppointmentSessionStruct();

    this.model = this.formBuilder.group({
      idPatientHealthcareAgreement: [null],
      idSpecialty: [null, [Validators.required]],
      idNatureOfAttendance: [{ value: null, disabled: true }, [Validators.required]],
    });

    if (this.digitalPromptServiceConfig.allowSelectionHealthcareAgreement) {
      this.model.get('idPatientHealthcareAgreement').setValidators([Validators.required]);
      this.model.get('idSpecialty').disable();
      this.model.updateValueAndValidity();
    }

    this.getHealthUnitDate();
    this.getNatureOfAttendance();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertDynamic('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.patientAppointmentSession.idMedicalSpecialty = this.model.get('idSpecialty').value;
    this.patientAppointmentSession.idTypeOfCare = this.model.get('idNatureOfAttendance').value;
    this.patientAppointmentSession.idPatientHealthcareAgreement = this.model.get('idPatientHealthcareAgreement').value;
    this.patientAppointmentSession.healthUnitName = this.healthUnitName;
    this.patientAppointmentSession.typeOfCareName = this.listNatureOfAttendance.find(nature => nature.idNatureOfAttendance == this.patientAppointmentSession.idTypeOfCare).natureOfAttendanceName;
    this.patientAppointmentSession.isCheckedNoHealthPlan = this.isCheckedNoHealthPlan;

    if (this.model.get('idPatientHealthcareAgreement') && this.model.get('idPatientHealthcareAgreement').value) {
      let request: EligibilityCheckPatientScheduleRequest = new EligibilityCheckPatientScheduleRequest();
      let planHealthCare = this.listPatientHealthcareAgreement.find(x => x.idPatientHealthcareAgreement == this.model.get('idPatientHealthcareAgreement').value);
      let medicalSpecialtyStruct = this.listMedicalSpecialty.find(x => x.medicalSpecialty.idMedicalSpecialty == this.model.get('idSpecialty').value)
      request.body = new EligibilityCheckPatientScheduleWebhookStruct();
      request.body.planName = planHealthCare.planName;
      request.body.nameHealthcareAgreement = planHealthCare.nameHealthcareAgreement;
      request.body.idMedicalSpecialtyFederalCouncilMedicine = medicalSpecialtyStruct.medicalSpecialty.idMedicalSpecialtyFederalCouncilMedicine;
      let dateString = this.maskService.formatDateToString(planHealthCare.healthcareAgreementExpirationDate);
      request.body.healthcareAgreementExpirationDate = this.maskService.formatStringToDate(dateString);
      request.body.healthcareAgreementCardNumber = planHealthCare.healthcareAgreementCardNumber;

      this.eligibilityCheckPatient(request, planHealthCare.idUserPatient);
    }
    else {
      this.router.navigate([`patient-center/scheduling-date/${this.utilsDigitalPromptServiceService.getUri()}`]);

      this.utilsDigitalPromptServiceService.setSchedulingInformations(this.patientAppointmentSession);
      this.isLoading = false;
    }
  }

  backBegin() {
    this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  onRedirectToHealthcareAgreement() {
    this.patientAppointmentSession.idMedicalSpecialty = this.model.get('idSpecialty').value;
    this.patientAppointmentSession.idTypeOfCare = this.model.get('idNatureOfAttendance').value;
    this.patientAppointmentSession.idPatientHealthcareAgreement = this.model.get('idPatientHealthcareAgreement').value;
    this.patientAppointmentSession.isCheckedNoHealthPlan = this.isCheckedNoHealthPlan;

    this.utilsDigitalPromptServiceService.setSchedulingInformations(this.patientAppointmentSession);
  }

  getHealthUnitDate() {
    this.isLoading = true;
    this.healthUnitService.GetHealthUnitCity().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.healthUnitName = response.healthUnitStruct.healthUnitName;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPatientHealthcareAgreement() {
    this.isLoading = true;
    this.patientHealthcareAgreementService.getAllPatientHealthcareAgreementByIdPatient().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPatientHealthcareAgreement = response.listPatientHealthcareAgreementStruct;
        this.isLoading = false;

        this.mapToCompletedPatientHealthcareAgreementStruct();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    })
  }

  getAllMedicalSpecialty(allowSelectionHealthcareAgreement: boolean) {
    this.isLoading = true;
    this.medicalSpecialtyPatientCenterService.listMedicalSpecialty().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.listMedicalSpecialty && response.listMedicalSpecialty.length == 0) {
          this.alertDynamic('Erro', "Não existem especialidades médicas cadastradas", AlertType.error);
          return;
        }

        this.listMedicalSpecialty = response.listMedicalSpecialty;

        if (allowSelectionHealthcareAgreement)
          this.getAllPatientHealthcareAgreement();
        else
          this.filterOptionMedicalSpecialtyStruct(allowSelectionHealthcareAgreement, null);

        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getNatureOfAttendance() {
    this.isLoading = true;
    this.lookupMedicalRecordService.listNatureOfAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.listNatureOfAttendance && response.listNatureOfAttendance.length == 0) {
          this.alertDynamic('Erro', "A lista contendo a natureza do atendimento está vazia.", AlertType.error);
          return;
        }

        this.listNatureOfAttendance = response.listNatureOfAttendance;
        this.isLoading = false;

        this.getAllMedicalSpecialty(this.digitalPromptServiceConfig.allowSelectionHealthcareAgreement);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToCompletedPatientHealthcareAgreementStruct() {
    this.isLoading = true;

    if (this.patientAppointmentSession.isCheckedNoHealthPlan) {
      this.isCheckedNoHealthPlan = true;
      this.disablePatientHealthcareAgreement();
      this.filterOptionMedicalSpecialtyStruct(false, null);
    }
    else if (this.patientAppointmentSession.idPatientHealthcareAgreement && this.listPatientHealthcareAgreement && this.listPatientHealthcareAgreement.length > 0) {
      let patientHealthcareAgreementInSession = new PatientHealthcareAgreementStruct();
      patientHealthcareAgreementInSession = this.listPatientHealthcareAgreement.find(item => item.idPatientHealthcareAgreement == this.patientAppointmentSession.idPatientHealthcareAgreement);

      if (patientHealthcareAgreementInSession) {
        this.model.get('idPatientHealthcareAgreement').setValue(this.patientAppointmentSession.idPatientHealthcareAgreement);
        this.filterOptionMedicalSpecialtyStruct(true, patientHealthcareAgreementInSession);
      }
    }

    this.isFirstLoading = false;
    this.isLoading = false;
  }

  filterOptionMedicalSpecialtyStruct(allowSelectionHealthcareAgreement: boolean, patientHealthcareAgreement: PatientHealthcareAgreementStruct) {
    this.isLoading = true;
    let listAuxMedicalSpecialtyCompleted = [];
    this.enableSpecialty();
    this.disableNatureOfAttendance();

    this.listMedicalSpecialty.forEach((item) => {
      let medicalSpecialtyCompleted = new MedicalSpecialtyCompletedStruct();
      medicalSpecialtyCompleted.medicalSpecialty = item.medicalSpecialty;
      medicalSpecialtyCompleted.listNatureOfAttendance = this.listNatureOfAttendance.filter((item1) => item.listNatureOfAttendance.some(item2 => item1.idNatureOfAttendance == item2));

      if (allowSelectionHealthcareAgreement && patientHealthcareAgreement) {
        medicalSpecialtyCompleted.hasTheSelectedHealthcareAgreement = (item.listIdHealthcareAgreement.includes(patientHealthcareAgreement.idHealthcareAgreement)
          && (!item.listIdHealthcareAgreementPlan || item.listIdHealthcareAgreementPlan.length == 0 || item.listIdHealthcareAgreementPlan.includes(patientHealthcareAgreement.idHealthcareAgreementPlan)));
        this.patientAppointmentSession.nameHealthcareAgreement = patientHealthcareAgreement.nameHealthcareAgreement;
        this.patientAppointmentSession.idHealthcareAgreement = patientHealthcareAgreement.idHealthcareAgreement;
        this.patientAppointmentSession.idHealthcareAgreementPlan = patientHealthcareAgreement.idHealthcareAgreementPlan;
        this.patientAppointmentSession.healthcareAgreementCardNumber = patientHealthcareAgreement.healthcareAgreementCardNumber;
        this.patientAppointmentSession.healthcareAgreementExpirationDate = patientHealthcareAgreement.healthcareAgreementExpirationDate;
      }
      else medicalSpecialtyCompleted.hasTheSelectedHealthcareAgreement = true;

      listAuxMedicalSpecialtyCompleted.push(medicalSpecialtyCompleted);

    });

    this.listMedicalSpecialtyCompleted = [...listAuxMedicalSpecialtyCompleted];

    if (this.patientAppointmentSession.idMedicalSpecialty && this.isFirstLoading) {
      let medicalSpecialtyInTheSession = new MedicalSpecialtyCompletedStruct();
      medicalSpecialtyInTheSession = this.listMedicalSpecialtyCompleted.find(item => item.medicalSpecialty.idMedicalSpecialty == this.patientAppointmentSession.idMedicalSpecialty && item.hasTheSelectedHealthcareAgreement);

      if (medicalSpecialtyInTheSession) {
        this.model.get('idSpecialty').setValue(this.patientAppointmentSession.idMedicalSpecialty);
        this.filterOptionNatureOfAttendanceSelect(medicalSpecialtyInTheSession);
      }
    }

    this.isLoading = false;
  }

  filterOptionNatureOfAttendanceSelect(medicalSpecialtyCompleted: MedicalSpecialtyCompletedStruct) {
    this.isLoading = true;
    this.listNatureOfAttendanceToDisplay = this.listNatureOfAttendance.filter((item1) => medicalSpecialtyCompleted.listNatureOfAttendance.some(item2 => item1.idNatureOfAttendance == item2.idNatureOfAttendance));
    this.patientAppointmentSession.medicalSpecialtyName = medicalSpecialtyCompleted.medicalSpecialty.medicalSpecialtyName;
    this.enableNatureOfAttendance();

    if (this.listNatureOfAttendanceToDisplay && this.listNatureOfAttendanceToDisplay.length == 0) {
      this.alertService.show('Erro', "Erro na disponibilização da natureza de atendimento da especialidade escolhida", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.patientAppointmentSession.idTypeOfCare && this.isFirstLoading) {
      let natureOfAttendanceInTheSession = new NatureOfAttendance();
      natureOfAttendanceInTheSession = this.listNatureOfAttendanceToDisplay?.find(item => item.idNatureOfAttendance == this.patientAppointmentSession.idTypeOfCare);

      if (natureOfAttendanceInTheSession)
        this.model.get('idNatureOfAttendance').setValue(natureOfAttendanceInTheSession.idNatureOfAttendance);
    }

    this.isLoading = false;
  }

  checkNoHealthPlanSelection(isChecked: boolean) {
    this.isLoading = true;
    this.isCheckedNoHealthPlan = isChecked;

    if (isChecked) {
      this.disablePatientHealthcareAgreement();
      this.filterOptionMedicalSpecialtyStruct(false, null);
    }
    else {
      this.enablePatientHealthcareAgreement();
      this.disableSpecialty();
      this.disableNatureOfAttendance();
    }

    this.patientAppointmentSession.nameHealthcareAgreement = "";
    this.isLoading = false;
  }

  verifyPatientHasHealthPlanToEmitAlert() {
    if (!this.listPatientHealthcareAgreement || this.listPatientHealthcareAgreement.length == 0)
      this.alertService.show('Alerta', "Você não possui convênio cadastrado.", AlertType.warning);
  }

  disableNatureOfAttendance() {
    this.model.get('idNatureOfAttendance').disable();
    this.model.get('idNatureOfAttendance').setValue(null);
    this.model.get('idNatureOfAttendance').markAsPristine();
    this.model.get('idNatureOfAttendance').markAsUntouched();
    this.model.get('idNatureOfAttendance').updateValueAndValidity();
  }

  enableNatureOfAttendance() {
    this.model.get('idNatureOfAttendance').enable();
    this.model.get('idNatureOfAttendance').setValue(null);
    this.model.get('idNatureOfAttendance').markAsPristine();
    this.model.get('idNatureOfAttendance').markAsUntouched();
    this.model.get('idNatureOfAttendance').updateValueAndValidity();
  }

  disablePatientHealthcareAgreement() {
    this.model.get('idPatientHealthcareAgreement').disable();
    this.model.get('idPatientHealthcareAgreement').setValue(null);
    this.model.get('idPatientHealthcareAgreement').markAsPristine();
    this.model.get('idPatientHealthcareAgreement').markAsUntouched();
    this.model.get('idPatientHealthcareAgreement').updateValueAndValidity();
  }

  enablePatientHealthcareAgreement() {
    this.model.get('idPatientHealthcareAgreement').enable();
    this.model.get('idPatientHealthcareAgreement').setValue(null);
    this.model.get('idPatientHealthcareAgreement').markAsPristine();
    this.model.get('idPatientHealthcareAgreement').markAsUntouched();
    this.model.get('idPatientHealthcareAgreement').updateValueAndValidity();
  }

  disableSpecialty() {
    this.model.get('idSpecialty').disable();
    this.model.get('idSpecialty').setValue(null);
    this.model.get('idSpecialty').markAsPristine();
    this.model.get('idSpecialty').markAsUntouched();
    this.model.get('idSpecialty').updateValueAndValidity();
  }

  enableSpecialty() {
    this.model.get('idSpecialty').enable();
    this.model.get('idSpecialty').setValue(null);
    this.model.get('idSpecialty').markAsPristine();
    this.model.get('idSpecialty').markAsUntouched();
    this.model.get('idSpecialty').updateValueAndValidity();
  }

  eligibilityCheckPatient(request: EligibilityCheckPatientScheduleRequest, idUserPatient: number) {
    this.eligibilityCheckPatientScheduleService.EligibilityCheckPatient(request, idUserPatient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.isEligible) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
              message: 'Sua carteirinha foi validada com sucesso. Você pode prosseguir com a seleção do horário para a consulta.',
              title: 'Sucesso!',
              colorCode: this.colorCode,
              btnDeny: 'Voltar',
              btnConfirm: 'Avançar'
            },
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              if(result && result.confirm) {
                this.router.navigate([`patient-center/scheduling-date/${this.utilsDigitalPromptServiceService.getUri()}`]);
                this.utilsDigitalPromptServiceService.setSchedulingInformations(this.patientAppointmentSession);
              }
              this.endLoading();
            }
          });
        }
        else {
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: {
              description: 'Não foi possível validar sua carteirinha. Por favor, verifique as informações fornecidas e tente novamente.',
              colorCode: this.colorCode
            },
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              this.endLoading();
              return;
            }
          });
        }
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  formatDateBack(dateUnformatted: string) {
    if (dateUnformatted) {
      dateUnformatted = dateUnformatted.replaceAll("/", "");
      let dobj = new Date(parseInt(dateUnformatted.substring(4, 8)), parseInt(dateUnformatted.substring(2, 4)) - 1, parseInt(dateUnformatted.substring(0, 2)));
      return dobj;
    }

    return null;
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
    this.isLoading = false;
  }
}