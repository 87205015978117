import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetPatientEpisodeQueueResponse } from '../../responses/orchestrator-telemedicine/get-patient-episode-queue.response';

@Injectable({
  providedIn: 'root'
})
export class PatientEpisodeQueueService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getPatientEpisodeQueueByCPF(cpf: string, idHealthUnit: number): Observable<GetPatientEpisodeQueueResponse> {
    let uri = `PatientEpisodeQueue/cpf/${cpf}/idHealthUnit/${idHealthUnit}`;

    return this.httpClient.get<GetPatientEpisodeQueueResponse>(environment.urlApiOrchestratorTelemedicine + uri)
      .pipe(catchError(this.handleError));
  }
}