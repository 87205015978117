import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LoginCheckPatientRequest } from '../../requests/orchestrator-queue/login-check-patient.request';
import { StatusQueueRequest } from '../../requests/queue/status-queue.request';
import { GetPatientResponse } from '../../responses/orchestrator-telemedicine/get-patient.response';

@Injectable({
    providedIn: 'root'
})

export class PatientAccessLeavingService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getIdPatientFromGuid(login: LoginCheckPatientRequest): Observable<GetPatientResponse>{

    let uri = `PatientAccessLeaving`;
  
    return this.httpClient.get<GetPatientResponse>(environment.urlApiOrchestratorTelemedicine + uri, this.addHeaderInternalToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public updateStatus(request: StatusQueueRequest): Observable<ReturnStruct> {
    let uri = `PatientAccessLeaving`

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorTelemedicine + uri, request, this.addHeaderInternalToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}