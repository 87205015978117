import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmModalComponent>
  ) { }

  public btnDeny: string = 'Cancelar';
  public btnConfirm: string = 'Confirmar';
  public title: string = 'Confirmação';
  public message: string = 'Você confirma?';
  public colorCode: string = '#99C8D6';

  ngOnInit(): void {
    if(this.data.btnConfirm)
      this.btnConfirm = this.data.btnConfirm;
    if(this.data.btnDeny)
      this.btnDeny = this.data.btnDeny;
    if(this.data.title)
      this.title = this.data.title;
    if(this.data.message)
      this.message = this.data.message;
    if (this.data.colorCode){
      this.colorCode = this.data.colorCode;
      document.documentElement.style.setProperty('--colorCode', this.colorCode);
    }
  }

  clickPrimary() {
    this.matDialogRef.close({ confirm: true });
  }

  clickAccent() {
    this.matDialogRef.close({ confirm: false });
  }
}