import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-notification-display-modal',
  templateUrl: './notification-display-modal.component.html',
  styleUrls: ['./notification-display-modal.component.css']
})
export class NotificationDisplayModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<NotificationDisplayModalComponent>) { }

  public isLoading: boolean;

  ngOnInit(): void {
    if(this.data.color)
    document.documentElement.style.setProperty('--color', this.data.color);
  }

  close(){
    this.matDialogRef.close(); 
  }

}
