<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header-modal">
            <h2>{{this.text}}</h2>
        </div>
        <div class="footer-modal">
            <button mat-flat-button class="btn-secundary-flex" color="primary" type="button" (click)="confirm()">
                <span *ngIf="!isLoading">OK, entendi</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
        </div>
    </div>
</div>