import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-emergency-check',
  templateUrl: './emergency-check.component.html',
  styleUrls: ['./emergency-check.component.css']
})
export class EmergencyCheckComponent implements OnInit {
  @Input() colorCode: string;
  @Output() next = new EventEmitter<any>();
  @Output() reload = new EventEmitter<any>();

  constructor(
    private utilsTelemedicineService: UtilsTelemedicineService,
  ) { }

  public telemedicineConfig: TelemedicineConfigStruct;
  public orientationsTitle: string = "";
  public orientationsDescription: string = "";
  public orientationsArray: string[] = [];
  public customOrientations: boolean = false;
  public flowEnum = TelemedicineFlowEnum.emergencyCheck;


  ngOnInit(): void {
    this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();

    if (this.telemedicineConfig.orientationsTitle != null && this.telemedicineConfig.orientationsDescription != null) {
      this.customOrientations = true;
      this.orientationsTitle = this.telemedicineConfig.orientationsTitle;
      this.orientationsDescription = this.telemedicineConfig.orientationsDescription;
      this.orientationsArray = this.orientationsDescription.split('\n');
    }

    this.utilsTelemedicineService.setOldFlowControl(TelemedicineFlowEnum.emergencyCheck);
  }

  forward() {
    this.next.emit();
  }

  finish() {
    this.reload.emit();
  }
}
