import { DirectionButton } from "../../models/self-triage/direction-button.model";
import { SelfTriageConfig } from "../../models/self-triage/self-triage-config.models";
import { CounselingDirectionStruct } from "./counseling-direction.struct";

export class SelfTriageConfigStruct  extends SelfTriageConfig {
    public listButtons: DirectionButton[];
    public listCounselingDirection: CounselingDirectionStruct[];

    public externalSearchTitle: string;
    public externalSearchKey: string;
    public externalSearchUrl: string;
    public logoString64Preview: string;
    public logoString64: string;
    public completeUrl: string;
    
    public isExternalSearchEnabled: boolean;
    public useSelfTriageInPatientCenter: boolean;

    public listIdHealthUnit: number[];
    public idExternalSearchField: number;
}