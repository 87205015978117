import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-lgpd-modal',
  templateUrl: './lgpd-modal.component.html',
  styleUrls: ['./lgpd-modal.component.css']
})
export class LgpdModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LgpdModalComponent>,
    private utilTelemedicineService: UtilsTelemedicineService,
    private alertService: AlertService,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public telemedicineConfig: TelemedicineConfigStruct;
  public colorCode: string;

  ngOnInit(): void {
    this.telemedicineConfig = this.utilTelemedicineService.getTelemdicineConfig();
    this.colorCode = this.telemedicineConfig.colorCode;
  }

  clickAcept() {
    this.matDialogRef.close({
      accept: true,
    })
  }

  clickReject() {
    this.matDialogRef.close({
      acept: false,
    });
  }

}
