
<div [ngClass]="{'center-video': !isMobileVersion }">
    <div [ngClass]="{'main-video':!isMobileVersion}">                     
        <span  *ngIf="!isMobileVersion" class="doc-name" [ngStyle]="{'color': colorCode}">Profissional: {{this.videoCallData?.attendantUserName}}</span> 
        <app-participant 
        #participants    
        (toggleMic)="onMute()"    
        (toggleVid)="onHideVideo()"  
        (setIsAlone)="functionToSetIsAlone($event)"                      
        [style.display]="!!activeRoom ? 'block' : 'none'"
        [activeRoomName]="!!activeRoom ? activeRoom.name : null"
        [isMobileVersion]="isMobileVersion"
        [isMuted]="isMuted"
        [isVideoOff]="isVideoOff"        
        [patientScreenIsLarger]="patientScreenIsLarger"
        (setDoctorScreenIsLarger)="functionToSetDoctorScreenIsLarger($event)"></app-participant>
    </div>
    <div [ngClass]="{'div-app-settings':!isMobileVersion}">            
        <span  *ngIf="!isMobileVersion" class="doc-name" [ngStyle]="{'color': colorCode}">{{this.patientCallData?.patientName}}</span>
        <app-settings #settings [isMobileVersion]="isMobileVersion" [isLocalParticipantAlone]="isLocalParticipantAlone" [doctorScreenIsLarger]="doctorScreenIsLarger" (setPatientScreenIsLarger)="functionToSetPatientScreenIsLarger($event)"></app-settings>
    </div>
</div>  
<div *ngIf = "!isMobileVersion" class="my-material-icons div-options">
    <button type="button" mat-flat-button class="btn-call-toggle" title="Desativar som" (click)="onMute()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isMuted ? 'mic_off' : 'mic'}}</mat-icon>
    </button>
    <button type="button" mat-flat-button class="btn-call-toggle" title="Desativar câmera." (click)="onHideVideo()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isVideoOff ? 'videocam_off' : 'videocam'}}</mat-icon>
    </button>
    <button type="button" mat-flat-button class="btn-end-call" title="Sair da sala." (click)="openLeaveRoomConfirmModal()">
        <mat-icon aria-hidden="false" aria-label="Plus">call_end</mat-icon>
    </button>
</div>  

<div *ngIf = "isMobileVersion" id="video-buttons" class="my-material-icons div-options-mobile"> 
    <button type="button" mat-flat-button class="btn-call-toggle" title="Desativar som" (click)="onMute()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isMuted ? 'mic_off' : 'mic'}}</mat-icon>
    </button>
    <button type="button" mat-flat-button class="btn-call-toggle" title="Desativar câmera." (click)="onHideVideo()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isVideoOff ? 'videocam_off' : 'videocam'}}</mat-icon>
    </button>
    <button type="button" mat-flat-button class="btn-end-call" title="Sair da sala." (click)="openLeaveRoomConfirmModal()">
        <mat-icon aria-hidden="false" aria-label="Plus">call_end</mat-icon>
    </button>
</div>  

