<div [style.display]="isPreviewing ? 'block' : 'none'" [ngClass]="{'div-body-settings':isMobileVersion}">
    <form class="form">
        <div class="form-group" *ngIf="hasAudioInputOptions">
            <app-device-select [kind]="'audioinput'"
                               [label]="'Audio Input Source'" [devices]="devices"
                               (settingsChanged)="onSettingsChanged($event)"></app-device-select>
        </div>
        <div class="form-group" *ngIf="hasAudioOutputOptions">
            <app-device-select [kind]="'audiooutput'"
                               [label]="'Audio Output Source'" [devices]="devices"
                               (settingsChanged)="onSettingsChanged($event)"></app-device-select>
        </div>
        <div class="form-group" *ngIf="hasVideoInputOptions">
            <app-device-select [kind]="'videoinput'" #videoSelect
                               [label]="'Video Input Source'" [devices]="devices"
                               (settingsChanged)="onSettingsChanged($event)"></app-device-select>
        </div>
    </form>
    
    <app-camera #camera [isMobileVersion]="isMobileVersion" [isLocalParticipantAlone]="isLocalParticipantAlone" [doctorScreenIsLarger]="doctorScreenIsLarger" (setPatientScreenIsLarger)="functionToSetPatientScreenIsLarger($event)"></app-camera>
</div>