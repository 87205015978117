import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { ClientDigitalPromptServiceConfigService } from 'src/app/shared/services/API/digital-prompt-service/client-digital-prompt-service-config.service';
import { RecoverPasswordService } from 'src/app/shared/services/API/patient-center/recover-password.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RecoverPasswordRequest } from 'src/app/shared/services/requests/patient-center/recover-password.request';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private recoverPasswordService: RecoverPasswordService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private clientDigitalPromptServiceConfigService: ClientDigitalPromptServiceConfigService,
    ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public urlToken: string;
  public colorCode: string = '#67C4E0';
  public hide: boolean = true;
  public hideRepeat: boolean = true;
  public digitalPromptConfig: DigitalPromptConfigStruct;
  public uri: string;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      newPassword: ['', [Validators.required, VerifyPassword()]],
      repetedPassword: ['', [Validators.required]],
    });

    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');

    this.digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();

    if (this.digitalPromptConfig) {
      if (this.digitalPromptConfig.useColor == true) {
        this.colorCode = this.digitalPromptConfig.colorCode;
        document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
      }
    }
    else 
      this.getConfig(this.uri);

    this.activatedRoute.queryParams.subscribe(params => {
      this.urlToken = params.token;

      this.verifyToken(this.urlToken);
    });

    this.isLoading = false;
  }

  submit() {
    if (this.model.invalid)
      return;

    if (this.model.get('newPassword').value != this.model.get('repetedPassword').value) {
      this.alertService.show('Erro', "Os dois campos de senha devem ser iguais", AlertType.error);
      return;
    }

    let body: RecoverPasswordRequest = {
      newPassword: this.model.get('newPassword').value,
      token: this.urlToken,
    }

    this.recoverPasswordService.recoverPassword(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Senha alterada com sucesso.', AlertType.success);
        this.isLoading = false;
        this.router.navigate([`patient-center/${this.utilsDigitalPromptServiceService.getUri()}`]);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  verifyToken(token: string) {
    this.recoverPasswordService.verifyToken(token).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getConfig(uri: string) {
    this.clientDigitalPromptServiceConfigService.getClientConfig(uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.digitalPromptConfig = response.digitalPromptConfig;
        this.utilsDigitalPromptServiceService.setDigitalPromptServiceStruct(response.digitalPromptConfig);
        if (response.digitalPromptConfig.useColor) {
          this.colorCode = this.digitalPromptConfig.colorCode;
          document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}