import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EndNpsComponent } from './end-nps/end-nps.component';
import { NpsComponent } from './nps/nps.component';

const routes: Routes = [
  { path: 'nps/:guid', component: NpsComponent, pathMatch: 'full'},
  { path: 'nps/end-nps/:guid', component: EndNpsComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NpsRoutingModule { }
