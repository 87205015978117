import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { createLocalVideoTrack, LocalVideoTrack, Participant, RemoteParticipant, Room} from 'twilio-video';
import { TwilioUtilService } from '../../twilio-util.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('preview') previewElement: ElementRef;
  @ViewChild('localBody') localBody: ElementRef;
  @Input() isMobileVersion: boolean;
  @Input() doctorScreenIsLarger: boolean;
  @Input() isLocalParticipantAlone: boolean;
  @Output('setPatientScreenIsLarger') setPatientScreenIsLarger = new EventEmitter<boolean>();
  
  isInitializing: boolean = true;
  videoTrack: LocalVideoTrack = null;
  patientScreenIsLarger: boolean = false;
  
  
  constructor(
    private twilioUtilService: TwilioUtilService,
    private readonly renderer: Renderer2
    ) { }
    
    async ngAfterViewInit() {
      this.finalizePreview();
      if (this.previewElement && this.previewElement.nativeElement) {
        const selectedVideoInput = this.twilioUtilService.get('videoInputId');
        //await this.initializeDevice(selectedVideoInput);
      }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
      if (changes.doctorScreenIsLarger) {
        if (changes.doctorScreenIsLarger.previousValue == undefined ) {
          return;
        }
        if (changes.doctorScreenIsLarger.currentValue !== changes.doctorScreenIsLarger.previousValue && changes.doctorScreenIsLarger.currentValue == true) {
          this.changePatientScreenToSmaller();
        }
      }
    }
    
    ngOnDestroy() {
      if(this.videoTrack)
      this.videoTrack.stop();
      this.finalizePreview();
    }
    
    async initializePreview(deviceId: string) {
      this.finalizePreview();
      await this.initializeDevice(deviceId);
    }
    
    finalizePreview() {
      try {
        if (this.videoTrack) {
          this.videoTrack.stop();
          this.videoTrack.detach().forEach(element => element.remove());
        }
        this.videoTrack = null;
      } catch (e) {
        console.error(e);
      }
    }
    
    private async initializeDevice(deviceId?: string) {
      try {
        this.isInitializing = true;        
        this.finalizePreview();  
        
        this.videoTrack = deviceId ? await createLocalVideoTrack({ deviceId }) : await createLocalVideoTrack();
        
        const videoElement = this.videoTrack.attach();
        if(this.isMobileVersion){
          this.previewElement.nativeElement.setAttribute('style', 'width: 20%; min-width: 140px; margin-bottom: 63px; margin-right: 10px; z-index: 1');        
          this.localBody.nativeElement.setAttribute('style', 'z-index: 1');        
          videoElement.style.transform = 'scale(-1,1)';
          videoElement.id = "local-video";
          this.renderer.setStyle(videoElement, 'width', '100%');
          this.renderer.setStyle(videoElement, 'height', '100%'); 
          this.renderer.setStyle(videoElement, 'border-radius', '10px');         
          this.renderer.appendChild(this.previewElement.nativeElement, videoElement); 
          videoElement.addEventListener('click',()=> {this.changePatientScreenToLarger()}, false);   
        } else{
          videoElement.style.transform = 'scale(-1,1)';
          this.renderer.setStyle(videoElement, 'width', '100%');
          this.renderer.setStyle(videoElement, 'height', '100%');      
          this.renderer.setStyle(videoElement, 'overflow', 'hidden');      
          this.renderer.setStyle(videoElement, 'margin-top', '5px');
          this.renderer.setStyle(videoElement, 'border-radius', '10px');
          this.renderer.setStyle(videoElement, 'object-fit', 'cover');          
          this.renderer.appendChild(this.previewElement.nativeElement, videoElement); 
          videoElement.addEventListener('click',()=> {this.changePatientScreenToLarger()}, false); 
        }
        
        
      } finally {
        this.isInitializing = false;
      }
    }
    
    async changePatientScreenToLarger(){
      if(this.isMobileVersion){        
        if(!this.patientScreenIsLarger && !this.isLocalParticipantAlone){
          const videoElement = document.getElementById('local-video');
          this.previewElement.nativeElement.setAttribute('style', 'width: 100%; height: 100%; margin-bottom: 0; margin-right: 0; z-index: 0');
          this.localBody.nativeElement.setAttribute('style', 'z-index: 0');  
          videoElement.style.transform = 'scale(-1,1)';
          this.renderer.setStyle(videoElement, 'width', '100%');
          this.renderer.setStyle(videoElement, 'height', '100%');  
          this.renderer.setStyle(videoElement, 'object-fit', 'cover'); 
          this.renderer.setStyle(videoElement, 'border-radius', '0');            
          this.patientScreenIsLarger = true;
          this.setPatientScreenIsLarger.emit(this.patientScreenIsLarger );
        }       
      }     
    }
    
    async changePatientScreenToSmaller(){
      if(this.isMobileVersion){        
        if(this.patientScreenIsLarger)   
        {
          const videoElement = document.getElementById('local-video');
          this.previewElement.nativeElement.setAttribute('style', 'width: 20%; min-width: 140px; margin-bottom: 63px; margin-right: 10px; z-index: 1');
          this.localBody.nativeElement.setAttribute('style', 'z-index: 1');       
          videoElement.style.transform = 'scale(-1,1)';
          this.renderer.setStyle(videoElement, 'width', '100%');
          this.renderer.setStyle(videoElement, 'height', '100%');   
          this.renderer.setStyle(videoElement, 'border-radius', '10px'); 
          // this.renderer.setStyle(videoElement, 'object-fit', 'cover'); 
          videoElement.addEventListener('click',()=> {this.changePatientScreenToLarger()}, false);
          
          this.patientScreenIsLarger = false;
        }        
      }     
    }
  }