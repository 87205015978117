import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControlName, UntypedFormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertService, AlertType } from '../../services/alert.service';
import { SelectService } from './select.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

  constructor(private selectService: SelectService, private alertService: AlertService) { }

  public selectedList: any[] = [];
  public initialList: any[] = [];
  public validadePreSelected: any[] = [];
  public axiliarList: any = [];
  public offlineList: boolean;
  public selected: any;
  public firstChildGroup: UntypedFormGroup;
  private timeoutKeySearch: any = null;
  public searchText: string;

  @Input() list: any[]; //Input responsável por gerar o select de maneira offline
  @Input() url: string; //Input responsável por gerar o select de maneira online, ou seja, qualquer pesquisa vai ao backend enviando um searchtext
  @Input() preSelectedlist: any[] = []; //Input responsável por deixar valores préselecionados, é necessário enviar um valor exatamente igual ao que vem nas listas, seja online ou offline.
  @Input() preSelectedBlock: boolean = false; // Input responsável por tornar os valores pré selecionados readonly
  @Input() idField: string; //Input responsável por determinar o campo que é o identificador único da lista (ex: [{id:1, name:"teste"},...] deve ser passado como [idField]="'id'")
  @Input() nameField: string; //Input responsável por determinar o campo que é o label do select que também será usado para filtrar (ex: [{id:1, name:"teste"},...] deve ser passado como [nameField]="'name'")
  @Input() labelField: string; //Nome que aparecerar para o usuário final
  @Input() multiple: boolean = false;//se é multiplo
  @Output() selectedValue = new EventEmitter<any>();//Colocar uma função para receber a lista de objetos selecionados

  ngOnInit(): void {
    if (this.list && this.list.length > 0) {
      this.initialList = [...this.list];
      this.selectedList = [...this.list];
      this.offlineList = true;
    } else {
      this.populateSelect("");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preSelectedlist) {
      if (!changes.preSelectedlist.currentValue) return;
      if (changes.preSelectedlist.currentValue !== changes.preSelectedlist.previousValue) {
        if (this.multiple) {
          this.selected = this.preSelectedlist.map(d => d[this.idField]);
        } else {
          this.selected = this.preSelectedlist.map(d => d[this.idField])[0];
        }
        var ids = new Set(this.preSelectedlist.map(d => d[this.idField]));
        this.selectedList = [...this.preSelectedlist, ...this.selectedList.filter(d => !ids.has(d[this.idField]))];
      }
    } else if (changes.url) {
      if (!changes.url.currentValue) return;
      if (changes.url.currentValue !== changes.url.previousValue) {
        this.populateSelect(this.searchText);
      }
    }
  }

  onChangeSelect(event: any) {
    if (this.multiple) {
      this.preSelectedlist = this.selectedList.filter(c => event.value.includes(c[this.idField]));
      this.selectedValue.emit(this.preSelectedlist);
    } else {
      this.preSelectedlist = this.selectedList.find(c => c[this.idField] == event.value)
      this.selectedValue.emit(this.preSelectedlist);
    }
  }

  populateSelect(searchText: string) {
    if (this.url) {
      this.selectService.getSelectValues(this.url, searchText).subscribe((response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (!this.axiliarList || this.axiliarList.length === 0) this.axiliarList = response.list;

        this.selectedList = response.list;
        if (this.preSelectedlist && this.preSelectedlist.length > 0) {
          var ids = new Set(this.preSelectedlist.map(d => d[this.idField]));
          this.selectedList = [...this.preSelectedlist, ...this.selectedList.filter(d => !ids.has(d[this.idField]))];
        }
      });
    }
  }

  onKey(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        var ids = new Set($this.preSelectedlist.map(d => d[$this.idField]));
        $this.selectedList = [...$this.preSelectedlist, ...$this.search(event).filter(d => !ids.has(d[$this.idField]))];
      }
    }, 1000);
  }

  search(value: string) {
    if (this.offlineList) {
      return this.initialList.filter(option => option[this.nameField].toLowerCase().includes(value.toLowerCase()));
    } else {
      this.populateSelect(value);
    }
  }

  click() {
    if (this.selectedList.length <= 0) {
      this.searchText = null;
      this.selectedList = this.axiliarList;
    }
  }
}