<div class="container-box">
    <div class="container orientations-session">

        <div class="section-title">
            <h3 [ngStyle]="{'color': colorCode}">Deseja sair da fila de atendimento?</h3>
        </div>
        <h2>Ao confirmar, você perderá seu lugar na fila e terá que recomeçar o processo.</h2>
    </div>

    <div class="button-container">
        <button mat-flat-button class="forward-button" type="button" (click)="clickCancel()">
            <span *ngIf="!isLoading">CONTINUAR NA FILA</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
        <button mat-flat-button class="btn-secundary-flex" color="primary" type="button" (click)="clickOk()">
            <span *ngIf="!isLoading">SAIR DA FILA</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>

</div>