export class ValidateUserPatientRequest {
    constructor(guid: string, listIdHealthUnit: number[], idHealthUnit: number) {
        this.guid = guid;
        this.idHealthUnit = idHealthUnit;
        this.listIdHealthUnit = listIdHealthUnit;
    }

    public guid: string;
    public idHealthUnit: number;
    public listIdHealthUnit: number[];
}