import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ClientConfigResponse } from '../../responses/client-config.response';
import { PatientCenterRequest } from '../../requests/srv-email/patient-center.request';
import { GetPatientCompleteDataResponse } from '../../responses/get-patient-complete-data.response';
import { ConfirmationEmailRequest } from '../../requests/patient-center/confirmation-email.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
    providedIn: 'root'
})

export class SRVEmailService extends CommonService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    public sendEmail(request: PatientCenterRequest): Observable<ClientConfigResponse> {
        let uri = `PatientCenter`

        return this.httpClient.post<ClientConfigResponse>(environment.urlApiSrvEmail + uri, request)
            .pipe(
                catchError(this.handleError)
            )
    }

    public registryConfirmationEmail(request: ConfirmationEmailRequest): Observable<ReturnStruct> {
        let uri = `PatientCenter`

        return this.httpClient.post<ReturnStruct>(environment.urlApiSrvEmail + uri, request)
            .pipe(
                catchError(this.handleError)
            )
    }
}