<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': '#EBEBEB'}">
        <div class="body-content">
            <div class="white-container">
                <div class="hub-header">
                    <div *ngIf="patientName" class="patient-info">
                        <span class="primary-greeting">Olá, {{patientName}}!</span>
                    </div>
                    <div class="menu">
                        <div class="menu-icon">
                            <mat-icon class="icon" [matBadge]="unreadMessages" matBadgeColor="accent" matBadgeSize="small" *ngIf="unreadMessages > 0"
                                aria-hidden="false" aria-label="Menu apps" (click)="openNotification()">notifications
                            </mat-icon>
                            <mat-icon class="icon" *ngIf="!unreadMessages" aria-hidden="false" aria-label="Menu apps" (click)="openNotification()">
                                notifications
                            </mat-icon>
                            <app-notification [listMessagePatientCenter]="listMessagePatientCenter" [isVisible]="isVisibleNotification" [isLoading]="isLoadingNotification"
                                (closeNotification)="closeNotification()" (populateNotification)="populateNotification()" [colorCode]="colorCode">
                            </app-notification>
                        </div>        
                        <div class="menu-icon profile-icon">
                            <mat-icon class="icon" matSuffix (click)="showOrHideOptions()">account_circle
                            </mat-icon>
                            <div *ngIf="showUserOptions" class="list-user-options" (click)="logout()">
                                <div class="arrow"></div>
                                <a class="link-redirection" (click)="alterRegister()">
                                    <mat-icon aria-hidden="false" class="icon-config">menu</mat-icon>
                                    Meu Cadastro
                                </a>
                                <a class="link-redirection" (click)="logout()">
                                    <mat-icon aria-hidden="false" class="logout-icon">logout</mat-icon>
                                    Sair
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 class="patient-center-section">Serviços</h1>
                <hr />
                <div class="box-div">
                    <div class="service-box" (click)="navigatePatientScheduling()">
                        <mat-icon class="service-icon" matSuffix>today
                        </mat-icon>
                        <span class="service-description">Agendar consulta</span>
                    </div>
                    <div *ngIf="useTelemedicineInPatientCenter" class="service-box"
                        (click)="navigatePatientAccessTelemedicine()">
                        <div class="spinner-container-box" *ngIf="loadingAccessTelemedicine">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                        <div *ngIf="!loadingAccessTelemedicine">
                            <mat-icon class="service-icon" matSuffix>medical_services
                            </mat-icon>
                            <span class="service-description">Telemedicina</span>
                        </div>
                    </div>
                    <div *ngIf="useSelfTriageInPatientCenter" class="service-box"
                        (click)="navigatePatientAccessSelfTriage()">
                        <div class="spinner-container-box" *ngIf="loadingAccessSelfTriage">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                        <div *ngIf="!loadingAccessSelfTriage">
                            <mat-icon class="service-icon" matSuffix>local_hospital
                            </mat-icon>
                            <span class="service-description">Autotriagem</span>
                        </div>
                    </div>
                </div>
                <h1 class="patient-center-section">Área do paciente</h1>
                <hr />
                <div class="box-div">
                    <div class="service-box" (click)="navigatePatientAppointments()">
                        <mat-icon class="service-icon" matSuffix>event_available
                        </mat-icon>
                        <span class="service-description">Meus agendamentos</span>
                    </div>
                    <div class="service-box" (click)="navigatePatientHealthcareAgreement()">
                        <mat-icon class="service-icon" matSuffix>badge
                        </mat-icon>
                        <span class="service-description">Meus convênios</span>
                    </div>
                </div>
                <h1 class="patient-center-section">Próximas consultas</h1>
                <div class="card-list"
                    *ngIf="(listPatientAppointment && listPatientAppointment.length > 0) && !isLoading">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6" *ngFor="let item of listPatientAppointment">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">
                                        <mat-icon class="date-icon" matSuffix>today
                                        </mat-icon>
                                        {{item.datetimeStart | date:'dd/MM/yyyy'}} às
                                        {{item.datetimeStart | date:'HH:mm'}}</span>
                                    <span class="secundary">Dr(a). {{item.userName}}</span>
                                    <span class="secundary">{{item.medicalSpecialtyName}}</span>
                                    <span class="secundary">Atendimento: {{item.natureOfAttendanceName}}</span>
                                </div>
                                <div class="button-container">
                                    <a mat-flat-button type="button" (click)="showDetails(item)"
                                        class="btn-block secondary-button">
                                        <span class="label">Ver detalhes</span>
                                    </a>
                                    <button *ngIf="item.idTypeOfCare === onlineAttendance" matTooltip="Iniciar consulta"
                                        mat-flat-button (click)="startAppointment(item)"
                                        class="btn-block primary-button">
                                        <span class="label">Iniciar consulta</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-appointment"
                    *ngIf="(!listPatientAppointment || listPatientAppointment.length == 0) && !isLoading">
                    <span>
                        Não há consultas agendadas
                    </span>
                </div>
                <div class="spinner-container" *ngIf="isLoading">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
                <div class="footer-white-container">
                    <p>Central do paciente</p>
                </div>
            </div>
        </div>
    </body>
</div>