import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html',
  styleUrls: ['./phone-modal.component.css']
})
export class PhoneModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PhoneModalComponent>) {
  }

  public completeURL: string;
  public isAdvanced: boolean = false;
  public isLoading: boolean = false;
  public checkMobile: boolean = this.data.checkMobile;
  public colorCode: string = this.data.colorCode;

  ngOnInit(): void {
  }

  advance() {
    this.isAdvanced = true;
    this.matDialogRef.close({ isAdvanced: this.isAdvanced });
  }
  close() {
    this.isAdvanced = false;
    this.matDialogRef.close();
  }
}