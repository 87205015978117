import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { SelfTriageScreenEnum } from 'src/app/shared/enum/self-triage/self-triage-screen.enum';
import { ServiceTypeEnum } from 'src/app/shared/enum/service-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientTelemedicineAccessService } from 'src/app/shared/services/API/telemedicine/client-telemedicine-access.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DirectionButton } from 'src/app/shared/services/models/self-triage/direction-button.model';
import { Patient } from 'src/app/shared/services/models/self-triage/patient.models';
import { SelfTriageCounselingModel } from 'src/app/shared/services/models/self-triage/self-triage-counseling.model';
import { ClientTelemedicineAccessRequest } from 'src/app/shared/services/requests/telemedicine/client-telemedicine-access.request';
import { FlowchartCompleteStruct } from 'src/app/shared/services/structs/self-triage/flowchart-complete.struct';
import { QuestionAnswerStruct } from 'src/app/shared/services/structs/self-triage/question-answer.struct';
import { SelfTriageConfigStruct } from 'src/app/shared/services/structs/self-triage/self-triage-config.struct';
import { PhoneModalComponent } from './phone-modal/phone-modal.component';
import { ResumeModalComponent } from './resume-modal/resume-modal.component';
import { TelemedicineModalComponent } from './telemedicine-modal/telemedicine-modal/telemedicine-modal.component';
import { UrlModalComponent } from './url-modal/url-modal/url-modal.component';
import { UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-counseling',
  templateUrl: './counseling.component.html',
  styleUrls: ['./counseling.component.css']
})

export class CounselingComponent implements OnInit {
  @Output() redirectEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog,
    private maskService: MaskService,
    private utilSelfTriageService: UtilsSelfTriageService,
    private utilTelemedicineService: UtilsTelemedicineService,
    public router: Router,
    private alertService: AlertService,
    private clientTelemedicineAccessService: ClientTelemedicineAccessService,
  ) { }

  public masks: Masks;

  public patientCenterAccessSelfTriage: boolean = false;
  public isLoading: boolean = false;
  public checkMobile: boolean;

  public serviceTypeLink: number = ServiceTypeEnum.link;
  public serviceTypeTelephone: number = ServiceTypeEnum.telephone;
  public serviceTypeTelemedicine: number = ServiceTypeEnum.telemedicine;
  public thisScreen: SelfTriageScreenEnum = SelfTriageScreenEnum.counseling;

  public patient: Patient;
  public flowchart: FlowchartCompleteStruct;
  public selfTriageConfig: SelfTriageConfigStruct;
  public counseling: SelfTriageCounselingModel;
  public listButtons: DirectionButton[];
  public results: QuestionAnswerStruct[] = [];

  public uri: string;
  public colorCode: string = '#99C8D6';

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfigStruct();
    this.uri = this.utilSelfTriageService.getUri();
    this.detectMobile()

    this.patientCenterAccessSelfTriage = this.utilSelfTriageService.getSelfTriageAccessSelfTriage();

    if (this.patientCenterAccessSelfTriage)
      this.selfTriageConfig = this.utilSelfTriageService.getSelfTriageConfig();

    if (!this.selfTriageConfig)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]);

    this.patient = this.utilSelfTriageService.getPatientData();
    if (!this.patient)
      this.router.navigate([`self-triage/${this.utilSelfTriageService.getUri()}`]); //VERIFICAR ALGUMA MENSAGEM DE ERRO

    this.counseling = this.utilSelfTriageService.getProtocolResponse().counseling;
    this.listButtons = this.utilSelfTriageService.getProtocolResponse().listButtons;
    this.results = this.utilSelfTriageService.getProtocolResponse().results;
    this.flowchart = this.utilSelfTriageService.getProtocolResponse().flowchart;

    if (!this.counseling || !this.listButtons) {
      this.alertService.show("Erro", "Houve um erro na busca do aconselhamento", AlertType.error);
      return;
    }

    if (this.selfTriageConfig.useColor == true) {
      this.colorCode = this.selfTriageConfig.colorCode;
      document.documentElement.style.setProperty('--colorCodeSelfTriage', this.colorCode);
    }
  }

  openModal(item) {
    let button = this.listButtons.find(x => x.idDirectionButton == item.idDirectionButton);
    if (button.idServiceType == this.serviceTypeTelephone) {
      if (button.buttonDescription) {
        const dialogRef = this.dialog.open(PhoneModalComponent, {
          panelClass: 'body-phone-modal',
          data: {
            buttonName: button.buttonName,
            description: button.buttonDescription,
            directionAddress: button.directionAddress,
            checkMobile: this.checkMobile,
            colorCode: this.colorCode
          },
        });
        dialogRef.afterClosed().subscribe({
          next: result => {
            if (result && result.isAdvanced) {
              if (this.checkMobile)
                window.open("tel:" + button.directionAddress, "_blank");
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      }
      else {
        if (this.checkMobile)
          window.open("tel:" + button.directionAddress, "_blank");
        else {
          const dialogRef = this.dialog.open(PhoneModalComponent, {
            panelClass: 'body-phone-modal',
            data: {
              buttonName: button.buttonName,
              description: button.buttonDescription,
              directionAddress: button.directionAddress,
            },
          });
        }
      }
    }
  }

  openUrlModal(item: DirectionButton) {
    let button = this.listButtons.find(x => x.idDirectionButton == item.idDirectionButton);

    if (button.buttonDescription) {
      const dialogRef = this.dialog.open(UrlModalComponent, {
        autoFocus: false,
        data: {
          buttonName: button.buttonName,
          buttonDescription: button.buttonDescription,
        },
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.isAdvanced)
            window.open(item.directionAddress, "_blank");
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else
      window.open(item.directionAddress, "_blank");
  }

  telemedicineRedirect(item): void {
    console.log("bateTelemedicineRedirect")
    let clientTelemedicineAccessRequest: ClientTelemedicineAccessRequest = new ClientTelemedicineAccessRequest();
    clientTelemedicineAccessRequest.idHealthUnit = this.selfTriageConfig.idHealthUnit;
    clientTelemedicineAccessRequest.idSelfTriagePatient = this.patient.idPatient;

    let button = this.listButtons.find(x => x.idDirectionButton == item.idDirectionButton);
    if (button.buttonDescription) {
      const dialogRef = this.dialog.open(TelemedicineModalComponent, {
        autoFocus: false,
        data: {
          buttonName: button.buttonName,
          buttonDescription: button.buttonDescription,
        },
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.isAdvanced) {
            this.generateAccessTelemedicine(clientTelemedicineAccessRequest);           
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.generateAccessTelemedicine(clientTelemedicineAccessRequest);
    }
  }

  generateAccessTelemedicine(clientTelemedicineAccessRequest: ClientTelemedicineAccessRequest){
    this.clientTelemedicineAccessService.postTelemedicineAccess(clientTelemedicineAccessRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.utilTelemedicineService.setSelfTriageAcessTelemedicine(true);
        window.open(response.completeUrl, "_self");
        //window.open(response.completeUrl, "_blank"); //apenas para teste
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  detectMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    )
      this.checkMobile = true;
    else
      this.checkMobile = false;
  }

  openResume() {
    const dialogRef = this.dialog.open(ResumeModalComponent, {
      autoFocus: false,
      panelClass: 'results-modal',
      data: {
        results: this.results,
        flowchart: this.flowchart,
        counseling: this.counseling.counseling,
        colorCode: this.colorCode
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  goEndSelfTriage() {
    this.isLoading = true;
    this.router.navigate([`self-triage/end/${this.utilSelfTriageService.getUri()}`]);
  }
}