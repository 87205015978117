import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivateAccountModalComponent } from './activate-account-modal/activate-account-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SRVEmailService } from 'src/app/shared/services/API/srv-email/srv-email.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LoginRequest } from 'src/app/shared/services/requests/patient-center/login.request';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { environment } from 'src/environments/environment';
import { UtilsDigitalPromptServiceService } from 'src/app/patient-center/utils.service';
import { PatientCenterRequest } from 'src/app/shared/services/requests/srv-email/patient-center.request';
import { ClientDigitalPromptServiceConfigService } from 'src/app/shared/services/API/digital-prompt-service/client-digital-prompt-service-config.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { UserPatientService } from 'src/app/shared/services/API/orchestrator-patient/user-patient.service';
import { DigitalPromptConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private sRVEmailService: SRVEmailService,
    private clientDigitalPromptServiceConfigService: ClientDigitalPromptServiceConfigService,
    private renderer: Renderer2,
    private authService: AuthService,
    private userPatientService: UserPatientService,
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private utilsDigitalPromptServiceService: UtilsDigitalPromptServiceService,
    private healthUnitService: HealthUnitService,) {
  }
  
  @Input() preview: boolean = false;
  
  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean = false;
  public colorCode: string = '#67C4E0';
  public hide: boolean = false;
  public loginRequest: LoginRequest = new LoginRequest;
  public digitalPromptConfig: DigitalPromptConfigStruct;
  public cpf: string;
  public userName: string = "";
  public userEmail: string = "";
  public guid: string = "";
  public healthUnitStruct: HealthUnitStruct;
  public uri: string;
  public patientRegisterRoute: string;
  public sessionTimeout: number = null;

  ngOnInit(): void {
    this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
    this.digitalPromptConfig = this.utilsDigitalPromptServiceService.getDigitalPromptServiceStruct();

    if (this.digitalPromptConfig) {
      if (this.digitalPromptConfig.useColor == true) {
        this.colorCode = this.digitalPromptConfig.colorCode;
        document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
      }
      this.getHealthUnit(this.digitalPromptConfig.idHealthUnit);
    }
    else
      this.getConfig(this.uri);

    this.patientRegisterRoute = `patient-center/patient-register/${this.utilsDigitalPromptServiceService.getUri()}`;

    if (this.activatedRoute.snapshot.paramMap.get('guid')) {
      this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
      this.validateAccount();
    }

    this.masks = this.maskService.getMasks();

    const storedCPF = localStorage.getItem('cpf');
    const storedPassword = localStorage.getItem('password');
    const storedRememberMe = localStorage.getItem('rememberMe');

    this.model = this.formBuilder.group({
      cpf: [storedCPF, [Validators.required, VerifyCPF()]],
      password: [storedPassword, Validators.required],
      rememberMe: [storedRememberMe]
    });

    this.cpf = this.utilsDigitalPromptServiceService.getCPF();

    if (this.cpf != null)
      this.formatCPF(this.cpf);

    if (!this.digitalPromptConfig)
      this.router.navigate([`patient-center/${this.utilsDigitalPromptServiceService.getUri()}`]);

    this.loadScript(`https://www.google.com/recaptcha/api.js?render=${environment.siteKey}`);
  }

  getConfig(uri: string) {
    this.clientDigitalPromptServiceConfigService.getClientConfig(uri).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.digitalPromptConfig = response.digitalPromptConfig;
        if(this.digitalPromptConfig.sessionTimeoutValue != null && this.digitalPromptConfig.sessionTimeoutValue != 0)
        {
          this.sessionTimeout = this.digitalPromptConfig.sessionTimeoutValue;
        }
        this.utilsDigitalPromptServiceService.setDigitalPromptServiceStruct(response.digitalPromptConfig);
        if (response.digitalPromptConfig.useColor) {
          this.colorCode = this.digitalPromptConfig.colorCode;
          document.documentElement.style.setProperty('--colorCodePatientCenter', this.colorCode);
        }

        this.getHealthUnit(this.digitalPromptConfig.idHealthUnit);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthUnit(idHealthUnit: number) {
    this.healthUnitService.getHealthUnit(idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.utilsDigitalPromptServiceService.updatehealthUnit(response.healthUnitStruct);
        this.healthUnitStruct = response.healthUnitStruct;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.get('rememberMe').value) {
      localStorage.setItem('cpf', this.model.get('cpf').value);
      localStorage.setItem('password', this.model.get('password').value);
      localStorage.setItem('rememberMe', this.model.get('rememberMe').value);
    }
    else {
      localStorage.removeItem('cpf');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }

    if (this.model.invalid || this.isLoading || this.preview)
      return;

    this.isLoading = true;
    grecaptcha.ready(() => {
      grecaptcha.execute(environment.siteKey, { action: 'submit' }).then((token) => {

        this.authService.signInPatientCenter(this.model.get('cpf').value, this.model.get('password').value, '192.168.0.2', token, this.digitalPromptConfig.logoString64, this.digitalPromptConfig.idHealthUnit, this.healthUnitStruct.listIdHealthUnitInGroup, this.sessionTimeout).subscribe({
          next: (response) => {
            if (response.isError && response.errorCode == 1) {
              this.userEmail = response.userEmail;
              this.userName = response.userName;
              this.guid = response.guid;
              this.sendEmail();
              this.alertService.show("Erro", response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            if (response.isError) {
              this.alertService.show("Erro", response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            this.authService.updateToken(response);
            this.router.navigate([`patient-center/hub/${this.utilsDigitalPromptServiceService.getUri()}`]);
          },
          error: (error) => {
            console.log(error);
            this.alertService.show("Erro inesperado", error, AlertType.error);
            this.isLoading = false;
          }
        });
      });
    });
  }

  getCpfErrorMessage() {
    if (this.model.get('cpf').hasError('required'))
      return 'Informe o CPF';

    return this.model.get('cpf').hasError('invalidCPF') ? 'CPF inválido' : '';
  }

  openActivateAccountModal() {
    const dialogRef = this.dialog.open(ActivateAccountModalComponent, {
      data: {
        colorCode: this.colorCode,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      }
    });
  }

  loadScript(url: string) {
    const script = this.renderer.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    this.renderer.appendChild(document.body, script);
  }

  forgoutPassword() {
    if(this.preview)
      return

    if (this.model.get('cpf') && this.model.get('cpf').value != null)
      this.utilsDigitalPromptServiceService.setCPF(this.model.get('cpf').value);

    this.router.navigate([`patient-center/forgot-password/${this.utilsDigitalPromptServiceService.getUri()}`]);
  }

  goRegister() {
    if(this.preview)
      return

    this.router.navigate([`${this.patientRegisterRoute}`]);
  }

  formatCPF(cpf: string): string {
    if (!cpf) return '';

    cpf = cpf.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos

    // Formatação do CPF
    this.model.get('cpf').setValue(cpf);
  }

  sendEmail() {
    let request: PatientCenterRequest = new PatientCenterRequest();
    request.urlPatientCenter = environment.urlUIPatientBase + "digital-prompt-service/" + this.utilsDigitalPromptServiceService.getUri() + "/" + this.guid;
    request.userEmail = this.userEmail;
    request.userName = this.userName;
    request.isAtivation = true;

    this.sRVEmailService.sendEmail(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.openActivateAccountModal();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  validateAccount() {
    let healthUnitInfos = this.utilsDigitalPromptServiceService.getHealthUnit();
    this.userPatientService.validateAccount(this.guid, healthUnitInfos.listIdHealthUnitInGroup, healthUnitInfos.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.userEmail = response.userPatient.userEmail;
        this.userName = response.userPatient.userName;
        this.confirmationAtivationEmail();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  confirmationAtivationEmail() {
    let request: PatientCenterRequest = new PatientCenterRequest();
    request.userEmail = this.userEmail;
    request.userName = this.userName;
    request.isAtivation = false;
    request.urlPatientCenter = environment.urlUIPatientBase + "digital-prompt-service/" + this.utilsDigitalPromptServiceService.getUri();

    this.sRVEmailService.sendEmail(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('', 'Conta ativada com sucesso!', AlertType.success);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }
}