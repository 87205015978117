import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientLookupService } from 'src/app/shared/services/API/medical-record/client-lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { Gender } from 'src/app/shared/services/models/medical-record/gender.model';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { HealthIdentificationEnum } from 'src/app/shared/enum/health-identification.enum';
import { PatientData, UtilsTelemedicineService } from 'src/app/telemedicine/utils.service';
import { ClientHealthUnitStateService } from 'src/app/shared/services/API/user/client-health-unit-state.service';
import { ClientHealthUnitCityService } from 'src/app/shared/services/API/user/client-health-unit-city.service';
import { HealthUnitStateModel } from 'src/app/shared/services/models/user/health-unit-state.model';
import { HealthUnitCityModel } from 'src/app/shared/services/models/user/health-unit-city.model';
import { CancelModalComponent } from '../cancel-modal/cancel-modal.component';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { MatDialog } from '@angular/material/dialog';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyExpirationDate } from 'src/app/shared/custom-validators/expiration-date.validator';
import { Observable, map, startWith } from 'rxjs';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { emailValidator } from '../../custom-validators/email-domain.validator';

@Component({
  selector: 'app-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.css']
})
export class AdditionalDataComponent implements OnInit {

  @Input() patient: PatientData;
  @Input() telemedicineConfig: TelemedicineConfigStruct;
  @Output() savedPatientData = new EventEmitter<boolean>();
  @Output() next = new EventEmitter<any>();

  constructor(
    public utilsTelemedicineService: UtilsTelemedicineService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private clientLookupService: ClientLookupService,
    private utilService: UtilsTelemedicineService,
    private clienthealthUnitStateService: ClientHealthUnitStateService,
    private clienthealthUnitCityService: ClientHealthUnitCityService,
    public dialog: MatDialog,
    public viaCepService: ViaCepService,
  ) { }

  public model: FormGroup;
  public checkedCpf: boolean = false;
  public isLoadingCep: boolean;
  public masks: Masks;
  public isLoading: boolean;
  public listGender: Gender[];
  public idHealthIdentification: number;
  public idCNS: number = HealthIdentificationEnum.cns;
  public idHealthPlan: number = HealthIdentificationEnum.healthPlan;
  public patientData: PatientData;
  public isSelectCity: boolean = false;
  public preSelectState: any[] = [];
  public listAllStates: HealthUnitStateModel[] = [];
  public listAllCities: HealthUnitCityModel[] = [];
  public preSelectCity: any[] = [];
  public colorCode: string;
  public flowEnum = TelemedicineFlowEnum.additionalData;
  public listFilteredStates: Observable<HealthUnitStateModel[]>;
  public listFilteredCities: Observable<HealthUnitCityModel[]>;

  ngOnInit(): void {
    this.idHealthIdentification = this.telemedicineConfig.idHealthIdentification;
    this.patientData = new PatientData();
    this.colorCode = this.telemedicineConfig.colorCode;

    if (this.telemedicineConfig.useColor == true) {
      document.documentElement.style.setProperty('--colorCodeTelemedicine', this.colorCode);
    }

    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      completeName: ['', [Validators.required]],
      cpf: ['', [Validators.required, VerifyCPF()]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, emailValidator()]],
      birthDate: ['', [Validators.required, this.validateBirthDate]],
      gender: ['', [Validators.required]],
      zipCode: [''],
      state: [''],
      city: [''],
      neighborhood: [''],
      street: [''],
      houseNumber: [''],
      cns: [''],
      healthPlan: [''],
      healthPlanNumber: [''],
      healthPlanExpirationDate: [''],
    });

    if (this.telemedicineConfig.idHealthIdentification == HealthIdentificationEnum.healthPlan) {
      this.model.get('healthPlanExpirationDate').setValidators([VerifyExpirationDate()])
      this.model.get('healthPlanExpirationDate').updateValueAndValidity();
    }

    this.lookupGender();
    this.populateAdditionalData();
    this.getListState();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.createPatientOnSession();
  }

  createPatientOnSession() {
    this.isLoading = true;
    this.patientData.idHealthUnit = this.telemedicineConfig.idHealthUnit;
    this.patientData.patientName = this.model.get('completeName').value;
    this.patientData.cpf = this.model.get('cpf').value;
    this.patientData.phone = this.model.get('phone').value;
    this.patientData.email = this.model.get('email').value;
    this.patientData.birthDate = (this.formatDateBack(this.model.get('birthDate').value));
    this.patientData.idGender = this.model.get('gender').value;
    this.patientData.neighborhood = this.model.get('neighborhood').value;
    this.patientData.cns = this.model.get('cns').value;
    this.patientData.healthPlan = this.model.get('healthPlan').value;
    this.patientData.healthPlanNumber = this.model.get('healthPlanNumber').value;
    this.patientData.healthPlanExpirationDate = (this.formatDateBack(this.model.get('healthPlanExpirationDate').value));
    this.patientData.state = this.model.get('state').value;
    this.patientData.city = this.model.get('city').value;
    this.patientData.zipCode = this.model.get('zipCode').value;
    this.patientData.street = this.model.get('street').value;
    this.patientData.houseNumber = this.model.get('houseNumber').value ? parseInt(this.model.get('houseNumber').value) : null;
    let PatientDataOld: PatientData = this.utilsTelemedicineService.getPatientData();

    if (PatientDataOld && PatientDataOld.idPatientTelemedicineIntegration) {
      this.patientData.idPatientTelemedicineIntegration = PatientDataOld.idPatientTelemedicineIntegration;
    }

    this.utilService.updatePatientData(this.patientData);
    // this.savedPatientData.emit(true);
    this.next.emit();
  }

  populateAdditionalData() {
    this.model.get('completeName').setValue(this.patient.patientName);
    this.model.get('cpf').setValue(this.patient.cpf);
    this.model.get('phone').setValue(this.patient.phone);
    this.model.get('email').setValue(this.patient.email);
    this.model.get('birthDate').setValue(this.dataAtualFormatada(this.patient.birthDate));
    this.model.get('gender').setValue(this.patient.idGender);
    this.model.get('zipCode').setValue(this.patient.zipCode);
    if (this.patient.zipCode) {
      this.searchByCEP(this.patient.zipCode, true);
    }
    this.model.get('city').setValue(this.patient.city);
    this.model.get('state').setValue(this.patient.state);
    this.model.get('neighborhood').setValue(this.patient.neighborhood);
    this.model.get('street').setValue(this.patient.street);
    this.model.get('houseNumber').setValue(this.patient.houseNumber ? this.patient.houseNumber.toString() : '');
    this.model.get('cns').setValue(this.patient?.cns);

    this.isLoading = false;
  }

  dataAtualFormatada(dateToFormat: Date) {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    }
    return null;
  }

  formatDateBack(dateUnformatted: string) {
    if (dateUnformatted) {
      dateUnformatted = dateUnformatted.replaceAll("/", "");
      let dobj = new Date(parseInt(dateUnformatted.substring(4, 8)), parseInt(dateUnformatted.substring(2, 4)) - 1, parseInt(dateUnformatted.substring(0, 2)));
      return dobj;
    }
    return null;
  }

  lookupGender() {
    this.clientLookupService.getGender().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listGender = response.listGenders;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    const dialogRef = this.dialog.open(CancelModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.utilsTelemedicineService.clearTelemdicineConfig();
        this.utilsTelemedicineService.removeEpisodeData();
        this.utilsTelemedicineService.removePatientData();
        window.location.reload();
      }
    });
  }

  getListState() {
    this.clienthealthUnitStateService.getStates().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listAllStates = response.list;
        this.listFilteredStates = this.model.get('state').valueChanges.pipe(
          startWith(''),
          map(value => this.filterSate(value || '')),
        );
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  filterSate(value: string): HealthUnitStateModel[] {
    const filterValue = value.toLowerCase();

    return this.listAllStates.filter(state => state.stateName.toLowerCase().includes(filterValue));
  }

  getListCitiesByUf(idHealthUnitState: number) {
    this.clienthealthUnitCityService.ListHealthUnitCity(idHealthUnitState).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.model.get('city').enable();
        this.listAllCities = response.list;
        this.mapCities();

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapCities() {
    this.listFilteredCities = this.model.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCity(value || '')),
    );
  }

  filterCity(value: string): HealthUnitCityModel[] {
    const filterValue = value.toLowerCase();

    return this.listAllCities.filter(city => city.cityName.toLowerCase().includes(filterValue));
  }

  clearListCities() {
    if (this.listAllCities.length > 0) {
      this.listAllCities = [];
      this.mapCities();
    }
  }

  private timeoutKeySearch: any = null;

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5 && event.target.value !== '00000000')
        $this.searchByCEP(event.target.value);
    },
      1000);
  }

  searchByCEP(cep: string, hasCep: boolean = false) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        if (response.cep == null) {
          this.isLoadingCep = false;
          this.isLoading = false;
          return this.alertService.show('Alerta', `Não foi encontrado nenhum endereço para o CEP ${cep}.`, AlertType.warning);
        }
        if (hasCep) {
          if (response.localidade)
            this.model.get('city').setValue(response.localidade);
          if (response.uf)
            this.model.get('state').setValue(response.uf);
          if (response.bairro)
            this.model.get('neighborhood').setValue(response.bairro);
          if (response.logradouro)
            this.model.get('street').setValue(response.logradouro);
        } else {
          this.model.get('city').setValue(response.localidade);
          this.model.get('state').setValue(response.uf);
          this.model.get('neighborhood').setValue(response.bairro);
          this.model.get('street').setValue(response.logradouro);
        }
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isLoadingCep = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validateBirthDate(control: FormControl) {
    if (!control.value)
      return null;

    const value = control.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    if (!value)
      return null;

    // Verifica se a data é válida
    const date = new Date(value.split('/').reverse().join('/'));
    if (!isNaN(date.getTime())) {
      // Verifica se a data é menor ou igual à data atual
      if (date <= new Date() && date >= new Date("01/01/1500"))
        return null;
    }

    return { invalidDate: true };
  }

  // validateLittleWalletDate(control: FormControl) {
  //   const value = control.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
  //   if (!value)
  //     return null;
  //   // Verifica se a data é válida
  //   const date = new Date(value.split('/').reverse().join('/'));
  //   if (!isNaN(date.getTime())) {
  //     // Verifica se a data é maior ou igual à data atual
  //     if (date >= new Date())
  //       return null;
  //   }

  //   return { invalidDate: true };
  // }
}