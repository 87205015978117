import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { UserPatientRequest } from '../../requests/patient-center/user-patient.request';
import { CreateUserPatientResponse } from '../../responses/patient-center/create-user-patient.response';
import { ValidateEmailRequest } from '../../requests/patient-center/validate-email.request';
import { GetClientPatientByCpfResponse } from '../../responses/medical-record/client-patient.response';
import { UserPatientResponse } from '../../responses/patient-center/user-patient.response';

@Injectable({
  providedIn: 'root'
})

export class ClientUserPatientService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public CreateUserPatient(request: UserPatientRequest): Observable<CreateUserPatientResponse> {
    let uri = `ClientUserPatient`;

    return this.httpClient.post<CreateUserPatientResponse>(environment.urlApiPatientCenter + uri, request, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getUserPatient(cpf: string, idHealthUnit: number): Observable<GetClientPatientByCpfResponse> {

    let uri = `ClientUserPatient`;
    uri = uri + `/cpf/${cpf}/idHealthUnit/${idHealthUnit}`;

    return this.httpClient.get<GetClientPatientByCpfResponse>(environment.urlApiPatientCenter + uri, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getUserPatientById(idPatientUser: number): Observable<UserPatientResponse> {
    let uri = `ClientUserPatient`;
    uri = uri + `/idPatientUser/${idPatientUser}`;

    return this.httpClient.get<UserPatientResponse>(environment.urlApiPatientCenter + uri, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateBasicInfo(idPatientUser: number, request: UserPatientRequest): Observable<ReturnStruct> {
    let uri = `ClientUserPatient`;
    uri = uri + `/updateBasicInfo/idPatientUser/${idPatientUser}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiPatientCenter + uri, request, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public ValidateEmail(request: ValidateEmailRequest): Observable<ReturnStruct> {
    let uri = `ClientUserPatient/validateEmail`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiPatientCenter + uri, request, this.addHeaderInternalToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}