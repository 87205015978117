import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warn-patient-doctor-evade',
  templateUrl: './warn-patient-doctor-evade.component.html',
  styleUrls: ['./warn-patient-doctor-evade.component.css']
})
export class WarnPatientDoctorEvadeComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<WarnPatientDoctorEvadeComponent>,
  ) { this.matDialogRef.disableClose = true; }

  public seconds: any = 5;
  public isLoading: boolean;
  public colorCode: string;
  public isAttendPatientCenter: boolean = false;

  ngOnInit(): void {
    this.startTime();
    this.colorCode = this.data.colorCode;

    if (this.data.isAttendPatientCenter)
      this.isAttendPatientCenter = this.data.isAttendPatientCenter;
  }

  startTime() {
    setTimeout(() => {
      this.isLoading = true;
      this.matDialogRef.close();
    }, this.seconds * 1000)
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}