<div>
    <app-header></app-header>

    <body class="body-container" [ngStyle]="{'background-color': colorCode}">
        <div class="body-content">

            <div class="white-container">
                <h1 class="self-triage-title">Informe seus dados adicionais</h1>
                <hr />
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('completeName').errors || !model.get('completeName').touched,'outline-spinner form-field-self-triage-error': model.get('completeName').errors && model.get('completeName').touched}">
                                <mat-label class="input-label">Nome completo</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="completeName"
                                    placeholder="Digite seu nome completo" required>
                                <mat-error *ngIf="model.get('completeName').invalid">Informe o nome completo</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('cpf').errors || !model.get('cpf').touched,'outline-spinner form-field-self-triage-error': model.get('cpf').errors && model.get('cpf').touched}">
                                <mat-label class="input-label">CPF</mat-label>
                                <input matInput inputmode="numeric" type="text" formControlName="cpf" [mask]="masks.cpf"
                                    placeholder="Digite seu CPF" required>
                                <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('phone').errors || !model.get('phone').touched,'outline-spinner form-field-self-triage-error': model.get('phone').errors && model.get('phone').touched}">
                                <mat-label class="input-label">Telefone</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="phone"
                                    [mask]="masks.phone" placeholder="(31)99999-9999" required>
                                <mat-error *ngIf="model.get('phone').invalid">Informe o Telefone</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('email').errors || !model.get('email').touched,'outline-spinner form-field-self-triage-error': model.get('email').errors && model.get('email').touched}">
                                <mat-label class="input-label">E-mail</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="email"
                                    placeholder="Digite seu E-mail" required>
                                <mat-error *ngIf="model.get('email').invalid">Informe o e-mail</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('birthDate').errors || !model.get('birthDate').touched,'outline-spinner form-field-self-triage-error': model.get('birthDate').errors && model.get('birthDate').touched}">
                                <mat-label class="input-label">Data de nascimento</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="birthDate"
                                    [mask]="masks.date" placeholder="Digite sua data de nascimento" required>
                                <mat-error *ngIf="model.get('birthDate').invalid">Informe a data de
                                    nascimento</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 div-radio-button">
                            <mat-radio-group class="additional-data-radio-button input-self-triage"
                                formControlName="gender">
                                <mat-label class="input-title">Gênero:</mat-label>
                                <mat-radio-button *ngFor="let item of listGender"
                                    [value]="item.idGender">{{item.genderName}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <mat-form-field appearance="outline" class="outline-spinner"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('zipCode').errors || !model.get('zipCode').touched,'outline-spinner form-field-self-triage-error': model.get('zipCode').errors && model.get('zipCode').touched}">
                                <mat-label class="input-label">CEP</mat-label>
                                <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)"
                                    [mask]="masks.cep">
                                <app-loading-list *ngIf="isLoadingCep"></app-loading-list>
                                <mat-error *ngIf="model.get('zipCode').invalid">CEP inválido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-8">
                            <mat-form-field appearance="outline" [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('street').errors || !model.get('street').touched,'outline-spinner form-field-self-triage-error': model.get('street').errors && model.get('street').touched}">
                                <mat-label class="input-label">Logradouro</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="street"
                                    placeholder="Digite seu logradouro">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-4">
                            <mat-form-field appearance="outline" [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('houseNumber').errors || !model.get('houseNumber').touched,'outline-spinner form-field-self-triage-error': model.get('houseNumber').errors && model.get('houseNumber').touched}">
                                <mat-label class="input-label">Número</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="houseNumber"
                                    placeholder="Digite o número do seu endereço" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-8">
                            <mat-form-field appearance="outline" [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('neighborhood').errors || !model.get('neighborhood').touched,'outline-spinner form-field-self-triage-error': model.get('neighborhood').errors && model.get('neighborhood').touched}">
                                <mat-label class="input-label">Bairro</mat-label>
                                <input matInput inputmode="text" type="text" formControlName="neighborhood"
                                    placeholder="Digite seu bairro">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('city').errors || !model.get('city').touched,'outline-spinner form-field-self-triage-error': model.get('city').errors && model.get('city').touched}">
                                <mat-label class="input-label">Cidade</mat-label>
                                <input matInput type="text" formControlName="city" [matAutocomplete]="autoCity">
                                <mat-autocomplete #autoCity="matAutocomplete" >
                                    <mat-option *ngFor="let item of (listFilteredCities | async)" value="{{item.cityName}}">{{item.cityName}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6">
                            <mat-form-field appearance="outline"
                                [ngClass]="{ 'outline-spinner form-field-self-triage': !model.get('state').errors || !model.get('state').touched,'outline-spinner form-field-self-triage-error': model.get('state').errors && model.get('state').touched}">
                                <mat-label class="input-label">Estado</mat-label>
                                <input matInput type="text" formControlName="state" [matAutocomplete]="autoState" (keyup)="clearListCities()">
                                <mat-autocomplete #autoState="matAutocomplete" >
                                    <mat-option *ngFor="let item of (listFilteredStates | async)" value="{{item.stateName}}" (click)="getListCitiesByUf(item.idState)">{{item.stateName}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>

                    <button mat-flat-button color="primary" class="button-go" [disabled]="!this.model.valid">
                        <span *ngIf="isLoading == false">Prosseguir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </form>

                <div class="footer-white-container">
                    <p [ngStyle]="{'color': colorCode}">Autotriagem by ToLife®</p>
                </div>
            </div>
            <app-pagination [screenStage]="this.thisScreen"></app-pagination>

        </div>
    </body>
</div>