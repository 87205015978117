import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageCounselingModel } from 'src/app/shared/services/models/self-triage/self-triage-counseling.model';
import { AnswerStruct } from 'src/app/shared/services/structs/self-triage/answer.struct';
import { FlowchartCompleteStruct } from 'src/app/shared/services/structs/self-triage/flowchart-complete.struct';
import { ProtocolCompleteStruct } from 'src/app/shared/services/structs/self-triage/protocol-complete.struct';
import { QuestionStruct } from 'src/app/shared/services/structs/self-triage/question.struct';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    ) { }
    
    @Input() currentQuestion: QuestionStruct;
    @Input() flowchartName: string;
    @Input() colorCode: string;
    @Output() selectedQuestionFunction: EventEmitter<{ question: QuestionStruct, answer: AnswerStruct }> = new EventEmitter<{ question: QuestionStruct, answer: AnswerStruct }>();
    @Output() goBack: EventEmitter<QuestionStruct> = new EventEmitter<QuestionStruct>();
    @Output() selectCounseling: EventEmitter<{ counseling: SelfTriageCounselingModel, answer: AnswerStruct }> = new EventEmitter<{ counseling: SelfTriageCounselingModel, answer: AnswerStruct }>();
    public model: UntypedFormGroup;
    public isSelectCounseling: boolean = false;
    public isLoading: boolean = false;
    public selectedQuestion: QuestionStruct;
    public selectedAnswer: AnswerStruct;
    public selectedCounseling: SelfTriageCounselingModel;

    ngOnInit(): void {
      this.model = this.formBuilder.group({
        selectedChoice: ['', [Validators.required]],
      });
    }
    
    btnBack() {
      this.goBack.emit();
    }
    
    submit(){
      if(this.isSelectCounseling){
        this.isLoading = true;
        this.selectCounseling.emit({counseling: this.selectedCounseling, answer: this.selectedAnswer});
        this.model.get('selectedChoice').setValue('');
      }
      else{
        this.selectedQuestionFunction.emit({question: this.selectedQuestion, answer: this.selectedAnswer});
        this.model.get('selectedChoice').setValue('');
      }
    }
    
    selection(selectedAnswer: AnswerStruct) {
      if (selectedAnswer.questionChild && selectedAnswer.questionChild.answerStructs && selectedAnswer.questionChild.answerStructs.length > 0) {
        this.selectedQuestion = selectedAnswer.questionChild;
        this.selectedAnswer = selectedAnswer;   
      } 
      else if (selectedAnswer.counseling) {
        this.selectedCounseling = selectedAnswer.counseling;
        this.selectedAnswer = selectedAnswer; 
        this.isSelectCounseling = true; 
      } 
      else {
        this.alertService.show("Erro", "Essa reposta não foi configurada corretamente", AlertType.error);
      }
    }
  }
  