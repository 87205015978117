import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerifyMinDate } from 'src/app/shared/custom-validators/date.validator';
import { PatientHealthcareAgreementPatientCenterService } from 'src/app/shared/services/API/patient-center/patient-healthcare-agreement-patient-center.service';
import { HealthcareAgreementPlanService } from 'src/app/shared/services/API/private-billing/healthcare-agreement-plan.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { PatientHealthcareAgreementRequest } from 'src/app/shared/services/requests/patient-center/patient-healthcare-agreement.request';
import { PatientHealthcareAgreementStruct } from 'src/app/shared/services/structs/orchestrator-schedule/patient-healthcare-agreement.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';

@Component({
  selector: 'app-update-healthcare-agreement-modal',
  templateUrl: './update-healthcare-agreement-modal.component.html',
  styleUrls: ['./update-healthcare-agreement-modal.component.css']
})
export class UpdateHealthcareAgreementModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<UpdateHealthcareAgreementModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private patientHealthcareAgreementPatientCenterService: PatientHealthcareAgreementPatientCenterService,
    private healthcareAgreementPlanService: HealthcareAgreementPlanService,
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public patientHealthcareAgreement: PatientHealthcareAgreementStruct;
  public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listHealthcareAgreementPlan: Plan[] = [];
  
  public isLoading: boolean = true;
  public firstLoading: boolean = true;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.patientHealthcareAgreement = this.data.patientHealthcareAgreement;
    this.getListHealthcareAgreement();
    this.getPlans(this.patientHealthcareAgreement.idHealthcareAgreement);

    this.isLoading = false;
    if(this.data.color)
      document.documentElement.style.setProperty('--color', this.data.color);

    this.model = this.formBuilder.group({
      healthcareAgreement: ['', [Validators.required]],
      healthcareAgreementPlan: [{value: '', disabled: true}, [Validators.required]],
      healthcareAgreementCardNumber: ['', [Validators.required]],
      healthcareAgreementExpirationDate: ['', [Validators.required, VerifyMinDate()]],
    });
    this.populateForm();
  }

  submit(){
    if (this.isLoading){
      return;
    }
      
    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    let request = new PatientHealthcareAgreementRequest();
    request.idPatientHealthcareAgreement = this.patientHealthcareAgreement.idPatientHealthcareAgreement;
    request.idHealthcareAgreement = this.model.get('healthcareAgreement').value;
    request.idHealthcareAgreementPlan = this.model.get('healthcareAgreementPlan').value;
    request.healthcareAgreementCardNumber = this.model.get('healthcareAgreementCardNumber').value;
    request.healthcareAgreementExpirationDate = this.maskService.formatStringToDate(this.model.get('healthcareAgreementExpirationDate').value);

    this.patientHealthcareAgreementPatientCenterService.UpdatePatientHealthcareAgreement(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Convênio alterado com sucesso!", AlertType.success); 
        this.isLoading = false;
        this.matDialogRef.close({updateItem: true}); 
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    })
  }

  populateForm() {
    this.model.get('healthcareAgreement').setValue(this.patientHealthcareAgreement.idHealthcareAgreement);
    this.model.get('healthcareAgreementPlan').setValue(this.patientHealthcareAgreement.idHealthcareAgreementPlan);
    this.model.get('healthcareAgreementCardNumber').setValue(this.patientHealthcareAgreement.healthcareAgreementCardNumber);
    this.model.get('healthcareAgreementExpirationDate').setValue(this.maskService.formatDateToString(this.patientHealthcareAgreement.healthcareAgreementExpirationDate, false));
  }

  close(){
    this.matDialogRef.close({updateItem: false}); 
  }

  clickCancel(){
    this.matDialogRef.close({updateItem: false}); 
  }

  getListHealthcareAgreement() {
    if(!this.data.listHealthcareAgreement)
      return;

    this.listHealthcareAgreement = this.data.listHealthcareAgreement.filter(item => item.isActive);
    let healthcareAgreementSelected: HealthcareAgreementStruct = this.data.listHealthcareAgreement.find(item => this.patientHealthcareAgreement.idHealthcareAgreement == item.idHealthcareAgreement && !item.isActive);
    if(healthcareAgreementSelected)
      this.listHealthcareAgreement.push(healthcareAgreementSelected);
  }

  getPlans(idHealthcareAgreement: number) {
    this.isLoading = true;
    this.listHealthcareAgreementPlan = [];

    this.healthcareAgreementPlanService.GetAllToSelectById(idHealthcareAgreement, null, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listHealthcareAgreementPlan = response.listPlan.filter(item => !item.isDeleted);

        if(this.firstLoading) {
          let healthcareAgreementPlanSelected: Plan = response.listPlan.find(item => this.patientHealthcareAgreement.idHealthcareAgreementPlan == item.idPlan && item.isDeleted);
          if(healthcareAgreementPlanSelected)
            this.listHealthcareAgreementPlan.push(healthcareAgreementPlanSelected);
        }
        else 
          this.model.get('healthcareAgreementPlan').setValue(null);

        if(this.listHealthcareAgreementPlan?.length > 0)
          this.model.get('healthcareAgreementPlan').enable();
        this.firstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
