import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketTelemedicineService } from '../websocket-telemedicine.service';
import { EpisodeData } from 'src/app/telemedicine/utils.service';
import { WebsocketTelemedicineActionEnum } from '../../components/enum/websocket-telemedicine-action.enum';
import { AlertService, AlertType } from '../alert.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketTelemedicineUtilService {

    constructor(private websocketTelemedicineService: WebsocketTelemedicineService, private alertService: AlertService) { }
    socket: Socket;
    idEpisode: number;
    waitingTime: number;
    onClientTelemedicine: Function;
    episodeData: EpisodeData;
    context: any;
    lastActtion: number;
    public time: string = null;

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    setEpisodeData(episodeData: EpisodeData) {
        this.episodeData = episodeData;
    }

    blankFunction() {
    }

    setFunctions(onClientTelemedicine: Function) {
        this.onClientTelemedicine = onClientTelemedicine ? onClientTelemedicine : this.blankFunction;
    }

    setContext(thisParam: any) {
        this.context = thisParam;
    }

    emitAction(action: number, idEpisode: number) {
        let request = {
            action: action,
            idEpisode: idEpisode,
        }
        this.lastActtion = action;
        this.socket.emit('action', request);
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
        this.setFunctions(null);
    }

    emitStartCount() {
        this.lastActtion = WebsocketTelemedicineActionEnum.startCountdown;
        this.socket.emit('startCount', {
            room: `idEpisode-${this.idEpisode}`,
            seconds: this.waitingTime
        });
    }

    connectwebsocketRoom(idEpisode: number, waitingTime: number, context: any): void {
        this.lastActtion = null;
        this.idEpisode = idEpisode;
        this.waitingTime = waitingTime;
        this.context = context;
        this.socket = this.websocketTelemedicineService.websocketConnection();
        this.socket.emit('join', { room: `idEpisode-${idEpisode}`, medicSide: false });
        this.socket.on('clientTelemedicine', (res) => {
            this.lastActtion = res.action;
            this.time = null;
            this.onClientTelemedicine.call(this.context, res);
        });

        this.socket.on('countReady', () => {
            this.time = null;
            this.onClientTelemedicine.call(this.context, { action: WebsocketTelemedicineActionEnum.startCountdown });
        });

        this.socket.on('someoneDisconnected', () => {
            this.time = null;
            if (this.lastActtion && ![
                WebsocketTelemedicineActionEnum.medicEvasion,
                WebsocketTelemedicineActionEnum.confirmAttend,
                WebsocketTelemedicineActionEnum.patientInternetDown,
                WebsocketTelemedicineActionEnum.forward,
                WebsocketTelemedicineActionEnum.endMedicalCare
            ].includes(this.lastActtion)) {
                setTimeout(() => {
                    this.socket.emit('verifying', `idEpisode-${idEpisode}`);
                }, 20000);
                this.alertService.show("Aviso", "O profissional está com instabilidade, aguarde alguns segundos!", AlertType.warning);
            }
        });

        this.socket.on('personsInRoom', (res) => {
            this.time = null;
            if (Number.isFinite(res) && res === 1) {
                this.onClientTelemedicine.call(this.context, { action: WebsocketTelemedicineActionEnum.medicEvasion });
            }
        });

        this.socket.on('timeExpired', () => {
            this.onClientTelemedicine.call(this.context, { action: WebsocketTelemedicineActionEnum.timeExpired });
        });

        this.socket.on('countSeconds', (seconds) => {
            this.time = seconds;
        });

        this.socket.on('reconnect', () => {
            this.time = null;
            this.alertService.show("Aviso", "Houve uma instabilidade, aguarde alguns segundos!", AlertType.warning);
            this.socket.emit('join', { room: `idEpisode-${idEpisode}`, medicSide: false });
        });
    }
}